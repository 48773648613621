import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import styles from '../SCSS/VideoPlayer.module.scss';

interface Props {
  isShow: boolean;
  onHide: () => void;
  videoUrl: string;
  videoTitle: string;
}

export class VideoPlayer extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleHide = this.handleHide.bind(this);
  }

  handleHide() {
    this.props.onHide();
  }

  render() {
    return (
      <Modal
        dialogClassName={styles['modal-dialog']}
        className={styles.modal}
        show={this.props.isShow}
        onHide={this.handleHide}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles['modal-title']}>{this.props.videoTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            title='Video'
            src={`${this.props.videoUrl}?autoplay=1&showinfo=0`}
            allow='autoplay'
            frameBorder='0'
            allowFullScreen
          />
        </Modal.Body>
      </Modal>
    );
  }
}
