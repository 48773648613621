import { Component } from 'react';
import cx from 'classnames';
import { FormGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { Segment, VerticalSpacer } from '../core/core';
import Documents from '../Documents';
import { alreadyHighestLicense, canUpgradeToPro, getLicenseLevel } from '../GlobalFunctions';
import { OpenLockIcon } from '../OpenLockIcon';
import styles from '../SCSS/Upgrade.module.scss';
import utilityStyles from '../SCSS/common/utilityStyles.module.scss';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import TierPriceDisplay, { getTierName, getTierOffering } from '../components/TierPriceDisplay/TierPriceDisplay';
import TextButton from '../components/TextButton/TextButton';
import { showIntercomNewMessage } from '../IntercomUtilityFunctions';
import { History } from 'history';
import { UserProfileType } from '../../peekapak-types/DataProtocolTypes';

interface Props {
  stepNumber: number;
  onNextStep: (currentStep: number, isStepValid: boolean) => boolean;
  usersProfile: UserProfileType | null;
  tier: string;
  history: History;
}

class _UpgradeStep1 extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleOnNextStep = this.handleOnNextStep.bind(this);
  }

  handleOnNextStep(event: React.SyntheticEvent) {
    event.preventDefault();
    this.props.onNextStep(this.props.stepNumber, true);
  }

  render() {
    const usersCurrentLicenseLevel = getLicenseLevel();
    if (usersCurrentLicenseLevel === null) {
      throw new Error('getLicenseLevel: user is not logged in');
    }
    if (usersCurrentLicenseLevel === undefined) {
      throw new Error('getLicenseLevel: is undefined');
    }

    if (alreadyHighestLicense(usersCurrentLicenseLevel)) {
      return (
        <Segment className={styles.upgradeSegment}>
          <VerticalSpacer ems={1} />
          <div className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}>
            <OpenLockIcon />
            <VerticalSpacer ems={1} />
            <div className={styles.title}>Peekapak’s PRO Memberships</div>
            <VerticalSpacer ems={1} />
            <div className={styles.subtitle}>
              Congratulations, you&apos;re already a subscribed member of Peekapak at the Peekapak PRO level.
            </div>
          </div>
          <VerticalSpacer ems={1} />
          <div className={utilityStyles.absCentre}>
            <PrimaryButton onClick={() => this.props.history.push('/library')} autoFocus>
              Go to Library
            </PrimaryButton>
          </div>
          <VerticalSpacer ems={1} />
        </Segment>
      );
    } else if (!canUpgradeToPro(usersCurrentLicenseLevel)) {
      return (
        <Segment className={styles.upgradeSegment}>
          <VerticalSpacer ems={1} />
          <div className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}>
            <OpenLockIcon />
            <VerticalSpacer ems={1} />
            <div className={styles.title}>Peekapak’s PRO Memberships</div>
            <VerticalSpacer ems={1} />
            <div className={styles.subtitle}>
              Congratulations, you&apos;re already a subscribed member of Peekapak at the {getTierName(this.props.tier)}{' '}
              level!
            </div>
          </div>
          <VerticalSpacer ems={1} />
          <div className={cx(utilityStyles.absCentre, styles.upgradeButtonGroup)}>
            <PrimaryButton onClick={() => showIntercomNewMessage(`I'd like to upgrade to Peekapak PRO`)} autoFocus>
              Upgrade to Peekapak PRO
            </PrimaryButton>
            <PrimaryButton onClick={() => this.props.history.push('/library')}>Go back to Library</PrimaryButton>
          </div>
          <VerticalSpacer ems={1} />
        </Segment>
      );
    }

    return (
      <Segment className={styles.upgradeSegment}>
        <VerticalSpacer ems={1} />
        <div className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}>
          <OpenLockIcon />
          <div className={styles.title}>Subscribe to a {getTierName(this.props.tier)} Membership</div>
          <VerticalSpacer ems={1} />
          <div className={styles.subtitle}>
            <p>
              Upgrade for unlimited access to {getTierOffering(this.props.tier)}. Learn about the benefits of upgrading
              on our{' '}
              <Link className={styles.textLink} to='/features'>
                features page
              </Link>
            </p>
            <p>
              For schools and districts, request a custom quote{' '}
              <a className={styles.textLink} href={`${Documents.requestInfoAndQuote}`} rel='noreferrer noopener'>
                here
              </a>
              .
            </p>
          </div>
        </div>
        <VerticalSpacer ems={1} />
        <div className={styles.theForm}>
          <form>
            <TierPriceDisplay tier={this.props.tier} />
            <FormGroup>
              <VerticalSpacer ems={1} />
              <div className={utilityStyles.absCentre}>
                <>
                  <PrimaryButton onClick={() => this.props.onNextStep(this.props.stepNumber, true)}>
                    Purchase with Credit Card
                  </PrimaryButton>
                  <VerticalSpacer ems={1} />
                  <p className={styles.billingInformation}>
                    Your next automatic billing date will be one year from today’s date. You may manage your billing
                    details in your account settings. If you purchase, our secure payment provider, Stripe, Inc., will
                    collect and store your credit card information. We ourselves will not store this information. You
                    may review{' '}
                    <span
                      className={styles.textLink}
                      onClick={() => window.open('https://stripe.com/privacy', '_blank')}
                    >
                      Stripe&apos;s privacy policy,
                    </span>{' '}
                    review{' '}
                    <span
                      className={styles.textLink}
                      onClick={() => window.open('https://www.peekapak.com/privacyPolicy', '_blank')}
                    >
                      Peekapak&apos;s privacy policy
                    </span>{' '}
                    or if you have questions, please{' '}
                    <span
                      className={styles.textLink}
                      onClick={() => showIntercomNewMessage('I need help with upgrading')}
                    >
                      get in touch.
                    </span>
                  </p>
                </>
                <div>
                  <TextButton blue underlineHover onClick={() => this.props.history.go(-1)}>
                    No thanks
                  </TextButton>
                </div>
                <div>
                  <p className={styles.billingInformation}>
                    Need to purchase using a purchase order, or want more information?
                  </p>
                  <TextButton
                    blue
                    underlineHover
                    onClick={() =>
                      showIntercomNewMessage(
                        `I'd like to get an invoice for a purchase order, please. Here's my information: `,
                      )
                    }
                  >
                    Request Invoice for a Purchase Order
                  </TextButton>
                </div>
              </div>
            </FormGroup>
          </form>
        </div>
        <VerticalSpacer ems={1} />
      </Segment>
    );
  }
}

export function UpgradeStep1(props: Props) {
  const history = useHistory();
  return <_UpgradeStep1 {...props} history={history} />;
}
