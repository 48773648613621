import { useEffect } from 'react';
import { Route, Router, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import SignUp from './SignUp';
import SlideShow from './SlideShow';
import { NotFoundSoRedirect } from './NotFoundSoRedirect';
import { myBrowserHistory } from './ApplicationState';
import { ErrorBoundary } from './components/ErrorBoundary';
import Logout from './Logout';
import UnauthenticatedLayout from './UnauthenticatedLayout';
import AuthenticatedRoute from './AuthenticatedRoute';
import MainLayout from './MainLayout';
import MiddleSchoolSlideShow from './MiddleSchoolSlideShow';
import CookieConsent from './components/CookieConsent';

// code split and load dynamically
// - seems to be unstable; will result in errors in an unpredictable way
//
// const UnauthenticatedLayout = lazy(() => import('./UnauthenticatedLayout.tsx'));
// const AuthenticatedRoute = lazy(() => import('./AuthenticatedRoute.tsx'));
// const MainLayout = lazy(() => import('./MainLayout.tsx'));
// const MiddleSchoolSlideShow = lazy(() =>
//   import('./MiddleSchoolSlideShow/MiddleSchoolSlideShow.tsx')
// );

const Root = () => {
  return (
    // TODO: used to have onRouteUpdate that would handle scrolling to an ID
    // anchor referenced by a hash sign
    <Router history={myBrowserHistory}>
      <ErrorBoundary enabled>
        <ScrollToAnchorOrTop />
        <RedirectMiddleSchoolToSecondary />
        <CookieConsent />
        <Switch>
          <Route
            exact
            path={[
              '/',
              '/elementary',
              '/secondary',
              '/highSchool',
              '/parents',
              '/parents/extending',
              '/parents/homeSchooling',
              '/about',
              '/districtLead',
              '/myPeekaville',
              '/product',
              '/(remote|covid)',
              '/features',
              '/termsOfUse',
              '/privacyPolicy',
              '/resources',
              '/pricing',
              '/trial',
              '/elementaryTrial',
              '/middleSchoolTrial',
              '/highSchoolTrial',
              '/registrationConfirmation',
              '/requestInfo',
              '/requestInfoSuccess',
              '/newPasswordConfirmation',
              '/resendNewPasswordConfirmation',
              '/passwordReset',
              '/resetConfirmation',
              '/passwordResetRedirect',
              '/sessionExpired',
              '/unauthorized',
              '/parentalInviteAcknowledge',
              '/parentDecline',
              '/parentOptOut',
              '/waitlist',
              '/membersOnlyRedirect',
              '/forStudents',
              '/storybookForParents',
              '/researchElementary',
              '/researchMiddleSchool',
              '/researchHighSchool',
              '/press',
              '/familyWellBeing',
              '/employeeWellBeing',
              '/successStories/:successStory',
              '/whatIsSEL',
              '/webinars',
              '/funding',
              '/grantToolkit',
              '/successStories',
              '/designPrinciples',
              '/optinSuccess',
              '/professionalDevelopment',
              '/whoItsFor',
              '/educators',
              '/counselors',
              '/careers',
              '/contact',
              '/texas-SEL',
              '/california-SEL',
              '/oklahoma-SEL',
              '/libraryEdition',
              '/techSpecs',
            ]}
          >
            <UnauthenticatedLayout />
          </Route>
          <Route
            path={['/oauth/login', '/oauth/preauth/:providerId', '/preauth/:providerId', '/oauth/secondary/Clever']}
          >
            <UnauthenticatedLayout noHeader />
          </Route>
          <Route path={'/redirect/:origin'}>
            <UnauthenticatedLayout noFooter />
          </Route>
          <Route path={'/login'}>
            <UnauthenticatedLayout headerBgColor='transparent' noFooter />
          </Route>
          <Route path='/signup'>
            <SignUp />
          </Route>
          <Route path='/logout'>
            <Logout />
          </Route>
          <AuthenticatedRoute path='/:classroomName/lessonPlan/slides/:id'>
            <SlideShow />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            path={[
              '/middleSchoolSlides/:id/:language/:grade/content/:contentId',
              '/middleSchoolSlides/:id/:language/:grade',
            ]}
          >
            <MiddleSchoolSlideShow />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            path={[
              '/highSchoolSlides/:id/:language/:grade/content/:contentId',
              '/highSchoolSlides/:id/:language/:grade',
            ]}
          >
            <MiddleSchoolSlideShow />
          </AuthenticatedRoute>
          <Route
            path={[
              '/library',
              '/students',
              '/myPeekavilleUnits',
              '/connectParents',
              '/classroom/rostering/:providerId/:invokeSource',
              '/classroom/rostering/:providerId',
              '/classroom',
              '/reports',
              '/moreInformation/school',
              '/subscribe/:tier',
              '/redeemCode',
              '/activateTrial',
              '/schoolEditor',
              '/schoolEditor/new',
              '/notifications',
              '/studentProfile/:studentID',
              '/accountSettings',
              '/gallery',
              '/videos',
              '/:classroomName/lessonPlan/readAloud/:unitId/:subunitId/Subunit:subunitNumber/Lesson:lessonNumber/Step:stepNumber',
              '/:classroomName/lessonPlan/:unitId/:subunitId/Subunit:subunitNumber/Lesson:lessonNumber/Step:stepNumber',
              '/:classroomName/lessonPlan/:unitId',
              '/lessonPlan/:unitId/:language/:grade',
              '/lessonPlan/:unitId/:language/:grade/lesson/:lesson/activity/:activity',
            ]}
          >
            <MainLayout withHeader withFooter withBanner />
          </Route>
          <Route
            path={[
              '/studentportal/toDos',
              '/studentportal/accountSettings',
              '/studentportal/checkIn',
              '/studentportal/strengths',
              '/studentportal/activity/:activity/:language/:grade/toDo/:toDo/confirmation',
              '/studentportal/activity/:activity/:language/:grade/toDo/:toDo',

              '/studentportal/activity/:activity/:language/:grade/toDo/:toDo/review',
            ]}
          >
            <MainLayout />
          </Route>
          <Route path={['/version', '/story/:language/:storyId/pg:page']}>
            <MainLayout withFooter />
          </Route>
          <Route path='/story/:storyId/pg:page'>
            <MainLayout withFooter />
          </Route>
          <Route path='/story/:language/:storyId'>
            <MainLayout withFooter />
          </Route>
          <Route path='/story/:storyId'>
            <MainLayout withFooter />
          </Route>
          <Route exact path={['/play/login', '/play/game']}>
            {() => {
              if (import.meta.env.MODE === 'development') {
                return (window.location.href = 'https://test.peekapak.com/play/index.htm');
              } else if (import.meta.env.MODE === 'production') {
                return (window.location.href = 'https://peekapak.com/play/index.htm');
              }
            }}
          </Route>
          <Route path='*'>
            <NotFoundSoRedirect />
          </Route>
        </Switch>
      </ErrorBoundary>
    </Router>
  );
};

function ScrollToAnchorOrTop() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (history.action !== 'POP') {
      if (location.hash) {
        setTimeout(() => {
          const id = location.hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) element.scrollIntoView();
        }, 0);

        return;
      } else {
        window.scrollTo(0, 0);
      }
    }
  }, [history, location]);

  return null;
}

function RedirectMiddleSchoolToSecondary() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/middleSchool' || location.pathname === '/middleschool') {
      return history.replace('/secondary');
    }
  }, [history, location]);

  return null;
}

export default Root;
