import { useHistory } from 'react-router-dom';
import MyHelmet from '../../MyHelmet';
import Banner from '../../components/HeroBanner';
import HeroLanding, { HeroSubtitle, HeroTitle } from '../../anonymous/HeroLanding';
import HighlightsSegment from '../../anonymous/HighlightsSegment';
import AwardSection from '../../components/AwardSection';
import IntegrationPartners from '../IntegrationPartners';
import SelPlanSegment from '../../anonymous/SelPlanSegment';
import { ReasonsGrid } from '../../anonymous/ReasonsSegment';
import ExpertsSegment from '../../anonymous/ExpertsSegment';
import PeekapakInAction from '../PeekapakInAction';
import TestimonialsSegment from '../../anonymous/TestimonialsSegment';
import CTASegment, { OpenCalendlyCTA } from '../../components/CTASegment';
import CTAButton from '../../components/CTAButton';
import heroImage from '../../images/hero/hero-homepage.png';
import homeStyles from '../../SCSS/Home.module.scss';

const heroImageSettings = {
  image: heroImage,
  backgroundPositions: {
    mobile: 'top 0 left 60%',
    ipad: 'top 0 left 0',
    ipadpro: 'top 0 left 0',
    mdpi: 'top 25% left 75%',
  },
  gradient: `rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)`,
};

const roleCards = [
  {
    title: 'Elementary (PreK-Gr. 5)',
    description: `Features levelled readers and fully-planned learning activities to help students develop SEL while also building reading and writing skills.`,
    url: '/elementary',
  },
  {
    title: 'Secondary (Gr. 6-12)',
    description: `Peekapak’s ready-to-teach units, lessons and human-interest stories help support both SEL & ELA development.`,
    url: '/secondary',
  },
  {
    title: 'Administrators',
    description: `Empower educators, engage students and connect parents, while collecting insights and real-time reports on your SEL initiative.`,
    url: '/districtLead',
  },
];

const Home = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className={homeStyles.anonContainer}>
      <MyHelmet>
        <meta name='keywords' content='Social Emotional Learning Activities' />
        <title>Peekapak Social Emotional Learning Curriculum for K-12</title>
        <meta
          name='description'
          content={`Peekapak infuses social emotional learning & well-being in K-12 classrooms. We integrate into literacy & ELA curriculums to captivate students with engaging, narrative-based content.`}
        />
      </MyHelmet>
      <Banner history={history} />
      <HeroLanding heroImage={heroImageSettings} history={history} roleCards={roleCards}>
        <HeroTitle>Meet Academic Standards While Teaching Social Emotional Learning</HeroTitle>
        <HeroSubtitle>
          A narrative based learning experience empowering educators to facilitate engaging social emotional learning in
          the classroom, students to take ownership of their learning, and families to reinforce SEL at home.
        </HeroSubtitle>
      </HeroLanding>
      <HighlightsSegment />
      <AwardSection title='Award Winning Learning' />
      <IntegrationPartners />
      <SelPlanSegment />
      <ReasonsGrid />
      <ExpertsSegment />
      <PeekapakInAction clickHandler={() => history.push('/successStories')} />
      <TestimonialsSegment />
      <CTASegment>
        <OpenCalendlyCTA />
        <CTAButton secondary onClick={() => history.push('/signup')}>
          explore
        </CTAButton>
      </CTASegment>
    </div>
  );
};

export default Home;
