import { useCallback, useEffect, useState } from 'react';
import YesNoDialog from '../YesNoDialog/YesNoDialog';
import styles from './CookieConsent.module.scss';
import ExpandableContentCard from '../ExpandableContentCard';
import ToggleSlider from '../ToggleSlider';
import { getCookie, setCookie } from '../../aaa';

interface SettingsDatum {
  id: string;
  label: string;
  description: string;
  allowToggle: boolean;
  allowCookie: boolean;
}

const referenceSettingsData: SettingsDatum[] = [
  {
    id: 'required',
    label: 'Functional cookies',
    description: `Functional cookies, such as remembering your location, or remembering \
the status of your consent to the use of cookies, are required for the site to function properly.`,
    allowToggle: false,
    allowCookie: true,
  },
  {
    id: 'analytics',
    label: 'Analytics',
    description:
      'General usage analytics to help us improve the site and provide better content, e.g. Google Analytics.',
    allowToggle: true,
    allowCookie: true,
  },
];

export const CookieConsent = (): JSX.Element => {
  const [isShowModal, setIsShowModal] = useState<boolean>(true);
  const [settingsData, setSettingsData] = useState<SettingsDatum[]>(referenceSettingsData);
  const [page, setPage] = useState<'consent' | 'configure'>('consent');

  useEffect(() => {
    const cookieConsentInformation = getCookie('peekapak.cookieConsentInformation');
    if (cookieConsentInformation) {
      const parsedCookieConsentInformation = JSON.parse(cookieConsentInformation);
      setSettingsData(parsedCookieConsentInformation.settings);
      setIsShowModal(false);
    }
  }, []);

  if (import.meta.env.MODE === 'development') {
    return <div style={{ display: 'none' }} />;
  }

  if (page === 'configure') {
    return (
      <YesNoDialog
        isShow={isShowModal}
        onHide={() => setPage('consent')}
        cancelButtonLabel='Back'
        continueButtonLabel='Save'
        title='Cookie Settings'
        onYesResponse={handleOnAgree}
        onNoResponse={() => setPage('consent')}
      >
        <div className={styles.settingsContainer}>
          {settingsData.map((setting) => (
            <div key={setting.id} className={styles.settingsRow}>
              <ExpandableContentCard
                key={`setting ${setting.id}`}
                expandable={true}
                expanded={false}
                title={setting.label}
                content={setting.description}
              />
              {renderToggleButton(setting)}
            </div>
          ))}
        </div>
      </YesNoDialog>
    );
  }
  return (
    <>
      <YesNoDialog
        cancelButtonLabel='Customize'
        continueButtonLabel='I agree'
        onYesResponse={handleOnAgree}
        onNoResponse={handleOnCustomize}
        onHide={handleOnCancel}
        isShow={isShowModal}
        title='Do you agree to the use of cookies?'
        enableCloseButton={false}
      >
        <p className={styles.subtitle}>
          We use cookies to store your preferences and other information to customize your experience on our website,
          such as a cookie to remember your location in order to provide you with content and materials that are
          specific to where you are located.
        </p>
        <p className={styles.subtitle}>
          For subscribers, we also use cookies to collect usage analytics in order to provide school- and
          district-oriented reports to educators and administrators. We do not serve ads, so we do not use cookies to
          track your activity for targeted advertising.
        </p>
        <p className={styles.subtitle}>By clicking &ldquo;I agree&rdquo; you consent to the use of all cookies.</p>
      </YesNoDialog>
    </>
  );

  function renderToggleButton(setting: SettingsDatum) {
    if (!setting.allowToggle) {
      return <span className={styles.requiredText}>REQUIRED</span>;
    }

    return (
      <span className={styles.toggle}>
        <ToggleSlider
          isChecked={setting.allowCookie}
          onToggle={() => {
            setSettingsData((prevData) =>
              prevData.map((data) => {
                if (data.id === setting.id) {
                  return { ...data, allowCookie: !data.allowCookie };
                }
                return data;
              }),
            );
          }}
          onBackgroundColor='#00ca48'
          offBackgroundColor='#a5a5a5'
          switchColor='#fff'
        />
      </span>
    );
  }

  function handleOnCustomize() {
    setPage('configure');
  }

  function handleOnAgree() {
    const cookieConsentInformation = {
      agree: true,
      timestamp: new Date().toISOString(),
      settings: settingsData,
    };

    setCookie('peekapak.cookieConsentInformation', JSON.stringify(cookieConsentInformation), 182);
    setIsShowModal(false);

    window.location.reload();
  }

  function handleOnCancel() {
    setIsShowModal(false);
  }
};

export default CookieConsent;
