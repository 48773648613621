import { useEffect, useState } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { getCookie, setCookie } from '../../aaa';
import { Param, landingPageParams } from './tables';

interface Props {
  children: JSX.Element;
}

const LandingPageRoute = ({ children }: Props): JSX.Element => {
  const [visitorCountry, setVisitorCountry] = useState('');
  const [visitorRegion, setVisitorRegion] = useState('');

  const location = useLocation();
  const history = useHistory();

  const getVisitorOriginBasedOnIP = async () => {
    try {
      const result = await axios.get('https://api.peekapak.com/commonservicesdev/users/get-country-origin');
      const persist = { ...result.data, createdAt: Date.now() };
      setCookie('peekapak.visitorOrigin', JSON.stringify(persist));
      return result.data;
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
    }
  };

  useEffect(() => {
    const checkVisitorOrigin = async () => {
      const existingRecordJSON = getCookie('peekapak.visitorOrigin');

      if (existingRecordJSON) {
        const existingRecord = JSON.parse(existingRecordJSON);
        if (Date.now() - existingRecord.createdAt < 1000 * 3600 * 24) {
          setVisitorCountry(existingRecord.country);
          setVisitorRegion(existingRecord.region);
        }
      } else {
        const origin = await getVisitorOriginBasedOnIP();
        setVisitorCountry(origin.country);
        setVisitorRegion(origin.region);
      }
    };
    checkVisitorOrigin();
  }, []);

  useEffect(() => {
    let param;
    if (landingPageParams[`${visitorCountry}`]) {
      if (landingPageParams[`${visitorCountry}`][`${visitorRegion}`]) {
        param = (landingPageParams[`${visitorCountry}`][`${visitorRegion}`] as Param).param;
      } else {
        param = landingPageParams[`${visitorCountry}`].param;
      }
    } else {
      param = null;
    }

    if (!param) {
      return;
    } else if (param && location.search && location.search !== param) {
      return history.replace({
        pathname: '/',
        search: location.search,
      });
    } else {
      return history.replace({
        pathname: '/',
        search: param as string,
      });
    }
  }, [location.search, visitorCountry, visitorRegion]);

  return (
    <Route
      path='/'
      render={() => {
        return children;
      }}
    />
  );
};

export default LandingPageRoute;
