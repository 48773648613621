import LeftRightCard, { ImagePart, TextPart, Copy, Prompt } from '../../components/LeftRightCard';
import SectionHeader from '../../components/SectionHeader';
import cx from 'classnames';
import checkmarkCircleGrey from '../../images/pkp-checkmark-in-circle-grey.svg';
import elaAndSelImage from '../../images/mockup-k-8.png';
import styles from './WellnessEducationSection.module.scss';

interface Props {
  onClickRequestQuote: () => void;
}

const WellnessEducationSection = ({ onClickRequestQuote }: Props): JSX.Element => {
  return (
    <section className={styles.wellnessEducationSection}>
      <LeftRightCard textLeadEven>
        <ImagePart>
          <img className={styles.imageMaxWidth} src={elaAndSelImage} alt='mockup pk-12' />
        </ImagePart>
        <div className={cx(styles.leftMarginHack, styles.wellnessEducationCopy)}>
          <TextPart>
            <SectionHeader alignLeft preTitle='SOCIAL EMOTIONAL LEARNING CURRICULUM' title='Wellness Education' />
            <Copy>
              <p>
                Prepare students with future ready skills through Peekapak&apos;s social emotional learning (SEL)
                curriculum. We help educators weave easy to use, bite sized SEL lessons into academic instruction time,
                empowering educators to support well-being to unlock students&apos; full potential.
              </p>
              <ul className={styles.lists}>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  ELA and CASEL Aligned
                </li>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  Blended, Plug and Play Lessons
                </li>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  Gamified Learning
                </li>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  In Depth Analytics
                </li>
              </ul>
            </Copy>
            <Prompt clickHandler={onClickRequestQuote}>Request a Quote</Prompt>
          </TextPart>
        </div>
      </LeftRightCard>
    </section>
  );
};

export default WellnessEducationSection;
