export const values = [
  {
    title: 'Calm',
    offset: 0,
    percentage: 100 / 7,
    segmentColor: '#bfe5e4',
    subEmotions: ['Serious', 'Collected', 'Quiet', 'Thankful', 'Peaceful', 'Content', 'Relaxed'],
    subEmotionColor: '#daeff1',
  },
  {
    title: 'Happy',
    offset: 100 / 7,
    percentage: 14.285714,
    segmentColor: '#fdf0aa',
    subEmotions: ['Loved', 'Excited/Energetic', 'Joyful', 'Optimistic/Hopeful', 'Grateful', 'Amused', 'Passionate'],
    subEmotionColor: '#fef8d8',
  },
  {
    title: 'Confident',
    offset: 100 / 7,
    percentage: 100 / 7,
    segmentColor: '#f58955',
    subEmotions: ['Brave/Courageous', 'Assertive', 'Powerful', 'Respected/Valued', 'Intelligent', 'Proud', 'Important'],
    subEmotionColor: '#f5c4b1',
  },
  {
    title: 'Surprised',
    offset: 0,
    percentage: 14.285714,
    segmentColor: '#f15e4a',
    subEmotions: ['Startled', 'Overwhelmed', 'Shocked', 'Confused', 'Stunned', 'Amazed', 'Fascinated'],
    subEmotionColor: '#f59192',
  },
  {
    title: 'Angry',
    offset: 0,
    percentage: 14.285714,
    segmentColor: '#181597',
    subEmotions: ['Hurt', 'Upset', 'Jealous', 'Frustrated', 'Annoyed', 'Offended', 'Irritated'],
    subEmotionColor: '#5982bc',
  },
  {
    title: 'Scared',
    offset: 0,
    percentage: 14.285714,
    segmentColor: '#5982bc',
    subEmotions: ['Anxious', 'Intimidated', 'Embarrassed', 'Weak', 'Distracted/Forgetful', 'Stressed', 'Nervous'],
    subEmotionColor: '#91b6e0',
  },
  {
    title: 'Sad',
    offset: 0,
    percentage: 14.285714,
    segmentColor: '#6db8e3',
    subEmotions: ['Disappointed', 'Insecure', 'Guilty/Ashamed', 'Depressed', 'Lonely/Disconnected', 'Tired', 'Bored'],
    subEmotionColor: '#aee0f6',
  },
];

export const getInnerSlicePath = (
  centreX: number,
  centreY: number,
  startAngleRadians: number,
  sweepAngleRadians: number,
  radius: number,
) => {
  const firstCircumferenceX = centreX + radius * Math.cos(startAngleRadians);
  const firstCircumferenceY = centreY + radius * Math.sin(startAngleRadians);
  const secondCircumferenceX = centreX + radius * Math.cos(startAngleRadians + sweepAngleRadians);
  const secondCircumferenceY = centreY + radius * Math.sin(startAngleRadians + sweepAngleRadians);

  return `M${centreX} ${centreY} L${firstCircumferenceX} ${firstCircumferenceY} A${radius} ${radius} 0 0 1 ${secondCircumferenceX} ${secondCircumferenceY} Z`;
};

export const getInnerTextCoordinates = (
  centreX: number,
  centreY: number,
  startAngleRadians: number,
  sweepAngleRadians: number,
  radius: number,
): [number, number] => {
  const secondCircumferenceX = centreX + radius * Math.sin(startAngleRadians + sweepAngleRadians * 0.5);
  const secondCircumferenceY = centreY + radius * Math.cos(startAngleRadians + sweepAngleRadians * 0.5);

  return [secondCircumferenceX, secondCircumferenceY];
};

export const getOuterSlicePath = (
  centreX: number,
  centreY: number,
  startAngleRadians: number,
  sweepAngleRadians: number,
  radius: number,
) => {
  const startX = centreX + radius * Math.cos(startAngleRadians);
  const startY = centreY + radius * Math.sin(startAngleRadians);
  const firstArcEndX = centreX + radius * Math.cos(startAngleRadians + sweepAngleRadians);
  const firstArcEndY = centreY + radius * Math.sin(startAngleRadians + sweepAngleRadians);
  const lineEndX = firstArcEndX + radius * Math.cos(startAngleRadians + sweepAngleRadians);
  const lineEndY = firstArcEndY + radius * Math.sin(startAngleRadians + sweepAngleRadians);
  const secondArcEndX = startX + radius * Math.cos(startAngleRadians);
  const secondArcEndY = startY + radius * Math.sin(startAngleRadians);

  return `M${startX} ${startY} A${radius} ${radius} 0 0 1 ${firstArcEndX} ${firstArcEndY} L${lineEndX} ${lineEndY} A${
    2 * radius
  } ${2 * radius} 0 0 0 ${secondArcEndX} ${secondArcEndY} Z`;
};

export const getOuterTextCoordinates = (
  centreX: number,
  centreY: number,
  startAngleRadians: number,
  sweepAngleRadians: number,
  radius: number,
) => {
  const secondCircumferenceX = centreX + radius * Math.cos(startAngleRadians + sweepAngleRadians * 0.5);
  const secondCircumferenceY = centreY + radius * Math.sin(startAngleRadians + sweepAngleRadians * 0.5);

  return [secondCircumferenceX, secondCircumferenceY];
};
