import React, { Children, cloneElement } from 'react';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useScrollToSection } from './Hooks/useScrollToSection';
import { noWhiteSpace } from '../../../GlobalFunctions';
import GenericCTAButton, { CtaButtonProps } from '../../CTAButton/CTAButton';
import cx from 'classnames';
import styles from './Hero.module.scss';

interface TitleProps {
  children: ReactNode | string;
  className?: string;
}

interface HeroProps {
  bgImage: string;
  bgImageMobile?: string;
  bgPosition?: string | undefined;
  bgPositionMobile?: string | undefined;
  children: ReactNode;
  className?: string;
  defaultCTAButtons?: boolean;
  elementRef?: React.MutableRefObject<HTMLDivElement> | undefined;
  gradient?: string;
  gradientMobile?: string;
  offsetNumber?: number;
}

export const Title = ({ children, className }: TitleProps): JSX.Element => {
  return <div className={cx(styles.title, { [`${className}`]: className })}>{children}</div>;
};

export const Subtitle = ({ children, className }: TitleProps): JSX.Element => {
  return <div className={cx(styles.subtitle, { [`${className}`]: className })}>{children}</div>;
};

export const HeroCTAButton = ({ children, ...props }: CtaButtonProps): JSX.Element => {
  return (
    <GenericCTAButton isSmall {...props}>
      {children}
    </GenericCTAButton>
  );
};

const Hero = ({ ...props }: HeroProps): JSX.Element => {
  const {
    bgImage,
    bgImageMobile,
    children,
    className,
    defaultCTAButtons = true,
    elementRef,
    offsetNumber,
    gradient = 'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)',
    gradientMobile,
    bgPosition = 'top 50% left 25%',
    bgPositionMobile = 'top 0 left 17%',
  } = props;

  const history = useHistory();
  const { scrollToSection } = useScrollToSection();
  const isLargeScreen = useMediaQuery({ minWidth: 420 });

  const otherChildren = Children.map(children, (child) => {
    if (
      React.isValidElement(child) &&
      child.type !== Title &&
      child.type !== Subtitle &&
      child.type !== HeroCTAButton
    ) {
      return cloneElement(<>{child}</>);
    }
  });

  const titleChild = Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === Title) {
      return cloneElement(<>{child}</>);
    }
  });
  const subtitleChild = Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === Subtitle) {
      return cloneElement(<>{child}</>);
    }
  });
  const ctaButtonsChild = Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === HeroCTAButton) {
      return cloneElement(<>{child}</>);
    }
  });

  const handleGetStarted = () => {
    history.push('/signup');
  };

  const handleScrollToSection = () => {
    elementRef && scrollToSection(elementRef, offsetNumber);
  };

  return (
    <div className={cx(styles.content, { [`${className}`]: className })}>
      <div
        className={styles.heroImage}
        style={
          isLargeScreen
            ? {
                backgroundImage: noWhiteSpace`url(${bgImage}), linear-gradient(${gradient})`,
                backgroundPosition: bgPosition,
              }
            : {
                backgroundImage: noWhiteSpace`url(${bgImageMobile || bgImage}), linear-gradient(${
                  gradientMobile || gradient
                })`,
                backgroundPosition: bgPositionMobile,
              }
        }
      >
        <div className={styles.contentContainer}>
          <React.Fragment> {otherChildren}</React.Fragment>
          <React.Fragment>{titleChild}</React.Fragment>
          <React.Fragment>{subtitleChild}</React.Fragment>
          <div
            className={cx(styles.buttonsContainer, {
              [`${styles.noButtons}`]: !defaultCTAButtons,
            })}
          >
            {ctaButtonsChild?.length ? ctaButtonsChild : null}
            {!ctaButtonsChild?.length && defaultCTAButtons && (
              <React.Fragment>
                <HeroCTAButton secondary onClick={handleGetStarted}>
                  get started
                </HeroCTAButton>
                <HeroCTAButton onClick={handleScrollToSection}>learn more</HeroCTAButton>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
