import { Component } from 'react';
import { openStep } from './UtilityFunctions';
import { getLetterGradeFromNumberGrade } from '../GlobalFunctions';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import styles from '../SCSS/lpMain.module.scss';

// note lock decorator as HOC at bottom
class _SkipToNextStepButton extends Component {
  constructor(props) {
    super(props);

    this.openNextStep = this.openNextStep.bind(this);
    this.getNextStep = this.getNextStep.bind(this);
  }

  getAndContentAccessKeys() {
    if (this.props.unitMeta) {
      const lessonMeta =
        this.props.unitMeta.subUnits[this.props.unitPosition.subunit].lessons[this.props.unitPosition.lesson];
      if (lessonMeta.andContentAccessKey) {
        return [lessonMeta.andContentAccessKey];
      } else if (lessonMeta.andContentAccessKeys) {
        return lessonMeta.andContentAccessKeys;
      }
    }

    return [];
  }

  getOrContentAccessKeys() {
    if (this.props.unitMeta) {
      const lessonMeta =
        this.props.unitMeta.subUnits[this.props.unitPosition.subunit].lessons[this.props.unitPosition.lesson];
      if (lessonMeta.orContentAccessKey) {
        return [lessonMeta.orContentAccessKey];
      } else if (lessonMeta.orContentAccessKeys) {
        return lessonMeta.orContentAccessKeys;
      }
    }

    return [];
  }

  render() {
    if (this.isLocked() && this.isAtLastReadAloudStep()) {
      return null;
    }

    const { subunit, lesson, step } = this.props.unitPosition;
    const isCurrentStepCompleted = this.props.completionStatus[subunit][lesson][step] === 'Completed';

    if (!this.isAlreadyAtLastStep()) {
      const nextStepTitle = this.getNextStepsTitle();
      return (
        <div>
          {!this.isAlreadyAtLastStep() && (
            <div className={`${styles.skipToNextStepButton} ${styles.withLink}`} onClick={this.openNextStep}>
              {isCurrentStepCompleted ? (
                <div>Go to Next Step {nextStepTitle}</div>
              ) : (
                <div>Or Skip to Next Step {nextStepTitle}</div>
              )}
            </div>
          )}
        </div>
      );
    }

    return <div className={styles.skipToNextStepButton}>See you in the next unit!</div>;
  }

  openNextStep() {
    const { unitPosition, unitMeta, classroom } = this.props;
    const nextStepUnitPosition = this.getNextStep();
    let targetSubunitUrl = unitMeta.subUnits[unitPosition.subunit].url;

    // if we need to move to the next subunit
    if (nextStepUnitPosition.subunit > unitPosition.subunit) {
      targetSubunitUrl = unitMeta.subUnits[nextStepUnitPosition.subunit].url;
    }

    if (this.props.isPreviewMode) {
      this.props.onPreviewChange(nextStepUnitPosition);
      return null;
    }

    openStep(
      classroom.className,
      `${unitMeta.title}-${getLetterGradeFromNumberGrade(classroom.grade)}`,
      targetSubunitUrl,
      nextStepUnitPosition.subunit,
      nextStepUnitPosition.lesson,
      nextStepUnitPosition.step,
    );
  }

  getNextStepsTitle() {
    const { subunit, lesson, step } = this.getNextStep();

    // we're still within the same subunit
    // WARNING: i18n will be a problem with this code
    if (this.props.unitPosition.subunit === subunit) {
      const currentLesson = this.props.unitMeta.subUnits[subunit].lessons[lesson];
      return currentLesson.activities[step].title;
    }

    return 'in the next Big Idea';
  }

  getNextStep() {
    // find where I am in the subunit
    // is there a next step in the lesson I am in?
    // if so, return the next step
    //    if not, is there a next lesson in the subunit I am in?
    //        if so, return the first step in the next lesson
    //        if not, get the first step in the first lesson in the next unit
    const { subunit, lesson, step } = this.props.unitPosition;
    const currentSubunit = this.props.unitMeta.subUnits[subunit];
    const currentLesson = this.props.unitMeta.subUnits[subunit].lessons[lesson];

    if (step + 1 < currentLesson.activities.length) {
      return { subunit, lesson, step: step + 1 };
    } else if (lesson + 1 < currentSubunit.lessons.length) {
      return { subunit, lesson: lesson + 1, step: 0 };
    }

    return { subunit: subunit + 1, lesson: 0, step: 0 };
  }

  isAlreadyAtLastStep() {
    const { subunit, lesson, step } = this.props.unitPosition;

    if (subunit + 1 < this.props.unitMeta.subUnits.length) {
      return false;
    } else if (lesson + 1 < this.props.unitMeta.subUnits[subunit].lessons.length) {
      return false;
    } else if (step + 1 < this.props.activityMeta.length) {
      return false;
    }

    return true;
  }

  isAtLastReadAloudStep() {
    if (this.props === undefined) {
      return false;
    }

    if (this.props && this.props.activityMeta.contentType !== 'readAloud') {
      return false;
    }

    const { lesson, step } = this.props.unitPosition;

    if (lesson + 1 < this.props.activityMeta.activities.length) {
      return false;
    } else if (step + 1 < this.props.activityMeta.activities[lesson].content.activities.length) {
      return false;
    }

    return true;
  }
}

export const SkipToNextStepButton = lockWithPreviewUnlessHasAny(
  'KEY_ALL_LESSON_PLANS',
  'KEY_MASTER_KEY',
)(_SkipToNextStepButton);
