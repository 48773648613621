import { ReactNode } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import TextInput from '../TextInput';
import cx from 'classnames';
import styles from './ConfirmIrreversible.module.scss';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

interface Props {
  className?: string;
  hideModal: () => void;
  title: string;
  confirmTestString: string;
  instructions: string;
  warningMessage: string | ReactNode;
  performIrreversibleAction: () => void;
  [k: string]: string | unknown | (() => void) | ReactNode;
}

interface FormValues {
  [key: string]: string;
}

const ConfirmIrreversible = ({
  className,
  hideModal,
  title,
  confirmTestString,
  warningMessage,
  instructions,
  performIrreversibleAction,
  ...buttonProps
}: Props): JSX.Element => {
  const initialValue: FormValues = {
    confirmTest: '',
  };

  const schemaValues = {
    confirmTest: Yup.string()
      .required(`Please type '${confirmTestString}' to proceed`)
      .oneOf([confirmTestString], `Please type '${confirmTestString}' to proceed`),
  };

  const validationSchema = Yup.object().shape(schemaValues);

  const handleConfirm = async () => {
    try {
      performIrreversibleAction();
    } catch {
      console.error('Could not complete deletion');
    } finally {
      hideModal();
    }
  };

  return (
    <div className={cx(styles.container, { [`${className}`]: className })}>
      <div className={styles.title}>
        <h2>{title}</h2>
        <button onClick={hideModal}>x</button>
      </div>
      <div className={styles.content}>
        <p className={styles.warningMessage}>{warningMessage}</p>
        <p className={styles.warningMessage}>{instructions}</p>
        <Formik initialValues={initialValue} onSubmit={handleConfirm} validationSchema={validationSchema}>
          <Form className={styles.formContainer}>
            <TextInput name='confirmTest' type='text' placeholder={confirmTestString} />
            <div className={styles.buttonRow}>
              <PrimaryButton type='submit' small alert {...buttonProps}>
                {title}
              </PrimaryButton>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ConfirmIrreversible;
