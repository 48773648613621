import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getLastSelectedClassroom, getLetterGradeFromNumberGrade, storeSelectedClassroom } from './GlobalFunctions';
import { getStore } from './ApplicationState';
import type { SessionState } from './Session';
import { ClassroomType } from '../peekapak-types/DataProtocolTypes';
export enum State {
  notLoaded = 'NOT_LOADED',
  loading = 'LOADING',
  loaded = 'LOADED',
}
export type ClassroomsStateType = (typeof State)[keyof typeof State];

export interface ClassroomsState {
  state: ClassroomsStateType;
  classrooms: ClassroomType[];
  selectedClassroom: number;
}
export enum ActionType {
  LOAD = 'LOAD_CLASSROOMS',
  UNLOAD = 'UNLOAD_CLASSROOMS',
  SET_SELECTED = 'SET_SELECTED',
  UPDATE_CLASSROOM = 'UPDATE_CLASSROOM',
  REPLACE_CLASSROOM = 'REPLACE_CLASSROOM',
  DELETE_CLASSROOM = 'DELETE_CLASSROOM',
  CREATE_CLASSROOM = 'CREATE_CLASSROOM',
}

export interface LoadParams {
  classrooms: ClassroomType[];
  selectClassroomName?: string;
}
export const selectCompletionStatus = (state: { classrooms: ClassroomsState }) =>
  state.classrooms.classrooms[state.classrooms.selectedClassroom].completionStatus;
export const selectClassroom = (state: { classrooms: ClassroomsState }) =>
  state.classrooms.classrooms[state.classrooms.selectedClassroom];
export const selectUnitCompletionStatus = (state: { classrooms: ClassroomsState; session: SessionState }) => {
  if (!state.session.unit || state.classrooms.state !== State.loaded) {
    console.warn('selectUnitCompletionStatus is returning undefined because session.unit is undefined');
    return undefined;
  }

  const classroom = state.classrooms.classrooms[state.classrooms.selectedClassroom];
  const unitRoot = state.session.unit;
  const grade = getLetterGradeFromNumberGrade(classroom.grade);
  return classroom.completionStatus[`${unitRoot}-${grade}`];
};
export const initialState: ClassroomsState = {
  state: State.notLoaded as ClassroomsStateType,
  classrooms: [],
  selectedClassroom: -1,
};

const throwIfErrorCondition = (actionType: string, stateToInspect: ClassroomsStateType) => {
  if (actionType === ActionType.SET_SELECTED && stateToInspect === State.notLoaded) {
    throw new Error(`Cannot set selected when classrooms are not loaded`);
  }

  if (actionType === ActionType.UPDATE_CLASSROOM && stateToInspect === State.notLoaded) {
    throw new Error(`Cannot update classroom when classrooms are not loaded`);
  }

  if (actionType === ActionType.REPLACE_CLASSROOM && stateToInspect === State.notLoaded) {
    throw new Error(`Cannot replace classroom when classrooms are not loaded`);
  }

  if (actionType === ActionType.DELETE_CLASSROOM && stateToInspect === State.notLoaded) {
    throw new Error(`Cannot delete classroom when classrooms are not loaded`);
  }

  if (actionType === ActionType.CREATE_CLASSROOM && stateToInspect === State.notLoaded) {
    throw new Error(`Cannot create classroom when classrooms are not loaded`);
  }
};

export const classroomsSlice = createSlice({
  name: 'classrooms',
  initialState,
  reducers: {
    loadClassrooms: (state, action: PayloadAction<LoadParams>) => {
      throwIfErrorCondition(ActionType.LOAD, state.state);
      const { classrooms, selectClassroomName } = action.payload as LoadParams;
      const selectedClassroom = determineSelectedClassroom(classrooms, selectClassroomName);

      state.classrooms = action.payload.classrooms;
      state.state = State.loaded;
      state.selectedClassroom = selectedClassroom;
    },
    unloadClassrooms: (state) => {
      throwIfErrorCondition(ActionType.UNLOAD, state.state);
      state.classrooms = [];
      state.state = State.notLoaded;
      state.selectedClassroom = -1;
    },
    setSelectedClassroom: (state, action: PayloadAction<number>) => {
      throwIfErrorCondition(ActionType.SET_SELECTED, state.state);
      state.selectedClassroom = action.payload;
    },
    updateClassroom: (state, action: PayloadAction<ClassroomType>) => {
      throwIfErrorCondition(ActionType.UPDATE_CLASSROOM, state.state);
      const { className, ...rest } = action.payload;
      const classroom = state.classrooms.find((c) => c.className === className);
      if (!classroom) {
        throw new Error(`Could not find classroom with className ${className}`);
      }
      const newClassroom = { ...classroom, ...rest };
      const newClassrooms = state.classrooms.map((c) => (c.className === className ? newClassroom : c));
      if (newClassroom.isArchived) {
        const newSelectedClassroom = determineSelectedClassroom(newClassrooms);
        state.selectedClassroom = newSelectedClassroom;
      }
      state.classrooms = newClassrooms;
    },
    replaceClassroom: (
      state,
      action: PayloadAction<{
        original: ClassroomType;
        replacement: ClassroomType;
      }>,
    ) => {
      throwIfErrorCondition(ActionType.REPLACE_CLASSROOM, state.state);
      const { original, replacement } = action.payload;
      const newClassrooms = state.classrooms.map((c) => (c.className === original.className ? replacement : c));
      state.classrooms = newClassrooms;
    },
    deleteClassroom: (state, action: PayloadAction<ClassroomType>) => {
      throwIfErrorCondition(ActionType.DELETE_CLASSROOM, state.state);
      const { className } = action.payload;
      const newClassrooms = state.classrooms.filter((c) => c.className !== className);
      const newSelectedClassroom = determineSelectedClassroom(newClassrooms);
      state.classrooms = newClassrooms;
      state.selectedClassroom = newSelectedClassroom;
    },
    createClassroom: (state, action: PayloadAction<ClassroomType>) => {
      throwIfErrorCondition(ActionType.CREATE_CLASSROOM, state.state);
      const toCreate = action.payload;
      const before: Array<ClassroomType> = state.classrooms.filter((c) => c.className < toCreate.className);
      const after: Array<ClassroomType> = state.classrooms.filter((c) => c.className > toCreate.className);
      const newClassrooms = [...before, toCreate, ...after];
      state.classrooms = newClassrooms;
      state.selectedClassroom = before.length;
    },
  },
});

function determineSelectedClassroom(classrooms: ClassroomType[], classroomName?: string) {
  if (classroomName) {
    // there's a name to search for currently selected classroom
    const classroom = classrooms.find((c) => c.className === classroomName);

    if (classroom) {
      return classrooms.indexOf(classroom);
    }
  }

  const selected = (() => {
    // if selected classroom is archived, find first non-archived classroom
    // if no non-archived classroom, find first classroom
    const s = getLastSelectedClassroom(classrooms);
    if (classrooms[s].isArchived) {
      const notArchived = classrooms.find((c) => !c.isArchived);
      if (notArchived) {
        return classrooms.indexOf(notArchived);
      }
      return 0;
    }

    return s;
  })();
  storeSelectedClassroom(selected);
  return selected;
}

export function getMostRecentlyCreatedClassroom(classroomsList?: ClassroomType[]) {
  const classrooms = classroomsList || getStore().getState().classrooms.classrooms;
  let selectedIndex = 0;
  let mostRecentTimeStamp = 0;
  // "default" was the className of version 2.0 of the
  // database. We want to use version 3.0 data
  classrooms.forEach((classroom, index) => {
    if (classroom.className !== 'default') {
      if (classroom.createdAt > mostRecentTimeStamp) {
        mostRecentTimeStamp = classroom.createdAt;
        selectedIndex = index;
      }
    }
  });
  return selectedIndex;
}

export const {
  loadClassrooms,
  unloadClassrooms,
  setSelectedClassroom,
  updateClassroom,
  replaceClassroom,
  deleteClassroom,
  createClassroom,
} = classroomsSlice.actions;

export default classroomsSlice.reducer;
