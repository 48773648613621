import { useLocation } from 'react-router-dom';
import { useEffect, useState, useCallback, useLayoutEffect, MutableRefObject, useRef } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import {
  isUserLoggedIn,
  isUserLoggedInStateInTransition,
  isUserLoggedInStateUnknown,
  isUserParentAccessStorybook,
  isUserDistrictAdministrator,
  isUserSessionExpired,
  openHostedUIForClever,
  openHostedUIForGoogle,
  openHostedUIForClassLink,
  reactivateUserSession,
  signInUser,
} from './UserManagementHelpers';
import { noWhiteSpace } from './GlobalFunctions';
import { logger } from './error-tracker';
import { isUserHasKey, isUserMissingSchool } from './GlobalFunctions';
import { getStore } from './ApplicationState';
import { setCookie } from './aaa';

export function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

export function useAuth() {
  return {
    isUserSessionExpired,
    isUserLoggedIn,
    isUserHasKey,
    isUserDistrictAdministrator,
    isUserMissingSchool,
    isUserLoggedInStateUnknown,
    isUserLoggedInStateInTransition,
    isUserParentAccessStorybook,
    reactivateUserSession,
    onLoggedInStateKnown: (callback: () => void) => {
      const unsubscribe = getStore().subscribe(() => {
        if (!isUserLoggedInStateInTransition()) {
          unsubscribe();
          callback();
        }
      });
    },
  };
}

export const useDebouncedEffect = (effect: () => void, deps: any[] = [], delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, delay]);
};

export const useOutsideClick = (ref: React.RefObject<HTMLElement>, action: () => void) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        action();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export function useLogin() {
  return {
    nativeLogin: async (email: string, password: string) => {
      try {
        return await signInUser(email, password);
      } catch (error) {
        if (error instanceof Error) {
          const message = getUserFriendlyErrorMessage(error);

          console.error(`Login failed: ${message}`);
          const errorToLog = new Error(`Login failed for ${email} with error: ${message}`);
          logger.logException(errorToLog);
        }
        throw error;
      }

      function getUserFriendlyErrorMessage(error: Error) {
        if (!error.message) {
          return error.toString();
        }

        const originalMessage = error.message;

        if (originalMessage.includes('UserMigration failed')) {
          return 'Incorrect username or password.';
        } else if (originalMessage.includes('Failed to fetch')) {
          return 'There was a problem that occurred on the server preventing login';
        } else if (originalMessage.includes('User is not authorized to get auth details')) {
          logger.logException(
            new Error(noWhiteSpace`Attempt to login using username \
        and password, but external provider account exists`),
          );
          return noWhiteSpace`An account already exists that was created from an \
        external provider, such as Google or Clever. Please try using an alternative sign \
        in method instead`;
        } else {
          logger.logException(new Error(`Could not create user friendly error message for ${originalMessage}`));
          return originalMessage;
        }
      }
    },
    googleSignIn: (origin: string) => {
      setCookie('peekapak.idP', 'Google');
      setCookie('peekapak.oauthSignInOrigin', origin);
      openHostedUIForGoogle();
    },
    cleverSignIn: (origin: string) => {
      setCookie('peekapak.idP', 'Clever');
      setCookie('peekapak.oauthSignInOrigin', origin);
      openHostedUIForClever();
    },
    classLinkSignIn: (origin: string) => {
      setCookie('peekapak.idP', 'ClassLink');
      setCookie('peekapak.oauthSignInOrigin', origin);
      openHostedUIForClassLink();
    },
  };
}

export const useWidth = (elementRef: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number | null>(null);

  const updateWidth = useCallback(() => {
    if (elementRef && elementRef.current) {
      const { width } = elementRef.current.getBoundingClientRect();
      setWidth(width);
    }
  }, [elementRef]);

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);

  return width;
};

interface Size {
  width: number;
  height: number;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [MutableRefObject<T | null>, Size] {
  const target = useRef<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setRoundedSize = ({ width, height }: Size) => {
    setSize({ width: Math.round(width), height: Math.round(height) });
  };

  useLayoutEffect(() => {
    target.current && setRoundedSize(target.current.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setRoundedSize({ width, height });
  });

  return [target, size];
}
