import $ from 'jquery';
import { connect, ConnectedProps } from 'react-redux';
import Toggle from 'react-toggle';
import {
  setCurrentTime,
  pause,
  play,
  setVolume,
  setMedia,
  Media,
  AudioPlayer,
  State,
} from '../../components/AudioPlayer/AudioPlayer';
import restartIcon from '../../images/restart.svg';
import playButton from '../../images/play-true.svg';
import pauseButton from '../../images/play-false.svg';
import pushPinTrue from '../../images/push-pin-true.svg';
import pushPinFalse from '../../images/push-pin-false.svg';
import volumeSoft from '../../images/volume-soft.svg';
import volumeLoud from '../../images/volume-loud.svg';
import Slider from 'rc-slider';
import styles from '../../SCSS/AudioToolBar.module.scss';
import { AppDispatch, RootState } from '../../ApplicationState';
import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

interface LoadAudioParams {
  base: string;
  language: string;
  storyId: string;
}

let isSeeking = false;

function volumeLevel(currentVolume: number) {
  if (currentVolume < 0.5) {
    return 'soft';
  }
  return 'loud';
}

function generateAudioPointer(params: LoadAudioParams) {
  const { base, language, storyId } = params;
  return {
    sources: { mp3: getAudioUrl({ base, language, storyId }) },
    title: storyId,
  };
}

interface TimingInterval {
  start: number;
  end: number;
}

function findIntervalIndex(timingsArray: TimingInterval[], timeValue: number) {
  return timingsArray.findIndex((interval) => interval.start <= timeValue && timeValue < interval.end);
}

function findPlayingInterval(timingsArray: TimingInterval[], timeValue: number) {
  const foundIndex = findIntervalIndex(timingsArray, timeValue);

  if (foundIndex === -1) {
    const inBetweenIndex = timingsArray.findIndex((interval) => timeValue < interval.end);

    if (inBetweenIndex === -1) {
      return 0;
    }

    return inBetweenIndex;
  }

  return foundIndex;
}

const baseAudioUrl = 'https://s3.amazonaws.com/peekaplatform/assets/premium/storybookAudio/';

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    play: (time?: number) => {
      dispatch(play(time));
    },
    pause: (time?: number) => {
      dispatch(pause(time));
    },
    setCurrentTime: (time: number) => {
      dispatch(setCurrentTime(time));
    },
    setVolume: (volume: number) => {
      dispatch(setVolume(volume));
    },
    setMedia: (media: Media) => {
      dispatch(setMedia(media));
    },
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    currentTime: state.audioPlayer.currentTime,
    media: state.audioPlayer.media,
    isPlayerPaused: state.audioPlayer.paused,
    isPlayerLoading: state.audioPlayer.state === State.loading,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  // whether to show audio controls (dep on whether there's timing keys)
  isShow: boolean;
  currentPage: number;
  pauseState: boolean;
  timeKeys: {
    timings: TimingInterval[];
    lastPage: number;
  };
  showControl: boolean;
  chevronHover: boolean;
  isMobile: boolean;
  autoPlay: boolean;
  showVolume: boolean;
  toggleChevronHover: (hover: boolean) => void;
  toggleShowVolume: () => void;
  pagesDisplayed: number;
  storyId: string;
  language: string;
  // from containing component
  pauseAudio: (b: boolean) => void;
  volume: number;
  setAudioVolume: (volume: number) => void;
  togglePinState: () => void;
  toggleAutoPlay: (arg0: string) => void;
};

const FlipbookAudio = (props: Props) => {
  const propsRef = useRef<Props>(props);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  useEffect(() => {
    setStoryMedia(props);
  }, []);

  const isOnLastPageWithAudio = (props: Props) => props.currentPage === props.timeKeys.lastPage;
  const isAudioOutOfPageSync = (props: Props) => {
    const test =
      props.currentTime > props.timeKeys.timings[props.currentPage + (props.pagesDisplayed - 1) - 1].end ||
      props.currentTime < props.timeKeys.timings[props.currentPage - 1].start;
    return test;
  };

  const getPositionRelativeToLastPageWithAudio = (props: Props) => props.currentPage - props.timeKeys.lastPage;
  const isBeyondPagesWithAudio = (props: Props) => getPositionRelativeToLastPageWithAudio(props) > 0;
  const isCurrentStoryMediaLoaded = (props: Props) => props?.media?.title === props.storyId;

  function setStoryMedia(props: Props) {
    props.setMedia(
      generateAudioPointer({
        base: baseAudioUrl,
        language: props.language,
        storyId: props.storyId,
      }),
    );
  }

  function playAudioFromCurrentPage(props: Props) {
    // console.debug( `playAudioFromCurrentPage` );
    if (isBeyondPagesWithAudio(props)) {
      return;
    }

    if (!isCurrentStoryMediaLoaded(props)) {
      setStoryMedia(props);
    }

    if (!props.isPlayerPaused) {
      // props.pause( 'FlipbookAudio' );
    }

    props.setVolume(props.volume);
    props.play(props.timeKeys.timings[props.currentPage - 1].start);
    props.pauseAudio(false);
  }

  function playAudioFromCurrentTime(props: Props) {
    // console.debug( `playAudioFromCurrentTime` );
    if (isBeyondPagesWithAudio(props)) {
      return;
    }

    if (!isCurrentStoryMediaLoaded(props)) {
      setStoryMedia(props);
    }

    if (!props.isPlayerPaused) {
      // props.pause( 'FlipbookAudio' );
    }

    props.setVolume(props.volume);
    props.play();
    props.pauseAudio(false);
  }

  function pauseAudioAtCurrentTime(props: Props) {
    // console.debug( `pauseAudioAtCurrentTime` );
    props.pauseAudio(true);
    props.pause();
  }

  function pauseAudioAtBeginning(props: Props) {
    // console.debug( `pauseAudioAtBeginning` );
    props.pauseAudio(true);
    props.pause(props.timeKeys.timings[0].start);
  }

  const onPlay = () => {
    // if ( import.meta.env.MODE === 'development' ) {
    //   console.debug( `FlipbookAudio onPlay
    //     currentPage: ${ propsRef.current.currentPage }
    //     currentTime: ${ propsRef.current.currentTime }
    //     startTime: ${ propsRef.current.timeKeys.timings[ propsRef.current.currentPage - 1 ].start }
    //     endTime: ${ propsRef.current.timeKeys.timings[ propsRef.current.currentPage + ( propsRef.current.pagesDisplayed - 1 ) - 1 ].end }
    //   ` );
    // }

    if (isSeeking) return;

    if (isAudioOutOfPageSync(propsRef.current)) {
      playAudioFromCurrentPage(propsRef.current);
    } else if (isBeyondPagesWithAudio(propsRef.current)) {
      pauseAudioAtBeginning(propsRef.current);
    } else {
      playAudioFromCurrentTime(propsRef.current);
    }
  };

  const onTimeUpdate = () => {
    const turnToNextPage = () => {
      const nextPageNumber = findPlayingInterval(propsRef.current.timeKeys.timings, propsRef.current.currentTime) + 1;

      // null means that currentTime is outside of all intervals, i.e.
      // beyond last page interval with audio
      // console.debug( `turnToNextPage: turning to page ${ nextPageNumber }` );
      $('#Flipbook').turn('page', nextPageNumber);
    };

    const isAudioPastCurrentPage = () => {
      const timingIndexOfCurrentPage = propsRef.current.currentPage + (propsRef.current.pagesDisplayed - 1) - 1;
      const endTimeOfCurrentPages = propsRef.current.timeKeys.timings[timingIndexOfCurrentPage].end;

      return propsRef.current.currentTime > endTimeOfCurrentPages;
    };

    const mustPauseInManualTurnMode = () => {
      if (propsRef.current.autoPlay) {
        return false;
      }

      return isAudioPastCurrentPage();
    };

    const mustTurnInAutoTurnMode = () => {
      if (!propsRef.current.autoPlay) {
        return false;
      }

      if (isOnLastPageWithAudio(propsRef.current)) {
        return false;
      }

      if (isAudioPastCurrentPage()) {
        return true;
      }

      return false;
    };

    const mustPauseInAutoTurnMode = () => {
      if (!propsRef.current.autoPlay) {
        return false;
      }

      if (!isOnLastPageWithAudio(propsRef.current)) {
        return false;
      }

      if (isAudioPastCurrentPage()) {
        return true;
      }

      return false;
    };

    // if (import.meta.env.MODE === 'development') {
    //   const timingIndexOfCurrentPage =
    //     propsRef.current.currentPage +
    //     (propsRef.current.pagesDisplayed - 1) -
    //     1;
    //   const endTimeOfCurrentPages =
    //     propsRef.current.timeKeys.timings[timingIndexOfCurrentPage].end;

    //   console.debug(`onTimerUpdate
    //      currentPage: ${propsRef.current.currentPage}
    //      currentTime: ${propsRef.current.currentTime}
    //      startTime: ${
    //        propsRef.current.timeKeys.timings[propsRef.current.currentPage - 1]
    //          .start
    //      }
    //      endTimeOfCurrentPages: ${endTimeOfCurrentPages}
    //      timingIndex: ${timingIndexOfCurrentPage}
    //    `);
    // }

    propsRef.current.setVolume(propsRef.current.volume);

    if (isSeeking) {
      return;
    }

    if (propsRef.current.pauseState) {
      pauseAudioAtCurrentTime(propsRef.current);
    } else if (isBeyondPagesWithAudio(propsRef.current)) {
      pauseAudioAtBeginning(propsRef.current);
    } else if (mustPauseInAutoTurnMode() || mustPauseInManualTurnMode()) {
      pauseAudioAtBeginning(propsRef.current);
    } else if (mustTurnInAutoTurnMode()) {
      turnToNextPage();
    }
  };

  const onPause = () => {
    // if (import.meta.env.MODE === 'development') {
    //   console.debug(`FlipbookAudio onPause
    //      currentPage: ${propsRef.current.currentPage}
    //      currentTime: ${propsRef.current.currentTime}
    //      startTime: ${
    //        propsRef.current.timeKeys.timings[propsRef.current.currentPage - 1]
    //          .start
    //      }
    //      endTime: ${
    //        propsRef.current.timeKeys.timings[
    //          propsRef.current.currentPage +
    //            (propsRef.current.pagesDisplayed - 1) -
    //            1
    //        ].end
    //      }
    //    `);
    // }

    if (isSeeking) {
      return;
    }

    pauseAudioAtCurrentTime(propsRef.current);
  };

  const onEnded = () => {
    // if (import.meta.env.MODE === 'development') {
    //   console.debug(`FlipbookAudio onEnded
    //      currentPage: ${propsRef.current.currentPage}
    //      currentTime: ${propsRef.current.currentTime}
    //      startTime: ${
    //        propsRef.current.timeKeys.timings[propsRef.current.currentPage - 1]
    //          .start
    //      }
    //      endTime: ${
    //        propsRef.current.timeKeys.timings[
    //          propsRef.current.currentPage +
    //            (propsRef.current.pagesDisplayed - 1) -
    //            1
    //        ].end
    //      }
    //    `);
    // }
  };

  const onSeeking = () => {
    // if (import.meta.env.MODE === 'development') {
    //   console.debug(`FlipbookAudio Seeking
    //      currentPage: ${propsRef.current.currentPage}
    //      currentTime: ${propsRef.current.currentTime}
    //      startTime: ${
    //        propsRef.current.timeKeys.timings[propsRef.current.currentPage - 1]
    //          .start
    //      }
    //      endTime: ${
    //        propsRef.current.timeKeys.timings[
    //          propsRef.current.currentPage +
    //            (propsRef.current.pagesDisplayed - 1) -
    //            1
    //        ].end
    //      }
    //    `);
    // }

    isSeeking = true;
  };
  const onSeeked = () => {
    // if (import.meta.env.MODE === 'development') {
    //   console.debug(`FlipbookAudio Seeked
    //      currentPage: ${propsRef.current.currentPage}
    //      currentTime: ${propsRef.current.currentTime}
    //      startTime: ${
    //        propsRef.current.timeKeys.timings[propsRef.current.currentPage - 1]
    //          .start
    //      }
    //      endTime: ${
    //        propsRef.current.timeKeys.timings[
    //          propsRef.current.currentPage +
    //            (propsRef.current.pagesDisplayed - 1) -
    //            1
    //        ].end
    //      }
    //    `);
    // }

    isSeeking = false;
  };
  const onPlaying = () => {
    // if (import.meta.env.MODE === 'development') {
    //   console.debug(`FlipbookAudio Playing
    //      currentPage: ${propsRef.current.currentPage}
    //      currentTime: ${propsRef.current.currentTime}
    //      startTime: ${
    //        propsRef.current.timeKeys.timings[propsRef.current.currentPage - 1]
    //          .start
    //      }
    //      endTime: ${
    //        propsRef.current.timeKeys.timings[
    //          propsRef.current.currentPage +
    //            (propsRef.current.pagesDisplayed - 1) -
    //            1
    //        ].end
    //      }
    //    `);
    // }
  };

  const showControls = props.showControl || props.chevronHover || props.isMobile;

  if (!props.isShow || !props?.media?.title) {
    return null;
  }

  return (
    <>
      <AudioPlayer
        onTimeUpdate={onTimeUpdate}
        onPause={onPause}
        onEnded={onEnded}
        onSeeking={onSeeking}
        onSeeked={onSeeked}
        onPlaying={onPlaying}
        onPlay={onPlay}
      />
      <CSSTransition
        in={showControls}
        key='controls'
        unmountOnExit={true}
        classNames={{
          enter: styles['controls-enter'],
          enterActive: styles['controls-enter-active'],
          enterDone: styles['controls-enter-done'],
          exit: styles['controls-exit'],
          exitActive: styles['controls-exit-active'],
          exitDone: styles['controls-exit-done'],
        }}
        timeout={{
          enter: 600,
          exit: 600,
        }}
      >
        <PlayerControls
          {...props}
          playAudioFromCurrentTime={playAudioFromCurrentTime}
          playAudioFromCurrentPage={playAudioFromCurrentPage}
          pauseAudioAtBeginning={pauseAudioAtBeginning}
          pauseAudioAtCurrentTime={pauseAudioAtCurrentTime}
          toggleAutoPlay={props.toggleAutoPlay}
          setAudioVolume={props.setAudioVolume}
          togglePinState={props.togglePinState}
        />
      </CSSTransition>
      <CSSTransition
        in={!showControls}
        key='chevron'
        unmountOnExit={true}
        classNames={{
          enter: styles['chevron-enter'],
          enterActive: styles['chevron-enter-active'],
          enterDone: styles['chevron-enter-done'],
          exit: styles['chevron-exit'],
          exitActive: styles['chevron-exit-active'],
          exitDone: styles['chevron-exit-done'],
        }}
        timeout={{
          enter: 250,
          exit: 400,
        }}
      >
        <div className={styles['audio-control-chevron']} onMouseEnter={() => props.toggleChevronHover(true)}>
          <i className='fa fa-chevron-up' aria-hidden='true' />
        </div>
      </CSSTransition>
    </>
  );
};

interface PlayerControlsProps {
  toggleShowVolume: (arg0: boolean) => void;
  toggleAutoPlay: (arg0: string) => void;
  pauseState: boolean;
  playAudioFromCurrentTime: (props: Props) => void;
  pauseAudioAtCurrentTime: (props: Props) => void;
  pauseAudioAtBeginning: (props: Props) => void;
  playAudioFromCurrentPage: (props: Props) => void;
  autoPlay: boolean;
  isMobile: boolean;
  togglePinState: () => void;
  showControl: boolean;
  setAudioVolume: (arg0: number) => void;
  setVolume: (arg0: number) => void;
  volume: number;
  isPlayerLoading: boolean;
  isPlayerPaused: boolean;
}

function PlayerControls(props: PlayerControlsProps) {
  return (
    <div className={styles['audio-container']}>
      <div className={styles['audio-controls']} onMouseLeave={() => props.toggleShowVolume(false)}>
        <button
          className={styles['audio-a']}
          disabled={props.isPlayerLoading}
          onClick={() => {
            if (props.isPlayerPaused) {
              props.playAudioFromCurrentTime(props);
            } else {
              props.pauseAudioAtCurrentTime(props);
            }
          }}
        >
          <img
            className={styles['play-control']}
            src={props.isPlayerPaused ? playButton : pauseButton}
            alt='Play/Pause'
          />
        </button>
        <button
          className={styles['audio-b']}
          disabled={props.isPlayerLoading}
          onClick={() => {
            props.playAudioFromCurrentPage(props);
          }}
        >
          <img className={styles['play-control']} src={restartIcon} alt='Replay' />
        </button>
        <div className={styles['vertical-divider-container']}>
          <div className={styles['vertical-divider']} />
        </div>
        <div className={styles['auto-play']}>
          <h3 className={styles['auto-play-text']}>Auto Turn</h3>
          <div className={styles['auto-play-toggle-container']} style={{ display: 'inline-block' }}>
            <Toggle
              className={styles['auto-play-toggle']}
              style={{ display: 'inline-block' }}
              defaultChecked={props.autoPlay}
              onChange={() => {
                props.toggleAutoPlay('AutoPlay');
              }}
            />
          </div>
        </div>
        {!props.isMobile && (
          <div className={styles['pin-container']}>
            <button
              className={styles['audio-b']}
              onClick={() => {
                props.togglePinState();
              }}
            >
              <img alt='Pin' className={styles['pin-icon']} src={props.showControl ? pushPinTrue : pushPinFalse} />
            </button>
          </div>
        )}
        <div className={styles['volume-level-container']}>
          <div className={styles['audio-b']}>
            <img
              className={styles['play-control']}
              src={volumeLevel(props.volume) === 'soft' ? volumeSoft : volumeLoud}
              alt='Volume'
            />
          </div>
          <Slider
            className={styles['volume-slider']}
            onChange={(value) => props.setAudioVolume(value / 100)}
            onAfterChange={() => {
              props.setVolume(props.volume);
            }}
            defaultValue={props.volume * 100}
          />
        </div>
        <div className={styles['volume-right-margin']} />
      </div>
    </div>
  );
}

function getAudioUrl(params: LoadAudioParams) {
  const { base, storyId, language } = params;
  if (language) {
    return `${base}${language}/${storyId}.mp3`;
  }
  return `${base}${storyId}.mp3`;
}

export default connector(FlipbookAudio);
