export const ErrorMessages = {
  // Cognito account does not exist
  NO_ACCOUNT_FOUND: 'User does not have account',
  // Cognito account exists but no user profile
  NO_ACCOUNT_FOUND_INCOMPLETE: 'User does not have account - incomplete',
  // Cognito account exists, no profile, has enterprise license but not library user
  NO_ACCOUNT_FOUND_NOT_ROSTERED:
    'User does not have account - student is not rostered',
  NO_ACCOUNT_FOUND_TEACHER_STUDENT_MIXUP:
    'User does not have account - student registered with teacher role',
  ENTERPRISE_ACCOUNT_TEACHER: 'User is an enterprise account teacher',
} as const;

export type ErrorMessages = (typeof ErrorMessages)[keyof typeof ErrorMessages];
