import { useEffect } from 'react';
import styles from './ModalContainer.module.scss';

interface Props {
  hideModal: () => void;
  children: React.ReactElement | React.ReactElement[];
}

const ModalContainer = ({ hideModal, children }: Props): JSX.Element => {
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      hideModal();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown, true);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown, true);
    };
  }, [closeOnEscapeKeyDown]);
  return (
    <div className={styles.container} onClick={hideModal}>
      <div className={styles.safetyWrapper} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;
