import * as React from 'react';
import { connect } from 'react-redux';
import { getPublicMediaRoot, postServiceRequest } from './serviceAgent';
import { ActivityNavigator } from './LessonPlan/ActivityNavigator';
import { getUnit } from './core/context/contextHelpers';
import { LockedContentMessage } from './LessonPlan/LockedContentMessage';
import SpinnerOverlay from './SpinnerOverlay';
import { markStepComplete, resetStep, sendParentEmailUpdateAndActivity } from './BackendInterface';
import { fixedEncodeURIComponent, getLetterGradeFromNumberGrade, getUsersEmail } from './GlobalFunctions';
import { State as ClassroomsState, selectClassroom, selectUnitCompletionStatus, updateClassroom } from './Classrooms';
import { computeCompletionStatus } from './completionStatus';
import { setUnit } from './Session';
import { State as LessonsState, LoadUnitPayload, loadUnit, startLoad, unloadUnit } from './Lessons';
import { Icon } from '@iconify/react';
import styles from './SCSS/LessonPlanLayout.module.scss';
import { History, Location } from 'history';
import { Switch } from 'react-router-dom';
import AuthenticatedRoute from './AuthenticatedRoute';
import GenericClassroomRedirector from './GenericClassroomRedirector';
import UnitPage from './LessonPlan/UnitPage';
import ActivityStepPage, { UnitPosition } from './LessonPlan/ActivityStepPage';
import ConfirmationModal from './components/ConfirmationModal';
import { AppDispatch, RootState } from './ApplicationState';
import {
  ClassroomType,
  ElementaryActivityStep,
  ElementaryUnit,
  LessonPlanContent,
  SendParentUpdate,
} from '../peekapak-types/DataProtocolTypes';
import { AxiosAugmentedError, ErrorType } from './AugmentedError';
import { expireSessionAndRedirect } from './aaa';

interface Params {
  classroomName: string;
  unitId: string;
  subunitId: string | null | undefined;
  subunitNumber: string | null | undefined;
  lessonNumber: string | null | undefined;
  stepNumber: string | null | undefined;
}

const getUnitPositionFromParams = (params: Params) => {
  const atUnitOverviewLevel = () => !params.subunitId;

  if (atUnitOverviewLevel()) {
    return {
      subunit: 0,
      lesson: 0,
      step: 0,
    };
  } else {
    const subunit = parseInt(params.subunitNumber ?? '0', 10) - 1;
    const lesson = parseInt(params.lessonNumber ?? '0', 10) - 1;
    const step = parseInt(params.stepNumber ?? '0', 10) - 1;
    return {
      subunit,
      lesson,
      step,
    };
  }
};

const mapStateToProps = (state: RootState, ownProps) => ({
  classrooms: {
    state: state.classrooms.state,
    list: state.classrooms.classrooms,
    selectedClassroom: state.classrooms.selectedClassroom,
  },
  classroom: selectClassroom(state),
  completionStatus: selectUnitCompletionStatus(state),
  lessons: {
    state: state.lessons.state,
    unit: state.lessons.unit,
  },
  session: {
    unit: state.session.unit,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startLoadUnit: () => dispatch(startLoad()),
  loadUnit: (unit: LoadUnitPayload) => dispatch(loadUnit(unit)),
  unloadUnit: () => dispatch(unloadUnit()),
  updateClassroom: (classroom: ClassroomType) => dispatch(updateClassroom(classroom)),
  setUnit: (newUnit: string) => dispatch(setUnit(newUnit)),
});

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    params: Params;
    location: Location;
    children: React.ReactNode;
    isPreviewMode: boolean;
    isAuthenticated: boolean;
    history: History;
  };
type State = {
  unitId: string;
  isShowConfirmationModal: boolean | undefined;
  isShowLockedMessage: boolean;
  isStillInitializing: boolean;
  isUpdatingData: boolean;
  unit:
    | {
        headerImage: string;
        grade: number;
        title: string;
        label: string;
      }
    | ElementaryUnit['value']['lessonPlan'];
  unitPosition: UnitPosition;
  unitPositionToBeMarked: UnitPosition | undefined;
  confirmationModalsubtitle: string | React.ReactNode;
  confirmationModalTitle: string;
  cancelButtonText: string;
  confirmButtonText: string;
  isCurrentStepClassActivity: boolean;
  isSentHome: boolean;
};

function getCurrentUnitId(props: Props) {
  if (props.session.unit) {
    return `${props.session.unit}-${getLetterGradeFromNumberGrade(props.classroom.grade)}`;
  } else if (props.params.unitId) {
    return `${props.params.unitId}`;
  } else {
    throw new Error('Assertion that unit is known has failed');
  }
}

class LessonPlanLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isStillInitializing: true,
      isShowConfirmationModal: undefined,
      unitPosition: getUnitPositionFromParams(props.params),
      unitPositionToBeMarked: undefined,
      isShowLockedMessage: false,
      isUpdatingData: false,
      unitId: props.params.unitId,
      unit: {
        headerImage: '',
        title: '',
        grade: -99,
        label: '',
      },
      confirmationModalTitle: '',
      confirmationModalsubtitle: '',
      cancelButtonText: '',
      confirmButtonText: '',
      isCurrentStepClassActivity: true,
      isSentHome: false,
    };
  }

  handleClickLockedContent = () => {
    this.setState({
      isShowLockedMessage: true,
    });
  };
  handleHideLockedMessage = () => {
    this.setState({
      isShowLockedMessage: false,
    });
  };
  updateCompletionStatus = (newStatus: string, { unitId }: { unitId: string }, unitPosition: UnitPosition) => {
    const classroomUnderEdit = { ...this.props.classroom };
    const newCompletionStatus = { ...classroomUnderEdit.completionStatus };
    const newCompletionStatusForUnit = JSON.parse(JSON.stringify(newCompletionStatus[unitId]));
    newCompletionStatusForUnit[unitPosition.subunit][unitPosition.lesson][unitPosition.step] = newStatus;
    newCompletionStatus[unitId] = newCompletionStatusForUnit;
    classroomUnderEdit.completionStatus = newCompletionStatus;
    this.props.updateClassroom(classroomUnderEdit);
  };
  handleCompletionStatusUpdate = async (changeRequest: string, unitPosition: UnitPosition) => {
    if (this.props.lessons.state !== LessonsState.loaded) {
      return;
    }

    const currentStepState = (this.props.completionStatus as string[][][])[unitPosition.subunit][unitPosition.lesson][
      unitPosition.step
    ];

    if (currentStepState === 'Completed' && changeRequest === 'Complete') {
      return;
    }

    if (currentStepState === 'NotStarted' && changeRequest === 'Reset') {
      return;
    }
    if (!this.props.lessons.unit) {
      throw new Error('handleCompletionStatusUpdate: props.lessons.unit is undefined');
    }
    const unitId = `${this.props.lessons.unit.title}-${this.props.lessons.unit.grade}`;
    const ci = this.props.classrooms.selectedClassroom;
    const classroomId = this.props.classrooms.list[ci].classroomId;
    this.setState({
      isUpdatingData: true,
    });
    // console.debug(
    //   `%cLessonPlanLayout updating data`,
    //   'background: blue; color: yellow'
    // );
    try {
      if (changeRequest === 'Complete') {
        await markStepComplete(classroomId, unitId, unitPosition);
        this.updateCompletionStatus('Completed', this.props.params, unitPosition);
      } else {
        await resetStep(classroomId, unitId, unitPosition);
        this.updateCompletionStatus('NotStarted', this.props.params, unitPosition);
      }
    } catch (error) {
      if ((error as AxiosAugmentedError).code === ErrorType.UNAUTHORIZED) {
        expireSessionAndRedirect();
      } else {
        throw error;
      }
    } finally {
      this.setState({
        isUpdatingData: false,
      });
    }
  };
  handleSendHome = async () => {
    if (this.state.isCurrentStepClassActivity) {
      return;
    }
    const { subunit, lesson, step } = this.state.unitPositionToBeMarked as UnitPosition;
    const activityMeta: ElementaryActivityStep = (this.props.lessons.unit as unknown as LessonPlanContent).subUnits[
      subunit
    ].lessons[lesson].activities[step];
    const unitMeta = this.props.lessons.unit as unknown as LessonPlanContent;
    const stepTitle = activityMeta.title;
    const classroomId = this.props.classrooms.list[this.props.classrooms.selectedClassroom].classroomId;
    const classUpdate = activityMeta.classUpdate?.text;
    const homeActivity = activityMeta.activityInfo?.text;
    const storybookCoverUrl = `${getPublicMediaRoot()}images/thumbnails/${unitMeta.title}Bookshelf.png`;
    const storybookTitle = `${unitMeta.title}-${this.props.classroom.readingLevel}`;
    const storybookUrl = `${document.location.protocol}//${document.location.host}/story/${storybookTitle}`;
    const activitySheetUrl =
      activityMeta.files.length > 0
        ? `${getPublicMediaRoot()}resources/${fixedEncodeURIComponent(activityMeta.files[0].actualName)}`
        : '';
    const activitySheetDisplayName =
      activityMeta.files !== undefined && activityMeta.files.length > 0 ? activityMeta.files[0].displayName : '';

    const email = getUsersEmail();
    const sendParams: SendParentUpdate = {
      unitTitle: unitMeta.title,
      email: email!,
      stepTitle,
      classroomId,
      classUpdate: classUpdate!,
      homeActivity: homeActivity!,
      storybookCoverUrl,
      storybookUrl,
      activitySheetDisplayName,
      activitySheetUrl,
      webAppBaseUrl: `${window.location.origin}`,
    };
    const successIcon = () => {
      return (
        <>
          <span>
            <Icon className={styles.successIcon} icon='ep:success-filled' />
          </span>{' '}
          <strong>Success!</strong>
          <span className={styles.successMessage}>
            Home activity - <i>{stepTitle}</i> has been sent!
          </span>
        </>
      );
    };
    try {
      await sendParentEmailUpdateAndActivity(sendParams);
      setTimeout(() => {
        this.setState({
          isShowConfirmationModal: true,
          isSentHome: true,
          confirmationModalTitle: '',
          confirmationModalsubtitle: successIcon(),
          cancelButtonText: '',
          confirmButtonText: 'OK',
        });
      }, 500);
    } catch (error) {
      console.error('StepPage::handleOnSendHome - postServiceRequest failed');
      throw error;
    }
  };
  handleMarkStepComplete = (unitPosition: UnitPosition) => {
    const cs = computeCompletionStatus('step', this.props.completionStatus, unitPosition);
    if (cs !== 'Completed') {
      this.handleCompletionStatusUpdate('Complete', unitPosition);
    }
  };
  handleDismissConfirmationModal = () => {
    this.setState({
      isSentHome: false,
      isShowConfirmationModal: false,
    });
  };
  handleResetStep = (unitPosition: UnitPosition) => {
    this.handleCompletionStatusUpdate('Reset', unitPosition);
  };
  handleOnSendHome = (unitPosition: UnitPosition) => {
    const { lesson, subunit, step } = unitPosition;
    const stepTitle = (this.props.lessons.unit as unknown as LessonPlanContent).subUnits[subunit].lessons[lesson]
      .activities[step].title;

    this.setState({
      isShowConfirmationModal: true,
      unitPositionToBeMarked: unitPosition,
      confirmationModalTitle: `Would you like to send Home Activity - "${stepTitle}" to parents?`,
      confirmationModalsubtitle: '',
      isCurrentStepClassActivity: false,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    });
  };

  handleConfirmConfirmationModal = () => {
    if (this.state.isCurrentStepClassActivity) {
      if (!this.state.unitPositionToBeMarked) {
        throw new Error('handleConfirmConfirmationModal: state.unitPositionToBeMarked is undefined');
      }
      this.handleCompletionStatusUpdate('Complete', this.state.unitPositionToBeMarked);
    } else if (this.state.isSentHome) {
      this.handleDismissConfirmationModal();
    } else {
      this.handleSendHome();
    }
  };
  loadUnitLessons = () => {
    if (this.props.lessons.state === LessonsState.loading) {
      // console.debug(
      //   `%cAlready loading lessons...`,
      //   'background: orange; color: black'
      // );
      return Promise.resolve();
    }

    // console.debug(
    //   `%cActually loading lessons...`,
    //   'background: orange; color: black'
    // );
    this.props.startLoadUnit();
    return new Promise((resolve, reject) => {
      const classroom = this.props.classrooms.list[this.props.classrooms.selectedClassroom];
      const language = classroom.language;
      const name = `${language}-${this.props.params.unitId}`;
      const version = 'published';
      const keys = {
        name,
        version,
      };
      const unitInfo = {
        keys: keys,
        lessonMeta: {},
        //placeholder
        lastVersion: 0, //placeholder
      };
      // console.debug(
      //   `%cgetUnit: `,
      //   'background: grey; color: white',
      //   ' ',
      //   keys.name
      // );
      getUnit(unitInfo)
        .then((result) => {
          const unit = result.value.lessonPlan;
          // console.debug(noWhiteSpace`Get unit information unit.grade = ${unit.grade} unit.origin = ${unit.origin}
          // selectedClassroom = ${this.props.classrooms.selectedClassroom} className = ${classroom.className}`);
          this.props.loadUnit(unit);
          this.setState({
            isStillInitializing: false,
            unit,
          });
          return resolve();
        })
        .catch((err) => {
          console.error('getUnit failed with status ' + err);
          return reject(err);
        });
    });
  };

  async componentDidMount() {
    if (!this.props.session.unit) {
      const unitParts = this.props.params.unitId.split('-');
      const unitRootParts = unitParts.slice(0, -1);
      const unitRoot = unitRootParts.join('-');
      this.props.setUnit(unitRoot);
    }

    if (this.props.classrooms.state === ClassroomsState.loaded) {
      this.loadUnitLessons();
    }
  }

  componentWillUnmount() {
    this.props.unloadUnit();
  }

  classroomGradeMatchesLessonGrade = () => {
    if (this.props.classrooms.state !== ClassroomsState.loaded || this.props.lessons.state !== LessonsState.loaded) {
      // return boolean that won't cause the expression value to change
      return true;
    }

    const classroomGrade = getLetterGradeFromNumberGrade(this.props.classroom.grade);
    const lessonGrade = this.props.lessons.unit?.grade;
    return classroomGrade === lessonGrade;
  };

  async componentDidUpdate(prevProps: Props) {
    if (this.props.classroom !== prevProps.classroom) {
      // console.debug(
      //   `%cLPL didupdate classroom changed `,
      //   'background: green; color: white',
      //   prevProps.classroom?.className ?? 'none',
      //   ' / ',
      //   this.props.classroom?.className ?? 'none'
      // );
      const classroomName = this.props.classroom.className;
      this.props.history.push(`/${fixedEncodeURIComponent(classroomName)}/lessonPlan/${getCurrentUnitId(this.props)}`);
    }

    if (
      this.props.params.unitId !== prevProps.params.unitId ||
      this.props.classrooms.state !== prevProps.classrooms.state ||
      (this.props.classrooms.state === ClassroomsState.loaded && !this.classroomGradeMatchesLessonGrade())
    ) {
      // console.debug('Grade matches ', this.classroomGradeMatchesLessonGrade());
      // console.debug(
      //   '%cGo load unit lessons',
      //   'background: orange; color: black'
      // );
      this.loadUnitLessons();
    }
  }
  render() {
    // console.debug(
    //   `%cLessonPlanLayout render`,
    //   'background: blue; color: yellow'
    // );

    const isReadyToRenderChildren =
      this.props.classrooms.state === ClassroomsState.loaded &&
      this.props.lessons.state === LessonsState.loaded &&
      this.props.completionStatus !== undefined &&
      this.classroomGradeMatchesLessonGrade();

    // TODO - it's the combination of grade AND language that's req'd
    if (!this.props.isPreviewMode && !isReadyToRenderChildren) {
      // console.debug(
      //   `%cLessonPlanLayout spinner because not ready`,
      //   'background: blue; color: yellow'
      // );
      return <SpinnerOverlay isShow={true} />;
    }

    const { classroom } = this.props;
    const currentUnitPosition = getUnitPositionFromParams(this.props.params);
    const propagatingProps = {
      history: this.props.history,
      location: this.props.location,
      params: this.props.params,
      isAuthenticated: this.props.isAuthenticated,
      unitId: getCurrentUnitId(this.props),
      unitPosition: currentUnitPosition,
      unitMeta: this.props.lessons.unit,
      completionStatus: this.props.completionStatus,
      onClickLockedContent: this.handleClickLockedContent,
      onMarkStepComplete: this.handleMarkStepComplete,
      onSendHome: this.handleOnSendHome,
      onResetStep: this.handleResetStep,
      isSentHome: this.state.isSentHome,
    };

    return (
      <div className={styles.lessonPlanLayout}>
        <SpinnerOverlay isShow={this.state.isUpdatingData} />
        <div>
          <ActivityNavigator
            params={this.props.params}
            headerImage={getPublicMediaRoot() + this.props.lessons.unit?.headerImage}
            title={this.props.lessons.unit?.title}
            label={this.props.lessons.unit?.label}
            unitId={getCurrentUnitId(this.props)}
            unitPosition={currentUnitPosition}
            unitMeta={this.props.lessons.unit}
            completionStatus={this.props.completionStatus}
            classroom={classroom}
            onClickLockedContent={this.handleClickLockedContent}
            onMarkStepComplete={this.handleMarkStepComplete}
            onResetStep={this.handleResetStep}
            onSendHome={this.handleOnSendHome}
          />
          <Switch>
            <AuthenticatedRoute path='/:classroomName/lessonPlan/readAloud/:unitId/:subunitId/Subunit:subunitNumber/Lesson:lessonNumber/Step:stepNumber'>
              <ActivityStepPage {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/:classroomName/lessonPlan/:unitId/:subunitId/Subunit:subunitNumber/Lesson:lessonNumber/Step:stepNumber'>
              <ActivityStepPage {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/:classroomName/lessonPlan/:unitId'>
              <UnitPage {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/lessonPlan/:unitId'>
              <GenericClassroomRedirector {...propagatingProps} />
            </AuthenticatedRoute>
          </Switch>
          <LockedContentMessage isShow={this.state.isShowLockedMessage} onHide={this.handleHideLockedMessage} />
          <ConfirmationModal
            cancelText={this.state.cancelButtonText}
            confirmText={this.state.confirmButtonText}
            handleHide={this.handleDismissConfirmationModal}
            handlePerformAction={this.handleConfirmConfirmationModal}
            show={this.state.isShowConfirmationModal || false}
            title={this.state.confirmationModalTitle}
            subtitle={this.state.confirmationModalsubtitle}
          />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LessonPlanLayout);
