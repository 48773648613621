import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import { SpinnerOverlay } from '../../SpinnerOverlay';
import { InlineIcon, Icon } from '@iconify/react';
import closeCircleOutline from '@iconify/icons-eva/close-circle-outline';
import checkmarkCircleOutline from '@iconify/icons-eva/checkmark-circle-outline';
import eyeOutline from '@iconify/icons-eva/eye-outline';
import eyeOff2Outline from '@iconify/icons-eva/eye-off-2-outline';
import styles from './ReviewMultipleChoice.module.scss';
import { MultipleChoice } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  data: {
    assessmentMatrix?: MultipleChoice['assessmentMatrix'];
    content: {
      choices?: {
        score: number;
        text: string;
      }[];
      content?: string;
      correctIndices?: number[];
      data?: {
        choices: {
          score: number;
          text: string;
        }[];
        prompt?: string;
      };
      index: number;
      prompt?: string;
      type: string;
      title?: string;
    }[];
    grade: number[];
    title: string;
    type: string;
    index: number;
  };
  studentWork: {
    classroomId: string;
    createdAt: number;
    descriptor: string;
    lesson: string;
    metawork: {
      studentScore: number;
      totalScore?: number;
      scoreIndex?: number;
    };
    modifiedAt: number;
    module: string;
    status: string;
    todo: string;
    unit: string;
    userId: string;
    work: {
      content: {
        content: ({ answerIndex: number; index: number } | { answerText: string; index: number })[];
        index: number;
        type: string;
      }[];
    };
  };
  textAnswer: {
    grade: number[];
    index: number;
    question: string;
    type: string;
  }[];
  teacherReview?: boolean;
}

const ReviewMultipleChoice = ({ data, studentWork, teacherReview, textAnswer }: Props) => {
  const [isShowSpinner, _setShowSpinner] = useState(false);
  const [showCorrect, setShowCorrect] = useState<number[]>([]);

  const history = useHistory();
  const metawork = studentWork.metawork;

  const isRelfectionQuestionAtBeginning = textAnswer && textAnswer.find((item) => item.index === 300) !== undefined;

  const existingWork = studentWork?.work?.content !== undefined ? studentWork.work.content : null;

  const studentChoices = existingWork
    ? existingWork[0]?.content
        ?.map((object) => ('answerIndex' in object ? object.answerIndex : undefined))
        .filter((x) => x !== undefined)
    : null;

  const textResponse =
    existingWork && existingWork[0].content.some((item) => item.index === 200)
      ? existingWork[0]?.content.filter((item) => item.index === 200)[0]?.answerText
      : null;

  const reflectionResponse =
    existingWork && existingWork[0].content.some((item) => item.index === 300)
      ? existingWork[0]?.content.filter((item) => item.index === 300)[0]?.answerText
      : null;

  const hasTextBlock = data?.content?.some((item) => item.type === 'textBlock');

  const getTextAnswerFromMC = (questionIndex: number) => {
    const textAnswerFromMC =
      existingWork && existingWork[0].content
        ? existingWork[0]?.content.find((item) => item.index === questionIndex)?.answerText
        : null;
    return textAnswerFromMC;
  };

  const handleButtonClick = () => {
    history.push('/studentportal/ToDos');
  };

  const handleRevealClick = (index: number) => {
    const stateArray = [...showCorrect];
    !stateArray.includes(index) ? stateArray.push(index) : stateArray.splice(stateArray.indexOf(index));
    setShowCorrect(stateArray);
  };

  useEffect(() => {
    const showAllCorrect = studentChoices?.map((x, i) => i);
    teacherReview && setShowCorrect(showAllCorrect || []);
  }, [!teacherReview && showCorrect]);

  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isShowSpinner} />
      {isRelfectionQuestionAtBeginning && (
        <div className={styles.reflections}>
          <h3>Reflection</h3>
          <div
            className={styles.reflectionQuestion}
            dangerouslySetInnerHTML={{
              __html: textAnswer[0].question,
            }}
          />
          <div className={styles.answerContainer}>{reflectionResponse}</div>
        </div>
      )}
      <h3>{data.title}</h3>
      {data.content.map((question, ind) => {
        const mapIndex = hasTextBlock ? ind - 1 : ind;
        const studentChoicesMapIndex = studentChoices ? studentChoices[mapIndex] : null;

        const textAnswerFromMC = question.data?.input ? getTextAnswerFromMC(question.data.input.index) : null;

        return question.type === 'textBlock' ? (
          <div
            key={`textBlock ${ind}`}
            className={styles.title}
            dangerouslySetInnerHTML={{
              __html: question.content || '',
            }}
          />
        ) : (
          <div key={`question ${hasTextBlock ? ind : ind + 1}`}>
            <div className={styles.question}>
              {hasTextBlock ? ind : ind + 1}.&nbsp;
              {question.prompt ? question.prompt : question.data?.prompt}
            </div>
            <div
              id={question.index.toString()}
              role='group'
              aria-labelledby={question.index.toString()}
              className={styles.answers}
            >
              {question.data?.choices?.length || 0 > 0
                ? question.data?.choices?.map((choice: { score: number; text: string }, aInd: number) => {
                    return (
                      <label className={styles.assessmentLabel} key={`question#: ${ind + 1} answer: ${aInd + 1}`}>
                        <input
                          type='radio'
                          name={question.index.toString()}
                          value={aInd.toString()}
                          readOnly
                          checked={aInd === studentChoicesMapIndex}
                        />
                        &nbsp;&nbsp;{choice.text}
                      </label>
                    );
                  })
                : question.choices?.map((choice: { score: number; text: string }, i: number) => {
                    const isCorrect =
                      i === studentChoicesMapIndex &&
                      data.content[ind].correctIndices?.includes(studentChoicesMapIndex);
                    const isWrong =
                      i === studentChoicesMapIndex &&
                      !data.content[ind].correctIndices?.includes(studentChoicesMapIndex);
                    const isCorrectRevealed =
                      showCorrect.includes(mapIndex) && data.content[ind].correctIndices?.includes(i);
                    return (
                      <div className={styles.answerRow} key={`questionNo: ${ind + 1} answer: ${i + 1}`}>
                        <div
                          className={styles.answer}
                          style={{
                            backgroundColor: isCorrect
                              ? '#f0f8ea'
                              : isWrong
                                ? '#ffefe8'
                                : isCorrectRevealed
                                  ? '#e7f4f7'
                                  : '',
                          }}
                        >
                          <input
                            type='radio'
                            name={question.index.toString()}
                            value={i.toString()}
                            readOnly
                            checked={i === studentChoicesMapIndex}
                          />
                          <label>&nbsp;&nbsp;{choice.text}</label>
                          <span className={styles.icon}>
                            {isCorrect && <InlineIcon icon={checkmarkCircleOutline} color='#69BE28' />}
                            {isWrong && <InlineIcon icon={closeCircleOutline} color='#FF6319' />}
                            {isCorrectRevealed && !isCorrect && (
                              <InlineIcon icon={checkmarkCircleOutline} color='#00afd8' />
                            )}
                          </span>
                        </div>
                        <div className={styles.reveal}>
                          {isWrong && !teacherReview && (
                            <div className={styles.revealEye} onClick={() => handleRevealClick(mapIndex)}>
                              <InlineIcon
                                icon={showCorrect.includes(mapIndex) ? eyeOff2Outline : eyeOutline}
                                width={22}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
            </div>
            {textAnswerFromMC && (
              <div
                className={styles.answerContainer}
                dangerouslySetInnerHTML={{
                  __html: textAnswerFromMC,
                }}
              />
            )}
          </div>
        );
      })}
      {textResponse && (
        <div className={styles.scoring}>
          <div className={styles.studentScore}>Assessment Score:</div>
          {data.assessmentMatrix?.map((score, index) => {
            return (
              <div
                className={styles.answer}
                style={{
                  backgroundColor: index === metawork.scoreIndex ? '#e7f4f7' : '',
                }}
                key={`assessment score ${index}`}
              >
                {score.assessment}
                {index === metawork.scoreIndex && (
                  <Icon className={styles.icon} icon={checkmarkCircleOutline} color='#00afd8' width={18} />
                )}
              </div>
            );
          })}
          <div className={styles.textInput}>
            Thoughts about your score:
            <div className={styles.answerContainer}>{textResponse}</div>
          </div>
        </div>
      )}
      {!teacherReview && (
        <Button type='button' darkBlue thinText onClick={handleButtonClick}>
          View ToDos
        </Button>
      )}
    </div>
  );
};

export default ReviewMultipleChoice;
