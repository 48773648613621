import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ImageUploading from 'react-images-uploading';
import Button from '../Button';
import styles from './ToDoImageUpload.module.scss';
import { Icon } from '@iconify/react';
import cloudOutline from '@iconify/icons-eva/cloud-upload-outline';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import { submitAssignment } from '../../BackendInterface';

interface Props {
  data: {
    question: string;
    placeholder: string;
    index: number;
    template?: string;
    isMultiline?: boolean;
  }[];
  studentWork: {
    userId: string;
    createdAt: number;
    work: { content: { answerText: string }[] };
  };
}

const ToDoImageUpload = ({ data, studentWork }: Props): JSX.Element => {
  const [images, setImages] = useState<Record<string, unknown>[]>([]);
  const [preview, setPreview] = useState<Record<string, unknown> | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const maxNumber = 5;
  const { activity, toDo, grade, language } = useParams();
  const history = useHistory();
  const isTeacher = isNaN(parseInt(toDo));

  const handlePreview = (index: number) => {
    setPreview(images[index]);
  };

  const onChange = (imageList: Record<string, unknown>[]) => {
    setImages(imageList);
    setShowError(false);
  };

  const handleSubmit = async () => {
    if (images.length === 0) return setShowError(true);
    setSubmitting(true);

    const content = {
      type: 'imageCapture',
      index: data.index,
      content: images,
    };

    try {
      const saveResponse = await submitAssignment(studentWork.userId, studentWork.createdAt, [content]);
      // console.info(`result = ${JSON.stringify(saveResponse, null, 2)}`);
      setShowError(false);
    } catch (error) {
      console.info(`error is ${JSON.stringify(error, null, 2)}`);
      setShowError(true);
    } finally {
      setSubmitting(false);
      history.push(`/studentportal/activity/${activity}/${language}/${grade}/toDo/${toDo}/confirmation`);
    }
  };

  useEffect(() => {
    setPreview(null);
  }, [images]);
  return (
    <>
      {!!preview && (
        <div className={styles.previewImageContainer} onClick={() => setPreview(null)}>
          <div className={styles.closeHint}>x</div>
          <div className={styles.imgContainer}>
            <img src={preview['data_url']} alt='' />
          </div>
        </div>
      )}
      <div key={data.title} className={styles.container}>
        <h3>{data.prompt}</h3>
        <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey='data_url'>
          {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
            <div>
              {images[0] !== undefined && (
                <div className={styles.previewContainer}>
                  {imageList.map((image, index) => (
                    <div key={index} className={styles.assetCard}>
                      <img src={image['data_url']} alt='' />
                      <h4 className={styles.cardTitle}>{image?.file?.name}</h4>
                      <div className={styles.assetLinks}>
                        <div onClick={() => handlePreview(index)}>View</div>
                        <div onClick={() => onImageRemove(index)}>Remove</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              <Button
                thinText
                upload
                onClick={onImageUpload}
                className={isDragging ? styles.dragDrop : ''}
                {...dragProps}
              >
                {!isSubmitting ? (
                  <>
                    <Icon icon={cloudOutline} width={24} className={styles.icon} />
                    {isDragging ? 'Drop files here' : 'Upload Your Submission'}
                  </>
                ) : (
                  'Uploading Image'
                )}
              </Button>
            </div>
          )}
        </ImageUploading>
        <Button thinText darkBlue disabled={isTeacher} onClick={handleSubmit}>
          Submit
        </Button>
        {!isShowError ? null : (
          <div className={styles.error}>
            <Icon icon={alertCircleFill} color='#ff6319' className={styles.icon} width={18} />
            {images.length === 0
              ? 'Please select an image to upload'
              : 'Unable to submit, please check your internet connection or try again later'}
          </div>
        )}
      </div>
    </>
  );
};

export default ToDoImageUpload;
