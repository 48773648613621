import { Prompt } from '../../components/LeftRightCard/LeftRightCard';
import SectionHeader from '../../components/SectionHeader';
import imageOne from '../../images/UAELanding/student-interact-peekaville-1.png';
import imageTwo from '../../images/UAELanding/teaching-peekaville.png';
import imageThree from '../../images/UAELanding/student-interact-peekaville-2.png';
import styles from './PeekapakInAbuDhabiSection.module.scss';
import Documents from '../../Documents';

const data = [
  {
    percent: '100%',
    text: 'of teachers said students enjoyed the stories.',
  },
  {
    percent: '30%',
    text: 'gain on students emotional vocabulary.',
  },
  {
    percent: '8%',
    text: 'on gratitude and respect skills.',
  },
];

const images = [
  {
    image: imageOne,
    alt: 'student interact with myPeekaville',
  },
  {
    image: imageTwo,
    alt: 'teacher teaching peekapak lesson',
  },
  {
    image: imageThree,
    alt: 'another student interact with myPeekaville',
  },
];

const PeekapakInAbuDhabiSection = () => {
  return (
    <section className={styles.peekapakInAbuDhabiSection}>
      <SectionHeader
        className={styles.sectionHeader}
        preTitle='2023 PILOT DATA'
        title='Success with Peekapak in Abu Dhabi'
        copy='Abu Dhabi Grammar School (ADGS) used Peekapak in May 2023 to further their commitment to social-emotional learning (SEL). After a six week pilot focused on learning self-regulation skills they saw:'
        center
      />
      <Prompt clickHandler={() => window.open(Documents.ADGSCaseStudy, '_blank')}>Download the Study</Prompt>
      <div className={styles.successData}>
        {data.map((item, index) => (
          <div key={index} className={styles.dataContainer}>
            <div className={styles.circle}>
              <h3 className={styles.percent}>{item.percent}</h3>
            </div>
            <div className={styles.text}>{item.text}</div>
          </div>
        ))}
      </div>
      <div className={styles.imageContainer}>
        {images.map((item, index) => (
          <img key={index} src={item.image} alt={item.alt} />
        ))}
      </div>
    </section>
  );
};

export default PeekapakInAbuDhabiSection;
