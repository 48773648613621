import { Component } from 'react';
import styles from '../SCSS/LockIcon.module.scss';
import lockIcon from '../images/pkp-lock.svg';

interface Props {
  styleOverride?: React.CSSProperties;
}

export class LockIcon extends Component<Props> {
  render() {
    return <img alt='Lock' className={styles.lockIconImage} style={this.props.styleOverride} src={lockIcon} />;
  }
}
