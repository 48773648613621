import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { secondarySchoolLessonNumberLookup } from '../GlobalFunctions';
import { Icon } from '@iconify/react';
import bookOutline from '@iconify-icons/ion/book-outline';
import checkmarkIcon from '@iconify-icons/bx/bx-check';
import styles from './UnitNavigator.module.scss';
import { CompletionStatus, LessonCompletionStatus } from '../../peekapak-types/LessonPlanTypes';

interface Props {
  title: string;
  lessonItems: {
    lessonId: string;
    title: string;
    lessonFlow: { index: number; id: string }[];
    overview: string;
  }[];
  handleNavigatorClick: (lessonType: string, activityId: string) => void;
  completionStatus?: CompletionStatus;
}

interface urlParams {
  unitId: string;
  grade: string;
  language: string;
  lesson: string;
}

const UnitNavigator = ({ title, lessonItems, handleNavigatorClick, completionStatus }: Props) => {
  const isBig = useMediaQuery({ minWidth: 768 });
  const isXSmall = useMediaQuery({ maxWidth: 414 });
  const history = useHistory();
  const { unitId, grade, language, lesson }: urlParams = useParams();
  const isHighSchool = parseInt(grade) > 8;

  return (
    <div className={styles.navigatorContainer}>
      {!isXSmall && (
        <div
          key='small'
          className={styles.bookIcon}
          onClick={() => history.push(`/lessonPlan/${unitId}/${language}/${grade}`)}
        >
          <Icon icon={bookOutline} color='#fff' width={isBig ? 18 : 12} />
        </div>
      )}
      {isBig && (
        <div key='big' onClick={() => history.push(`/lessonPlan/${unitId}/${language}/${grade}`)}>
          <h2>{title}</h2>
        </div>
      )}
      <div className={styles.numberLinkContainer}>
        {lessonItems?.map((x, i) => {
          const activityId = x.lessonFlow[0].id;
          const isCurrentLesson =
            secondarySchoolLessonNumberLookup(x.lessonId, isHighSchool) ===
            secondarySchoolLessonNumberLookup(lesson, isHighSchool);

          return (
            <span key={`item ${i + 1}`}>
              {completionStatus !== undefined &&
              x.lessonId in completionStatus &&
              Object.values(completionStatus[x.lessonId] as LessonCompletionStatus).every(
                (v: string) => v === 'Completed' || 'DeemedCompleted',
              ) &&
              Object.values(completionStatus[x.lessonId]).length === x.lessonFlow.length ? (
                <div
                  className={isCurrentLesson ? styles.completeOutline : styles.complete}
                  key={`complete ${i + 1}`}
                  onClick={() => handleNavigatorClick(x.lessonId, activityId)}
                >
                  <Icon icon={checkmarkIcon} color='#fff' width='1.5em' />
                </div>
              ) : (
                <div
                  key={`incomplete ${i + 1}`}
                  className={isCurrentLesson ? styles.incompleteOutline : styles.incomplete}
                  onClick={() => handleNavigatorClick(x.lessonId, activityId)}
                >
                  {i + 1}
                </div>
              )}
              {i + 1 < lessonItems.length && <div key={`line ${i + 1}`} className={styles.connectingLine} />}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default UnitNavigator;
