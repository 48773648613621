import { useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button';
import { SpinnerOverlay } from '../../SpinnerOverlay';

import styles from './ReviewStrengthsEvaluation.module.scss';
import {
  QuestionAnswer,
  StrengthsEvaluationMetawork,
  Assignment,
  MultipleChoiceWork,
  EmbeddedContent,
} from '../../../peekapak-types/LessonPlanTypes';

interface StudentWork extends Assignment {
  userId: string;
  createdAt: number;
  work: {
    content: [MultipleChoiceWork];
  };
  metawork: StrengthsEvaluationMetawork;
}

interface StrengthsQuestions {
  index: number;
  question1: string;
  question2: string;
  strength1: string;
  strength2: string;
}

interface StrengthsExplanation {
  strength: string;
  description: string;
}

interface StrengthsEvaluation extends EmbeddedContent {
  questions: StrengthsQuestions[];
  tiebreakerQuestions: StrengthsQuestions[];
  content: StrengthsExplanation[];
}

interface Props {
  teacherReview: boolean;
  data: StrengthsEvaluation;
  studentWork: StudentWork;
  textAnswer: QuestionAnswer[];
}

/*
interface FormValues {
  index: number;
  value: string;
}
 */
type FormValues = {
  // value is the index [0..3] of the answer in string form
  [index: string]: string;
};

const ReviewStrengthsEvaluation = ({ teacherReview, data, studentWork, textAnswer }: Props): JSX.Element => {
  const [isShowSpinner, setShowSpinner] = useState(false);

  const history = useHistory();

  const existingWork =
    studentWork?.work?.content !== undefined
      ? studentWork.work.content[0].content.filter((item) => Number.isFinite(item.index))
      : null;

  const textResponse = existingWork?.some((item) => item.index === 200)
    ? existingWork?.filter((item) => item.index === 200)[0]?.answerText
    : null;

  const displayQuestions = (() => {
    if (studentWork?.metawork?.questionOrder) {
      return studentWork.metawork.questionOrder.reduce((acc, curr) => {
        const question = data.questions.find((item) => item.index === curr);
        if (question) {
          return [...acc, question];
        }
        return acc;
      }, [] as StrengthsQuestions[]);
    }
  })();

  const tiebreakerDisplay = (() => {
    if (studentWork?.metawork?.tiebreakerOrder) {
      return studentWork.metawork.tiebreakerOrder.reduce((acc, curr) => {
        const question = data.tiebreakerQuestions.find((item) => item.index === curr);
        if (question) {
          return [...acc, question];
        }
        return acc;
      }, [] as StrengthsQuestions[]);
    }
  })();

  const getStrengthText = (keyStrength: string) =>
    data.content.find((item) => item.strength === keyStrength) || {
      strength: '',
      description: '',
    };

  const optionsText = ['Very much like me', 'Somewhat like me', 'Somewhat like me', 'Very much like me'];

  const calculateScore = (values: FormValues) => {
    const answerArray = Object.entries(values).reduce(
      (acc, [k, v]) => [
        ...acc,
        {
          index: parseInt(k),
          answerIndex: parseInt(v),
        },
      ],
      [] as { index: number; answerIndex: number }[],
    );

    const combinedQuestions = [...displayQuestions, ...tiebreakerDisplay];
    const strengthsObject = combinedQuestions.reduce(
      (acc: { [key: string]: number }, curr: StrengthsQuestions) => {
        const thisIndex = answerArray.find((item) => item.index === curr.index);
        if (!thisIndex || isNaN(thisIndex?.answerIndex)) return acc;
        if (thisIndex.answerIndex === 0)
          return acc?.[curr.strength1]
            ? { ...acc, [curr.strength1]: acc[curr.strength1] + 2 }
            : { ...acc, [curr.strength1]: 2 };
        if (thisIndex.answerIndex === 1)
          return acc?.[curr.strength1]
            ? { ...acc, [curr.strength1]: acc[curr.strength1] + 1 }
            : { ...acc, [curr.strength1]: 1 };
        if (thisIndex.answerIndex === 2)
          return acc?.[curr.strength2]
            ? { ...acc, [curr.strength2]: acc[curr.strength2] + 1 }
            : { ...acc, [curr.strength2]: 1 };
        if (thisIndex.answerIndex === 3)
          return acc?.[curr.strength2]
            ? { ...acc, [curr.strength2]: acc[curr.strength2] + 2 }
            : { ...acc, [curr.strength2]: 2 };
        return acc;
      },
      {} as { [key: string]: number },
    );
    const strengths = Object.keys(strengthsObject).filter((x) => {
      return strengthsObject[x] == Math.max.apply(null, Object.values(strengthsObject));
    });

    return strengths;
  };

  const values = studentWork.work.content[0].content.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.index]: curr.answerIndex === null ? '' : curr.answerIndex && curr.answerIndex.toString(),
    }),
    {} as FormValues,
  );

  const keyStrength = calculateScore(values);

  const assessmentText = getStrengthText(keyStrength[0]);

  const handleButtonClick = () => {
    history.push('/studentportal/ToDos');
  };
  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isShowSpinner} />
      <div className={styles.questionContainer}>
        {displayQuestions?.map((question, ind) => {
          const questionIndex = question.index.toString();
          return (
            <div className={styles.questionBox} key={`question ${ind + 1}`}>
              <div
                id={questionIndex}
                role='group'
                aria-labelledby={question.index.toString()}
                className={styles.answers}
              >
                <div className={styles.questionRow}>
                  <p>{question.question1} </p>
                  <p>{question.question2}</p>
                </div>
                <div className={styles.answerRow}>
                  {optionsText.map((option: string, aInd: number) => {
                    return (
                      <label key={`question#: ${ind + 1} answer: ${aInd + 1}`}>
                        <input
                          type='radio'
                          name={question.index}
                          value={aInd.toString()}
                          readOnly
                          checked={parseInt(values[questionIndex]) === aInd}
                        />
                        {option}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        {tiebreakerDisplay !== undefined && tiebreakerDisplay.length > 0 && (
          <div className={styles.questionContainer}>
            <h2 className={styles.title}>Tiebreaker Questions</h2>
            {tiebreakerDisplay?.map((question, ind) => {
              const questionIndex = question.index.toString();
              const thisAnswer = studentWork.work.content[0].content.find(
                (item) => item.index.toString() === questionIndex,
              );
              const hasAnswer = thisAnswer?.answerIndex !== null;
              return hasAnswer ? (
                <Fragment key={`tiebreakerQuestion ${ind + 1}`}>
                  <div className={styles.questionBox}>
                    <div
                      id={questionIndex}
                      role='group'
                      aria-labelledby={question.index.toString()}
                      className={styles.answers}
                    >
                      <div className={styles.questionRow}>
                        <p>{question.question1}</p>
                        <p>{question.question2}</p>
                      </div>
                      <div className={styles.answerRow}>
                        {optionsText.map((option: string, aInd: number) => {
                          return (
                            <label key={`question#: ${ind + 1} answer: ${aInd + 1}`}>
                              <input
                                type='radio'
                                name={question.index}
                                value={aInd.toString()}
                                readOnly
                                checked={parseInt(values[questionIndex]) === aInd}
                              />
                              {option}
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Fragment>
              ) : null;
            })}
          </div>
        )}
      </div>
      <div>
        <div className={styles.assessmentContainer}>
          <h3>
            <u>Your Strength is: {assessmentText?.strength}</u>
          </h3>
          <div
            dangerouslySetInnerHTML={{
              __html: assessmentText?.description,
            }}
          />
          <div className={styles.reflections}>
            <div className={styles.textInput}>
              <p
                dangerouslySetInnerHTML={{
                  __html: textAnswer[0].question,
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.textInput}>
          Thoughts about your score:
          <div
            className={styles.answerContainer}
            dangerouslySetInnerHTML={{
              __html: textResponse,
            }}
          />
        </div>
        {!teacherReview && (
          <Button type='button' darkBlue thinText onClick={handleButtonClick}>
            View ToDos
          </Button>
        )}
      </div>
    </div>
  );
};

export default ReviewStrengthsEvaluation;
