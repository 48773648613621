import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { myBrowserHistory } from '../ApplicationState';
import { getPublicMediaRoot } from '../serviceAgent';
import { ExternalLink } from '../components/ExternalLink';
import readStoryIcon from '../images/pkp-readStoryIcon.svg';
import styles from '../SCSS/UnitPage.module.scss';

const unitToStore = {
  'Self-Regulation': 'https://shop.peekapak.com/collections/self-regulation',
  Respect: 'https://shop.peekapak.com/collections/respect',
  Gratitude: 'https://shop.peekapak.com/collections/gratitude',
  Perseverance: 'https://shop.peekapak.com/collections/perseverance',
  Empathy: 'https://shop.peekapak.com/collections/empathy',
  Teamwork: 'https://shop.peekapak.com/collections/teamwork',
  Kindness: 'https://shop.peekapak.com/collections/kindness',
  Honesty: 'https://shop.peekapak.com/collections/honesty',
  Optimism: 'https://shop.peekapak.com/collections/optimism',
  Courage: 'https://shop.peekapak.com/collections/courage',
};

class ReadStoryButton extends Component {
  constructor(props) {
    super(props);

    this.openStory = this.openStory.bind(this);
  }

  openStory() {
    if (this.props.isPreviewMode) {
      return null;
    }
    myBrowserHistory.push(`/story/${this.props.storyUrl}`);
  }

  render() {
    let unitThumbnailName = this.props.unitTitle;
    let storybookCoverUrl;
    if (unitThumbnailName === 'Imagination' && parseInt(this.props.storyUrl.slice(-1)) > 2) {
      unitThumbnailName = 'ImaginationStation';
    } else {
      storybookCoverUrl =
        this.props.language && this.props.language !== 'en'
          ? `${getPublicMediaRoot()}images/thumbnails/${this.props.language}${unitThumbnailName}Bookshelf.png`
          : `${getPublicMediaRoot()}images/thumbnails/${unitThumbnailName}Bookshelf.png`;
    }

    return (
      <div className={styles.readStoryButton}>
        <div onClick={this.openStory} role='button' tabIndex={0}>
          <img src={storybookCoverUrl} className={styles.readStoryButtonImage} alt='book cover' />
        </div>
        <div className={styles.readStoryButtonWrapper}>
          <Button className={styles.readStoryButton} onClick={this.openStory}>
            <img src={readStoryIcon} alt='read story book' />
            &nbsp; Read Story
          </Button>
        </div>
        {unitToStore[this.props.unitTitle] && (
          <div className={styles.readStoryButtonWrapper}>
            <ExternalLink href={unitToStore[this.props.unitTitle]} target='_blank'>
              <Button className={styles.readStoryButton}>&nbsp; Buy Print Books</Button>
            </ExternalLink>
          </div>
        )}
      </div>
    );
  }
}

ReadStoryButton.propTypes = {
  isPreviewMode: PropTypes.boolean,
  storyUrl: PropTypes.string.isRequired,
  unitTitle: PropTypes.string.isRequired,
  language: PropTypes.string,
};

ReadStoryButton.defaultProps = {
  language: '',
};

export default ReadStoryButton;
