import { UnitPosition } from './LessonPlan/ActivityStepPage';

const areAllStepsInSubunitCompleted = (completionStatus: string[][][], subunitToExamine: number) => {
  let isCompleted = true;

  completionStatus.forEach((subunit: string[][], index: number) => {
    if (index === subunitToExamine) {
      subunit.forEach((lesson) => {
        lesson.forEach((step) => {
          if (step !== 'Completed') {
            isCompleted = false;
          }
        });
      });
    }
  });

  return isCompleted;
};

const areAllStepsInSubunitNotStarted = (completionStatus: string[][][], subunitToExamine: number) => {
  let isNotStarted = true;

  completionStatus.forEach((subunit, index) => {
    if (index === subunitToExamine) {
      subunit.forEach((lesson) => {
        lesson.forEach((step) => {
          if (step !== 'NotStarted') {
            isNotStarted = false;
          }
        });
      });
    }
  });

  return isNotStarted;
};

const areAllStepsInLessonCompleted = (completionStatus: string[][][], lessonToExamine: UnitPosition) => {
  const searchArray = completionStatus[lessonToExamine.subunit][lessonToExamine.lesson];

  for (let step = 0; step < searchArray.length; step++) {
    if (searchArray[step] !== 'Completed') {
      return false;
    }
  }

  return true;
};

const areAllStepsInLessonNotStarted = (completionStatus: string[][][], lessonToExamine: UnitPosition) => {
  const searchArray = completionStatus[lessonToExamine.subunit][lessonToExamine.lesson];

  for (let step = 0; step < searchArray.length; step++) {
    if (searchArray[step] !== 'NotStarted') {
      return false;
    }
  }

  return true;
};

const areAllStepsInUnitCompleted = (completionStatus: string[][][]) => {
  for (let si = 0; si < completionStatus.length; ++si) {
    if (!areAllStepsInSubunitCompleted(completionStatus, si)) {
      return false;
    }
  }

  return true;
};

const areAllStepsInUnitNotStarted = (completionStatus: string[][][]) => {
  for (let si = 0; si < completionStatus.length; ++si) {
    if (!areAllStepsInSubunitNotStarted(completionStatus, si)) {
      return false;
    }
  }

  return true;
};

export const isStepCompleted = (completionStatus: string[][][], unit: number, lesson: number, step: number) => {
  return completionStatus[unit][lesson][step] === 'Completed';
};

export const computeCompletionStatus = (
  level: 'subunit' | 'unit' | 'lesson' | 'step',
  completionStatus: string[][][],
  unitPosition: UnitPosition,
) => {
  // one reason that completionStatus is undefined
  // is when it's being called for previews
  // in this case, ignore the completion status
  if (completionStatus === undefined) {
    return 'NotStarted';
  }

  switch (level) {
    case 'subunit':
      if (areAllStepsInSubunitCompleted(completionStatus, unitPosition.subunit)) {
        return 'Completed';
      } else if (areAllStepsInSubunitNotStarted(completionStatus, unitPosition.subunit)) {
        return 'NotStarted';
      }
      return 'InProgress';
    case 'unit':
      if (areAllStepsInUnitCompleted(completionStatus)) {
        return 'Completed';
      } else if (areAllStepsInUnitNotStarted(completionStatus)) {
        return 'NotStarted';
      }
      return 'InProgress';
    case 'lesson':
      if (areAllStepsInLessonCompleted(completionStatus, unitPosition)) {
        return 'Completed';
      } else if (areAllStepsInLessonNotStarted(completionStatus, unitPosition)) {
        return 'NotStarted';
      }
      return 'InProgress';
    case 'step': {
      const subunit = unitPosition.subunit;
      const lesson = unitPosition.lesson;
      const step = unitPosition.step;

      return completionStatus[subunit][lesson][step];
    }

    default:
      throw new Error(`computeCompletionStatus: Unknown level ${level}`);
  }
};

export const findFirstNotStartedSubunit = (completionStatus: string[][][]) => {
  for (let si = 0; si < completionStatus.length; ++si) {
    if (areAllStepsInSubunitNotStarted(completionStatus, si)) {
      return si;
    }
  }

  throw new Error('findFirstNotStartedSubunit: There are no entirely NotStarted Subunits');
};

export const findFirstNotStartedLesson = (completionStatus: string[][][]) => {
  for (let si = 0; si < completionStatus.length; si++) {
    for (let li = 0; li < completionStatus[si].length; li++) {
      if (
        areAllStepsInLessonNotStarted(completionStatus, {
          subunit: si,
          lesson: li,
          step: NaN, // don't care about step
        })
      ) {
        return { subunit: si, lesson: li };
      }
    }
  }

  throw new Error('findFirstNotStartedLesson: There are no NotStarted lessons');
};

export const findFirstInProgressLesson = (completionStatus: string[][][]) => {
  for (let si = 0; si < completionStatus.length; si++) {
    for (let li = 0; li < completionStatus[si].length; li++) {
      const checkIndex = { subunit: si, lesson: li, step: NaN };
      // cmSt   lc lns  interpretation
      // N, N - F, T - in progress
      // C, N - F, F - in progress
      // N, C - F, F - in progress
      // C, C - T, F - not in progress
      const isLessonCompleted = areAllStepsInLessonCompleted(completionStatus, checkIndex);
      if (!isLessonCompleted) {
        return checkIndex;
      }
    }
  }

  throw new Error('findFirstInProgressLesson: There are no InProgress lessons');
};

export const findFirstInProgressSubunit = (completionStatus: string[][][]) => {
  for (let si = 0; si < completionStatus.length; ++si) {
    if (!areAllStepsInSubunitCompleted(completionStatus, si) && !areAllStepsInSubunitNotStarted(completionStatus, si)) {
      return si;
    }
  }

  throw new Error('findFirstInProgressSubunit: There are no InProgress Subunits');
};

export const findFirstInProgressStepInSubunit = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  const startSubunit = fromUnitPosition.subunit;
  for (let si = startSubunit; si < completionStatus.length; si++) {
    for (let li = 0; li < completionStatus[si].length; li++) {
      for (let step = 0; step < completionStatus[si][li].length; step++) {
        if (completionStatus[si][li][step] === 'InProgress') {
          return { subunit: si, lesson: li, step };
        }
      }
    }
  }

  throw new Error('findFirstInProgressStepInSubunit: There are no InProgress steps');
};

export const findFirstNotStartedStepInSubunit = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  const startSubunit = fromUnitPosition.subunit;
  for (let si = startSubunit; si < completionStatus.length; si++) {
    for (let li = 0; li < completionStatus[si].length; li++) {
      for (let step = 0; step < completionStatus[si][li].length; step++) {
        if (completionStatus[si][li][step] === 'NotStarted') {
          return { subunit: si, lesson: li, step };
        }
      }
    }
  }

  throw new Error('findFirstInProgressStepInSubunit: There are no NotStarted steps');
};

export const areAnySubunitsEntirelyNotStarted = (completionStatus: string[][][]) => {
  try {
    findFirstNotStartedSubunit(completionStatus);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAnySubunitsInProgress = (completionStatus: string[][][]) => {
  try {
    findFirstInProgressSubunit(completionStatus);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAnyLessonsEntirelyNotStarted = (completionStatus: string[][][]) => {
  try {
    findFirstNotStartedLesson(completionStatus);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAnyLessonsInProgress = (completionStatus: string[][][]) => {
  try {
    findFirstInProgressLesson(completionStatus);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAnyStepsInProgressInSubunit = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  try {
    findFirstInProgressStepInSubunit(completionStatus, fromUnitPosition);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAnyStepsNotStartedInSubunit = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  try {
    findFirstNotStartedStepInSubunit(completionStatus, fromUnitPosition);
    return true;
  } catch (e) {
    return false;
  }
};

export const findFirstInProgressStepInLesson = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  const startSubunit = fromUnitPosition.subunit;
  const startLesson = fromUnitPosition.lesson;
  const searchArray = completionStatus[startSubunit][startLesson];

  for (let step = 0; step < searchArray.length; step++) {
    if (searchArray[step] === 'InProgress') {
      return { subunit: startSubunit, lesson: startLesson, step };
    }
  }

  throw new Error('findFirstInProgressStepInLesson: There are no InProgress steps');
};

export const findFirstNotStartedStepInLesson = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  const startSubunit = fromUnitPosition.subunit;
  const startLesson = fromUnitPosition.lesson;
  const searchArray = completionStatus[startSubunit][startLesson];

  for (let step = 0; step < searchArray.length; step++) {
    if (searchArray[step] === 'NotStarted') {
      return { subunit: startSubunit, lesson: startLesson, step };
    }
  }

  throw new Error('findFirstNotStartedStepInLesson: There are no NotStarted steps');
};

export const areAnyStepsInProgressInLesson = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  try {
    findFirstInProgressStepInLesson(completionStatus, fromUnitPosition);
    return true;
  } catch (e) {
    return false;
  }
};

export const areAnyStepsNotStartedInLesson = (completionStatus: string[][][], fromUnitPosition: UnitPosition) => {
  try {
    findFirstNotStartedStepInLesson(completionStatus, fromUnitPosition);
    return true;
  } catch (e) {
    return false;
  }
};

export const countCompletionStatuses = (completionStatus: string[][][]) => {
  const statuses: Record<string, number> = {
    Completed: 0,
    NotStarted: 0,
    InProgress: 0,
    Total: 0,
  };

  let total = 0;

  if (completionStatus === undefined) {
    statuses.Total = 1;
    return statuses;
  }

  for (let si = 0; si < completionStatus.length; ++si) {
    const subunit = completionStatus[si];

    for (let sui = 0; sui < subunit.length; ++sui) {
      const lesson = subunit[sui];

      for (let li = 0; li < lesson.length; ++li) {
        total += 1;
        statuses[lesson[li]] = statuses[lesson[li]] + 1;
      }
    }
  }

  statuses.Total = total;

  return statuses;
};

export const getSubunitCompletionStatus = (completionStatus: string[][][], subunitIndex: number) => {
  const status: Record<string, number> = {
    Completed: 0,
    NotStarted: 0,
    InProgress: 0,
    Total: 0,
  };

  let total = 0;
  const subunit = completionStatus[subunitIndex];

  for (let sui = 0; sui < subunit.length; ++sui) {
    const lesson = subunit[sui];

    for (let li = 0; li < lesson.length; ++li) {
      total += 1;
      status[lesson[li]] = status[lesson[li]] + 1;
    }
  }

  status.Total = total;

  return status;
};
