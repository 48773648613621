import { CSSProperties, useState } from 'react';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import { noWhiteSpace } from '../GlobalFunctions';
import AwardSection from '../components/AwardSection';
import MuxPlaceholderSplash from '../components/MuxPlaceholderSplash';
import reason01 from '../images/wheel.png';
import reason02 from '../images/coppa.png';
import reason03 from '../images/video.png';
import reason04 from '../images/mockup-k-8.png';
import reason05 from '../images/diversity.png';
import reason06 from '../images/dashboard.png';
import styles from '../SCSS/ReasonsSegment.module.scss';

const reasons = [
  {
    name: 'casel',
    title: 'Aligned to CASEL standards',
    copy: noWhiteSpace`Peekapak’s curriculum is aligned to CASEL’s framework of
    five social emotional learning competencies. Students develop these important skills through engaging stories, fun games and activities to continuously practice and reinforce learning.`,
    image: reason01,
    imageAlt: 'CASEL areas',
  },
  {
    name: 'coppa',
    title: 'Safe and Effective in Schools',
    copy: noWhiteSpace`Peekapak’s lessons are developed using evidence-based
    practices and have been researched in districts, Pre-K and after-school
    programs. We value student privacy and are iKeepSafe Certified (COPPA
    compliant).`,
    image: reason02,
    imageAlt: 'coppa seal',
  },
  {
    name: 'enaging',
    title: 'Engages Students',
    copy: noWhiteSpace`Peekapak gives students agency to explore a personalized learning experience where they can practice lessons learned in class.`,
    image: reason03,
    imageAlt: 'teacher in classroom',
    videoId: '4Dqq2tPjqHNQkrp9LYpk01i21uDo6IdHeHTflBPdwLuY',
  },
  {
    name: 'accessible',
    title: 'Accessible and Easily Modified',
    copy: noWhiteSpace`Use all Peekapak units and resources for ongoing
    classroom support or select the pieces that fit within your existing
    curriculum. Our levelled readers and variety of lessons provide you flexibility to meet the needs of all your students.`,
    image: reason04,
    imageAlt: 'snapshot of library view',
  },
  {
    name: 'diversity',
    title: 'Promotes Diversity and Inclusion',
    copy: noWhiteSpace`With Peekapak, educators, parents, and students all play a role in the learning process by celebrating the diversity in each other’s backgrounds, talents and interests.`,
    image: reason05,
    imageAlt: 'group play',
  },
  {
    name: 'reports',
    title: 'Real-Time Reports and Insights',
    copy: noWhiteSpace`Teachers and administrators can monitor real-time
    progress, see insights on student and parent engagement, and collect social emotional data through our Mood Board.`,
    image: reason06,
    imageAlt: 'mood board',
  },
];

interface Props {
  className?: string;
  reasonsArr?: ReasonsArraryProps[];
  reasonsGridStyle?: CSSProperties;
  reasonsGridStyleMobile?: CSSProperties;
}

interface ReasonsArraryProps {
  name: string;
  title: string;
  copy: string;
  image: string;
  imageAlt: string;
  videoId?: string | undefined;
}

const ReasonsGrid = ({
  reasonsArr = reasons,
  reasonsGridStyle,
  reasonsGridStyleMobile,
  className,
}: Props): JSX.Element => {
  const [activeItem, setActiveItem] = useState(reasonsArr[0].name);
  function handleListClick(name: string) {
    setActiveItem(name);
  }

  return (
    <section className={cx(styles.content, className)}>
      <MediaQuery minWidth={1280}>
        <section className={styles.reasonsGrid} style={reasonsGridStyle as CSSProperties}>
          <ul className={styles.reasonsList}>
            {reasonsArr.map((reason: ReasonsArraryProps, index: number) => {
              return (
                <li
                  className={cx(styles.reason, {
                    [`${styles.activeItem}`]: activeItem === reason.name,
                  })}
                  key={`reason-text-${index}`}
                  onClick={() => handleListClick(reason.name)}
                >
                  <h1>
                    <span
                      className={cx(styles.listLeader, {
                        [`${styles.active}`]: activeItem === reason.name,
                      })}
                    >
                      {activeItem === reason.name ? '\uff0d' : '\uff0b'}
                    </span>
                    &nbsp;&nbsp;
                    {reason.title}
                  </h1>
                  <p
                    className={cx(styles.reasonCopy, {
                      [`${styles.activeCopy}`]: activeItem === reason.name,
                    })}
                  >
                    {reason.copy}
                  </p>
                </li>
              );
            })}
          </ul>
          <figure className={styles.graphic}>
            {reasonsArr.map((reason: ReasonsArraryProps, index: number) => {
              return reason.videoId ? (
                <div
                  key={`reason-video-${index}`}
                  className={cx(styles.videoContainer, {
                    [`${styles.activeVideoContainer}`]: activeItem === reason.name,
                  })}
                >
                  <MuxPlaceholderSplash
                    iconNone
                    title='Elizabeth Public Schools Case Study: Principal Maria Labrador'
                    graphicUrl={reason.image}
                    videoId={reason.videoId}
                  />
                </div>
              ) : (
                <img
                  key={`reason-graphic-${index}`}
                  className={cx(styles.reasonGraphic, {
                    [`${styles.activeGraphic}`]: activeItem === reason.name,
                  })}
                  src={reason.image}
                  alt={reason.imageAlt}
                />
              );
            })}
          </figure>
        </section>
      </MediaQuery>
      <MediaQuery maxWidth={1279}>
        <section className={styles.reasonsContainer} style={reasonsGridStyleMobile as CSSProperties}>
          <ul className={styles.reasonsList}>
            {reasonsArr.map((reason: ReasonsArraryProps, index: number) => {
              return (
                <li
                  key={`reason-text-${index}`}
                  className={cx(styles.reason, {
                    [`${styles.activeItem}`]: activeItem === reason.name,
                  })}
                  onClick={() => handleListClick(reason.name)}
                >
                  <h1>
                    <span
                      className={cx(styles.listLeader, {
                        [`${styles.active}`]: activeItem === reason.name,
                      })}
                    >
                      {activeItem === reason.name ? '\uff0d' : '\uff0b'}
                    </span>
                    &nbsp;&nbsp;
                    {reason.title}
                  </h1>
                  <p
                    className={cx(styles.reasonCopy, {
                      [`${styles.activeCopy}`]: activeItem === reason.name,
                    })}
                  >
                    {reason.copy}
                  </p>
                  <figure className={styles.graphic}>
                    {reason.videoId ? (
                      <div
                        className={cx(styles.reasonGraphicMobile, {
                          [`${styles.activeGraphicMobile}`]: activeItem === reason.name,
                        })}
                      >
                        <MuxPlaceholderSplash
                          iconNone
                          title='Elizabeth Public Schools Case Study: Principal Maria Labrador'
                          graphicUrl={reason.image}
                          videoId={reason.videoId}
                        />
                      </div>
                    ) : (
                      <img
                        className={cx(styles.reasonGraphicMobile, {
                          [`${styles.activeGraphicMobile}`]: activeItem === reason.name,
                        })}
                        src={reason.image}
                        alt={reason.imageAlt}
                      />
                    )}
                  </figure>
                </li>
              );
            })}
          </ul>
        </section>
      </MediaQuery>
    </section>
  );
};

const ReasonsSegment = ({ className, reasonsArr, reasonsGridStyle, reasonsGridStyleMobile }: Props): JSX.Element => {
  return (
    <section className={className}>
      <AwardSection title='Why Choose Peekapak?' />
      <ReasonsGrid
        reasonsArr={reasonsArr}
        reasonsGridStyle={reasonsGridStyle}
        reasonsGridStyleMobile={reasonsGridStyleMobile}
      />
    </section>
  );
};

export default ReasonsSegment;
export { ReasonsGrid };
