import { expireSessionAndRedirect } from './aaa';
import { getStore } from './ApplicationState';
import { LoginState } from './UserManagement';
import { logger } from './error-tracker';
import { isTokenExpired } from './GlobalFunctions';

export const _zTopContent = 1000; // to ensure top of focus all the Time

// TODO service versioning on payload
export const postServiceRequest = (
  url,
  payload,
  optionalSuccessCallback,
  optionalErrorCallback,
  optionalHeaders?: Record<string, string>,
) => {
  const request = new XMLHttpRequest();
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status > 199 && request.status < 300) {
        if (optionalSuccessCallback) {
          return optionalSuccessCallback(request.status, request);
        }
      }

      if (request.status === 401) {
        logIfAccessTokenExpired();

        if (optionalErrorCallback) {
          optionalErrorCallback(request.status, request);
          return expireSessionAndRedirect(false);
        }
        return expireSessionAndRedirect();
      } else if (optionalErrorCallback) {
        return optionalErrorCallback(request.status, request);
      }
    }
  };

  function logIfAccessTokenExpired() {
    // NB: logging to track how often we use an access token which
    // was not expired when got it, but has since expired by the
    // time we use it
    const store = getStore();
    const currentState = store.getState().user.loginState;

    if (currentState === LoginState.loggedIn || currentState === LoginState.authenticated) {
      const expiry = getStore().getState().user.cognitoProfile.getSignInUserSession().getIdToken().payload.exp;
      if (isTokenExpired(expiry)) {
        logger.logMessage(`serviceAgent token expired`);
      }
    }
  }

  request.open('POST', `${document.location.protocol}//${document.location.host}${url}`, true);

  if (optionalHeaders) {
    Object.keys(optionalHeaders).forEach((key) => {
      request.setRequestHeader(key, optionalHeaders[key]);
    });
  }

  if (typeof payload === 'object') {
    request.send(JSON.stringify(payload));
  } else {
    request.send(payload);
  }
  return request;
};

export const getPublicMediaRoot = () => 'https://s3.amazonaws.com/peekaplatform/media30/';

export const getContentRequest = (url, postCompleteCallback, optionalHeaders) => {
  const request = new XMLHttpRequest();
  request.onreadystatechange = function () {
    if (request.readyState === 4) {
      if (request.status === 200 && undefined !== postCompleteCallback) {
        return postCompleteCallback(request.status, request);
      }

      if (request.status === 401) {
        if (postCompleteCallback !== undefined) {
          postCompleteCallback(request.status, request);
          expireSessionAndRedirect(false);
        } else {
          expireSessionAndRedirect();
        }
      } else if (postCompleteCallback !== undefined) {
        console.error(`getContentRequest failed for: ${url}`);
        postCompleteCallback(request.status, request);
      }
    }
  };

  request.open('GET', url, true);

  if (optionalHeaders) {
    Object.keys(optionalHeaders).forEach((key) => {
      request.setRequestHeader(key, optionalHeaders[key]);
    });
  }

  request.send();
};
