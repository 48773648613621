interface Props {
  condition: boolean | string | undefined;
  wrapper: (ReactChildren: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

const ConditionalWrapper = ({ condition, wrapper, children }: Props): JSX.Element =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
