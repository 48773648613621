import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export interface Media {
  sources: {
    [key: string]: string;
  };
  title: string;
}

export enum State {
  // initial = stopped
  // setMedia --> loading
  //
  paused = 'paused',
  playing = 'playing',
  notReady = 'notReady',
  ready = 'ready',
  loading = 'loading',
}

export interface AudioPlayerState {
  currentTime: number;
  media: Media | null;
  paused: boolean;
  volume: number;
  state: State;
}

const initialState: AudioPlayerState = {
  currentTime: 0,
  media: null,
  paused: true,
  volume: 0.5,
  state: State.notReady,
};

const audioObject = new Audio();

export const setMedia = createAsyncThunk('audioPlayer/setMedia', (media: Media | null): Promise<Media | null> => {
  return new Promise((resolve, reject) => {
    const handleCanPlay = () => {
      audioObject.removeEventListener('canplay', handleCanPlay);
      resolve(media);
    };
    const handleOnError = () => {
      audioObject.removeEventListener('error', handleOnError);
      reject();
    };

    if (!media) {
      audioObject.src = '';
      return resolve(media);
    }

    audioObject.addEventListener('canplay', handleCanPlay);
    audioObject.addEventListener('error', handleOnError);
    audioObject.src = media.sources.mp3;
    audioObject.load();
  });
});

const audioPlaySlice = createSlice({
  name: 'audioPlayer',
  initialState,
  reducers: {
    setCurrentTime(state, action: PayloadAction<number>) {
      if (state.state in [State.loading, State.notReady]) {
        throw new Error('Trying to set current time when media is not ready');
      }
      state.currentTime = action.payload;
    },
    pause(state, action: PayloadAction<number | undefined>) {
      if (state.state in [State.loading, State.notReady]) {
        throw new Error('Trying to pause when media is not ready');
      }

      if (action.payload !== undefined) {
        audioObject.currentTime = state.currentTime = action.payload;
      }

      state.paused = true;
      audioObject.pause();
    },
    setVolume(state, action: PayloadAction<number>) {
      audioObject.volume = state.volume = action.payload;
    },
    play(state, action: PayloadAction<number | undefined>) {
      if (state.state in [State.loading, State.notReady]) {
        throw new Error('Trying to play when media is not ready');
      }

      if (action.payload !== undefined) {
        audioObject.currentTime = state.currentTime = action.payload;
      }
      state.paused = false;
      state.state = State.playing;
      audioObject.play();
    },
  },
  extraReducers: {
    [setMedia.pending.type]: (state) => {
      state.state = State.loading;
    },
    [setMedia.fulfilled.type]: (state, action: PayloadAction<Media>) => {
      state.state = State.ready;
      state.media = action.payload;
      state.volume = 0.5;
      state.paused = true;
    },
    [setMedia.rejected.type]: (state) => {
      state.state = State.notReady;
    },
  },
});

export const { play, setCurrentTime, pause, setVolume } = audioPlaySlice.actions;

interface Props {
  onPlay: () => void;
  onPause: () => void;
  onEnded: () => void;
  onTimeUpdate: (currentTime: number) => void;
  onSeeking: () => void;
  onSeeked: () => void;
  onPlaying: () => void;
}

export const AudioPlayer = (props: Props) => {
  const dispatch = useDispatch();

  function handleTimeUpdate() {
    const t = audioObject.currentTime;
    dispatch(setCurrentTime(t));
    props.onTimeUpdate(t);
  }

  function handleOnPause() {
    dispatch(pause(audioObject.currentTime));
    props.onPause();
  }

  useEffect(() => {
    audioObject.addEventListener('ended', props.onEnded);
    audioObject.addEventListener('timeupdate', handleTimeUpdate);
    audioObject.addEventListener('seeking', props.onSeeking);
    audioObject.addEventListener('seeked', props.onSeeked);
    audioObject.addEventListener('playing', props.onPlaying);
    audioObject.addEventListener('pause', handleOnPause);
    audioObject.addEventListener('play', props.onPlay);
    return () => {
      audioObject.removeEventListener('ended', props.onEnded);
      audioObject.removeEventListener('timeupdate', handleTimeUpdate);
      audioObject.removeEventListener('seeking', props.onSeeking);
      audioObject.removeEventListener('seeked', props.onSeeked);
      audioObject.removeEventListener('playing', props.onPlaying);
      audioObject.removeEventListener('pause', handleOnPause);
      audioObject.removeEventListener('play', props.onPlay);

      cleanUpResources();
      function cleanUpResources() {
        dispatch(pause());
        dispatch(setMedia(null));
        audioObject.load();
      }
    };
  }, []);

  return <></>;
};

export default audioPlaySlice.reducer;
