import { Link } from 'react-router-dom';
import { ExternalLink } from '../components/ExternalLink';
import SectionHeader from '../components/SectionHeader';
import { Icon, IconifyIcon } from '@iconify/react';
import pedestrianFamily from '@iconify-icons/carbon/pedestrian-family';
import chalkboardTeacher from '@iconify/icons-la/chalkboard-teacher';
import bxBookOpen from '@iconify-icons/bx/bx-book-open';
import styles from '../SCSS/SelPlanSegment.module.scss';

const InfoCards = [
  {
    title: 'Engage Families With Virtual Well Being Nights',
    icon: pedestrianFamily,
    linkURL: '/familyWellBeing',
  },
  {
    title: 'Educator Professional Development on the CASEL Competencies',
    icon: chalkboardTeacher,
    linkURL: '/professionalDevelopment',
  },
  {
    title: 'Print Resources to Support Literacy and Your School Culture',
    icon: bxBookOpen,
    linkURL: 'https://shop.peekapak.com',
  },
];

interface Props {
  infoCards?: {
    title: string;
    subtitle?: string | undefined;
    icon: IconifyIcon;
    linkText?: string;
    linkURL: string;
  }[];
}

const SelPlanSegment = ({ infoCards = InfoCards }: Props): JSX.Element => {
  return (
    <section className={styles.content}>
      <SectionHeader
        center
        title='Build a Holistic SEL Plan'
        copy='Our team can help your school build a holistic SEL plan with engaging curriculum, print books, professional development and family engagement resources.'
      />
      <div className={styles.infoCardContainer}>
        {infoCards.map((x) => (
          <div className={styles.card} key={x.title}>
            <div className={styles.circle}>
              <Icon width={40} icon={x.icon} />
            </div>
            <h2 className={styles.title}>{x.title}</h2>
            {x.subtitle ? <h3 className={styles.subtitle}>{x.subtitle}</h3> : null}
            <div className={styles.linkContainer}>
              {x.linkURL && x.linkURL?.includes('https') ? (
                <ExternalLink className={styles.textLink} href={x.linkURL} target='_blank'>
                  {x.linkText || 'Learn more'}
                </ExternalLink>
              ) : (
                <Link className={styles.textLink} to={x.linkURL}>
                  {x.linkText || 'Learn more'}
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SelPlanSegment;
