import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../ApplicationState';
import { selectClassroom } from '../../Classrooms';
import { getPublicMediaRoot } from '../../serviceAgent';
import { fixedEncodeURIComponent, getPublicMediaResourcesPath } from '../../GlobalFunctions';
import MuxPlayer from '../MuxPlayer';
import styles from './MaterialCard.module.scss';
import { Icon } from '@iconify/react';

import playOutline from '@iconify-icons/carbon/play-outline';

interface Props {
  title: string;
  image: string;
  buttonText: string;
  linkURL: string;
  type: string;
}

const MaterialCard = ({ title, image, buttonText, linkURL, type }: Props): JSX.Element => {
  const [isShowPlayer, setShowPlayer] = useState(false);
  const classroom = useSelector((state: RootState) => {
    return selectClassroom(state);
  });

  const handleClick = () => {
    const slidesTypeBasedOnLevel = classroom.grade > 8 ? 'highSchoolSlides' : 'middleSchoolSlides';
    const slidesLevelRegex = new RegExp(slidesTypeBasedOnLevel);
    if (type === 'video') {
      setShowPlayer(true);
      return;
    } else if (type === 'handout') {
      let grade = '6';
      if (classroom) {
        grade = classroom.grade;
      }
      window.open(
        `${getPublicMediaRoot()}${getPublicMediaResourcesPath(grade)}handouts/${fixedEncodeURIComponent(linkURL)}`,
        '_blank',
      );
    } else if (slidesLevelRegex.test(linkURL)) {
      history.push(linkURL);
    } else if (type === 'checkInTool') {
      window.open('/studentportal/checkin');
    } else window.open(`${linkURL}`);
  };
  const handleHide = () => setShowPlayer(false);

  const history = useHistory();

  return (
    <>
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.materialImage}>
          <img src={image} alt={title} />
          <div className={styles.titleBand}>{title}</div>
        </div>
        <div className={styles.buttonContainer}>
          <Icon icon={playOutline} />
          &nbsp;{buttonText}
        </div>
      </div>
      {isShowPlayer && <MuxPlayer videoId={linkURL} isShow={isShowPlayer} onHide={handleHide} title={title} />}
    </>
  );
};

export default MaterialCard;
