import { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import { LockedContentMessage } from './LockedContentMessage';
import StepHeaderButton from './StepHeaderButton';
import { getPossessiveForm } from '../GlobalFunctions';
import { printLesson } from './UtilityFunctions';
import styles from '../SCSS/lpMain.module.scss';

const mapStateToProps = (state) => ({
  userProfile: state.user.userProfile,
});

class UnitHomeActivityContent extends Component {
  constructor(props) {
    super(props);

    this.handlePrint = this.handlePrint.bind(this);
    this.getStepTitle = this.getStepTitle.bind(this);
  }

  handlePrint() {
    if (this.props.isPreviewMode) {
      return null;
    }
    const teacher = `${this.props.userProfile.salutation} ${getPossessiveForm(this.props.userProfile.lastName)}`;
    const heading = `${teacher} update on ${this.getStepTitle()} for ${this.props.activityMeta.contentLabel}`;
    printLesson(heading);
  }

  getStepTitle() {
    return this.props.activityMeta.title;
  }

  render() {
    if (this.isLocked()) {
      return <LockedContentMessage content isShow onHide={() => this.props.history.go(-1)} />;
    }

    return (
      <div className={styles.greyBackgroundLessonViewer}>
        <div className={styles.stepContentMenu}>
          <span className={styles.stepContentHeader}>{this.props.activityMeta.contentLabel}</span>
          <Button className={styles.stepPrintButton} onClick={this.handlePrint}>
            <i className='fa fa-print' aria-hidden='true' />
          </Button>
          <hr className={styles.underHeadingSeparator} style={{ marginLeft: '-10px', marginRight: '-10px' }} />
          <div
            className={styles.stepContentContent}
            id='stepContentContent'
            dangerouslySetInnerHTML={{
              __html: this.props.onGetShareStepContent(),
            }}
          />
          {this.props.onGetAttachment()}
          <div style={{ padding: '1em' }} />
          <StepHeaderButton {...this.props} shareStep={this.props.isHomeActivity} />
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, null)(lockWithPreviewUnlessHasAny('KEY_HOME_LESSONS')(UnitHomeActivityContent));
