import cx from 'classnames';
import styles from './LeftRightCard.module.scss';

interface ChildProps {
  children: React.ReactNode;
  className?: string;
}

interface ClickProps {
  clickHandler?: (arg0: React.MouseEvent<HTMLDivElement>) => void;
}

interface CardProps {
  className?: string;
  textLead?: boolean;
  textLeadNarrow?: boolean;
  textLeadWide?: boolean;
  textLeadEven?: boolean;
  imageLead?: boolean;
  imageLeadNarrow?: boolean;
  imageLeadEven?: boolean;
  background?: string;
  height?: { height: string };
  style?: Record<string, string>;
}

type PromptProps = ChildProps & ClickProps;

type Props = PromptProps & CardProps;

const CirclePrompt = () => (
  <svg
    className={styles.circlePrompt}
    width='36px'
    height='36px'
    viewBox='0 0 36 36'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
  >
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='CirclePrompt' transform='translate(-1.000000, -1.000000)' fillRule='nonzero'>
        <g
          id='Group_171'
          transform='translate(19.168000, 19.168000) rotate(180.000000) translate(-19.168000, -19.168000) translate(0.668000, 0.668000)'
        >
          <circle
            id='Ellipse_40'
            fill='#00AFD8'
            transform='translate(18.668000, 18.668000) rotate(180.000000) translate(-18.668000, -18.668000) '
            cx='18.668'
            cy='18.668'
            r='18'
          ></circle>
          <path
            d='M16.075,18.386 L20.875,13.593 C21.2281861,13.238157 21.2268429,12.6641861 20.872,12.311 C20.517157,11.9578139 19.9431861,11.9591571 19.59,12.314 L14.154,17.749 C13.8136392,18.0896652 13.8017404,18.6378881 14.127,18.993 L19.584,24.461 C19.8108123,24.6981678 20.1481432,24.7941457 20.4658353,24.7119011 C20.7835273,24.6296565 21.0319101,24.3820481 21.1151463,24.0646144 C21.1983825,23.7471807 21.1034584,23.4095518 20.867,23.182 L16.075,18.386 Z'
            id='Icon_ionic-ios-arrow-back'
            fill='#FFFFFF'
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const TextPart = ({ children, className }: ChildProps): JSX.Element => {
  return <div className={cx(styles.textPart, { [`${className}`]: className })}>{children}</div>;
};
export const ImagePart = ({ children, className }: ChildProps): JSX.Element => {
  return <div className={cx(styles.imagePart, { [`${className}`]: className })}>{children}</div>;
};
export const Title = ({ children, className }: ChildProps): JSX.Element => {
  return <div className={cx(styles.title, { [`${className}`]: className })}>{children}</div>;
};
export const Copy = ({ children, className }: ChildProps): JSX.Element => {
  return <div className={cx(styles.copy, { [`${className}`]: className })}>{children}</div>;
};
export const Prompt = ({ children, className, clickHandler }: PromptProps): JSX.Element => {
  return (
    <div className={cx(styles.prompt, { [`${className}`]: className })} onClick={clickHandler}>
      <CirclePrompt />
      {children}
    </div>
  );
};

const LeftRightCard = ({
  children,
  className,
  textLead,
  textLeadNarrow,
  textLeadWide,
  textLeadEven,
  imageLead,
  imageLeadNarrow,
  imageLeadEven,
  background,
  height,
  style,
}: Props): JSX.Element => {
  const childProps = {
    style: {},
  };

  if (!textLead && !imageLead) {
    textLead = true;
  }

  if (background) {
    childProps.style = {
      backgroundImage: `url('${background}')`,
      backgroundPosition: 'top right',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    };
  }

  if (height) {
    childProps.style = { ...childProps.style, height };
  }

  return (
    <div
      className={cx(
        styles.card,
        {
          [`${styles.textLead}`]: textLead,
          [`${styles.textLeadNarrow}`]: textLeadNarrow,
          [`${styles.textLeadWide}`]: textLeadWide,
          [`${styles.textLeadEven}`]: textLeadEven,
          [`${styles.imageLead}`]: imageLead,
          [`${styles.imageLeadNarrow}`]: imageLeadNarrow,
          [`${styles.imageLeadEven}`]: imageLeadEven,
        },
        className,
      )}
      {...childProps}
      style={style}
    >
      {children}
    </div>
  );
};

export default LeftRightCard;
