import { Component } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import UnitHeader from './UnitHeader';
import { getAppRoot } from '../identity';
import { postServiceRequest } from '../serviceAgent';
import { updateClassroom as updateClassroomBackend } from '../BackendInterface';
import { canAccessPeekaville, getElementaryUnitDescriptions, getUnitTitleForDisplay } from '../GlobalFunctions';
import {
  CoreStandardsExpandable,
  Expandable,
  ExpandableGroup,
  LearningOutcomesExpandable,
  Resources,
  UnitSetup,
} from './lessonPlanComponents';
import { selectClassroom, updateClassroom } from '../Classrooms';
import ReadStoryButton from './ReadStoryButton';
import { getPublicMediaRoot } from '../serviceAgent';
import { UnitBreakDown } from './UnitBreakDown';
import { getStoryUrl } from './UtilityFunctions';
import styles from '../SCSS/UnitPage.module.scss';
import lockIconBlack from '../images/lock-icon-solid-black.png';
import peekavilleLogo from '../images/logo-myPeekaville.png';
import { History, Location } from 'history';
import { ClassroomType, ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';
import { AppDispatch, RootState } from '../ApplicationState';
import { UnitPosition } from './ActivityStepPage';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & {
    history: History;
    location: Location;
    params: {
      classroomName: string;
      unitId: string;
    };
    isAuthenticated: boolean;
    unitId: string;
    unitPosition: UnitPosition;
    unitMeta: ElementaryUnit['value']['lessonPlan'];
    completionStatus: ClassroomType['completionStatus'];
    onClickLockedContent: () => void;
    onMarkStepComplete: (unitPosition: UnitPosition) => void;
    onSendHome: (unitPosition: UnitPosition) => void;
    onResetStep: (unitPosition: UnitPosition) => Promise<void>;
    isPreviewMode?: boolean;
    onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  };

interface State {
  unitPosition: UnitPosition;
}
class UnitPage extends Component<Props, State> {
  consolidatedElaStandards: string[];
  consolidatedAdditionalStandards: string[];
  constructor(props: Props) {
    super(props);

    this.consolidatedElaStandards = this.consolidateStandards('coreStandards');
    this.consolidatedAdditionalStandards = this.consolidateStandards('additionalStandards');

    this.state = {
      unitPosition: { subunit: 0, lesson: 0, step: 0, level: 'unit' },
    };
  }
  unitTitle = getUnitTitleForDisplay(this.props.unitMeta.title);

  toggleLock = async (_event: React.MouseEvent<HTMLDivElement>) => {
    const modifyLocks = { ...this.props.classroom.peekavilleUnitLocks };

    const unitName = this.props.unitMeta.title.replace('-', ' ');
    // default to true/locked, so when we toggle it, the result is false
    modifyLocks[unitName] = modifyLocks[unitName] === undefined ? false : !modifyLocks[unitName];
    const modifyClassroom = { ...this.props.classroom };
    const classroomBackupInCaseOfError = { ...this.props.classroom };
    modifyClassroom.peekavilleUnitLocks = modifyLocks;
    const classroomUpdateData = {
      peekavilleUnitLocks: modifyClassroom.peekavilleUnitLocks,
      completionStatus: modifyClassroom.completionStatus,
    };
    if (!this.props.userProfile) {
      throw new Error('props.userProfile is null');
    }
    const { email } = this.props.userProfile;

    try {
      await updateClassroomBackend(email, modifyClassroom.className, classroomUpdateData);
    } catch (_) {
      console.error('UnitPage::toggleLock - postServiceRequest update failed');
      this.props.updateClassroom(classroomBackupInCaseOfError);
    }

    this.props.updateClassroom(modifyClassroom);
  };

  shouldRenderBook(unitMeta: Props['unitMeta'], storyUrl: string, classroom: ClassroomType) {
    if (unitMeta.title === 'Getting-Started') {
      return null;
    }
    return (
      <ReadStoryButton
        unitTitle={unitMeta.title}
        storyUrl={storyUrl}
        language={classroom.language}
        isPreviewMode={this.props.isPreviewMode}
      />
    );
  }

  shouldRenderMyPeekavilleUnlock(unitMeta: Props['unitMeta']) {
    const unitDescription = getElementaryUnitDescriptions().find((element) => element.unit === unitMeta.title);

    if (!unitDescription?.hasPeekavilleContent) {
      console.debug(`Skipping MyPeekaville unlock for ${unitMeta.title}`);
      return null;
    }

    const title = this.props.unitMeta.title.replace('-', ' ');
    const locks = this.props.classroom.peekavilleUnitLocks;
    if (!this.props.userProfile) {
      throw new Error('props.userProfile is null');
    }
    const { licenseLevel, licenseExpires } = this.props.userProfile;
    if (!canAccessPeekaville(licenseLevel, licenseExpires)) {
      return null;
    }

    const isCurrentUnitLocked = (() => {
      if (locks && locks[title] !== undefined && !locks[title]) return false;
      return true;
    })();

    return (
      <div className={styles.unitLockControl}>
        <div className={styles.imageWrapper}>
          <div
            className={cx(styles.lockImageOverlay, {
              [styles.lockImageDisable]: !isCurrentUnitLocked,
            })}
          >
            <img src={lockIconBlack} alt='lock icon' className={styles.lockIcon} />
          </div>
          <img
            src={peekavilleLogo}
            alt='myPeekaville Logo'
            className={cx(styles.logo, {
              [styles.lockOpacity]: isCurrentUnitLocked,
            })}
          />
        </div>
        <div className={styles.buttonLine}>
          <Button className={styles.unitLockButton} onClick={this.toggleLock}>
            {isCurrentUnitLocked ? 'Unlock' : 'Lock'}
          </Button>
        </div>
        <div className={styles.unlockDescription}>
          {isCurrentUnitLocked
            ? 'Unlock unit games for students on myPeekaville'
            : 'Lock unit games for students on myPeekaville'}
        </div>
      </div>
    );
  }

  consolidateStandards(typeOfStandards: string) {
    const unit_standards: string[] = [];
    this.props.unitMeta.subUnits.forEach((subunit) => {
      subunit.lessons.forEach((lesson) => {
        lesson.activities.forEach((activity) => {
          const standards =
            activity[
              typeOfStandards as keyof Props['unitMeta']['subUnits'][number]['lessons'][number]['activities'][number]
            ];
          if (standards) {
            (standards as string[]).forEach((standard) => {
              let found = false;
              for (const index of unit_standards) {
                if (index === standard) {
                  found = true;
                  break;
                }
              }
              if (found === false) {
                unit_standards.push(standard);
              }
            });
          }
        });
      });
    });
    unit_standards.sort();
    return unit_standards;
  }

  render() {
    // console.debug(
    //   `%cUnitPage render`,
    //   'background: blue; color: yellow',
    //   this.props.location.pathname
    // );
    if (
      //( !this.props.isPreviewMode &&
      !this.props.isAuthenticated
    ) {
      return null;
    }

    const unitMeta = this.props.unitMeta;
    const classroom = this.props.classrooms.list[this.props.classrooms.selectedClassroom];
    const completionStatus = classroom.completionStatus;

    const storyUrl = getStoryUrl(unitMeta, classroom);
    const totalNumberOfStandards = this.consolidatedElaStandards.length + this.consolidatedAdditionalStandards.length;

    return (
      <div className={styles.unitPage}>
        <UnitHeader
          unitId={this.props.unitId}
          headerImage={getPublicMediaRoot() + unitMeta.headerImage}
          title={this.unitTitle}
          label={unitMeta.label}
          unitMeta={unitMeta}
          completionStatus={completionStatus}
          unitPosition={this.state.unitPosition}
          classroom={classroom}
          onClickLockedContent={this.props.onClickLockedContent}
          history={this.props.history}
          isPreviewMode={this.props.isPreviewMode}
          onPreviewChange={this.props.onPreviewChange}
          params={this.props.params}
          numberOfStandards={totalNumberOfStandards}
        />
        <div className={`${styles.greyBackgroundLessonViewer}`}>
          <div className={`${styles.lessonViewerMaxWidthWrapper}`}>
            <div className={styles.preambleContent}>
              <h2 className={styles.lessonContentHeader}>Unit Overview</h2>
              <hr className={styles.contentSeparator} />
              <div className={styles.expandableHolder}>
                <Expandable label='About this Unit' text={unitMeta.about.text} openByDefault />
                <ExpandableGroup data={unitMeta.prologue} openByDefault={false} />
                <LearningOutcomesExpandable data={unitMeta.learningOutcomes} contentType='unit' />
                <ExpandableGroup data={unitMeta.epilogue} openByDefault={false} />
                <CoreStandardsExpandable label='ELA Standards' data={this.consolidateStandards('coreStandards')} />
                <CoreStandardsExpandable
                  label='Additional Standards'
                  data={this.consolidateStandards('additionalStandards')}
                />
                <Resources data={unitMeta.files} contentType='unit' />
              </div>
            </div>
            <div className={styles.sidebar}>
              <UnitSetup data={unitMeta} />
              {this.shouldRenderBook(unitMeta, storyUrl, classroom)}
              {this.shouldRenderMyPeekavilleUnlock(unitMeta)}
            </div>
            <div className={styles.lessonOverview}>
              <div className={styles.expandableHolder}>
                <UnitBreakDown
                  headerImage={getPublicMediaRoot() + unitMeta.headerImage}
                  title={unitMeta.title}
                  label={unitMeta.label}
                  unitMeta={unitMeta}
                  unitId={this.props.unitId}
                  params={this.props.params}
                  completionStatus={completionStatus}
                  classroom={classroom}
                  classrooms={this.props.classrooms}
                  onClickLockedContent={this.props.onClickLockedContent}
                  history={this.props.history}
                  onMarkStepComplete={this.props.onMarkStepComplete}
                  isPreviewMode={this.props.isPreviewMode}
                  onPreviewChange={this.props.onPreviewChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  classrooms: {
    state: state.classrooms.state,
    list: state.classrooms.classrooms,
    selectedClassroom: state.classrooms.selectedClassroom,
  },
  classroom: selectClassroom(state),
  userProfile: state.user.userProfile,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateClassroom: (classroom: ClassroomType) => dispatch(updateClassroom(classroom)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitPage);
