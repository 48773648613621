import { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cx from 'classnames';
import { sendUserToRoleHomePage } from '../../GlobalFunctions';
import { LoginState } from '../../UserManagement';
import { SignupButton, RequestInfoButton } from './HeaderButtons';
import MobilePanelMenu from './MobilePanelMenu';
import HeaderNavItem from './HeaderNavItem';
import { navItems } from './navItems';
import logoBookmark from '../../images/pkp-logo-bookmark.svg';
import logoBookmarkMobile from '../../images/pkp-peekapak-logo-mobile.svg';
import styles from './UnauthenticatedHeader.module.scss';

const mapStateToProps = (state: any) => ({
  userProfile: state.user.userProfile,
  loginState: state.user.loginState,
});

const Header = (props: any) => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const history = useHistory();

  const navigateHome = () => {
    if (props.loginState === LoginState.loggedIn) {
      sendUserToRoleHomePage(props.userProfile, history, false, true);
    } else {
      history.push('/');
    }
  };

  const renderTitle = () => (
    <ul className={styles.headerTitle}>
      <MediaQuery minWidth={1280}>
        <li className={cx(styles.headerLogoPicture, styles.dropShadow)}>
          <Link to='/' onClick={() => navigateHome}>
            <img alt='Backpack' src={logoBookmark} />
          </Link>
        </li>
      </MediaQuery>
      <MediaQuery maxWidth={1279}>
        <ul className={styles.headerLogoMobile}>
          <li className={styles.alignment}>
            <Link to='/' onClick={() => navigateHome}>
              <img alt='Backpack' src={logoBookmarkMobile} />
            </Link>
            <div className={styles.tollFreeNumber}>
              <a href='tel:+18885136418'>1-888-513-6418</a>
            </div>
          </li>
        </ul>
      </MediaQuery>
    </ul>
  );

  const renderAnonBar = () => {
    return (
      <>
        <MediaQuery minWidth={1280}>
          {navItems.map((c, index) => (
            <HeaderNavItem
              key={`HeaderNavItem-${index}`}
              name={c.name}
              route={c.route}
              hasSubMenu={!!c.subItems}
              subItems={c.subItems}
              history={history}
            />
          ))}
          <ul className={styles.buttonsContainer}>
            <SignupButton isMobileMenuActive={isMobileMenuActive} setIsMobileMenuActive={setIsMobileMenuActive} />
            <RequestInfoButton isMobileMenuActive={isMobileMenuActive} setIsMobileMenuActive={setIsMobileMenuActive} />
          </ul>
          <ul className={styles.tollFree}>
            <p>Toll free</p>
            <a href='tel:+18885136418'>1-888-513-6418</a>
          </ul>
        </MediaQuery>
        <MediaQuery maxWidth={1279}>
          <div
            className={cx(styles.hamburgerMenu, {
              [`${styles.active}`]: isMobileMenuActive,
            })}
          >
            <button className={styles.resembleLinkButton} onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}>
              <i className={cx('fa', 'fa-bars')} />
            </button>
          </div>
        </MediaQuery>
      </>
    );
  };

  return (
    <header className={styles.myHeader}>
      <MobilePanelMenu active={isMobileMenuActive} setIsMobileMenuActive={setIsMobileMenuActive} history={history} />
      <nav
        className={styles.Header}
        id='top'
        ref={props.forwardedRef}
        style={{
          backgroundColor: props.headerBgColor ? props.headerBgColor : '#ffffff',
        }}
      >
        <ul className={styles.headerLeft}>{renderTitle()}</ul>
        <ul className={styles.headerRight} ref={props.forwardedRef}>
          {renderAnonBar()}
        </ul>
      </nav>
    </header>
  );
};

// TODO: Typescript
export default connect<any, any>(mapStateToProps, null)(Header);
