import { Component } from 'react';
import { NormalHeader } from '../core/core';
import { getSubunitCompletionStatus } from '../completionStatus';
import { NavigatorCard } from './NavigatorCard';
import { getLetterGradeFromNumberGrade } from '../GlobalFunctions';
import styles from '../SCSS/UnitPage.module.scss';
import { ClassroomType, ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';
import { ClassroomsStateType } from '../Classrooms';
import { UnitPosition } from './ActivityStepPage';
import { History } from 'history';

interface Props {
  classroom: ClassroomType;
  classrooms: {
    state: ClassroomsStateType;
    list: ClassroomType[];
    selectedClassroom: number;
  };
  completionStatus: ClassroomType['completionStatus'];
  headerImage: string;
  isPreviewMode?: boolean;
  label: string;
  onClickLockedContent: () => void;
  onMarkStepComplete: (unitPosition: UnitPosition) => void;
  onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  history: History;
  params: {
    classroomName: string;
    unitId: string;
  };
  title: string;
  unitId: string;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  unitPosition?: UnitPosition;
}
export class UnitBreakDown extends Component<Props, {}> {
  render() {
    const classroom = this.props.classrooms.list[this.props.classrooms.selectedClassroom];
    const unitId = `${this.props.unitMeta.title}-${getLetterGradeFromNumberGrade(classroom.grade)}`;
    const cs = classroom.completionStatus[unitId];

    const cardDataArray: { value: number; color: string }[][] = [];

    (cs as string[][][]).forEach((subunit, index) => {
      const subcardData = [];

      const scs = getSubunitCompletionStatus(cs, index);

      if (scs.Total === scs.Completed) {
        subcardData.push({
          value: 1,
          color: index === 0 ? '#ff6319' : '#00afd8',
        });
      } else {
        for (let i = 0; i < scs.Total - scs.Completed; ++i) {
          subcardData.push({
            value: 1,
            color: '#ebedef',
          });
        }

        for (let i = 0; i < scs.Completed; ++i) {
          subcardData.push({
            value: 1,
            color: index === 0 ? '#ff6319' : '#00afd8',
          });
        }
      }

      cardDataArray.push(subcardData);
    });

    const currentSubunitIndex = this.props.unitPosition ? this.props.unitPosition.subunit : -1;

    return (
      <div className={styles.lessonContent}>
        <NormalHeader className={styles.lessonContentHeader} text={'Lessons'} />
        <hr className={styles.underHeadingSeparator} />
        <div
          className={styles.underHeadingContent}
          dangerouslySetInnerHTML={{ __html: this.props.unitMeta.about.text }}
        />
        {cardDataArray.map((current, index) => (
          <NavigatorCard
            completionStatus={this.props.completionStatus}
            unitMeta={this.props.unitMeta}
            key={index}
            data={current}
            here={currentSubunitIndex === index}
            subunitStub={this.props.unitMeta.subUnits[index]}
            subunitCardIndex={index}
            isReadAloudCard={index === 0}
            classroom={this.props.classroom}
            parentContainer={this}
            classrooms={this.props.classrooms}
            onClickLockedContent={this.props.onClickLockedContent}
            history={this.props.history}
            onMarkStepComplete={this.props.onMarkStepComplete}
            isPreviewMode={this.props.isPreviewMode}
            onPreviewChange={this.props.onPreviewChange}
            unitId={this.props.unitId}
          />
        ))}
      </div>
    );
  }
}
