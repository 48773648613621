import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum ActionType {
  PUSH = 'push',
  RESET = 'reset',
}

export interface AuthEvent {
  event: string;
  data?: any;
}

export interface AuthenticationEventsState {
  queue: AuthEvent[];
  numberOfPushes: number;
}

export const initialState: AuthenticationEventsState = {
  queue: [],
  numberOfPushes: 0,
};

export const eventsSlice = createSlice({
  name: 'authenticationEvents',
  initialState,
  reducers: {
    [ActionType.PUSH]: (state: AuthenticationEventsState, action: PayloadAction<AuthEvent>) => {
      state.queue = [action.payload, ...state.queue];
      state.numberOfPushes++;
    },
    [ActionType.RESET]: (state: AuthenticationEventsState) => {
      state.queue = [];
      state.numberOfPushes = 0;
    },
  },
});

export const { push, reset } = eventsSlice.actions;

export default eventsSlice.reducer;
