import { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { openHostedUIForClever, openHostedUIForGoogle, openHostedUIForClassLink } from '../UserManagementHelpers';
import { deleteCookie, getCookie, setCookie } from '../aaa';
import SignUpForm from '../components/SignUpForm';
import ErrorDisplay from '../components/ErrorDisplay';

import UnauthenticatedHeader from '../components/UnauthenticatedHeader/UnauthenticatedHeader';
import styles from './SignUp.module.scss';
import appleHeroImage from '../images/hero/hero-sign-up-apple.png';
import whiteBackpackLogo from '../images/pkp-backpack-name-logo.svg';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { useQuery } from '../CustomHooks';
import { Curriculum, OAuthSignUpData, SignUpData } from '../../peekapak-types/DataProtocolTypes';
import { CognitoUserProfileType } from '../UserManagement';

interface Props {
  history: History;
  cognitoProfile: CognitoUserProfileType;
  query: URLSearchParams;
  stepNumber: number;
  isErrorOccurred: boolean;
  onCopyUserInput: (data: OAuthSignUpData | SignUpData) => void;
  onOAuthSignUp: (isOAuthSignUp: boolean) => void;
  onNextStep(stepNumber: number, isStepValid: boolean): void;
  onStartOver: () => void;
}

interface State {
  errorTitle: string;
  errorSubtitle?: string;
  isLoggingIntoClever: boolean;
}

const mapStateToProps = (state: { user: { loginState: any; cognitoProfile: any } }) => ({
  loginState: state.user.loginState,
  cognitoProfile: state.user.cognitoProfile,
});

class SignUpStep1 extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.isStepValid = this.isStepValid.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.onGoBack = this.onGoBack.bind(this);

    this.state = {
      errorTitle: '',
      isLoggingIntoClever: false,
    };
  }

  componentDidMount = async () => {
    if (isSignUpFromOauthSignIn(this.props)) {
      const providerName =
        getCookie('peekapak.idP') ||
        JSON.parse(this.props.cognitoProfile.attributes.identities)[0].providerName ||
        'a third party';
      this.props.onCopyUserInput({
        type: 'oauth',
        name: this.props.cognitoProfile.attributes.name || 'Unknown',
        email: this.props.cognitoProfile.attributes.email,
        isOAuthSignUp: true,
        username: this.props.cognitoProfile.username,
        userId: this.props.cognitoProfile.attributes.sub,
        providerName,
      });

      this.props.onOAuthSignUp(true);
      return;
    }

    function isSignUpFromOauthSignIn(props: Props) {
      const signUpFromOauthLogin = props.query.get('signUpFromOauthLogin');
      return !!signUpFromOauthLogin;
    }
  };

  storeRedeemableLicenseCode = (codeToStore: string) => {
    if (codeToStore) {
      const cleaned = codeToStore.replace(/-/g, '');
      setCookie('peekapak.redeemableLicenseCode', cleaned);
    } else {
      deleteCookie('peekapak.redeemableLicenseCode');
    }
  };

  onGoogleSignUp = (redeemableCode: string) => {
    this.storeRedeemableLicenseCode(redeemableCode);
    setCookie('peekapak.oauthSignInOrigin', 'signup');
    setCookie('peekapak.idP', 'Google');
    openHostedUIForGoogle();
  };

  onCleverSignUp = (redeemableCode: string) => {
    this.storeRedeemableLicenseCode(redeemableCode);
    setCookie('peekapak.oauthSignInOrigin', 'signup');
    setCookie('peekapak.idP', 'Clever');
    openHostedUIForClever();
  };

  onClassLinkSignUp = (redeemableCode: string) => {
    this.storeRedeemableLicenseCode(redeemableCode);
    setCookie('peekapak.oauthSignInOrigin', 'signup');
    setCookie('peekapak.idP', 'ClassLink');
    openHostedUIForClassLink();
  };

  onLogoClick() {
    this.props.history.push('/');
  }

  onGoBack() {
    this.props.history.go(-1);
  }

  onSubmit(data: SignUpData) {
    deleteCookie('peekapak.idP');
    this.props.onCopyUserInput({
      type: 'manual',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      password: data.password || '',
      email: data.email || '',
      optIn: data.optIn,
      touPp: data.touPp,
      redeemableCode: data.redeemableCode,
      curriculum: Curriculum.ELEMENTARY,
    });

    setCookie('peekapak.emailOnSignUp', data.email, 10);

    this.props.onNextStep(this.props.stepNumber, this.isStepValid());
  }

  isStepValid() {
    return true;
  }

  render() {
    if (this.props.isErrorOccurred) {
      // reset isErrorOccurred otherwise Start Over will end up here afain
      return (
        <ErrorDisplay
          contentTitle={this.state.errorTitle}
          contentSubtitle={this.state.errorSubtitle}
          buttonText='Start Over'
          onButtonClick={this.props.onStartOver}
        />
      );
    }

    return (
      <div className={styles.pageContainer}>
        <MediaQuery maxWidth={1279}>
          <UnauthenticatedHeader />
        </MediaQuery>
        <div className={styles.heroContainer} style={{ backgroundImage: `url( '${appleHeroImage}' )` }}>
          <div className={styles.logoHitbox} onClick={this.onLogoClick}>
            <img alt='White Backpack Logo' src={whiteBackpackLogo} />
          </div>
        </div>
        <div className={styles.formContainer}>
          <SignUpForm
            onSubmit={this.onSubmit}
            onGoogle={this.onGoogleSignUp}
            onClever={this.onCleverSignUp}
            onClassLink={this.onClassLinkSignUp}
            onGoBack={this.onGoBack}
            districtName={this.props.query.get('districtName')}
            licenseLevel={this.props.query.get('licenseLevel')}
            curriculum={Curriculum.ELEMENTARY}
            hasFormDescription={false}
          />
        </div>
      </div>
    );
  }
}

function ClassFunctionWrapper(props: Props) {
  const history = useHistory();
  const query = useQuery();
  return <SignUpStep1 {...props} history={history} query={query} />;
}

export default connect(mapStateToProps, null)(ClassFunctionWrapper);
