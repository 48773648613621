import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useStateWithCallbackLazy } from 'use-state-with-callback';
import { getDescriptiveDateFromEpoch, getPossessiveForm } from '../../GlobalFunctions';
import { setMessageMark } from '../../core/context/contextFunctions';
import { getMessages } from '../../core/context/contextHelpers';
import { loadMessages as lm } from '../../Messages';
import { RootState } from '../../ApplicationState';

import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

import styles from './Notifications.module.scss';
import { MessageType } from '../../../peekapak-types/DataProtocolTypes';
import { AuthError } from '@aws-amplify/auth/lib-esm/Errors';

interface MessagesFromBackend {
  newMessages: MessageType[];
  messageMark: number;
}

const isNewMessage = (message: { date: number }, messageMark: number) => message.date > messageMark;

const getEventMessage = (message: MessageType, i: number) => (
  <div key={i}>
    <div className={styles.subject}>
      <span>{message.subject && message.subject}&nbsp;</span>
      <span className={styles.normalText}>{getDescriptiveDateFromEpoch(parseInt(message.date, 10))}</span>
    </div>
    <div style={{ marginLeft: '1em' }}>
      <span className={styles.parentsName}>
        {message.parentsName ? message.parentsName : `${getPossessiveForm(message.studentsName)} parent `}
      </span>
      <span className={styles.verb}>{message.verb}&nbsp;</span>
      <span className={styles.noun}>{message.noun}&nbsp;</span>
    </div>
  </div>
);

const Notifications = () => {
  const [isMarkButton, setIsMarkButton] = useStateWithCallbackLazy(true);
  const { list, messageMark } = useSelector((state: RootState) => ({
    list: state.messages.messages,
    messageMark: state.messages.messageMark,
  }));

  const newMessages = list.filter((message: MessageType) => {
    return isNewMessage(message, messageMark ?? Date.now() - 1000 * 60 * 60 * 24 * 30);
  });
  const messagePlural = newMessages.length === 1 ? 'message' : 'messages';

  const dispatch = useDispatch();

  const handleMarkAllMessagesRead = () => {
    setIsMarkButton(false, () => {
      setMyMessageMark();

      async function setMyMessageMark() {
        try {
          await setMessageMark();
          const { newMessages, messageMark }: MessagesFromBackend = await getMessages();
          dispatch(lm({ messages: newMessages, messageMark }));
        } catch (e) {
          if ((e as AuthError).name !== 'NotAuthorizedException') {
            throw e;
          }
        } finally {
          setIsMarkButton(true, () => undefined);
        }
      }
    });
  };

  useEffect(() => {
    return;
  }, [list]);

  const computeListItemClass = (message: { date: number }, messageMark?: number) => {
    let c = `${styles.NotificationListItem} .zippyClass`;
    if (messageMark === undefined) return c;
    if (isNewMessage(message, messageMark)) {
      c += ` ${styles.newMessage}`;
    }
    console.info('returning as style = ', c);
    return c;
  };

  return (
    <div className={styles.Notifications}>
      <div className={styles.titleContainer}>
        <div className={styles.messageCount}>
          <p>
            You have {newMessages.length} new {messagePlural}
          </p>
        </div>
        <PrimaryButton
          small
          className={styles.markAllButton}
          disabled={!isMarkButton}
          onClick={handleMarkAllMessagesRead}
        >
          {isMarkButton ? 'Mark All As Read' : 'Waiting...'}
        </PrimaryButton>
      </div>
      <div className={styles.NotificationListContainer}>
        {list.length < 1 ? (
          <div />
        ) : (
          list.map((message: MessageType, index: number) => (
            <div className={computeListItemClass(message, messageMark)} key={message.date}>
              {getEventMessage(message, index)}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Notifications;
