import MuxPlaceholderSplash from '../MuxPlaceholderSplash';
import SectionHeader from '../SectionHeader';
import styles from './VideoAndQuoteSection.module.scss';

interface Props {
  quote: string;
  quoteFrom: string;
  sectionPreTitle: string;
  sectionTitle: string;
  videoCoverImageUrl: string;
  videoId: string;
  videoTitle: string;
  [key: string]: unknown;
}

const VideoAndQuoteSection = ({
  quote,
  quoteFrom,
  sectionPreTitle,
  sectionTitle,
  videoCoverImageUrl,
  videoId,
  videoTitle,
  ...headerProps
}: Props): JSX.Element => {
  return (
    <section className={styles.videoSection}>
      <div className={styles.video}>
        <SectionHeader preTitle={sectionPreTitle} title={sectionTitle} {...headerProps} />
        <MuxPlaceholderSplash iconCentered title={videoTitle} graphicUrl={videoCoverImageUrl} videoId={videoId} />
        <div className={styles.quoteSection}>
          <p>{quote}</p>
          <p>{quoteFrom}</p>
        </div>
      </div>
    </section>
  );
};

export default VideoAndQuoteSection;
