import $ from 'jquery';
import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ReactGesture from 'react-gesture';
import { debounce, isTouchDevice } from '../../GlobalFunctions';
import { updateStorybookPercentageRead } from '../../Session';
import { AppDispatch, RootState } from '../../ApplicationState';

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  session: state.session,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateStorybookPercentageRead: (percent: number) => dispatch(updateStorybookPercentageRead(percent)),
});
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  url: string;
  pages: number;
  withAudio: boolean | null;
  format?: string;
  currentPage: number;
  changePage: (page: number) => void;
  setPagesDisplayed: (numPages: number) => void;
  background: string;
  isShowBook: boolean;
  setPauseState: (newPause: boolean) => void;
  pauseState: boolean;
  autoPlay: boolean;
};
interface State {
  redrawRequests: number;
  readingLevel: string;
  withAudioOffset: number;
}
class Flipbook extends Component<Props, State> {
  assetUrls: string[];
  baseImgUrl: string;
  originalBookPageWidth: number;
  originalBookPageHeight: number;
  viewportWidth: number;
  viewportHeight: number;
  imageWidth: number;
  imageHeight: number;
  constructor(props: Props) {
    super(props);
    // https://s3.amazonaws.com/peekaplatform/media/images/flipbooks/courage-1/page0.jpg
    // Assumed images are in jpg format
    // The grade, unit and numPages properties are MANDATORY
    this.assetUrls = [];
    this.baseImgUrl = 'https://s3.amazonaws.com/peekaplatform/media30/images/flipbooks/';
    this.generateAssetUrls(this.props.url, this.props.pages);

    // Original resolution of book page images
    this.originalBookPageWidth = 1621;
    this.originalBookPageHeight = 2100;
    this.viewportWidth = 0;
    this.viewportHeight = 0;
    this.imageWidth = 0;
    this.imageHeight = 0;

    // Initialize event handlers
    // $( window ).resize(() => {
    //  this.resizeFlipbook();
    // });
    this.state = {
      redrawRequests: 0,
      readingLevel: this.props.url[this.props.url.length - 2],
      withAudioOffset: this.props.withAudio ? -50 : 0,
    };

    this.resizeFlipbook = this.resizeFlipbook.bind(this);
    this.updateViewportDimensions = this.updateViewportDimensions.bind(this);
    this.handleOnSwipeLeft = this.handleOnSwipeLeft.bind(this);
    this.handleOnSwipeRight = this.handleOnSwipeRight.bind(this);
    this.updatePageForMixpanel = this.updatePageForMixpanel.bind(this);
  }

  updatePageForMixpanel(page: number) {
    const currentPercentage = Math.ceil((page / this.props.pages) * 100);

    if (!this.props.session.storybookTracker || this.props.session.storybookTracker.percent < currentPercentage) {
      this.props.updateStorybookPercentageRead(currentPercentage);
    }
  }

  updateViewportDimensions() {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const headerHeight = $('#FlipbookHeader').height() as number;

    // don't include footer height because it's okay to push it out
    // when we're reading the story book
    this.viewportHeight = windowHeight - headerHeight;
    this.viewportWidth = windowWidth;
  }

  generateAssetUrls(url: string, pages: number) {
    const imageFormat = this.props.format ? this.props.format : 'jpg';
    this.assetUrls = [];
    for (let i = 0; i < pages; i++) {
      this.assetUrls.push(`${this.baseImgUrl + url}page${i}.${imageFormat}`);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeFlipbook);
    if ($('#Flipbook').length) {
      if (!$('#Flipbook').turn('is')) {
        this.initFlipbook();
      }
    }
  }

  UNSAFE_componentWillMount() {
    if (this.state.readingLevel === '3' || this.state.readingLevel === '4') {
      this.originalBookPageWidth = 1500;
      this.originalBookPageHeight = 2180;
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFlipbook);
  }

  componentDidUpdate(prevProps: Props) {
    if ($('#Flipbook').length) {
      if (!$('#Flipbook').turn('is')) {
        this.initFlipbook();
      }

      if (prevProps.currentPage !== this.props.currentPage) {
        this.updateFlipbook(this.props.currentPage);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (nextProps.url !== this.props.url) {
      this.generateAssetUrls(nextProps.url, nextProps.pages);
    } else if (nextProps.withAudio !== this.props.withAudio) {
      this.setState({ withAudioOffset: nextProps.withAudio ? -50 : 0 });
    }
  }

  initFlipbook() {
    $('#Flipbook').turn({
      autoCenterAbs: true,
      acceleration: true,
      gradients: false,
      page: this.props.currentPage || 1,
      when: {
        turned: (event, page: number, newView) => {
          this.props.changePage(page);
        },
      },
    });

    this.resizeFlipbook();
  }

  resizeFlipbook() {
    this.updateViewportDimensions();

    const viewportAspectRatio = this.viewportWidth / this.viewportHeight;
    const doublePageAspectRatio = (this.originalBookPageWidth * 2) / this.originalBookPageHeight;

    let pagesDisplayed;
    if (viewportAspectRatio >= doublePageAspectRatio) {
      $('#Flipbook').turn('display', 'double');
      pagesDisplayed = 2;
      this.props.setPagesDisplayed(2);
    } else {
      $('#Flipbook').turn('display', 'single');
      pagesDisplayed = 1;
      this.props.setPagesDisplayed(1);
    }

    const bookPageAspectRatio = (this.originalBookPageWidth * pagesDisplayed) / this.originalBookPageHeight;

    // screen is wider than book page
    if (viewportAspectRatio >= bookPageAspectRatio) {
      // Height Constrained
      this.imageWidth = (bookPageAspectRatio / pagesDisplayed) * this.viewportHeight;
      this.imageHeight = this.viewportHeight + this.state.withAudioOffset;
      // this.halveWidthIfOnFirstPage( pagesDisplayed );
    } else {
      // Width Constrained
      this.imageWidth = this.viewportWidth;
      this.imageHeight = this.viewportWidth / bookPageAspectRatio + this.state.withAudioOffset;
      // this.halveWidthIfOnFirstPage( pagesDisplayed );
    }

    $('#Flipbook').turn('size', this.imageWidth * pagesDisplayed, this.imageHeight);
    $('#Flipbook').turn('resize');
    this.updateFlipbook(this.props.currentPage);
    this.setState({ redrawRequests: this.state.redrawRequests + 1 });
  }

  halveWidthIfOnFirstPage(pagesDisplayed: number) {
    if (pagesDisplayed === 2 && this.props.currentPage === 1) {
      this.imageWidth = Math.floor(this.imageWidth / 2);
    }
  }

  updateFlipbook(page: number) {
    this.updatePageForMixpanel(page);
    if (!this.props.autoPlay && !this.props.pauseState) {
      this.props.setPauseState(true);
    }
    $('#Flipbook').turn('page', page);
  }

  generateFlipbookAssets() {
    return (
      <div id='Flipbook' className={this.props.background}>
        {this.assetUrls.map((result, index) => (
          <div style={{ backgroundColor: 'white' }} key={index}>
            <img alt='Book page' src={result} style={{ width: this.imageWidth, height: this.imageHeight }} />
          </div>
        ))}
      </div>
    );
  }

  render() {
    if (!this.props.isShowBook) {
      return null;
    }

    if (isTouchDevice()) {
      return (
        <ReactGesture onSwipeLeft={this.handleOnSwipeLeft} onSwipeRight={this.handleOnSwipeRight}>
          {this.generateFlipbookAssets()}
        </ReactGesture>
      );
    }

    return <div>{this.generateFlipbookAssets()}</div>;
  }

  handleOnSwipeLeft = debounce(
    function () {
      if (!isTouchDevice()) {
        return;
      }

      this.props.goToNextPage();
    },
    500,
    true,
  );

  handleOnSwipeRight = debounce(
    function () {
      if (!isTouchDevice()) {
        return;
      }

      this.props.goToPreviousPage();
    },
    500,
    true,
  );
}

export default connector(Flipbook);
