import { useCallback, useEffect, useState, Fragment, SetStateAction } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import Spinner from 'react-spinkit';
import SortDropdown from '../SortDropdown';
import ConfirmIrreversible from '../ConfirmIrreversible';
import Button from '../Button';
import LoadingBar from '../LoadingBar';
import StudentSubmissionItem from '../StudentSubmissionItem';
import SingleStudentAssign from '../SingleStudentAssign';
import ModalContainer from '../ModalContainer';
import Tooltip from '../Tooltip';
import {
  deleteGroupAssignments,
  deleteIndividualAssignments,
  createGroupAssignments,
  listGroupAssignments,
  createIndividualAssignment,
  retractAssignment,
} from '../../BackendInterface';
import { isUserHasKey, getJobStatus } from '../../GlobalFunctions';
import styles from './SubmissionsList.module.scss';
import { Icon } from '@iconify/react';
import bxRefresh from '@iconify-icons/bx/bx-refresh';
import cancelLine from '@iconify/icons-clarity/cancel-line';
import { StudentProxyType } from '../../../peekapak-types/DataProtocolTypes';
import { Assignment, Module, UrlParams } from '../../../peekapak-types/LessonPlanTypes';
import libraryStyles from '../../SCSS/Library.module.scss';

const sortDropdownArray = [
  { text: 'Date Submitted', value: 'modifiedAt' },
  { text: 'First Name', value: 'firstName' },
  { text: 'Last Name', value: 'lastName' },
];

interface Props {
  classroomId: string;
  moduleData: Module;
  markModuleComplete: (moduleName: string) => Promise<void>;
  markModuleIncomplete: (moduleCancelled: string) => Promise<void>;
  studentsInClass: StudentProxyType[];
}

const deleteWarning =
  'Are you sure that you want to cancel this assignment? This will delete all assignment records, all student work and any student work in progress for this module. This change cannot be undone.';
const deleteInstructions = `To confirm, please type out 'Delete Assignments' and click the button below:`;

const SubmissionsList = ({
  classroomId,
  moduleData,
  markModuleComplete,
  markModuleIncomplete,
  studentsInClass,
}: Props): JSX.Element => {
  const [isAssigned, setIsAssigned] = useState(false);
  const [sortMethod, setSortMethod] = useState('modifiedAt');
  const [submissionsList, setSubmissionsList] = useState<Assignment[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [createCompletion, setCreateCompletion] = useState(0);
  const [deleteCompletion, setDeleteCompletion] = useState(0);
  const [isShowConfirmModal, setShowConfirmModal] = useState(false);
  const [studentsAddedLater, setStudentsAddedLater] = useState<StudentProxyType[] | []>([]);

  const history = useHistory();
  const { id } = moduleData;
  const { unitId, lesson, grade, language }: UrlParams = useParams();

  const handleDropdownChange = (e: React.FormEvent<HTMLSelectElement>) => {
    setSortMethod(e.currentTarget.value);
  };

  const sortUnassignedStudent = useCallback(
    async (studentList) => {
      if (studentList.length === 0) return;
      const displayCopy = [...studentList];
      let sortedCopy: SetStateAction<[] | StudentProxyType[]> = [];
      if (displayCopy.length > 0) {
        sortedCopy = displayCopy
          .filter((item) => item[sortMethod])
          .sort((a, b) => a[sortMethod].localeCompare(b[sortMethod]))
          .concat(displayCopy.filter((item) => !item[sortMethod]));
      }
      return setStudentsAddedLater(sortedCopy);
    },
    [sortMethod],
  );

  const checkForStudentsAddedLater = async (classAssignments: Assignment[], studentsInClass: StudentProxyType[]) => {
    const studentsAddedLater = studentsInClass.reduce((acc: StudentProxyType[], student: StudentProxyType) => {
      if (!classAssignments.some((obj) => obj.userId === student.userId)) acc.push(student);
      return acc;
    }, []);
    if (studentsAddedLater.length > 0) sortUnassignedStudent(studentsAddedLater);
  };

  const sortAndFilterSubmissionList = useCallback(
    async (submissionsList) => {
      const displayCopy = [...submissionsList];
      let sortedCopy: SetStateAction<[] | Assignment[]> = [];
      if (sortMethod === 'modifiedAt') {
        sortedCopy = displayCopy
          .filter((item) => item.status === 'submitted')
          .sort((a, b) => b[sortMethod] - a[sortMethod])
          .concat(displayCopy.filter((item) => item.status !== 'submitted'));
      } else if (displayCopy.length > 0) {
        sortedCopy = displayCopy
          .filter((item) => item[sortMethod])
          .sort((a, b) => {
            if (a[sortMethod].localeCompare(b[sortMethod]) !== 0) return a[sortMethod].localeCompare(b[sortMethod]);
            else {
              const aName = a.firstName + a.lastName;
              const bName = b.firstName + b.lastName;
              return aName.localeCompare(bName);
            }
          })
          .concat(displayCopy.filter((item) => !item[sortMethod]));
      }
      return setSubmissionsList(sortedCopy);
    },
    [sortMethod],
  );

  const loadGroupAssigments = async () => {
    setIsLoading(true);
    try {
      const classAssigments = await listGroupAssignments(classroomId, unitId, lesson, id, language, parseInt(grade));
      // filter out assignments of students who are no longer in the class
      const assigmentsList = classAssigments.filter((obj) =>
        studentsInClass.some((student) => student.userId === obj.userId),
      );
      if (assigmentsList.length === 0) return setIsAssigned(false);
      sortAndFilterSubmissionList(assigmentsList);
      checkForStudentsAddedLater(assigmentsList, studentsInClass);
      setIsAssigned(true);
    } catch {
      console.log('Error fetching class assigments');
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  };

  const handleAssignClick = useCallback(async () => {
    setIsLoading(true);
    setDeleteCompletion(0);
    setStudentsAddedLater([]);
    try {
      setCreateCompletion(1);
      const result = await createGroupAssignments(classroomId, unitId, lesson, id);
      await getJobStatus(result.jobId, setCreateCompletion);
    } catch {
      console.log('Error assigning module to class');
    } finally {
      markModuleComplete(id);
      setIsLoaded(false);
      setCreateCompletion(0);
      setIsAssigned(true);
      await loadGroupAssigments();
      setIsLoading(false);
    }
  }, []);

  const handleAssignToAdditionalStudent = useCallback(
    async (userId: string) => {
      setIsLoading(true);
      try {
        await createIndividualAssignment(classroomId, unitId, lesson, id, userId);
        const studentsAddedLaterCopy = [...studentsAddedLater];
        const index = studentsAddedLaterCopy.findIndex((el) => el.userId === userId);
        if (index > -1) studentsAddedLaterCopy.splice(index, 1);
        setStudentsAddedLater(studentsAddedLaterCopy);
        setIsLoaded(false);
      } catch {
        console.log('Error assigning module to student');
      } finally {
        loadGroupAssigments();
        setIsAssigned(true);
        setIsLoading(false);
      }
    },
    [classroomId, id, lesson, studentsAddedLater, unitId],
  );

  const deleteClassAssignment = async () => {
    setIsLoading(true);
    setCreateCompletion(0);
    try {
      setDeleteCompletion(1);
      const result = await deleteGroupAssignments(classroomId, unitId, lesson, moduleData.id);
      await getJobStatus(result.jobId, setDeleteCompletion);
    } finally {
      markModuleIncomplete(id);
      setSubmissionsList([]);
      setIsLoaded(false);
      setIsAssigned(false);
      setDeleteCompletion(0);
      await loadGroupAssigments();
      setIsLoading(false);
    }
  };

  const deleteStudentAssignment = async (userId: string) => {
    try {
      await deleteIndividualAssignments(userId, classroomId, unitId, lesson, moduleData.id);
      if (submissionsList.every((assignment) => assignment.userId === userId)) {
        setSubmissionsList([]);
        setIsAssigned(false);
        markModuleIncomplete(id);
      }
      setIsLoaded(false);
    } finally {
      loadGroupAssigments();
    }
  };

  const handleRetractAssignment = useCallback(async (userId: string, createdAt: number) => {
    setIsLoading(true);
    try {
      await retractAssignment(userId, createdAt);
    } catch {
      console.log('Error assigning module to student');
    } finally {
      loadGroupAssigments();
      setIsAssigned(true);
      setIsLoading(false);
    }
  }, []);

  const toggleIsShowConfirmModal = () => {
    setShowConfirmModal((prevState) => !prevState);
  };

  useEffect(() => {
    !isLoaded && loadGroupAssigments();
  }, []);

  useEffect(() => {
    sortAndFilterSubmissionList(submissionsList);
    sortUnassignedStudent(studentsAddedLater);
  }, [sortMethod]);

  return (
    <div className={styles.container}>
      <div className={styles.titleBand}>
        <h2>
          Student Submissions
          {isAssigned && (
            <Fragment>
              <span>
                ({submissionsList && submissionsList.filter((obj) => obj.status === 'submitted').length}/
                {isAssigned && submissionsList?.length})
              </span>
            </Fragment>
          )}
        </h2>
        {isAssigned && (
          <div className={styles.sortStudents}>
            <span className={styles.cancelAssignment} onClick={toggleIsShowConfirmModal}>
              <Tooltip content='Cancel Assignment' direction='left' color='blue'>
                <Icon icon={cancelLine} color='#fb7270' width={20} className={styles.icon} />
              </Tooltip>
            </span>

            {isShowConfirmModal && (
              <ModalContainer hideModal={toggleIsShowConfirmModal}>
                <ConfirmIrreversible
                  hideModal={toggleIsShowConfirmModal}
                  title='Cancel Assignment'
                  warningMessage={deleteWarning}
                  instructions={deleteInstructions}
                  confirmTestString={'Delete Assignments'}
                  performIrreversibleAction={deleteClassAssignment}
                />
              </ModalContainer>
            )}
            <Icon icon={bxRefresh} color='#86939e' width={24} className={styles.icon} onClick={loadGroupAssigments} />
            <SortDropdown options={sortDropdownArray} value={sortMethod} onChange={handleDropdownChange} />
          </div>
        )}
      </div>
      {isLoading ? (
        <div className={styles.loadingContainer}>
          {createCompletion === 0 && deleteCompletion === 0 && <Spinner />}
          {createCompletion > 0 && (
            <LoadingBar
              title='Assigning to Students...'
              width={300}
              numComplete={createCompletion}
              totalNum={100}
              barColor='#012a73'
            />
          )}
          {deleteCompletion > 0 && (
            <LoadingBar
              title='Deleting Assignment...'
              width={300}
              numComplete={deleteCompletion}
              totalNum={100}
              barColor='#012a73'
            />
          )}
        </div>
      ) : (
        <Fragment>
          {!isAssigned && isUserHasKey('KEY_MANAGE_STUDENTS') && studentsInClass?.length > 0 && (
            <div className={styles.assignButton}>
              Allow students to submit their answers
              <Button onClick={handleAssignClick}>ASSIGN TO STUDENTS</Button>
            </div>
          )}
          {!isAssigned && (studentsInClass?.length === 0 || !studentsInClass) && (
            <div className={styles.assignButton}>
              <p>
                There are no students in your selected class. Please visit the{' '}
                <Link className={libraryStyles.textLink} to='/students'>
                  Students
                </Link>{' '}
                tab to add students.
              </p>
            </div>
          )}
          {!isAssigned && !isUserHasKey('KEY_MANAGE_STUDENTS') && (
            <div className={styles.assignButton}>
              Student assignments are only available to Peekapak PRO users. Please upgrade to access our student
              assignment platform.
              <Button onClick={() => history.push('/activatetrial')}>UPGRADE NOW</Button>
            </div>
          )}
          {isAssigned &&
            studentsAddedLater &&
            studentsAddedLater.map((x) => {
              const { firstName, lastName, userId } = x;
              return (
                <SingleStudentAssign
                  key={userId}
                  firstName={firstName}
                  lastName={lastName}
                  userId={userId}
                  handleAssignToAdditionalStudent={handleAssignToAdditionalStudent}
                />
              );
            })}
          {isAssigned &&
            submissionsList &&
            submissionsList.map((x) => {
              const { todoTitle, modifiedAt, status, createdAt, firstName, lastName, userId, todo, metawork } = x;
              return (
                <StudentSubmissionItem
                  key={`${firstName}${lastName}${createdAt}`}
                  moduleData={moduleData}
                  firstName={firstName}
                  lastName={lastName}
                  modifiedAt={modifiedAt}
                  status={status}
                  createdAt={createdAt}
                  todoTitle={todoTitle}
                  userId={userId}
                  todo={todo}
                  metawork={metawork}
                  deleteStudentAssignment={deleteStudentAssignment}
                  retractAssignment={handleRetractAssignment}
                />
              );
            })}
        </Fragment>
      )}
    </div>
  );
};

export default SubmissionsList;
