import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../ApplicationState';
import { computeCompletionStatus } from '../completionStatus';
import { selectUnitCompletionStatus } from '../Classrooms';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import { Icon } from '@iconify/react';
import bxCheckCircle from '@iconify-icons/bx/bx-check-circle';
import { UnitPosition } from './ActivityStepPage';
import styles from '../SCSS/lpMain.module.scss';

interface Props {
  unitPosition: UnitPosition;
  isPreviewMode: boolean;
  onMarkStepComplete?: (unitPosition: UnitPosition) => void;
  onResetStep?: (unitPosition: UnitPosition) => void;
  onSendHome?: (unitPosition: UnitPosition) => void;
  shareStep: boolean;
}

const StepHeaderButton = (props: Props): JSX.Element => {
  const { unitPosition, onResetStep, onSendHome, isPreviewMode, onMarkStepComplete, shareStep } = props;

  const [isHover, setIsHover] = useState(false);

  const unitCompletionStatus = useSelector((state: RootState) => selectUnitCompletionStatus(state));

  const cs = computeCompletionStatus('step', unitCompletionStatus, unitPosition);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();

    const isStepHomeActivity = shareStep;

    if (!isPreviewMode && !isStepHomeActivity) {
      if (cs === 'Completed') {
        onResetStep(unitPosition);
      } else {
        onMarkStepComplete(unitPosition);
      }
    } else if (!isPreviewMode && isStepHomeActivity) {
      onSendHome(unitPosition);
    }
  };

  if (shareStep && cs !== 'Completed') {
    return (
      <div className={styles.sendHomeButtonWrapper}>
        <PrimaryButton
          small
          onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            handleOnClick(event);
          }}
        >
          <i className='fa fa-paper-plane' aria-hidden /> Send to Parents
        </PrimaryButton>
      </div>
    );
  }

  switch (cs) {
    case 'InProgress':
      return (
        <PrimaryButton small onClick={handleOnClick}>
          Mark As Complete
        </PrimaryButton>
      );
    case 'Completed':
      return (
        <PrimaryButton
          small
          completed
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          onClick={handleOnClick}
        >
          <span>
            <Icon icon={bxCheckCircle} width={30} style={{ margin: '0 0.2rem 0.5rem 0' }} />{' '}
            {isHover ? 'Mark Incomplete' : 'Completed'}
          </span>
        </PrimaryButton>
      );
    default:
      // this is a hack to disable 'in progress' steps to simplify user experience
      return (
        <PrimaryButton small onClick={handleOnClick}>
          Mark As Complete
        </PrimaryButton>
      );
  }
};

export default StepHeaderButton;
