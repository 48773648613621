import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../ApplicationState';
import { selectClassroom } from '../../Classrooms';
import { useDebouncedEffect } from '../../CustomHooks';
import { getPublicMediaRoot } from '../../serviceAgent';
import { fixedEncodeURIComponent, getPublicMediaResourcesPath } from '../../GlobalFunctions';
import ConditionalWrapper from '../ConditionalWrapper';
import { Icon } from '@iconify/react';
import bxsRightArrow from '@iconify-icons/bx/bxs-right-arrow';
import styles from './ExpandableLinkCard.module.scss';
import { UrlParams } from '../../../peekapak-types/LessonPlanTypes';

export type UnitCardProps = {
  expandable?: boolean;
  expanded: boolean;
  content?: string;
  files: { actualName: string; displayName: string }[];
  slides?: { id: string; displayName: string }[];
  isTextAndFiles?: boolean;
  handleNavigatorClick?: (lessonType: string, lessonId: string) => void;
  title: string;
  hasFilesSummary?: boolean;
};

const ExpandableLinkCard = ({
  expandable,
  expanded,
  content,
  files,
  slides,
  isTextAndFiles,
  handleNavigatorClick,
  title,
  hasFilesSummary,
}: UnitCardProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [textHeight, setTextHeight] = useState('0px');

  const history = useHistory();

  const contentRef = useRef<HTMLDivElement>(null);
  const { lesson, language, grade }: UrlParams = useParams();

  const classroom = useSelector((state: RootState) => {
    return selectClassroom(state);
  });

  const openResource = (fileName: string) => {
    let grade = '6';
    if (classroom) {
      grade = classroom.grade;
    }
    window.open(
      `${getPublicMediaRoot()}${getPublicMediaResourcesPath(grade)}handouts/${fixedEncodeURIComponent(fileName)}`,
      '_blank',
    );
  };

  const openSlides = (id: string) => {
    const levelPrefix = parseInt(grade) > 8 ? 'highSchool' : 'middleSchool';
    const linkURL = `/${levelPrefix}Slides/${id}/${language}/${grade}`;
    history.push(linkURL);
  };

  const toggleAccordion = () => {
    if (expandable) {
      setIsExpanded(!isExpanded);
      setTextHeight(isExpanded || contentRef.current === null ? '0px' : `${contentRef.current.scrollHeight}px`);
    }
  };

  const handleResize = () => {
    const newTextHeight = !isExpanded || contentRef.current === null ? `0px` : `${contentRef.current.scrollHeight}px`;
    setTextHeight(newTextHeight);
  };

  useDebouncedEffect(() => window.addEventListener('resize', handleResize), [handleResize], 1000);

  useEffect(() => {
    if (expanded || isExpanded) setTextHeight(`${contentRef.current?.scrollHeight}px`);
  }, [content, handleNavigatorClick, lesson]);

  return (
    <div className={styles.accordionContainer}>
      <button onClick={toggleAccordion}>
        <h3>{title}</h3>
        <div>
          <Icon icon={bxsRightArrow} width={16} height={16} rotate={isExpanded ? 1 : 0} color='#00afd8' />
        </div>
      </button>
      <div className={styles.accordionText} ref={contentRef} style={{ maxHeight: `${textHeight}` }}>
        {isTextAndFiles ? (
          <>
            <div
              dangerouslySetInnerHTML={
                content
                  ? {
                      __html: content,
                    }
                  : undefined
              }
            />
            <div>
              <ConditionalWrapper
                condition={hasFilesSummary}
                wrapper={(children) => (
                  <ul>
                    <li>
                      <strong>Offline implementation support: </strong>If your class doesn’t have one-to-one technology,
                      use the below PDFs to support an offline implementation:
                    </li>
                    {children}
                  </ul>
                )}
              >
                <ul>
                  {slides &&
                    slides.map((x, index) => {
                      return (
                        <li key={`slides link ${index}`} className={styles.clickable} onClick={() => openSlides(x.id)}>
                          {x.displayName}
                        </li>
                      );
                    })}
                  {files &&
                    files.map((x, index) => {
                      return (
                        <li
                          key={`file link ${index}`}
                          className={styles.clickable}
                          onClick={() => openResource(x.actualName)}
                        >
                          {x.displayName}
                        </li>
                      );
                    })}
                </ul>
              </ConditionalWrapper>
            </div>
          </>
        ) : (
          <ol className={styles.fileLinkList}>
            {files?.map((x, index) => {
              return (
                <li key={`file link ${index}`} onClick={() => openResource(x.actualName)}>
                  {x.displayName}
                </li>
              );
            })}
          </ol>
        )}
      </div>
    </div>
  );
};

export default ExpandableLinkCard;
