import { useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { deleteCookie, getCookie, getLocalStorage, setCookie } from './aaa';
import { getStore } from './ApplicationState';
import { useAuth } from './CustomHooks';
import { canShouldUserAutoSync } from './GlobalFunctions';
import SpinnerOverlay from './SpinnerOverlay';
import { isElementaryStudent, isStudent } from './UserManagementHelpers';

interface Props {
  children: JSX.Element | JSX.Element[];
  requiredKeys?: string | string[];
  [key: string]: unknown;
}

export const AuthenticatedRoute = ({ children, requiredKeys, ...rest }: Props) => {
  const location = useLocation();
  // console.debug(
  //   `%cAuthenticated route render `,
  //   'background: blue; color: yellow',
  //   location.pathname
  // );
  const [isCheckingAuth, setIsCheckingAuth] = useState<boolean>(false);
  const auth = useAuth();

  if (auth.isUserLoggedInStateUnknown()) {
    //console.debug( `%cRoot login state unknown, attempting to reactivate`, 'background: #222; color: #bada55' )
    auth.reactivateUserSession();
    setIsCheckingAuth(true);
    return <SpinnerOverlay isShow={true} />;
  }

  if (auth.isUserLoggedInStateInTransition()) {
    auth.onLoggedInStateKnown(() => {
      setIsCheckingAuth(false);
    });
    return <SpinnerOverlay isShow={true} />;
  }

  if (isCheckingAuth) {
    return <SpinnerOverlay isShow={true} />;
  }

  if (auth.isUserSessionExpired()) {
    return (
      <Route
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/sessionExpired',
              state: { nextPathname: location.pathname },
            }}
          />
        )}
      />
    );
  }

  if (!auth.isUserLoggedIn() && !auth.isUserParentAccessStorybook()) {
    // doesn't seem like the user is logged in or using story book access, but let's check just to be sure

    // was this a parent story book access link?
    // if so, we need to redirect to the storybook
    const storybookAccessUri = getCookie('peekapak.storybookForParents');
    if (storybookAccessUri) {
      window.location.href = storybookAccessUri;
      return;
    }

    if (rest.location instanceof Location) {
      setCookie('peekapak.pathBeforeLoginRedirect', rest.location.pathname, 7);
    }

    return (
      <Route
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/membersOnlyRedirect',
              state: { nextPathname: location.pathname },
            }}
          />
        )}
      />
    );
  }

  const keysAsArray = requiredKeys ? (!Array.isArray(requiredKeys) ? [requiredKeys] : requiredKeys) : [];

  for (let i = 0; i < keysAsArray.length; i += 1) {
    if (!auth.isUserHasKey(keysAsArray[i])) {
      return <Route {...rest} render={(_) => <Redirect to={{ pathname: '/unauthorized' }} />} />;
    }
  }

  return performPreUseOperations();

  function performPreUseOperations() {
    const { userProfile } = getStore().getState().user;

    if (userProfile && isElementaryStudent(userProfile)) {
      if (import.meta.env.MODE === 'development') {
        return (window.location.href = 'https://test.peekapak.com/play/index.htm');
      } else if (import.meta.env.MODE === 'production') {
        return (window.location.href = 'https://peekapak.com/play/index.htm');
      }
    }

    if (auth.isUserMissingSchool() && !isSkipCheckRouteForMissingSchool() && !isStudent(userProfile)) {
      return (
        <Route
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/moreInformation/school',
                state: { nextPathname: location.pathname },
              }}
            />
          )}
        />
      );
    }

    if (canShouldUserAutoSync() && !isSkipCheckRouteForAutoSync()) {
      const { user } = getStore().getState();
      const autoSyncClassroomsCompleted = getLocalStorage('autoSyncClassroomsCompleted');
      const autoSyncClassroomsErrored = getLocalStorage('autoSyncClassroomsErrored');
      if (autoSyncClassroomsCompleted !== 'true' && autoSyncClassroomsErrored !== 'true') {
        return (
          <Route
            render={({ location }) => (
              <Redirect
                to={{
                  pathname: `/classroom/rostering/${user.userProfile?.providerName}/authenticatedRoute`,
                  state: { nextPathname: location.pathname },
                }}
              />
            )}
          />
        );
      }
    }

    return <Route {...rest}>{children}</Route>;

    //
    // these routes should not trigger a prompt for missing
    // information, otherwise there'll be a continuous loop
    //
    function isSkipCheckRouteForAutoSync() {
      return (
        location.pathname.toLowerCase().includes('/classroom/rostering') ||
        location.pathname.toLowerCase().includes('/moreinformation/school')
      );
    }

    function isSkipCheckRouteForMissingSchool() {
      return ['/moreinformation/school'].includes(location.pathname.toLowerCase());
    }
  }
};

export default AuthenticatedRoute;
