import { InlineIcon } from '@iconify/react';
import warningIcon from '@iconify-icons/carbon/warning';
import styles from './SingleStudentAssign.module.scss';
import Button from '../Button';

interface Props {
  handleAssignToAdditionalStudent: (userId: string) => void;
  firstName: string;
  lastName?: string;
  userId: string;
}

const SingleStudentAssign = ({ handleAssignToAdditionalStudent, firstName, lastName, userId }: Props): JSX.Element => {
  return (
    <>
      <div className={styles.studentList}>
        <InlineIcon icon={warningIcon} width={30} color={'#fb7270'} />
        {
          //TODO: Replace avatar div with component
        }
        <div className={styles.avatar} style={{ backgroundColor: '#86939e' }}>
          {firstName?.charAt(0)}
          {lastName?.charAt(0)}
        </div>
        <div className={styles.textContainer}>
          <div className={styles.nameCol}>
            <h4>
              {firstName} {lastName}
            </h4>
            <div className={styles.submitInfo}>{'Not Yet Assigned'}</div>
          </div>
        </div>
        <div className={styles.assignButton}>
          <Button onClick={() => handleAssignToAdditionalStudent(userId)}>Assign Now</Button>
        </div>
      </div>
    </>
  );
};

export default SingleStudentAssign;
