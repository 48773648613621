import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { selectClassroom, updateClassroom } from '../Classrooms';
import { getCoreStandardsObject, CoreStandard } from '../core/context/contextHelpers';
import { SpinnerOverlay } from '../SpinnerOverlay';
import LessonHeader from './LessonHeader';
import UnitNavigator from '../MiddleSchoolUnitPage/UnitNavigator';
import ModuleContent from './ModuleContent';
import SubmissionsList from '../components/SubmissionsList';
import ExpandableContentCard from '../components/ExpandableContentCard';
import ExpandableStandardsCard from '../components/ExpandableStandardsCard';
import ExpandableLinkCard from '../components/ExpandableLinkCard';
import GradientLock from '../components/GradientLock';
import { RootState } from '../ApplicationState';
import { getMiddleSchoolModules, getMiddleSchoolUnit, deemLessonComplete, undoDeemComplete } from '../BackendInterface';
import { getModuleDuration, getModuleIcon, isUserHasKey, secondarySchoolLessonNumberLookup } from '../GlobalFunctions';
import styles from './MiddleSchoolLesson.module.scss';
import { InlineIcon, IconifyIcon } from '@iconify/react';
import {
  Lesson,
  ModuleResult,
  StandardsList,
  TextAndFile,
  TextBlock,
  TextualBlock,
  UnitData,
  UrlParams,
} from '../../peekapak-types/LessonPlanTypes';
import Analytics from '../Analytics';

export interface LessonDataType {
  index: number;
  lessonId: string;
  title: string;
  activityType: string;
  time: number;
  overview: string;
  content: [
    {
      expandable: boolean;
      expanded: boolean;
      index: number;
      grade: number[];
      title: string;
      content: string;
    },
  ];
}

interface TextualBlockComponentProps {
  i: number;
  block: TextualBlock;
}

const TextualBlockComponent = ({ i, block }: TextualBlockComponentProps): JSX.Element => {
  if (block.type === 'textBlock') {
    return (
      <div className={styles.expandableOverview}>
        <ExpandableContentCard
          key={`overview ${block.title}`}
          expandable={block.expandable}
          expanded={block.expanded || false}
          title={block.title}
          content={block.content}
        />
      </div>
    );
  }

  if (block.type === 'textAndFile') {
    return (
      <ExpandableLinkCard
        key={`resource ${i} ${block.title}`}
        expandable={block.expandable}
        expanded={block.expanded || false}
        title={block.title}
        content={block.content}
        files={block.files}
        slides={block.slides}
        isTextAndFiles
        hasFilesSummary={block.title.includes('Accommodations')}
      />
    );
  }

  throw 'TextualBlock that is neither textBlokc nor textAndFile encountered';
};

const MiddleSchoolLesson = (): JSX.Element => {
  const classroom = useSelector((state: RootState) => {
    const selectedClassroom = selectClassroom(state);
    return selectedClassroom;
  });

  const userProfile = useSelector((state: RootState) => {
    return state.user.userProfile;
  });

  const dispatch = useDispatch();

  const [unitData, setUnitData] = useState<UnitData>({
    displayName: '',
    lessons: { content: [] },
    id: '',
    index: 0,
    number: '',
    caselCaption: '',
    grade: [0, 0],
    overview: { content: [] },
    featuredImage1: '',
    featuredImage2: '',
  });
  const [lessonData, setLessonData] = useState<Lesson>({
    lessonId: '',
    title: '',
    overview: '',
    index: 0,
    lessonFlow: [{ id: '', index: 0, type: '' }],
    representativeImage: '',
    content: [{ type: '', index: 0 }],
  });
  const [activeTab, setActiveTab] = useState('');
  const [modules, setModules] = useState<ModuleResult[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [textualBlocks, setTextualBlocks] = useState<TextualBlock[]>([]);
  const [lessonStandards, setLessonStandards] = useState<StandardsList[]>([]);
  const { language, unitId, grade, lesson, activity }: UrlParams = useParams();
  const isHighSchool = parseInt(grade) > 8;
  const history = useHistory();

  const { displayName, lessons } = unitData;
  const { lessonId, overview, index, representativeImage, estimatedTime, title } = lessonData;
  const time = 30;

  const mapApplicableCoreStandards = (
    standards: StandardsList[],
    library: { [key: string]: CoreStandard },
  ): (CoreStandard | '')[] => {
    const standardsArray = standards[0].content.map((standard: string | CoreStandard) => {
      return typeof standard === 'string' && library[standard] !== undefined ? library[standard] : '';
    });
    return standardsArray;
  };

  const getUnit = useCallback(async () => {
    setIsLoading(true);

    try {
      const unitPayload = await getMiddleSchoolUnit(unitId, language, grade);
      setUnitData(unitPayload.data);

      const coreStandards = await getCoreStandardsObject();

      const thisLesson = unitPayload.data?.lessons?.content.filter((item: Lesson) => item.lessonId === lesson);

      if (thisLesson[0].content.some((item) => item.type === 'standardsList')) {
        const applicableStandards = thisLesson[0].content.filter(
          (item) => item.type === 'standardsList',
        ) as unknown as StandardsList[];

        const standardsToRender = [...applicableStandards];
        const mappedStandards = mapApplicableCoreStandards(applicableStandards, coreStandards);
        standardsToRender[0].content = mappedStandards;

        applicableStandards && setLessonStandards(standardsToRender);
      } else {
        setLessonStandards([]);
      }

      const filteredTextualBlocks: TextualBlock[] = thisLesson[0].content.filter(
        (item) => item.type === 'textBlock' || item.type === 'textAndFile',
      ) as unknown as TextualBlock[];

      const sortedTextualBlocks = filteredTextualBlocks.toSorted((a, b) => a.index - b.index);

      setTextualBlocks(sortedTextualBlocks);

      setLessonData(thisLesson[0]);
      const moduleIDs = thisLesson[0]?.lessonFlow.map((x: { id: string }) => x?.id);
      const lessonModules = await getMiddleSchoolModules(moduleIDs, language, grade);
      setModules(lessonModules);
      moduleIDs?.includes(activity) ? setActiveTab(activity) : moduleIDs && setActiveTab(moduleIDs[0]);
    } catch (error) {
      console.error(`error = ${JSON.stringify(error, null, 2)}`);
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  }, [language, unitId, isLoaded]);

  const isDeemedCompleted = () => {
    if (
      classroom?.completionStatus[unitId] === undefined ||
      (classroom?.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson] ===
        undefined ||
      (classroom.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson] ===
        'NotStarted'
    ) {
      return false;
    } else {
      return (
        Object.values(
          (classroom?.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).length === lessonData.lessonFlow.length &&
        Object.values(
          (classroom?.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).every((v) => v === 'Completed' || v === 'DeemedCompleted')
      );
    }
  };

  const isLessonComplete = () => {
    if (
      classroom?.completionStatus[unitId] === undefined ||
      (classroom?.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson] ===
        undefined ||
      (classroom.completionStatus[unitId][lesson] as Record<string, Record<string, Record<string, string>>>) ===
        'NotStarted'
    ) {
      return false;
    } else {
      return (
        Object.values(
          (classroom?.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).every((v) => v === 'Completed') &&
        Object.values(
          (classroom?.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).length === lessonData.lessonFlow.length
      );
    }
  };

  const markLessonComplete = async () => {
    const classroomToEdit = cloneDeep(classroom);
    if (!classroomToEdit.completionStatus[unitId])
      classroomToEdit.completionStatus = {
        ...classroomToEdit.completionStatus,
        [unitId]: {},
      };

    (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson] =
      modules.reduce((a, moduleName) => {
        if (
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][
            lesson
          ]?.[moduleName.data.id]
        )
          return { ...a, [moduleName.data.id]: 'Completed' };
        return { ...a, [moduleName.data.id]: 'DeemedCompleted' };
      }, {});

    try {
      dispatch(updateClassroom(classroomToEdit));
    } catch (error) {
      console.error(`Could not deem complete in state`);
    } finally {
      console.log('Lesson deemed complete in state');
    }
    try {
      await deemLessonComplete(classroom.classroomId, unitId, lesson);
    } catch (error) {
      console.error(`Could not deem complete in database`);
    }
  };

  const markLessonIncomplete = async () => {
    try {
      const classroomToEdit = cloneDeep(classroom);
      if (
        Object.values(
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).every((value) => value === 'DeemedCompleted')
      ) {
        delete (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][
          lesson
        ];
        dispatch(updateClassroom(classroomToEdit));
      } else {
        for (const [key, value] of Object.entries(
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        )) {
          if (value === 'DeemedCompleted')
            delete (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][
              lesson
            ][key];
        }
        dispatch(updateClassroom(classroomToEdit));
      }
      await undoDeemComplete(classroom.classroomId, unitId, lesson);
    } catch (error) {
      console.error(`Something went wrong removing DeemedCompleted entries`);
    } finally {
      console.log('Lesson marked incomplete');
    }
  };

  const markModuleComplete = async (moduleAssigned: string) => {
    setIsLoading(true);
    try {
      const classroomToEdit = cloneDeep(classroom);
      if (!classroomToEdit.completionStatus[unitId])
        classroomToEdit.completionStatus = {
          ...classroomToEdit.completionStatus,
          [unitId]: {},
        };
      (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson] = {
        ...(classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        [moduleAssigned]: 'Completed',
      };
      dispatch(updateClassroom(classroomToEdit));
    } catch (error) {
      console.error(`error = ${JSON.stringify(error, null, 2)}`);
    } finally {
      setIsLoading(false);
    }
  };

  const markModuleIncomplete = async (moduleCancelled: string) => {
    setIsLoading(true);
    try {
      const classroomToEdit = cloneDeep(classroom);
      if (
        Object.values(
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).includes('DeemedCompleted')
      ) {
        (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson][
          moduleCancelled
        ] = 'DeemedCompleted';
        dispatch(updateClassroom(classroomToEdit));
        return;
      }
      if (
        Object.keys(
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).length > 1
      ) {
        delete (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][
          lesson
        ][moduleCancelled];
        dispatch(updateClassroom(classroomToEdit));
      }
      if (
        Object.keys(
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).length === 1 &&
        Object.keys(classroomToEdit.completionStatus[unitId]).length > 1
      ) {
        delete (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][
          lesson
        ];
        dispatch(updateClassroom(classroomToEdit));
      }
      if (
        Object.keys(
          (classroomToEdit.completionStatus as Record<string, Record<string, Record<string, string>>>)[unitId][lesson],
        ).length === 1 &&
        Object.keys(classroomToEdit.completionStatus[unitId]).length === 1
      ) {
        delete classroomToEdit.completionStatus[unitId];
        dispatch(updateClassroom(classroomToEdit));
      }
    } catch {
      console.log('Could not mark module incomplete in state');
    } finally {
      setIsLoading(false);
    }
  };

  const isLessonLocked = () => {
    const lessonNumber = secondarySchoolLessonNumberLookup(lesson, isHighSchool);
    if (lessonNumber === 1) return false;
    if (userProfile?.licenseLevel.includes('TRIAL')) {
      return unitId.includes('stress') ? false : true;
    }
    if (isUserHasKey('KEY_ALL_LESSON_PLANS')) return false;
    return true;
  };

  const handleTabClick = (id: string) => {
    setActiveTab(id);
    // history.push(
    //   `/lessonPlan/${unitId}/${language}/${grade}/lesson/${lesson}/activity/${id}`
    // );
  };

  const handleNavigatorClick = (lessonType: string, activityId: string) => {
    setIsLoaded(false);
    history.push(`/lessonPlan/${unitId}/${language}/${grade}/lesson/${lessonType}/activity/${activityId}`);
  };

  useEffect(() => {
    if (!isLoaded) getUnit();
  }, [handleNavigatorClick, handleTabClick]);

  useEffect(() => {
    if (!classroom) return;
    const lessonAndClassroomId = `${classroom.className}-${lesson}`;
    const sendAnalyticsIfFirstInSession = async () => {
      const lessonsOpenedList = await JSON.parse(sessionStorage.getItem('lessonsOpenedList') || '[]');
      if (!lessonsOpenedList?.includes(lessonAndClassroomId)) {
        lessonsOpenedList.push(lessonAndClassroomId);
        Analytics.lessonsOpenedEvent(lesson, userProfile!);
        sessionStorage.setItem('lessonsOpenedList', JSON.stringify(lessonsOpenedList));
      }
    };
    sendAnalyticsIfFirstInSession();
  }, [lesson, classroom]);

  return (
    <div className='applicationLayout'>
      <SpinnerOverlay isShow={isLoading} />

      <UnitNavigator
        handleNavigatorClick={handleNavigatorClick}
        title={displayName}
        lessonItems={lessons?.content}
        completionStatus={classroom?.completionStatus?.[unitId]}
      />
      {isLessonLocked() && <GradientLock />}
      <LessonHeader
        title={title}
        time={time}
        index={index}
        representativeImage={representativeImage}
        markComplete={markLessonComplete}
        markIncomplete={markLessonIncomplete}
        isDeemedCompleted={classroom?.completionStatus && isDeemedCompleted()}
        isComplete={classroom?.completionStatus && isLessonComplete()}
      />
      <div className={styles.unitContainer}>
        <div className={styles.contentContainer}>
          <div className={styles.titleBand}>
            <h2>Lesson Overview</h2>
          </div>
          <div className={styles.lessonInfo}>
            <ExpandableContentCard title={'Overview'} key={'overview'} expandable expanded={false} content={overview} />
            <ExpandableContentCard
              key={'lessonFlow'}
              expandable
              expanded
              estimatedTime={estimatedTime}
              title={'Lesson Flow'}
              content={modules}
              isLessonFlow
              handleNavigatorClick={handleNavigatorClick}
            />
            {textualBlocks?.map((x: TextualBlock, i: number) => (
              <TextualBlockComponent key={`${i}-${x.title}`} i={i} block={x} />
            ))}
            {lessonStandards.length > 0 &&
              lessonStandards.map((x: StandardsList) => (
                <ExpandableStandardsCard
                  key={`standards ${x.title}`}
                  expandable={x.expandable}
                  expanded={x.expanded || false}
                  title={x.title}
                  content={x.content}
                />
              ))}
          </div>
        </div>
        <div className={styles.activitiesContainer}>
          <div className={styles.tabRow}>
            {modules.map((thisModule, index) => {
              const icon = getModuleIcon(thisModule?.data?.type) as IconifyIcon;
              return (
                <button
                  role='tab'
                  key={`tab ${index}`}
                  className={activeTab === thisModule?.data?.id ? styles.activeTab : ''}
                  onClick={() => handleTabClick(thisModule?.data?.id)}
                >
                  <InlineIcon icon={icon} width={22} className={styles.icon} />
                  <div className={styles.tabText}>{thisModule?.data?.type}</div>
                </button>
              );
            })}
          </div>
          {modules.map((thisModule, index) => {
            const { data } = thisModule;
            return (
              <div key={`module ${index}`}>
                <div className={styles.tabContent} key={`module ${index}`}>
                  {activeTab === data?.id && (
                    <ModuleContent
                      id={data?.id}
                      title={data?.title}
                      time={getModuleDuration(data)}
                      teacherContent={data?.teacherContent}
                      studentContent={data?.studentContent}
                      moduleContent={data?.moduleContent && data?.moduleContent}
                    />
                  )}
                </div>
                {data?.studentContent?.length > 0 && (
                  <div className={styles.submissionsContainer}>
                    {activeTab === data?.id && classroom?.classroomId && (
                      <SubmissionsList
                        markModuleIncomplete={markModuleIncomplete}
                        markModuleComplete={markModuleComplete}
                        classroomId={classroom?.classroomId}
                        moduleData={data}
                        studentsInClass={classroom?.listOfStudentProxies}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MiddleSchoolLesson;
