import { useEffect, useRef, useCallback } from 'react';
import { getVideoURL } from '../../BackendInterface';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
// Styles
import 'video.js/dist/video-js.css';
import { Modal } from 'react-bootstrap';
import styles from './MuxPlayer.module.scss';
import { logDebugLevel } from '../../GlobalFunctions';

interface IVideoPlayerProps {
  options?: videojs.PlayerOptions;
  videoId: string;
  isShow: boolean;
  onHide: () => void;
  title?: string;
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
  width: 560,
  autoplay: true,
};

const MuxPlayer = ({ options, videoId, isShow, onHide, title }: IVideoPlayerProps) => {
  const videoNode = useRef<HTMLVideoElement | null>(null);
  const playerRef = useRef<Player | null>(null);

  const getMuxURL = useCallback(async () => {
    // the video node may not be created yet, especially if
    // the video is embedded in a modal that is not yet shown
    if (!videoNode.current) {
      logDebugLevel('Video node not created yet, returning');
      return;
    }

    logDebugLevel('Attempting to initialize video player with videoId:', videoId);
    const muxURLResponse = await getVideoURL(videoId);

    playerRef.current = videojs(videoNode.current, {
      ...initialOptions,
      ...(options as object),
      sources: [{ src: muxURLResponse, type: 'application/x-mpegURL' }],
    });
  }, [videoId]);

  useEffect(() => {
    getMuxURL();
  }, [isShow]);

  return (
    <>
      <Modal
        dialogClassName={styles['modal-dialog']}
        className={styles.modal}
        show={isShow}
        onHide={internalOnHide}
        size='lg'
        autoFocus
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title className={styles['modal-title']}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.vidContainer}>
            <video ref={videoNode} className='video-js' />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );

  function internalOnHide() {
    const player = playerRef.current;

    logDebugLevel('intneralOnHide Disposing video player but checking if it exists...', player);
    if (player && !player.isDisposed()) {
      logDebugLevel('internalOnHide Disposing video player');
      player.dispose();
      playerRef.current = null;
    }
    onHide();
  }
};

export default MuxPlayer;
