import { Auth } from '@aws-amplify/auth';
import { Component } from 'react';
import { logger } from './error-tracker';
import { getCookie } from './aaa';
import { LoginState } from './UserManagement';
import {
  openHostedUIForClever,
  openHostedUIForGoogle,
  openHostedUIForClassLink,
  reactivateUserSessionIfExists,
  signOutUser,
} from './UserManagementHelpers';
import { noWhiteSpace, startPlatformAfterSuccessfulLogin } from './GlobalFunctions';
import StatusUpdate from './components/StatusUpdate';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from './CustomHooks';
import { getStore, RootState } from './ApplicationState';
import { reset, AuthEvent } from './AuthenticationEvents';
import { ShowErrorInterface, useErrorStatus } from './components/EnableErrorMessagePopup/EnableErrorMessagePopup';
import { History } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { EnterpriseUserError, ErrorType } from './AugmentedError';
import { ErrorMessages } from '../peekapak-types/ErrorMessages';
import Images from './Images';

interface Props extends ShowErrorInterface {
  setProperty: (arg0: string, arg1: string) => void;
  location: Location;
  history: History;
  query: URLSearchParams;
  queue: AuthEvent[];
  resetQueue: () => void;
}

type State = {
  isShowSpinner: boolean;
};

class OauthLogin extends Component<Props, State> {
  timeOutTimerId!: ReturnType<typeof setTimeout>;

  handleAuthEvents = () => {
    // console.debug( `AWS Amplify Hub - Received event ${ event }` );
    const eventQueue = [...this.props.queue];
    let payload = eventQueue.shift();

    while (payload) {
      this.clearTimeoutTimer();

      switch (payload.event) {
        case 'cognitoHostedUI':
          console.info(`%c Amplify.Hub handling cognitoHostedUI`, 'background: #222; color: #bada55');
          this.handleCognitoHostedUIEvent();
          break;

        case 'oAuthSignOut':
        case 'signOut':
          console.info(`%c Amplify.Hub handling oAuthSignOut`, 'background: #222; color: #bada55');
          signOutUser();
          break;

        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.info(`%c Amplify.Hub handling failure`, 'background: #222; color: #bada55');
          this.handleFailure.bind(this)(payload.data);
          break;

        case 'signIn':
          console.info(`%c Amplify.Hub handling signIn`, 'background: #222; color: #bada55');
          break;

        case 'tokenRefresh':
          console.info(`%c Amplify.Hub handling tokenRefresh`, 'background: #222; color: #bada55');
          break;
        default:
          console.warn(`AWS Amplify Hub - Did not handle event ${payload.event}`);
          break;
      }
      payload = eventQueue.shift();
    }

    this.props.resetQueue();
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isShowSpinner: true,
    };

    console.info(`%c OauthLogin constructor`, 'background: #222; color: #bada55');
    console.info(`%c location = `, 'background: #222; color: #bada55', props.location);
  }

  clearTimeoutTimer = () => {
    clearTimeout(this.timeOutTimerId);
  };

  handleCognitoHostedUIEvent = async () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    try {
      await loginUserFromCognitoOAuth();
    } catch (error) {
      const message = processError(error as Error);

      switch (message) {
        case ErrorMessages.NO_ACCOUNT_FOUND_NOT_ROSTERED:
        case ErrorMessages.NO_ACCOUNT_FOUND_INCOMPLETE:
        case ErrorMessages.NO_ACCOUNT_FOUND_TEACHER_STUDENT_MIXUP:
          return handleNonExistentAccount();

        case ErrorMessages.ENTERPRISE_ACCOUNT_TEACHER:
          return handleNonExistentAccount(error as Error);

        default:
          that.props.onShowErrorMessage(
            noWhiteSpace`We’re sorry we were unable to log you in: ${message}. \
              Please send a screen capture of that message to Peekapak support.`,
            'Unable to log in',
            () => that.props.history.push('/login'),
          );
          logger.logException(new Error(message));
          break;
      }
    }

    function alreadyLoggedIn() {
      startPlatformAfterSuccessfulLogin(that.props.location, that.props.history);
    }

    function processError(error: Error | string) {
      if (error === '{"error":"invalid_grant"}') {
        return 'There was a problem. Please try signing in again.';
      }

      const message = getUserFriendlyErrorMessage(error as Error);

      if (!message) {
        logger.logException(new Error(`Google login ${that.props.location.pathname} error: ${error}`));
        console.error(`Google login error = ${JSON.stringify(error, null, 2)}`);
      }

      return message;
    }

    function getUserFriendlyErrorMessage(error: Error) {
      if (!error.message) {
        return '';
      }

      switch (error.message) {
        case ErrorMessages.NO_ACCOUNT_FOUND_NOT_ROSTERED:
        case ErrorMessages.NO_ACCOUNT_FOUND_INCOMPLETE:
        case ErrorMessages.NO_ACCOUNT_FOUND_TEACHER_STUDENT_MIXUP:
          return error.message;

        case 'User Pool account already exists':
          logger.logException(
            new Error(`Google login ${that.props.location.pathname} error: ${error.message || error}`),
          );
          that.props.onShowErrorMessage(
            noWhiteSpace`A Peekapak account with that email \
            address already exists. Please use the email address and associated password \
            to login instead of the Google Sign In feature`,
            'Account already exists',

            () => that.props.history.push('/login'),
          );
          return 'Please use email address and password to sign in';

        default:
          return error.message;
      }
    }

    function handleNonExistentAccount(error?: Error) {
      const origin = getSignInOrigin();
      const type = getStore().getState().user?.cognitoProfile?.attributes['custom:userType'];

      //
      // handle student login without account
      //
      if (type === 'Student') {
        that.props.onShowErrorMessage(
          noWhiteSpace`We’re sorry we were unable to log you in. \
            Please ask your teacher to roster you into a classroom and then try logging in again.`,
          'Student account has not been rostered',
          () => {
            return Auth.signOut();
          },
        );

        logger.logException(new Error(`Student used OAuth login but has not yet been rostered`));
        return;
      }

      //
      // handle teacher login without account
      //
      if (
        (error && error instanceof EnterpriseUserError) ||
        (error as EnterpriseUserError)?.code === ErrorType.ENTERPRISE_USER
      ) {
        const enterpriseUserError = error as EnterpriseUserError;
        const data = JSON.parse(enterpriseUserError.data);
        const { districtName, licenseLevel } = data;
        return that.props.history.replace(
          `/signup?signUpFromOauthLogin=true&districtName=${districtName}&licenseLevel=${licenseLevel}`,
        );
      } else if (origin.includes('Trial') || origin === 'signup') {
        return that.props.history.replace(`/${origin}?signUpFromOauthLogin=true`);
      } else if (origin === 'preauth') {
        return that.props.history.replace(`/signup?signUpFromOauthLogin=true`);
      } else {
        return that.props.onShowErrorMessage(
          noWhiteSpace`You don't have a Peekapak profile yet. Please complete the following process to sign up for a free 14-day trial account.`,
          'Sign up for an account',

          () => {
            that.props.history.push('/trial');
          },
        );
      }
    }

    async function loginUserFromCognitoOAuth() {
      console.info(`%c OauthLogin loginUserFromCognitoOAuth`, 'background: #222; color: #bada55');
      const pathBeforeLoginRedirect = getCookie('peekapak.pathBeforeLoginRedirect');
      await reactivateUserSessionIfExists();
      const origin = getSignInOrigin();
      const { loginState } = getStore().getState().user;

      if (loginState === LoginState.loggedIn && origin) {
        if (origin.includes('Trial') || origin === 'signup') {
          if (that.props.onShowErrorMessage) {
            that.props.onShowErrorMessage(
              `Your Peekapak account has already been created, so there's no need to sign up again. You will now be logged into the system.`,
              'Account already exists',
              () => that.props.history.push('/'),
            );
          }
        } else if (pathBeforeLoginRedirect) {
          that.props.history.replace(pathBeforeLoginRedirect);
        } else {
          alreadyLoggedIn.bind(that)();
        }
      }
    }
  };

  showProblemMessage = () => {
    this.props.onShowErrorMessage(
      `Sorry, we couldn't log you in. Please try again from our login page, and let us know if you keep on having this problem.`,
      'Problem logging in',
      () => this.props.history.push('/login'),
    );
  };

  handleFailure = (data) => {
    const amplifyErrorMessage = data.message ? decodeURIComponent(data.message).replace(/\+/gi, ' ') : undefined;

    if (amplifyErrorMessage && amplifyErrorMessage.includes('failed with error')) {
      //
      // WARNING WARNING WARNING WARNING
      // this parsing is very brittle
      //
      const parts = amplifyErrorMessage.split(/(\w+ failed with error|[{}])/g);

      if (isAccountLinked(parts[4])) {
        const errorObject = JSON.parse(`{ ${parts[4]} }`);
        console.warn(`AWS Amplify Hub received notice of account linking with external idP -- ${errorObject.username}`);
        return;
      } else {
        console.error(`Unrecognized Google login error: ${amplifyErrorMessage}`);
        logger.logException(new Error(`Google login ${this.props.location.pathname} error: ${amplifyErrorMessage}`));
        this.showProblemMessage();
        return;
      }
    } else {
      console.error(`Unhandled Google login error: ${data}`);
      logger.logException(new Error(`Unhandled Google login ${this.props.location.pathname} error: ${data}`));
      this.showProblemMessage();
      return;
    }

    function isAccountLinked(checkError) {
      return checkError.includes('PreSignupExternalEmailAlreadyExists');
    }
  };

  componentWillUnmount() {
    this.clearTimeoutTimer();
  }

  componentDidUpdate(prevProps: Props) {
    console.info(`%c OauthLogin componentDidUpdate`, 'background: #222; color: #bada55', this.props.queue);
    if (prevProps.queue.length !== this.props.queue.length) {
      this.handleAuthEvents();
    }
  }

  componentDidMount = () => {
    console.info(`%c OauthLogin componentDidMount`, 'background: #222; color: #bada55');

    this.timeOutTimerId = setTimeout(() => {
      this.showProblemMessage();
    }, 45000);

    const parseError = (error) => {
      if (
        error.includes('PreSignupExternalEmailAlreadyExists') ||
        error.includes('Exception processing authorization code')
      ) {
        return handleProviderLinkedAccount();
      } else {
        this.setState({
          isShowSpinner: false,
        });
        this.clearTimeoutTimer();
        console.error(`OAuth login failed: ${error}`);
        logger.logException(new Error(`OAuth login failed with error: ${error}`));
        this.props.onShowErrorMessage(`There was an error encountered: ${error}`, 'Unable to continue', () =>
          this.props.history.replace('/login'),
        );
        return;
      }
    };

    const handleProviderLinkedAccount = () => {
      this.setState({
        isShowSpinner: false,
      });
      this.handleAccountLinkedOkay();
    };

    const error_description = this.props.query.get('error_description');

    if (error_description) {
      parseError(error_description);
    } else {
      this.handleAuthEvents();
    }
  };
  handleGoogleSignIn = () => {
    openHostedUIForGoogle();
  };
  handleCleverSignIn = () => {
    openHostedUIForClever();
  };
  handleClassLinkSignIn = () => {
    openHostedUIForClassLink();
  };
  handleAccountLinkedOkay = () => {
    const idP = getCookie('peekapak.idP');

    switch (idP) {
      case 'Clever':
        this.handleCleverSignIn();
        break;

      case 'ClassLink':
        this.handleClassLinkSignIn();
        break;

      case 'Google':
      default:
        this.handleGoogleSignIn();
        break;
    }
  };

  render() {
    return (
      <>
        {this.state.isShowSpinner && (
          <StatusUpdate pictureUrl={Images.backpackPurpleBg} contentTitle='Processing...' isShowSpinner={true} />
        )}
      </>
    );
  }
}

function getSignInOrigin() {
  return getCookie('peekapak.oauthSignInOrigin');
}

function getIdentityProvider() {
  return getCookie('peekapak.idP');
}

function ClassFunctionWrapper(props) {
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const onShowErrorMessage = useErrorStatus();
  const queue = useSelector((state: RootState) => {
    return state.events.queue;
  });
  const dispatch = useDispatch();
  const resetQueue = () => {
    dispatch(reset());
  };
  return (
    <OauthLogin
      {...props}
      history={history}
      location={location}
      query={query}
      onShowErrorMessage={onShowErrorMessage}
      queue={queue}
      resetQueue={resetQueue}
    />
  );
}

export default ClassFunctionWrapper;
