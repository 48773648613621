import { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

export class SignUpValidationErrorMessage extends Component {
  render() {
    return (
      <Modal show={this.props.isShow} onHide={this.props.onHide}>
        <Modal.Body>
          <div className='ModalDialogText signUpValidationErrorMessage'>
            <p style={{ paddingBottom: '1em' }}>
              There is either incomplete information or a problem with the information being provided.
            </p>
            <p>The problematic areas are highlighted in red. Please correct the problems and try again.</p>
          </div>
          <div style={{ textAlign: 'center', padding: '2em' }}>
            <Button className='button okayButton' onClick={this.props.onHide} autoFocus>
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
