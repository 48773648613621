import { History } from 'history';
import { Component } from 'react';
import { useHistory } from 'react-router-dom';
import { GeneralErrorMessage } from '../GeneralErrorMessage';
import { logger } from '../../error-tracker';
import { noWhiteSpace } from '../../GlobalFunctions';
import * as styles from './ErrorBoundary.module.scss';

interface Props {
  history: History;
}

interface State {
  hasError: boolean;
  errorObject: Error | undefined;
}

export class InnerErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
      errorObject: undefined,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      errorObject: error,
    };
  }

  hideDialog = () => this.setState({ hasError: false });

  componentDidCatch(error: Error) {
    // You can also log the error to an error reporting service
    logger.logException(error);
    console.error(`Error below was caught by the ErrorBoundary: ${error}`);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <GeneralErrorMessage
          isShow={this.state.hasError}
          onHide={this.hideDialog}
          messageText={noWhiteSpace`We’ve experienced a problem: ${this.state.errorObject?.message || 'unknown'}.\
            Please take a screenshot of this message and send it to Peekapak support.`}
          onOkay={() => this.props.history.push('/')}
          titleText='There’s an unexpected problem'
          titleClass={styles.errorTitle}
          dialogClass={styles.errorDialog}
          contentClass={styles.errorContent}
        />
      );
    }

    return this.props.children;
  }
}

export function ErrorBoundary(props: { enabled: boolean; children: React.ReactNode }) {
  const history = useHistory();

  if (!props.enabled) {
    return <>{props.children}</>;
  }

  return <InnerErrorBoundary {...props} history={history} />;
}
