import {
  UserType,
  StruggleJounralEntryData,
  ComprehensionJournalEntryData,
  IdpType,
} from './DataProtocolTypes';
import { Mood, SecondaryMood } from './Mood';
import {
  ElementaryUnitName,
  LessonLanguage,
  QuestName,
  CreateAssignmentsRequestBase,
} from './LessonPlanTypes';

export const EventSource = {
  PEEKAPAK: 'peekapak',
  MYPEEKAVILLE: 'mypeekaville',
} as const;

export type EventSource = (typeof EventSource)[keyof typeof EventSource];

export const EventName = {
  INTERACTION_END: 'interaction-end',
  INTERACTION_START: 'interaction-start',
  JOURNAL_ASSIGNMENT_COMPLETE: 'journal-assignment-complete',
  JOURNAL_ASSIGNMENT_OPEN: 'journal-assignment-open',
  JOURNAL_ASSIGNMENT_STORY_OPEN: 'journal-assignment-story-open',
  JOURNAL_BOOK_CLOSE: 'journal-book-close',
  JOURNAL_BOOK_COMPLETE: 'journal-book-complete',
  JOURNAL_BOOK_OPEN: 'journal-book-open',
  JOURNAL_GRADED_MINI_GAME_COMPLETE: 'journal-graded-mini-game-complete',
  JOURNAL_MOOD_LOG: 'journal-mood-log',
  JOURNAL_QUEST_COMPLETE: 'journal-quest-complete',
  JOURNAL_READING_COMPREHENSION_COMPLETE:
    'journal-reading-comprehension-complete',
  JOURNAL_STORY_OPEN: 'journal-assignment-story-open',
  LESSONS_ASSIGNMENTS_CREATE: 'lessons-assignments-create',
  LESSONS_BOOK_OPEN: 'lessons-book-open',
  LESSONS_COMPLETE: 'lessons-complete',
  LESSONS_OPEN: 'lessons-open',
  LESSONS_STORY_OPEN: 'lessons-story-open',
  LOGIN: 'login',
  PAGE_VIEW: 'page-view',
  PARENTS_INVITATION_EMAIL_OPEN: 'parents-invitation-email-open',
  PARENTS_RESOURCE_OPEN: 'parents-resource-open',
  PARENTS_STORYBOOK_OPEN: 'parents-storybook-open',
} as const;

export type EventName = (typeof EventName)[keyof typeof EventName];

export const InteractionEndTrigger = {
  // user has not interacted with the page for a while
  IDLE: 'idle',
  // web browser user unloads page
  UNLOAD: 'unload',
  // mobile application switched to background
  PAUSE: 'pause',
} as const;

export type InteractionEndTrigger =
  (typeof InteractionEndTrigger)[keyof typeof InteractionEndTrigger];

export interface AnalyticsEvent {
  source: EventSource;
  name: EventName;
}

export interface UnauthenticatedEvent extends AnalyticsEvent {
  description?: string;
}

export interface AuthenticatedEvent extends AnalyticsEvent {
  userId: string;
  userType: UserType;
  schoolId: string | 'none';
  district: string | 'none';
  account: string | 'none';
  seller: string | 'none';
  licenseLevel: string;
  licenseExpires: number;
}

export type StudentEvent = AuthenticatedEvent & StudentTags;
export type NonStudentEvent = AuthenticatedEvent & NonStudentTags;
export type UserTypeEvent = StudentEvent | NonStudentEvent;
export type AnyUserEvent = UnauthenticatedEvent | UserTypeEvent;

export interface StudentTags {
  userType: typeof UserType.STUDENT;
  grade: number;
  readingLevel: number;
  classroomId: string;
}

export interface NonStudentTags {
  userType: Exclude<UserType, typeof UserType.STUDENT>;
}

/////////////////////////////////////////////////////////////////
//
// user type-specific events
//
/////////////////////////////////////////////////////////////////
export type LoginEvent<T extends StudentEvent | NonStudentEvent> = T & {
  name: typeof EventName.LOGIN;
  loginMethod: IdpType;
};

export type StorybookEvent<T extends UserTypeEvent> = T & {
  unit: ElementaryUnitName;
  readingLevel: number;
  language: LessonLanguage;
};

export type StorybookOpenEvent<T extends UserTypeEvent> = StorybookEvent<T> & {
  name: typeof EventName.JOURNAL_BOOK_OPEN;
};

export type StorybookCloseEvent<T extends UserTypeEvent> = StorybookEvent<T> & {
  name: typeof EventName.JOURNAL_BOOK_CLOSE;
  duration: number; // in seconds
};

export type StorybookCompleteEvent<T extends UserTypeEvent> =
  StorybookEvent<T> & {
    name: typeof EventName.JOURNAL_BOOK_COMPLETE;
  };

export type JournalQuestCompleteEvent<T extends UserTypeEvent> = T & {
  name: typeof EventName.JOURNAL_QUEST_COMPLETE;
  quest: QuestName;
};

export type PageViewEvent = AnyUserEvent & {
  name: typeof EventName.PAGE_VIEW;
  url: string;
};

export type LessonsOpenedEvent = NonStudentEvent & {
  name: typeof EventName.LESSONS_OPEN;
  lesson: string;
};

export type LessonsStoryOpenEvent = NonStudentEvent & {
  name: typeof EventName.LESSONS_STORY_OPEN;
  story: string;
};

export type JournalAssignmentOpenEvent = StudentEvent & {
  name: typeof EventName.JOURNAL_ASSIGNMENT_OPEN;
  assignmentName: string;
  createdAt: number;
};

export type JournalAssignmentStoryOpenEvent = StudentEvent & {
  name: typeof EventName.JOURNAL_STORY_OPEN;
  story: string;
};

export type JournalGradedMiniGameCompleteEvent = StudentEvent &
  StruggleJounralEntryData & {
    name: typeof EventName.JOURNAL_GRADED_MINI_GAME_COMPLETE;
  };

export type ParentsStorybookOpenEvent = AnyUserEvent & {
  name: typeof EventName.PARENTS_STORYBOOK_OPEN;
};

export type InteractionStartEvent = AnyUserEvent & {
  name: typeof EventName.INTERACTION_START;
};

export type InteractionEndEvent = AnyUserEvent & {
  name: typeof EventName.INTERACTION_END;
  trigger: InteractionEndTrigger;
  duration: number; // in seconds
};

export type JournalMoodLogEvent<T extends UserTypeEvent> = T & {
  name: typeof EventName.JOURNAL_MOOD_LOG;
  mood: Mood | SecondaryMood;
  teacherRequested?: boolean;
  journalEntryCreated?: boolean;
};

export type JournalStruggleEvent<T extends UserTypeEvent> = T &
  StruggleJounralEntryData & {
    name: typeof EventName.JOURNAL_GRADED_MINI_GAME_COMPLETE;
  };

export type JournalReadingComprehensionCompleteEvent<T extends UserTypeEvent> =
  T &
    Omit<ComprehensionJournalEntryData, 'time' | 'individualQuestionsData'> & {
      name: typeof EventName.JOURNAL_READING_COMPREHENSION_COMPLETE;
      duration: number; // in seconds
    };

export type JournalAssignmentCompleteEvent<T extends StudentEvent> = T & {
  name: typeof EventName.JOURNAL_ASSIGNMENT_COMPLETE;
  unit: string;
  lesson: string;
  module: string;
  metawork?: {
    [k: string]: unknown;
  };
};

export type LessonsAssignmentsCreateEvent<T extends NonStudentEvent> = T &
  CreateAssignmentsRequestBase & {
    name: typeof EventName.LESSONS_ASSIGNMENTS_CREATE;
  };

export interface LogEventRequest {
  event: AnyUserEvent;
}
