import { useEffect, useState } from 'react';
import PrimaryButton from '../PrimaryButton';

interface MyProps {
  buttonMessage: string;
  countdownMessage: string;
  initialCountdown?: number;
}

type Props = MyProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonWithCountdown = (props: Props) => {
  const { initialCountdown = 5, buttonMessage, countdownMessage, onClick, ...rest } = props;

  if (!onClick) {
    throw new Error('onClick is required');
  }

  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown]);

  if (countdown === 0) {
    onClick();
    return null;
  }

  return (
    <>
      <PrimaryButton onClick={onClick} {...rest}>
        {buttonMessage}
      </PrimaryButton>
      <p style={{ textAlign: 'center' }}>
        {countdownMessage}
        {countdown}
      </p>
    </>
  );
};

export default ButtonWithCountdown;
