import { useHistory } from 'react-router-dom';
import LeftRightCard, { Copy, ImagePart, Prompt, TextPart, Title } from '../../components/LeftRightCard/LeftRightCard';
import SectionHeader from '../../components/SectionHeader';
import AppleAppStoreBadge from '../../components/AppleAppStoreBadge';
import GooglePlayStoreBadge from '../../components/PlayStoreBadge/PlayStoreBadge';
import cx from 'classnames';
import checkmarkCircleGrey from '../../images/pkp-checkmark-in-circle-grey.svg';
import partnershipUSBE from '../../images/trio-for-banner.png';
import elementarySolutionsImage from '../../images/new-mockup-k-5.png';
import dataUsageImage from '../../images/mockup-mypeekaville-data.png';
import styles from './UtahHighlightsSegment.module.scss';

const UtahHighlightsSegment = (): JSX.Element => {
  const history = useHistory();
  return (
    <section className={styles.content}>
      <div className={styles.verticalWhiteSpace} />
      <LeftRightCard textLeadEven className={styles.partnershipWithUSBE}>
        <ImagePart>
          <img style={{ maxWidth: '450px' }} src={partnershipUSBE} alt='mockup pk-6' />
        </ImagePart>
        <div className={cx(styles.leftMarginHack, styles.elaAndSelCard)}>
          <TextPart>
            <SectionHeader alignLeft preTitle='UTAH STATE BOARD OF EDUCATION' title='In Partnership with USBE' />
            <Copy>
              <p>
                Peekapak is an approved research project for K-3 classrooms in the Utah State Board of Education (USBE)
                for the 2023-2024 school year! Peekapak is the next generation SEL curriculum that goes beyond software
                to empower educators to support both online and offline learning to unlock students&apos; full
                potential.
              </p>
              <ul className={styles.lists}>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  ELA and CASEL Aligned
                </li>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  Plug and Play Lessons
                </li>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  Gamified Learning
                </li>
                <li>
                  <img src={checkmarkCircleGrey} alt='checkmark icon' />
                  In Depth Analytics
                </li>
              </ul>
            </Copy>
            <Prompt clickHandler={() => window.open('https://calendly.com/amishah/30min?back=1', '_blank')}>
              Learn more
            </Prompt>
          </TextPart>
        </div>
      </LeftRightCard>
      <div className={styles.verticalWhiteSpace} />
      <LeftRightCard imageLead>
        <ImagePart>
          <img className={styles.imageMaxWidth} src={elementarySolutionsImage} alt='classroom interaction' />
        </ImagePart>
        <TextPart>
          <Copy>
            <h3 className={styles.preTitle}>PRE-K TO 5TH GRADE</h3>
          </Copy>
          <Title>Elementary Solutions</Title>
          <Copy>
            <p>
              Our easy-to-use, educator-led curriculum features levelled readers and fully-planned lessons to help
              students develop self-regulation, empathy and more while also developing core academic skills, including
              reading, writing, listening, speaking, representing and problem-solving.
            </p>
            <p>
              In myPeekaville, Peekapak&apos;s elementary digital learning game, students experience unit concepts in
              the context of an interactive story-world, filled with quests, games, embedded with extrinsic rewards to
              motivate learning.
            </p>
          </Copy>
          <div className={styles.linksContainer}>
            <Prompt className={styles.elementaryPrompt} clickHandler={() => history.push('/elementary')}>
              Review Elementary Resources
            </Prompt>
            <div className={styles.storeBadges}>
              <AppleAppStoreBadge />
              <GooglePlayStoreBadge />
            </div>
          </div>
        </TextPart>
      </LeftRightCard>
      <div className={styles.verticalWhiteSpace} />
      <LeftRightCard textLeadEven>
        <ImagePart>
          <img className={styles.imageMaxWidth} src={dataUsageImage} alt='peekaville map' />
        </ImagePart>
        <div className={styles.leftMarginHack}>
          <TextPart>
            <Copy>
              <h3 className={styles.preTitle}>DATA DRIVEN DECISIONS</h3>
            </Copy>
            <Title>Usage & Sentiment Data</Title>
            <Copy>
              <p>
                Peekapak solutions equip educators and administrators with real-time results to track student usage and
                progress monitoring using embedded data analytics.
              </p>
              <ul className={styles.dataUsageList}>
                <li>
                  <strong>Moodboard: </strong>Monitor students&apos; feelings through a digital check-in tool on the
                  student platform.
                </li>
                <li>
                  <strong>Assessment: </strong>At a glance, see how students are progressing in their learning to make
                  data-informed decisions to support learners.
                </li>
                <li>
                  <strong>ELA standards: </strong>Measure which standards have been taught by lesson completion.
                </li>
              </ul>
            </Copy>
          </TextPart>
        </div>
      </LeftRightCard>
    </section>
  );
};

export default UtahHighlightsSegment;
