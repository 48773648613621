import { Button } from 'react-bootstrap';
import combineClasses from 'classnames';
import styles from './LessRoundButton.module.scss';

interface Props {
  small?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const LessRoundButton = ({ small, disabled, children, onClick, ...otherProps }: Props) => (
  <div
    className={combineClasses({
      [`${styles.lessRoundButton}`]: true,
      [`${styles.small}`]: small,
      [`${styles.disabled}`]: disabled,
    })}
  >
    <Button onClick={onClick} {...otherProps}>
      {children}
    </Button>
  </div>
);

export default LessRoundButton;
