import './SCSS/bundle.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import { getStore, initializeApplicationState, myBrowserHistory } from './ApplicationState';
import { initializeMixpanel } from './mixpanel';
import initializeErrorTracker from './error-tracker';
import { initializeApplicationRoot } from './identity';
import { unregister } from './registerServiceWorker';
import { fixedEncodeURIComponent, parseQuery } from './GlobalFunctions';
import Root from './Root';
import { SingleMessagePage } from './Pages/SingleMessagePage';
import logoImage from './images/logo-pkp-horizontal-colour.png';
import { getPlatformMode } from './BackendInterface';

main();

async function main() {
  if (typeof JSON === 'undefined') {
    alert(`This website requires a modern browser such as: Chrome, Safari, Firefox,
        or Internet Explorer v11+`);
  }

  // the following sequence of function calls is not
  // idempotent since there are side effects from the
  // initialization of initial application state
  //
  initializeApplicationState();
  initializeApplicationRoot();
  initializeMixpanel();

  const tagManagerArgs = {
    gtmId: 'GTM-M7XZPGC',
    auth: 'K4RwN8ehr-OfaEm0BBaMRw',
    preview: 'env-1',
    events: {
      requestInfoSuccess: 'Request Info Sucesss',
    },
  };
  TagManager.initialize(tagManagerArgs);

  initializeErrorTracker();

  const queryString = parseQuery(window.location.search, window.location.hash);

  const store = getStore(); // render depends on this

  const maintenanceMode = await (async () => {
    try {
      const result = await getPlatformMode();
      return result;
    } catch (error) {
      return { mode: 'production' };
    }
  })();

  if (maintenanceMode.mode === 'maintenance') {
    renderMaintenancePage();
  } else if ('route' in queryString) {
    const newRoute = `${queryString.route}`;
    const newRouteWithQuery = buildRouteWithQuery(newRoute, queryString);

    myBrowserHistory.replace(`/${newRouteWithQuery}`);
    renderRouterConfiguration(Root);
  } else {
    renderRouterConfiguration(Root);
  }

  function renderMaintenancePage() {
    ReactDOM.render(
      <Provider store={store}>
        <HelmetProvider>
          <StrictMode>
            <SingleMessagePage
              graphic=<img style={{ width: '40em', marginBottom: '4em' }} src={logoImage} alt='Peekapak Logo' />
              title='Maintenance'
              body=<p>We are currently performing maintenance on our site. Please check back later.</p>
            />
          </StrictMode>
        </HelmetProvider>
      </Provider>,
      document.getElementById('root'),
    );
    unregister();
  }

  function renderRouterConfiguration(Component: React.FC) {
    ReactDOM.render(
      <Provider store={store}>
        <HelmetProvider>
          <StrictMode>
            <Component />
          </StrictMode>
        </HelmetProvider>
      </Provider>,
      document.getElementById('root'),
    );
    unregister();
  }

  function buildRouteWithQuery(rootRoute, queryStringObject) {
    const copy = queryStringObject;
    delete copy.route;

    // properly encode each element in the path of the route
    const elements = rootRoute.split('/');
    const encodedElements = [];
    elements.forEach((element) => {
      encodedElements.push(fixedEncodeURIComponent(element));
    });

    let newRoute = encodedElements.join('/');
    const keys = Object.keys(copy);
    let remaining = keys.length;

    if (remaining > 0) {
      const nextKey = keys.pop();
      const nextValue = fixedEncodeURIComponent(copy[nextKey]);
      newRoute = `${newRoute}?${nextKey}=${nextValue}`;
      remaining--;
    }

    while (remaining) {
      const nextKey = keys.pop();
      const nextValue = fixedEncodeURIComponent(copy[nextKey]);
      newRoute = `${newRoute}&${nextKey}=${nextValue}`;
      remaining--;
    }

    return newRoute;
  }
}

reportWebVitals();
