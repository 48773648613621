import { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import classNames from 'classnames';
import { Transition, animated } from 'react-spring';
import Documents from '../../Documents';
import ChooseRoleDialog, { SelectedRole } from '../../components/ChooseRoleDialog/ChooseRoleDialog';
import { getClassroomDescription, isUserHasKey } from '../../GlobalFunctions';
import { State as ClassroomsState } from '../../Classrooms';
import { State as MessagesState } from '../../Messages';
import SidebarId from './SidebarId';
import { RootState } from '../../ApplicationState';
import styles from '../../SCSS/Header.module.scss';
import { History, Location } from 'history';
import { ClassroomSelectorSidebar } from './ClassroomSelectorSidebar';

const mapStateToProps = (state: RootState) => ({
  messages: {
    state: state.messages.state,
    list: state.messages.messages,
    messageMark: state.messages.messageMark,
  },
  classrooms: {
    state: state.classrooms.state,
    list: state.classrooms.classrooms,
    selectedClassroom: state.classrooms.selectedClassroom,
  },
  userProfile: state.user.userProfile,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  history: History;
  location: Location;
  isAuthenticated: boolean;
};

interface SubheaderProps {
  toggleSidebar: () => void;
  history: History;
  location: Location;
  licenseLevel?: string;
  isAuthenticated: boolean;
  isElementary: boolean;
}

const SubheaderDropdown = ({
  toggleSidebar,
  history,
  licenseLevel,
  isAuthenticated,
  location,
  isElementary,
}: SubheaderProps) => {
  return (
    <div onClick={() => toggleSidebar()} style={{ overflow: 'hidden' }}>
      <MenuSeparator />
      {isAuthenticated ? (
        <div onClick={() => toggleSidebar()}>
          <HeaderUpgradeButton licenseLevel={licenseLevel} pathname={location.pathname} history={history} />
        </div>
      ) : null}
      <div onClick={() => history.push('/library')}>Library</div>
      <div onClick={() => history.push('/students')}>{isElementary ? 'Students + Parents' : 'Students'}</div>
      <div onClick={() => history.push('/classroom')}>Classroom Settings</div>
      {isElementary && <div onClick={() => history.push('/gallery')}>Gallery</div>}
      <div onClick={() => history.push('/reports')}>Reports</div>
      <div onClick={() => history.push('/accountSettings')}>Account Settings</div>
    </div>
  );
};

interface State {
  sidebarOpen: boolean;
  displayClassroomSelector: boolean;
  classroomName: string;
  displayDropdown: boolean;
  showChooseRoleDialog: boolean;
}

class HeaderSidebar extends Component<Props, State> {
  // Sidebar not built using standard react design patterns for simplicity
  transitionTime: string;
  sidebarWidthPercent: string;
  mainContainer: HTMLElement | null;
  sidebar: HTMLElement | null;
  constructor(props: Props) {
    super(props);
    this.transitionTime = '0.6s';
    this.sidebarWidthPercent = '80%';
    this.mainContainer = document.getElementById('root');
    this.sidebar = document.getElementById('sidebar');

    if (this.mainContainer) {
      this.mainContainer.style.transition = this.transitionTime;
    }

    this.state = {
      sidebarOpen: false,
      displayClassroomSelector: false,
      classroomName: 'Classroom Selector',
      displayDropdown: false,
      showChooseRoleDialog: false,
    };

    this.navigateLogin = this.navigateLogin.bind(this);
    this.navigateAccountSettings = this.navigateAccountSettings.bind(this);
    this.handleClassroomOptionView = this.handleClassroomOptionView.bind(this);
    this.handleClassroomName = this.handleClassroomName.bind(this);
    this.handleDropdownOptionView = this.handleDropdownOptionView.bind(this);
    this.toggle = this.toggle.bind(this);
    this.countNewMessages = this.countNewMessages.bind(this);
    this.detectIfUpdateRequire = this.detectIfUpdateRequire.bind(this);
  }

  onHideChooseDialog = () => this.setState({ showChooseRoleDialog: false });

  onChooseDialogSelect = (selection: SelectedRole) => {
    this.onHideChooseDialog();

    switch (selection) {
      case 'Student':
        window.location.href = 'https://www.mypeekaville.com';
        break;
      case 'Parent':
        this.props.history.push('/parents');
        break;
      default:
        this.props.history.push('/login');
        break;
    }
  };

  countNewMessages() {
    if (!this.props?.messages || this.props?.messages?.state !== MessagesState.loaded) {
      return 0;
    }

    const messageArray = this.props.messages.list;
    const compareTime = this.props.messages.messageMark;

    if (compareTime) {
      const filtered = messageArray.filter((message) => message.date > compareTime);
      return filtered.length;
    }

    return messageArray.length;
  }

  detectIfUpdateRequire() {
    if (this.props.userProfile) {
      if (!this.props.userProfile.schoolName && !this.props.userProfile.tel) {
        return true;
      }
    }
    return false;
  }

  handleDropdownOptionView() {
    this.setState({ displayDropdown: !this.state.displayDropdown });
  }

  handleClassroomOptionView() {
    this.setState({
      displayClassroomSelector: !this.state.displayClassroomSelector,
    });
  }

  handleClassroomName(newName: string) {
    this.setState({ classroomName: newName });
  }

  toggle() {
    this.state.sidebarOpen ? this.toggleClosed() : this.toggleOpen();
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  }

  toggleOpen() {
    this.mainContainer.style['margin-left'] = `-${this.sidebarWidthPercent}`;
    this.mainContainer.style['margin-right'] = this.sidebarWidthPercent;
    this.sidebar.style['margin-right'] = '0';
  }

  toggleClosed() {
    this.mainContainer.style['margin-left'] = '0';
    this.mainContainer.style['margin-right'] = '0';
    this.sidebar.style['margin-right'] = `-${this.sidebarWidthPercent}`;
  }

  componentDidMount() {
    // window.addEventListener( 'resize', () => this.handleResize() );
    this.sidebar = document.getElementById('HeaderSidebar');
  }

  navigateLogin() {
    this.setState({ showChooseRoleDialog: true });
  }

  navigateWaitlist() {
    this.props.history.push('/waitlist');
  }

  navigateAccountSettings() {
    this.props.history.push('/accountSettings');
  }

  getClassroomDescription = () => {
    const { state, list, selectedClassroom } = this.props.classrooms;
    if (state === ClassroomsState.loaded) {
      return getClassroomDescription(list[selectedClassroom]);
    }

    return '';
  };

  handleSignOut = () => {
    this.toggle();
    this.props.history.push('/logout');
  };

  render() {
    const description = this.getClassroomDescription();
    const currentClassroom = this.props.classrooms.list[this.props.classrooms.selectedClassroom];
    return (
      <div className={`${styles.headerButton} ${styles.HeaderSidebar}`}>
        <div className={styles.badgeWrapper}>
          <i
            className={classNames('fa', 'fa-bars', {
              [`${styles.rotatedBars}`]: this.state.sidebarOpen,
            })}
            style={{ transition: this.transitionTime }}
            onClick={() => this.toggle()}
          />
          <span
            className={`${styles.glyphicon} ${styles['glyphicon-exclamation-sign']}`}
            data-show={this.props.isAuthenticated && (this.detectIfUpdateRequire() || this.countNewMessages() !== 0)}
            data-location='mobileHeader'
          />
        </div>
        <div className={styles.sidebarMenu} id='HeaderSidebar' style={{ transition: this.transitionTime }}>
          <div onClick={this.handleDropdownOptionView}>
            <SidebarId
              {...this.state}
              isAuthenticated={this.props.isAuthenticated}
              userProfile={this.props?.userProfile}
            />
          </div>
          <Transition
            items={this.state.displayDropdown}
            from={{ maxHeight: '0' }}
            enter={{ maxHeight: '20em' }}
            leave={{ maxHeight: '0' }}
            reverse={this.state.displayDropdown}
          >
            {(styles, item) =>
              item && (
                <animated.div style={{ ...styles, overflow: 'hidden' }}>
                  <SubheaderDropdown
                    isAuthenticated={this.props.isAuthenticated}
                    isElementary={currentClassroom.grade < 6}
                    licenseLevel={this.props.userProfile?.licenseLevel}
                    location={this.props.location}
                    toggleSidebar={this.toggle}
                    history={this.props.history}
                  />
                </animated.div>
              )
            }
          </Transition>
          {this.props.userProfile && <MenuSeparator />}
          {this.props.userProfile && (
            <div>
              <div onClick={this.handleClassroomOptionView}>
                {description || 'Classroom Selector '}
                {!this.state.displayClassroomSelector ? '▼' : '▲'}
              </div>
              <Transition
                items={this.state.displayClassroomSelector}
                from={{ maxHeight: '0' }}
                enter={{ maxHeight: '20em' }}
                leave={{ maxHeight: '0' }}
                reverse={this.state.displayClassroomSelector}
              >
                {(styles, item) =>
                  item && (
                    <animated.div
                      style={{
                        ...styles,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                      }}
                    >
                      <ClassroomSelectorSidebar
                        {...this.props}
                        toggle={this.toggle}
                        closeClassroomSelector={this.handleClassroomOptionView}
                      />
                    </animated.div>
                  )
                }
              </Transition>
            </div>
          )}
          {this.props.userProfile && <MenuSeparator />}
          <div
            onClick={() => {
              this.props.history.push('/product');
              this.toggle();
            }}
          >
            Product
          </div>
          <div
            onClick={() => {
              this.props.history.push('/successStories');
              this.toggle();
            }}
          >
            Success Stories
          </div>
          <a href={Documents.research} rel='noreferrer noopener' target='_blank' className='boxedButton'>
            Research
          </a>
          <div
            onClick={() => {
              this.props.history.push('/resources');
              this.toggle();
            }}
          >
            Resources
          </div>
          {this.props.userProfile ? (
            <div>
              <div onClick={this.handleSignOut}>Logout</div>
            </div>
          ) : (
            <>
              <div onClick={() => this.toggle()}>
                <div onClick={this.navigateLogin}>Login</div>
                <div onClick={() => this.props.history.push('/signup')}>Sign Up</div>
                <div onClick={() => this.props.history.push('/requestInfo')}>Request Info</div>
              </div>
              <ChooseRoleDialog
                isShow={this.state.showChooseRoleDialog}
                onHide={this.onHideChooseDialog}
                handlerOnSelect={this.onChooseDialogSelect}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connector(HeaderSidebar);

function MenuSeparator() {
  return <div className={styles.menuSeparator} />;
}

function navigateToUpgrade(history: History) {
  history.push('/features');
}

interface HeaderUpgradeButtonParams {
  licenseLevel?: string;
  pathname: string;
  history: History;
}
function HeaderUpgradeButton({ licenseLevel, pathname, history }: HeaderUpgradeButtonParams) {
  if (
    licenseLevel &&
    licenseLevel.toLowerCase() !== 'trial' &&
    licenseLevel.toLowerCase() !== 'freemium' &&
    licenseLevel.toLowerCase() !== 'basic'
  ) {
    return null;
  }
  if (pathname.includes('/features')) {
    return <div style={{ cursor: 'default' }}>Upgrade</div>;
  }

  return (
    <div
      onClick={() => {
        navigateToUpgrade(history);
      }}
    >
      Upgrade
    </div>
  );
}
