import {
  ClassLinkApiSection,
  ClassLinkApiStudent,
  ClassLinkApiStudentWithSection,
  ClassroomType,
  CleverApiSection,
  CleverApiStudent,
  CleverApiStudentResult,
  NormalizedExternalSection,
  ProviderManagedAccessToken,
  ProviderManagedUserId,
  UserPropertyAccessor,
} from '../peekapak-types/DataProtocolTypes';
import { getStore } from './ApplicationState';
import {
  getAllMyCleverStudents,
  getClassLinkSections,
  getClassLinkStudents,
  getCleverSections,
} from './BackendInterface';
import { UserManagementState } from './UserManagement';

export enum SyncSource {
  CLEVER = 'Clever',
  GOOGLE = 'Google',
  CLASSLINK = 'ClassLink',
  UNDEFINED = '',
}

export function getSyncSourceFromClassroom(classroom: ClassroomType): SyncSource {
  const sourceName = classroom?.externalClassroomId?.split('_')[0]?.toLowerCase();

  if (!sourceName) return SyncSource.UNDEFINED;

  return getSyncSource(sourceName);
}

export function getSyncSource(providerName: string) {
  switch (providerName.toLowerCase()) {
    case 'clever':
      return SyncSource.CLEVER;
    case 'classlink':
      return SyncSource.CLASSLINK;
    case 'google':
      return SyncSource.GOOGLE;
    default:
      throw new Error(`Unrecognized import source`);
  }
}

export function createComposers(source: SyncSource) {
  switch (source) {
    case SyncSource.CLEVER:
      return {
        getNormalizedSections: getNormalizedCleverSections,
      };
    case SyncSource.CLASSLINK:
      return {
        getNormalizedSections: getNormalizedClassLinkSections,
      };
    default:
      throw new Error(`Import source ${source} is not recognized`);
  }

  function getNormalizedClassLinkSections(
    sections: ClassLinkApiSection[],
    students: ClassLinkApiStudentWithSection[],
  ): NormalizedExternalSection[] {
    const section2studentsMap: Record<string, ClassLinkApiStudent[]> = (() => {
      const map: Record<string, ClassLinkApiStudent[]> = {};
      let i = 0;
      while (i < students.length) {
        map[students[i].sectionSourcedId] = [...(map[students[i].sectionSourcedId] || []), students[i]];
        i += 1;
      }
      return map;
    })();

    const sectionsNormalizedStudents = [];
    const externalSections = sections.map((section) => ({
      id: section.sourcedId,
      name: section.title,
      modifiedAt: Date.parse(section.dateLastModified),
      students: [],
    }));

    let i = 0;
    while (i < sections.length) {
      const curSection = externalSections[i];
      const classLinkStudents = section2studentsMap?.[curSection.id] || [];

      if (classLinkStudents.length === 0) {
        i += 1;
        continue;
      }

      const externalStudents = classLinkStudents.map((student) => {
        return {
          profile: {
            id: student.sourcedId,
            name: {
              givenName: student.givenName,
              familyName: student.familyName,
              middleName: student.middleName || '',
            },
            emailAddress: student.email || '',
            grade: student.grades[0] || '',
          },
        };
      });
      const gradesHistogram: Record<string, number> = externalStudents.reduce(
        (acc: Record<string, number>, cur) => {
          return {
            ...acc,
            [cur.profile.grade]: (acc[cur.profile.grade] || 0) + 1,
          };
        },
        {} as Record<string, number>,
      );

      const grade = (() => {
        const grades = Object.keys(gradesHistogram);
        if (grades.length === 1) return grades[0];
        const maxGrade = grades.reduce((acc, cur) => {
          return gradesHistogram[cur] > gradesHistogram[acc] ? cur : acc;
        });
        return maxGrade;
      })();

      sectionsNormalizedStudents.push({
        ...curSection,
        grade,
        students: externalStudents,
      });
      i += 1;
    }

    return sectionsNormalizedStudents;
  }

  function getNormalizedCleverSections(
    sections: CleverApiSection[],
    students: CleverApiStudent[],
  ): NormalizedExternalSection[] {
    const allMyStudentsMap: Record<string, CleverApiStudent> = (() => {
      const map: Record<string, CleverApiStudent> = {};
      let i = 0;
      while (i < students.length) {
        map[students[i].data.id] = students[i];
        i += 1;
      }
      return map;
    })();

    const newSections = [];
    let i = 0;
    while (i < sections.length) {
      const curSection = {
        ...sections[i].data,
        modifiedAt: Date.parse(sections[i].data.last_modified),
      };

      const cleverStudents = (() => {
        const s = [];
        let j = 0;
        while (j < curSection.students.length) {
          if (Object.hasOwn(allMyStudentsMap, curSection.students[j])) {
            s.push(allMyStudentsMap[curSection.students[j]]);
          }
          j += 1;
        }
        return s;
      })();
      const externalStudents = cleverStudents.map((student) => {
        return {
          profile: {
            id: student.data.id,
            name: {
              givenName: student.data.name.first,
              familyName: student.data.name.last || '',
              middleName: student.data.name.middle || '',
            },
            emailAddress: student.data.email || '',
            grade: student.data.roles.student.grade,
          },
        };
      });
      newSections.push({ ...curSection, students: externalStudents });
      i += 1;
    }

    return newSections;
  }
}

export function createRetrievers(source: SyncSource) {
  switch (source) {
    case SyncSource.CLEVER:
      return {
        retrieveSections: retrieveCleverSections,
        retrieveStudents: retrieveCleverStudents,
      };
    case SyncSource.CLASSLINK:
      return {
        retrieveSections: retrieveClassLinkSections,
        retrieveStudents: retrieveClassLinkStudents,
      };
    default:
      throw new Error(`Import source ${source} is not recognized`);
  }

  async function retrieveClassLinkSections(): Promise<ClassLinkApiSection[]> {
    const state = getStore().getState();
    const user = state.user;
    if (!user) {
      throw new Error(`User is not logged in`);
    }

    const teacherSourcedId = user.cognitoProfile?.attributes['custom:pmUserId'];
    if (!teacherSourcedId) throw new Error(`User is missing ClassLink SourcedId`);
    const sections = await getClassLinkSections(teacherSourcedId);
    console.debug(`Retrieved ${sections.length} sections = `, sections);
    return sections;
  }

  async function retrieveClassLinkStudents(): Promise<ClassLinkApiStudentWithSection[]> {
    const state = getStore().getState();
    const user = state.user;
    if (!user) {
      throw new Error(`User is not logged in`);
    }

    const teacherSourcedId = user.cognitoProfile?.attributes['custom:pmUserId'];
    if (!teacherSourcedId) throw new Error(`User is missing ClassLink SourcedId`);
    const sections = await getClassLinkSections(teacherSourcedId);

    const sectionIds = sections.map((section) => section.sourcedId);

    const finalResults: ClassLinkApiStudentWithSection[] = [];
    for (const sectionId of sectionIds) {
      const students = await getClassLinkStudents(sectionId);
      finalResults.push(...students);
    }

    return finalResults;
  }

  async function retrieveCleverSections(
    userId: ProviderManagedUserId,
    accessToken: ProviderManagedAccessToken,
  ): Promise<CleverApiSection[]> {
    const sections = await getCleverSections(accessToken, userId);
    return sections.data;
  }

  async function retrieveCleverStudents(
    userId: ProviderManagedUserId,
    accessToken: ProviderManagedAccessToken,
  ): Promise<CleverApiStudent[]> {
    const allMyStudents: CleverApiStudentResult = await getAllMyCleverStudents(accessToken, userId);

    return allMyStudents.data;
  }
}

export function createUserPropertyAccessors(source: SyncSource, user: UserManagementState): UserPropertyAccessor {
  switch (source) {
    case SyncSource.CLEVER:
      return {
        getApiUserId: getCleverUserId,
        getApiAccessToken: getCleverAccessToken,
        isUserSignedIn: isUserSignedIn,
      };
    case SyncSource.CLASSLINK:
      return {
        getApiUserId: getClassLinkUserId,
        getApiAccessToken: getClassLinkAccessToken,
        isUserSignedIn: isUserSignedIn,
      };
    default:
      throw new Error(`Import source ${source} is not recognized`);
  }

  function isUserSignedIn() {
    return !!getStore().getState().user.userProfile;
  }

  function getClassLinkUserId() {
    if (!user?.cognitoProfile) {
      return undefined;
    }

    return user.cognitoProfile?.attributes['custom:pmUserId'];
  }

  function getClassLinkAccessToken() {
    if (!user?.cognitoProfile) {
      return undefined;
    }

    return user.cognitoProfile?.attributes['custom:pmAccessToken'];
  }

  function getCleverUserId(): ProviderManagedUserId | undefined {
    if (!user?.cognitoProfile) {
      return undefined;
    }

    return user.cognitoProfile?.attributes['custom:pmUserId'];
  }

  function getCleverAccessToken(): ProviderManagedAccessToken | undefined {
    if (!user?.cognitoProfile) {
      return undefined;
    }

    return user.cognitoProfile?.attributes['custom:pmAccessToken'];
  }
}
