import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { ConnectedProps, connect } from 'react-redux';
import cx from 'classnames';
import Spinner from 'react-spinkit';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { logger } from '../error-tracker';
import { initializeStripe, stripePromise } from '../stripe';
import SpinnerOverlay from '../SpinnerOverlay';
import { updateAllFields } from '../UserManagement';
import { TierInvoicePreview, getTierName } from '../components/TierPriceDisplay/TierPriceDisplay';
import { FormGroup } from 'react-bootstrap';
import { Segment, VerticalSpacer } from '../core/core';
import { createSubscription, getCustomer, getPaymentStatus, previewInvoice } from '../BackendInterface';
import PromotionCodeInput from '../components/PromotionCodeInput/PromotionCodeInput';
import { validatePromoCode } from '../BackendInterface';
import { OpenLockIcon } from '../OpenLockIcon';
import styles from '../SCSS/Upgrade.module.scss';
import utilityStyles from '../SCSS/common/utilityStyles.module.scss';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton/SecondaryButton';
import powerdByStripe from '../images/Powered by Stripe - black.png';
import { AppDispatch } from '../ApplicationState';
import { UserProfileType } from '../../peekapak-types/DataProtocolTypes';
import { StripeCardElement, StripeCardElementChangeEvent } from '@stripe/stripe-js';
let idleTimerHandle: NodeJS.Timeout | null = null;

const ErrorMessage = ({ children, isShow }: { children: React.ReactNode; isShow: boolean }) => {
  return <div className={styles.errorMessage}>{isShow && children}</div>;
};

interface CheckoutFormProps {
  tier: string;
  usersProfile: UserProfileType | null;
  updateAllFields: Props['updateAllFields'];
  onSuccess: () => void;
  region: string;
  invoicePreview: Record<string, unknown> | null;
  isInvoicePreviewReady: string;
  onApplyPromoCode: (newPromoCode: string) => void;
}
const CheckoutForm = ({
  tier,
  usersProfile,
  updateAllFields,
  onSuccess,
  region,
  invoicePreview,
  isInvoicePreviewReady,
  onApplyPromoCode,
}: CheckoutFormProps) => {
  const [isPaymentPending, setIsPaymentPending] = useState(false);
  const [isPreviewPending, setIsPreviewPending] = useState(false);
  const [isCardValid, setIsCardValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [isCheckingPromoCode, setIsCheckingPromoCode] = useState(false);
  const [promoCodeValidationState, setPromoCodeValidationState] = useState<'success' | 'error' | null>(null);
  const [codeErrorShake, setCodeErrorShake] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const promoCodeRef = useRef(promoCode);
  promoCodeRef.current = promoCode;

  const delay = () => new Promise((resolve) => setTimeout(resolve, 5000));

  useEffect(() => {
    if (isInvoicePreviewReady === 'no') {
      setIsPreviewPending(true);
    } else {
      setIsPreviewPending(false);
    }
  }, [isInvoicePreviewReady]);

  /* eslint-disable complexity */
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (shouldDisableSubmit()) {
      return;
    }

    setIsPaymentPending(true);
    setErrorMessage('');

    try {
      // const { id: customerId } = await getCustomer();
      const customer = await getCustomer();
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);
      // Use your card Element with other Stripe.js APIs
      // const { error, paymentMethod } = await stripe.createPaymentMethod({
      const result = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement as StripeCardElement,
      });

      if (result.error) {
        setIsPaymentPending(false);
        setErrorMessage(result.error.message as string);
        return;
      }

      const subscription = await createSubscription(
        customer.id,
        result.paymentMethod.id,
        tier,
        region,
        promoCodeValidationState === 'success' ? promoCode : '',
      );
      const { latest_invoice } = subscription;
      const { payment_intent } = latest_invoice;

      if (payment_intent.status === 'requires_action' || payment_intent.status === 'requires_payment_method') {
        const confirmResult = await stripe.confirmCardPayment(payment_intent.client_secret, {
          payment_method: result.paymentMethod.id,
        });

        if (confirmResult.error) {
          setIsPaymentPending(false);
          setErrorMessage(confirmResult.error.message as string);
          return;
        }
      }

      const newPaymentStatus = await waitForPaymentCompletion();
      const { licenseLevel, licenseExpires, keyRing, stripeInvoice, stripeSubscription } = newPaymentStatus;

      if (stripeSubscription.status === 'active' && stripeInvoice.status === 'paid') {
        updateAllFields({
          keyRing,
          licenseLevel,
          licenseExpires,
        });
      } else if (stripeInvoice.status && stripeInvoice.status !== 'paid') {
        setIsPaymentPending(false);
        setErrorMessage(`There was a probem charging your credit card`);
        return;
      }
    } catch (error) {
      console.error(error as Error);
      setIsPaymentPending(false);
      setErrorMessage((error as Error).message);
      return;
    }

    setIsPaymentPending(false);
    onSuccess();
    return;

    async function waitForPaymentCompletion() {
      let result = await getPaymentStatus();

      while (
        !result.stripeSubscription ||
        !result.stripeInvoice ||
        !result.stripeInvoice.status ||
        !result.stripeSubscription.status ||
        (result.stripeInvoice.status === 'payment_failed' && result.stripeSubscription.status === 'incomplete')
      ) {
        await delay();
        result = await getPaymentStatus();
      }

      return result;
    }
  };

  const onStopCheckingCode = (valid = false) => {
    setIsCheckingPromoCode(false);
    setCodeErrorShake(!valid);
    setPromoCodeValidationState(valid ? 'success' : 'error');
  };

  const clearTimersIfActivated = () => {
    if (idleTimerHandle) {
      // console.debug( `clearing timer ${ this.idleTimerHandle }` );
      clearTimeout(idleTimerHandle);
      idleTimerHandle = null;
    }
  };

  const onCheckCode = async () => {
    if (!promoCodeRef.current) return;
    setIsCheckingPromoCode(true);

    try {
      const valid = await validatePromoCode(promoCodeRef.current);
      onStopCheckingCode(valid);
    } catch (error) {
      console.error(error);
      onStopCheckingCode(false);
    }
  };

  const onCodeChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
    const beautified = (event.target as HTMLInputElement).value.toUpperCase().trim();

    if (beautified.length === 0) {
      setPromoCode('');
      setPromoCodeValidationState(null);
      return;
    }

    setPromoCode(beautified);
    clearTimersIfActivated();
    idleTimerHandle = setTimeout(onCheckCode, 1000); // console.debug( `created timer ${ this.idleTimerHandle }` );
  };

  const applyPromoCode = async () => {
    if (promoCodeValidationState === 'success') {
      // console.debug(`calling back applypromocode`);
      onApplyPromoCode(promoCode);
    }
  };

  return (
    <Segment className={styles.upgradeSegment}>
      <SpinnerOverlay isShow={isPaymentPending} />
      <VerticalSpacer ems={1} />
      <div className={`${utilityStyles.absCentre} ${styles.upgradePrologue}`}>
        <OpenLockIcon />
        <div className={styles.title}>Subscribe to a {getTierName(tier)} Membership</div>
        <VerticalSpacer ems={1} />
      </div>
      <VerticalSpacer ems={1} />
      <div className={styles.theCreditCardForm}>
        <form onSubmit={handleSubmit} className={styles.billingGrid}>
          <div className={styles.rightSide}>
            <div className={styles.invoicePreview}>
              <div
                className={cx(styles.previewSpinnerContainer, {
                  [`${styles.showPending}`]: isPreviewPending,
                })}
              >
                <Spinner name='three-bounce' className={styles.previewSpinner} />
              </div>
              <TierInvoicePreview
                tier={tier}
                invoicePreview={invoicePreview}
                isInvoicePreviewReady={isInvoicePreviewReady}
              />
              <div className={styles.promoCodeInput}>
                <PromotionCodeInput
                  className={cx(styles.codeWidth, {
                    [`${styles.errorShake}`]: codeErrorShake,
                  })}
                  onCodeChange={onCodeChange}
                  code={promoCode}
                  showSpinner={isCheckingPromoCode}
                  inputClassName={{}}
                  validationState={promoCodeValidationState}
                  placeholder='Enter coupon/promo code'
                />
                {/*
                <PrimaryButton
                 small
                 onClick={applyPromoCode}
                 {...{
                   disabled:
                     isCheckingPromoCode ||
                     promoCodeValidationState !== 'success',
                 }}
                >
                 Apply
                </PrimaryButton>
                */}
                <SecondaryButton
                  small
                  onClick={applyPromoCode}
                  {...{
                    disabled: isCheckingPromoCode || promoCodeValidationState !== 'success',
                  }}
                >
                  Apply
                </SecondaryButton>
              </div>
            </div>
          </div>
          <div className={styles.leftSide}>
            <div className={styles.billTo}>
              <p className={styles.sectionTitle}>Bill to</p>
              <ul className={styles.userInformation}>
                <li>
                  {usersProfile?.firstName} {usersProfile?.lastName}
                </li>
                <li>{usersProfile?.schoolName}</li>
                <li>{usersProfile?.schoolAddress}</li>
                <li>
                  {usersProfile?.schoolCity}, {usersProfile?.schoolState}
                  {'  '}
                  {usersProfile?.schoolZip}
                </li>
              </ul>
            </div>
            <div className={styles.paymentInformation}>
              <p className={styles.sectionTitle}>Payment Information</p>
              <FormGroup className={styles.creditCardInput}>
                <img src={powerdByStripe} alt='stripe logo' />
                <CardElement
                  id='stripeCardElement'
                  className={styles.creditCardBox}
                  options={{
                    style: {
                      base: {
                        color: '#32325d',
                        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                        fontSmoothing: 'antialiased',
                        fontSize: '16px',
                        '::placeholder': {
                          color: '#aab7c4',
                        },
                      },
                      invalid: {
                        color: '#fa755a',
                        iconColor: '#fa755a',
                      },
                    },
                  }}
                  onChange={handleOnCardChange}
                />
                <PrimaryButton type='submit' disabled={shouldDisableSubmit()} className={styles.buttonTopMargin}>
                  Subscribe
                </PrimaryButton>
                <ErrorMessage isShow={!!errorMessage}>{errorMessage}</ErrorMessage>
              </FormGroup>
            </div>
          </div>
        </form>
      </div>
    </Segment>
  );

  function shouldDisableSubmit() {
    if (!stripe) return true;
    if (!isCardValid) return true;
    if (isPaymentPending) return true;
    return false;
  }

  function handleOnCardChange(event: StripeCardElementChangeEvent) {
    if (event.complete && !event.error) {
      return setIsCardValid(true);
    }

    setIsCardValid(false);
  }
};

type Props = PropsFromRedux & {
  stepNumber: number;
  onNextStep: (currentStep: number, isStepValid: boolean) => boolean;
  usersProfile: UserProfileType | null;
  tier: string;
};

const StripeElementsCheckout = ({ tier, usersProfile, updateAllFields, onNextStep, stepNumber }: Props) => {
  const [stripeReady, setStripeReady] = useState(false);
  const [region, setRegion] = useState('');
  const [invoicePreview, setInvoicePreview] = useState<Record<string, unknown> | null>(null);
  const [isInvoicePreviewReady, setIsInvoicePreviewReady] = useState('');
  const promoCodeRef = useRef('');
  useEffect(() => {
    ensureStripeInitialized();
  }, []);
  useEffect(() => {
    determineRegion();

    function determineRegion() {
      const domNode = document.getElementById('dummyMap') as HTMLDivElement;

      if (domNode && usersProfile?.placeId) {
        const request = {
          placeId: usersProfile.placeId,
          fields: ['address_components'],
        };
        const service = new google.maps.places.PlacesService(domNode);
        service.getDetails(request, callback);
      } else {
        setRegion('Unknown');
      }

      function callback(place: google.maps.places.PlaceResult | null, status: google.maps.places.PlacesServiceStatus) {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const { address_components } = place;
          const regionComponent = address_components?.find((component) => {
            if (component.types) {
              return component.types.includes('administrative_area_level_1');
            }

            return false;
          });

          if (regionComponent) {
            setRegion(regionComponent.long_name);
          } else {
            setRegion('Unknown');
          }
        }
      }
    }
  }, [usersProfile?.placeId]);
  const memoizedGetInvoicePreview = useCallback(async () => {
    setIsInvoicePreviewReady('no');

    try {
      const preview = await previewInvoice(tier, region, promoCodeRef.current);

      if (preview) {
        setInvoicePreview(preview);
        setIsInvoicePreviewReady('yes');
        // console.debug(`invoice preview = `, preview);
      }
    } catch (error) {
      logger.logException(error as Error);
      setIsInvoicePreviewReady('error');
    }
  }, [tier, region, promoCodeRef]);
  //
  // to create the initial preview only, otherwise
  // we would get into an infinite loop
  //
  useEffect(() => {
    if (!invoicePreview && region) {
      memoizedGetInvoicePreview();
    }
  }, [tier, invoicePreview, region, memoizedGetInvoicePreview]);

  if (!stripeReady) {
    return <SpinnerOverlay isShow />;
  }

  const handleApplyPromoCode = (newPromoCode: string) => {
    promoCodeRef.current = newPromoCode;
    memoizedGetInvoicePreview();
  };

  return (
    <>
      <Elements stripe={stripePromise}>
        <CheckoutForm
          tier={tier}
          usersProfile={usersProfile}
          updateAllFields={updateAllFields}
          onSuccess={onSuccess}
          region={region}
          invoicePreview={invoicePreview}
          isInvoicePreviewReady={isInvoicePreviewReady}
          onApplyPromoCode={handleApplyPromoCode}
        />
      </Elements>
    </>
  );

  function onSuccess() {
    onNextStep(stepNumber, true);
  }

  async function ensureStripeInitialized() {
    await initializeStripe();
    setStripeReady(true);
  }
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateAllFields: (newFieldsObject: Partial<UserProfileType>) => dispatch(updateAllFields(newFieldsObject)),
});

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StripeElementsCheckout);
