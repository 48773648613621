import { connect, ConnectedProps } from 'react-redux';
import StandalonePageWrapper from './StandalonePageWrapper';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import { getAuthorizationToken } from './BackendInterface';
import { cognitoConfiguration } from './ApplicationState';
import { useEffect, useState } from 'react';
import { getRefreshTokenExpiry } from './UserManagementHelpers';
import { RootState } from './ApplicationState';
import { History } from 'history';
import { LoginState } from './UserManagement';

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
});
const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  loginState: LoginState;
  history: History;
  isAuthenticated: boolean;
  onShowGetStartedDialog: () => void;
  params: { classroomName: string };
};

const frontendCommitHash = import.meta.env.REACT_FRONTEND_COMMIT_HASH
  ? import.meta.env.REACT_FRONTEND_COMMIT_HASH
  : 'N/A';

const frontendBranchName = import.meta.env.REACT_FRONTEND_BRANCH_NAME
  ? import.meta.env.REACT_FRONTEND_BRANCH_NAME
  : 'N/A';

const serverCommitHash = import.meta.env.REACT_SERVER_COMMIT_HASH ? import.meta.env.REACT_SERVER_COMMIT_HASH : 'N/A';

const serverBranchName = import.meta.env.REACT_SERVER_BRANCH_NAME ? import.meta.env.REACT_SERVER_BRANCH_NAME : 'N/A';

const contentMetaCommitHash = import.meta.env.REACT_CONTENTMETA_COMMIT_HASH
  ? import.meta.env.REACT_CONTENTMETA_COMMIT_HASH
  : 'N/A';

const contentMetaBranchName = import.meta.env.REACT_CONTENTMETA_BRANCH_NAME
  ? import.meta.env.REACT_CONTENTMETA_BRANCH_NAME
  : 'N/A';

const microservicesCommitHash = import.meta.env.REACT_MICROSERVICES_COMMIT_HASH
  ? import.meta.env.REACT_MICROSERVICES_COMMIT_HASH
  : 'N/A';

const microservicesBranchName = import.meta.env.REACT_MICROSERVICES_BRANCH_NAME
  ? import.meta.env.REACT_MICROSERVICES_BRANCH_NAME
  : 'N/A';

const commonCommitHash = import.meta.env.REACT_COMMON_COMMIT_HASH ? import.meta.env.REACT_COMMON_COMMIT_HASH : 'N/A';

const commonBranchName = import.meta.env.REACT_COMMON_BRANCH_NAME ? import.meta.env.REACT_COMMON_BRANCH_NAME : 'N/A';

const cleverOpenIdBridgeHash = import.meta.env.REACT_BRIDGE_COMMIT_HASH
  ? import.meta.env.REACT_BRIDGE_COMMIT_HASH
  : 'N/A';

const cleverOpenIdBridgeBranchName = import.meta.env.REACT_BRIDGE_BRANCH_NAME
  ? import.meta.env.REACT_BRIDGE_BRANCH_NAME
  : 'N/A';

const buildDate = import.meta.env.REACT_BUILD_DATE ? import.meta.env.REACT_BUILD_DATE : 'N/A';

export const frontendVersionDescription = `[ ${frontendBranchName} ] ${frontendCommitHash}`;

const VersionInformation = (props: Props) => {
  const [authToken, setAuthToken] = useState('');

  useEffect(() => {
    getAuth();

    async function getAuth() {
      if (props.userProfile) {
        const a = await getAuthorizationToken();
        setAuthToken(a);
      }
    }
  });

  return (
    <StandalonePageWrapper>
      <div className='standaloneMessageWrapper'>
        <h1>Version Information</h1>
        <p>
          <strong>
            Frontend Branch <em>{frontendBranchName}</em> commit hash:{' '}
          </strong>
          {frontendCommitHash}
        </p>
        <p>
          <strong>
            Server Branch <em>{serverBranchName}</em> commit hash:{' '}
          </strong>
          {serverCommitHash}
        </p>
        <p>
          <strong>
            ContentMeta Branch <em>{contentMetaBranchName}</em> commit hash:{' '}
          </strong>
          {contentMetaCommitHash}
        </p>
        <p>
          <strong>
            Microservices Branch <em>{microservicesBranchName}</em> commit hash:{' '}
          </strong>
          {microservicesCommitHash}
        </p>
        <p>
          <strong>
            Common Branch <em>{commonBranchName}</em> commit hash:{' '}
          </strong>
          {commonCommitHash}
        </p>
        <p>
          <strong>
            Clever OpenID Bridge Branch <em>{cleverOpenIdBridgeBranchName}</em> commit hash:{' '}
          </strong>
          {cleverOpenIdBridgeHash}
        </p>
        <p>
          <strong>Build date: </strong>
          {buildDate}
        </p>
        {props.userProfile && props.userProfile.keyRing.includes('KEY_MASTER_KEY') && (
          <div>
            <p>
              <strong>Cognito Configuration: </strong>
              <br />
              {`${JSON.stringify(cognitoConfiguration, null, 2)}`}
            </p>
          </div>
        )}
        {props.userProfile && props.userProfile.keyRing.includes('KEY_MASTER_KEY') && (
          <div>
            <p>
              <strong>Auth Token: </strong>
              <br />
              {authToken}
            </p>
            <p>
              <strong>Refresh Token Expires: </strong>
              <br />
              {new Date(getRefreshTokenExpiry() * 1000).toString()}
            </p>
          </div>
        )}
        <div>
          <PrimaryButton onClick={goHome}>Home</PrimaryButton>
        </div>
      </div>
    </StandalonePageWrapper>
  );

  function goHome() {
    props.history.push('/');
  }
};

export default connector(VersionInformation);
