import { useField, FieldHookConfig } from 'formik';
import cx from 'classnames';

import styles from './TextInput.module.scss';

interface Props extends React.HTMLProps<HTMLInputElement> {
  fullBorder?: boolean;
  label?: string;
  className?: string;
}

const TextInput = ({ label, fullBorder, className, ...props }: Props & FieldHookConfig<string>): JSX.Element => {
  const [field, meta] = useField(props);
  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label} htmlFor={props.name}>
          {label}
        </label>
      )}
      <input
        {...field}
        {...props}
        className={cx(styles.input, className, {
          [`${styles.fullBorder}`]: fullBorder,
        })}
      />
      <div className={styles.errorHint}>{meta.touched && meta.error ? meta.error : null}</div>
    </div>
  );
};

export default TextInput;
