import ExternalProviderButton from './ExternalProviderButton';
import googleLogo from '../../images/logos/logo-google.png';
import styles from './ExternalProviderButton.module.scss';

interface Props {
  onClick: () => unknown;
  disable?: boolean;
}

const GoogleSignUpButton = (props: Props): JSX.Element => (
  <ExternalProviderButton className={styles.googleButton} onClick={props.onClick} disable={props.disable}>
    <img className={styles.logoImage} src={googleLogo} alt='google logo' />
    Register with Google
  </ExternalProviderButton>
);

export default GoogleSignUpButton;
