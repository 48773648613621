import { fixedEncodeURIComponent, getLetterGradeFromNumberGrade } from '../GlobalFunctions';
import { LockIcon } from './LockIcon';
import PrimaryButton from '../components/PrimaryButton/PrimaryButton';
import styles from '../SCSS/lpMain.module.scss';
import { myBrowserHistory } from '../ApplicationState';
import { ClassroomType, ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';
import { UnitPosition } from './ActivityStepPage';

export function getStoryUrl(unitMeta: ElementaryUnit['value']['lessonPlan'], classroom: ClassroomType) {
  if (classroom.language !== 'en') {
    return `${fixedEncodeURIComponent(classroom.language)}/${fixedEncodeURIComponent(
      unitMeta.title,
    )}-${fixedEncodeURIComponent(classroom.readingLevel.toString())}`;
  }

  return `${fixedEncodeURIComponent(unitMeta.title)}-${fixedEncodeURIComponent(classroom.readingLevel.toString())}`;
}

export function openUnitOverview(classroomName: string, unitId: string) {
  myBrowserHistory.push(`/${fixedEncodeURIComponent(classroomName)}/lessonPlan/${fixedEncodeURIComponent(unitId)}`);
}

export function openActivity(classroomName: string, unitId: string, subunitUrl: string, subunit: number) {
  let subunitNumber;
  if (typeof subunit === 'string') {
    subunitNumber = parseInt(subunit, 10) + 1;
  } else {
    subunitNumber = subunit + 1;
  }
  myBrowserHistory.push(`/${classroomName}/lessonPlan/${unitId}/${subunitUrl}/Subunit${subunitNumber}`);
}

export function openReadAloud(classroomName: string, unitId: string, subunitUrl: string, subunit: number) {
  let subunitNumber;
  if (typeof subunit === 'string') {
    subunitNumber = parseInt(subunit, 10) + 1;
  } else {
    subunitNumber = subunit + 1;
  }

  myBrowserHistory.push(`/${classroomName}/lessonPlan/readAloud/${unitId}/${subunitUrl}/Subunit${subunitNumber}`);
}

export function getLessonStepUrl(
  classroomName: string,
  unitId: string,
  subunitUrl: string,
  subunit: number,
  lesson: number,
  step: number,
) {
  // convert to visual representation of indices
  const subunitNumber = subunit + 1;
  const lessonNumber = lesson + 1;
  const stepNumber = step + 1;

  let url = `/${fixedEncodeURIComponent(classroomName)}`;
  url = `${url}/lessonPlan/${fixedEncodeURIComponent(unitId)}`;
  url = `${url}/${subunitUrl}/Subunit${subunitNumber}`;
  url = `${url}/Lesson${lessonNumber}/Step${stepNumber}`;

  if (subunit === 0) {
    const readAloudUrl = url.replace(/\/lessonPlan/, '/lessonPlan/readAloud');
    return readAloudUrl;
  }

  return url;
}

export function openStep(
  classroomName: string,
  unitId: string,
  subunitUrl: string,
  subunit: number,
  lesson: number,
  step: number,
  isPreviewMode?: boolean,
  onPreviewChange?: (newUnitPosition: UnitPosition) => void,
) {
  if (isPreviewMode && onPreviewChange) {
    onPreviewChange({ subunit, lesson, step });
    return null;
  }

  const url = getLessonStepUrl(classroomName, unitId, subunitUrl, subunit, lesson, step);
  myBrowserHistory.push(url);
}

export function getUnitPositionFromParams(routerParams: Record<string, string>) {
  const { lessonNumber, stepNumber, subunitNumber } = routerParams;
  return {
    subunit: parseInt(subunitNumber, 10) - 1,
    lesson: parseInt(lessonNumber, 10) - 1,
    step: parseInt(stepNumber, 10) - 1,
  };
}

export const getLockedSubunitButton = (
  unitMeta: ElementaryUnit['value']['lessonPlan'],
  subunitMeta: ElementaryUnit['value']['lessonPlan']['subUnits'][number],
  unitPosition: UnitPosition,
  onClick: () => void,
) => {
  const subunitLabel = subunitMeta.title ? subunitMeta.lessons[unitPosition.lesson].title : unitMeta.label;

  return (
    <PrimaryButton className={styles.unitHeaderButtons} small onClick={onClick}>
      Open {subunitLabel}
      <div className={styles.subunitHeaderButtonLock}>
        <LockIcon />
      </div>
    </PrimaryButton>
  );
};

export const getBeginLessonButton = (
  classroom: ClassroomType,
  unitMeta: ElementaryUnit['value']['lessonPlan'],
  targetStep: { subunit: number; lesson: number },
  isPreviewMode: boolean | undefined,
  onPreviewChange: ((newUnitPosition: UnitPosition) => void) | undefined,
) => {
  const onClickUrlTarget = unitMeta.subUnits[targetStep.subunit].url;
  const lessonTitle = unitMeta.subUnits[targetStep.subunit].lessons[targetStep.lesson].title;
  const subunitType = unitMeta.subUnits[targetStep.subunit].contentType;
  const classroomName = classroom.className;
  const unitId = `${unitMeta.title}-${getLetterGradeFromNumberGrade(classroom.grade)}`;

  if (subunitType === 'readAloud') {
    return (
      <PrimaryButton
        className={styles.unitHeaderButtons}
        small
        onClick={() => {
          openStep(
            classroomName,
            unitId,
            onClickUrlTarget,
            targetStep.subunit,
            targetStep.lesson,
            0,
            isPreviewMode,
            onPreviewChange,
          );
        }}
      >
        Open {lessonTitle}
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton
      className={styles.unitHeaderButtons}
      small
      onClick={() => {
        openStep(
          classroomName,
          unitId,
          onClickUrlTarget,
          targetStep.subunit,
          targetStep.lesson,
          0,
          isPreviewMode,
          onPreviewChange,
        );
      }}
    >
      Open {lessonTitle}
    </PrimaryButton>
  );
};

export const getResumeLessonButton = (
  classroom: ClassroomType,
  unitMeta: ElementaryUnit['value']['lessonPlan'],
  subunitData: ElementaryUnit['value']['lessonPlan']['subUnits'][number],
  lessonInProgres: { subunit: number; lesson: number },
) => {
  const onClickUrlTarget = unitMeta.title;
  const lessonTitle = unitMeta.subUnits[lessonInProgres.subunit].lessons[lessonInProgres.lesson].title;
  const subunitType = unitMeta.subUnits[lessonInProgres.subunit].contentType;
  const classroomName = classroom.className;
  const unitId = `${unitMeta.title}-${getLetterGradeFromNumberGrade(classroom.grade)}`;

  if (subunitType === 'readAloud') {
    return (
      <PrimaryButton
        className={styles.unitHeaderButtons}
        small
        onClick={() => {
          openStep(
            classroomName,
            unitId,
            onClickUrlTarget,
            lessonInProgres.subunit,
            lessonInProgres.lesson,
            0,
            undefined,
            undefined,
          );
        }}
      >
        Resume {lessonTitle}
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton
      className={styles.unitHeaderButtons}
      small
      onClick={() => {
        openStep(
          classroomName,
          unitId,
          onClickUrlTarget,
          lessonInProgres.subunit,
          lessonInProgres.lesson,
          0,
          undefined,
          undefined,
        );
      }}
    >
      Resume {lessonTitle}
    </PrimaryButton>
  );
};

export const showNumberStandards = (number: number) => {
  if (number === 0) {
    return '';
  }

  return number > 1 ? `${number} STANDARDS` : '1 STANDARD';
};

export const showDuration = (duration: number) => `${duration} MIN AVG`;

export const showDurationNumberStandards = (data: Record<string, unknown>) => {
  const sentence_components = [];

  if (data.duration) {
    sentence_components.push(showDuration(data.duration as number));
  }

  if (data.coreStandards && (data.coreStandards as number) > 0) {
    sentence_components.push(showNumberStandards(data.coreStandards as number));
  }

  return sentence_components.join(' \u2022 ');
};

//unused?
export const showLessonInfo = (data: Record<string, any>) => {
  const info_components = [];

  if (data.duration) {
    info_components.push(`${data.duration} MIN AVG`);
  }

  if (data.activityType) {
    info_components.push(data.activityType);
  }

  if (data.studentConfiguration) {
    info_components.push(data.studentConfiguration);
  } else {
    info_components.push('Whole Class');
  }

  return <p className={styles.lessonInfo}>{info_components.join(' | ')}</p>;
};

//unused
export function rollupLessonCoreStandardsIntoSubunit(unit) {
  const subunitcore_standards = [];

  // roll up core standards applicable to each
  // lesson into the subunit (read aloud)
  unit.activities.forEach((activity) => {
    Array.prototype.push.apply(subunitcore_standards, activity.content.coreStandards);
  });

  return subunitcore_standards;
}

export function printLesson(heading: string) {
  const printedContent = document.getElementById('stepContentContent');
  if (!printedContent) {
    throw new Error("document.getElementById('stepContentContent') returned null");
  }
  const newWindow = window.open(undefined, '_blank');
  if (!newWindow) {
    throw new Error('printLesson: newWindow is null');
  }
  newWindow.document.write(buildPrintoutHtml(heading, printedContent.innerHTML));
  newWindow.document.close();
  if (newWindow.document.readyState !== 'complete') {
    newWindow.document.onreadystatechange = function () {
      if (newWindow.document.readyState === 'complete') {
        newWindow.focus();
        newWindow.print();
        newWindow.close();
      }
    };
  } else {
    newWindow.focus();
    newWindow.print();
    newWindow.close();
  }
}

function buildPrintoutHtml(heading: string, content: string) {
  let document_html = '';
  document_html =
    `${
      '<!doctype html>\n' +
      '<html>\n' +
      '  <head>\n' +
      '    <title>Peekapak</title>\n' +
      '    <style type="text/css">\n' +
      '      @font-face { \n' +
      '        font-family: "GT-Walsheim-Pro-Light";\n' +
      '        src: url("https://www.peekapak.com/fonts/GT-Walsheim-Pro-Light.eot");\n' +
      '      }\n' +
      '    </style>\n' +
      '  </head>\n' +
      `  <body style="font-family: 'GT-Walsheim-Pro-Light', Arial;">\n` +
      `<table class="mainWrapper" cellspacing="0" cellpadding="0" style="margin-top:0;margin-bottom:0;margin-right:auto;padding-bottom:15px;padding-right:15px;width:600px" >\n` +
      `  <tr>\n` +
      `    <td>\n` +
      `      <table class='container' align="center" cellspacing="5" style="background-color:white;border-radius:5px;margin-bottom:10px;max-width:600px;padding-bottom:10px;padding-right:10px;" >\n` +
      `        <tr>\n` +
      `          <td>\n` +
      `            <h1 style="margin-top:0;margin-bottom:0;margin-right:0;margin-left:0;" >\n` +
      `                <img class="logo"\n` +
      `                src="https://s3.amazonaws.com/peekaplatform/assets/email/logo/logo.png" alt="" style="width:30%;" />\n` +
      `            </h1>\n` +
      `          </td>\n` +
      `        </tr>\n` +
      `        <tr>\n` +
      `          <td>\n` +
      `<p style="font-size: 24px; font-weight: 700;">${heading}</p>` +
      `          </td>\n` +
      `        </tr>\n` +
      `        <tr>` +
      `          <td>`
    }${content}          </td>` +
    `        </tr>` +
    `      </table>` +
    `    </td>` +
    `  </tr>` +
    `</table>` +
    `  </body>\n` +
    `</html>`;

  return document_html;
}
