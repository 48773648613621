import Modal from 'react-bootstrap/Modal';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

interface Props {
  isShow: boolean;
  onHide: () => void;
  messageText: string;
  titleText: string;
  onOkay: () => void;
  dialogClass?: string;
  contentClass?: string;
  titleClass?: string;
}

function handleGeneralErroMessageOnOkay(onHide: () => void, onOkay: () => void) {
  onHide();
  if (onOkay) {
    onOkay();
  }
}

export const GeneralErrorMessage = ({
  isShow,
  onHide,
  messageText,
  titleText,
  onOkay,
  titleClass,
  dialogClass,
  contentClass,
}: Props): JSX.Element => (
  <Modal
    show={isShow}
    onHide={onHide}
    dialogClassName={`ModalDialog responsiveModalWidth ${dialogClass ?? ''}`}
    contentClassName={contentClass ?? ''}
  >
    <Modal.Header closeButton>
      <div className={`generalDialogTitle ${titleClass ?? ''}`}>{titleText || `There's a Problem`}</div>
    </Modal.Header>
    <Modal.Body>
      {messageText && (
        <span className='ModalDialogText generalErrorMessage' dangerouslySetInnerHTML={{ __html: messageText }} />
      )}
      <div style={{ textAlign: 'center', padding: '2em' }}>
        <PrimaryButton onClick={() => handleGeneralErroMessageOnOkay(onHide, onOkay)}>Okay</PrimaryButton>
      </div>
    </Modal.Body>
  </Modal>
);
