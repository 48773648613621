import { Auth } from '@aws-amplify/auth';
import { logoutIntercomUser } from '../../IntercomUtilityFunctions';
import { logger } from '../../error-tracker';
import { getStore } from '../../ApplicationState';
import { setMessageMark as setMessageMarkBackend } from '../../BackendInterface';
import { getUsersId } from '../../GlobalFunctions';
import { logout, updateField } from '../../UserManagement';

export const setMessageMark = async () => {
  const userId = getUsersId();
  if (!userId) return;
  const dispatch = getStore().dispatch;
  const newMessageMark = Date.now();
  await setMessageMarkBackend(userId, newMessageMark);
  dispatch(updateField({ field: 'messageMark', value: newMessageMark }));
};

export const cognitoSignOut = () => {
  Auth.signOut()
    .then((_data) => {
      getStore().dispatch(logout());
      logoutIntercomUser();
    })
    .catch((error) => {
      logger.logException(error);
    });
};

export const unionArray = (...subArray) => {
  let union = [];
  for (const array of subArray) {
    union = [...new Set([...union, ...(array || [])])];
  }
  return union;
};

export const differenceArray = (diffArray, ...subArray) => {
  let difference = [];
  for (const array of subArray) {
    difference = (array || []).filter((x) => diffArray.indexOf(x) < 0);
  }
  return difference;
};

export const numericGradeToString = {
  '-1': 'Pre-Kindergarten',
  0: 'Kindergarten',
  1: 'Grade 1',
  2: 'Grade 2',
  3: 'Grade 3',
};
