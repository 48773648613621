import { Modal } from 'react-bootstrap';
import { VerticalSpacer } from './core/core';
import { myBrowserHistory } from './ApplicationState';
import PrimaryButton from './components/PrimaryButton/PrimaryButton';
import styles from './SCSS/Login.module.scss';

const redirectToSignUp = () => myBrowserHistory.push(`/signup?signUpFromOauthLogin=true`);

const NonexistentAccountDialog = ({ isShow, onHide }) => {
  return (
    <Modal show={isShow} onHide={onHide} dialogClassName={styles.modalDialog}>
      <Modal.Header closeButton>
        <Modal.Title>Account Sign Up Required</Modal.Title>
        <Modal.Body>
          <div>
            <p className={`${styles.NormalText} ${styles.forgotPasswordText}`}>
              We're sorry, it appears you haven't signed up for an account on Peekapak yet. Would you like to sign up
              for a free account right now?
            </p>
            <VerticalSpacer ems={2} />
            <div className={styles.forgotPasswordButton}>
              <PrimaryButton onClick={onHide}>No</PrimaryButton>
            </div>
            <div className={styles.forgotPasswordButton}>
              <PrimaryButton onClick={redirectToSignUp}>Yes</PrimaryButton>
            </div>
          </div>
        </Modal.Body>
      </Modal.Header>
    </Modal>
  );
};

export default NonexistentAccountDialog;
