import { Component } from 'react';
import cx from 'classnames';
import { Button, Card } from 'react-bootstrap';
import { CheckedCircle, NormalHeader, SubHeader, Text } from '../core/core';
import { getPublicMediaRoot } from '../serviceAgent';
import LinkDiv from '../components/LinkDiv/LinkDiv';
import { getCoreStandards as gcs } from '../BackendInterface';
import { fixedEncodeURIComponent } from '../GlobalFunctions';
import styles from '../SCSS/lpMain.module.scss';

export class ExpandableGroup extends Component {
  render() {
    if (this.props.data !== null && this.props.data !== undefined) {
      return (
        <div>
          {this.props.data.map((listValue, index) => (
            <Expandable
              key={index}
              label={listValue.label}
              text={listValue.text}
              openByDefault={this.props.openByDefault}
            />
          ))}
        </div>
      );
    } else return <div />;
  }
}

export class Expandable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: Boolean(this.props.openByDefault),
    };
  }

  render() {
    if (this.props.label && this.props.text) {
      return (
        <div className={styles.expandable}>
          <button className={styles.expandableHeader} onClick={() => this.setState({ open: !this.state.open })}>
            {this.props.label}
            <i
              className={
                this.state.open ? 'fa fa-caret-down collapseArrow collapseArrowUp' : 'fa fa-caret-right collapseArrow'
              }
              style={{ float: 'right' }}
              aria-hidden='true'
            />
          </button>
          <Card
            border='light'
            bsPrefix={cx(styles.expandableContent, this.state.open ? styles.visibleCard : styles.hiddenCard)}
            onToggle={() => this.setState({ open: !this.state.open })}
          >
            <Card.Body className={styles['panel-body']}>
              <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
            </Card.Body>
          </Card>
        </div>
      );
    }

    return null;
  }
}

export class UnitSetup extends Component {
  render() {
    if (this.props.data.poster !== undefined) {
      return (
        <Card bsPrefix={styles.unitSetup}>
          <NormalHeader className={styles.unitSetupHeader} text='Unit Setup' />
          <Text
            className={styles.unitSetupText}
            text='Before you begin a lesson plan, follow these steps to ensure you have everything ready.'
          />
          <LinkDiv onClick={() => openResource(this.props.data.poster.actualName)} style={{ marginTop: '15px' }}>
            <CheckedCircle className={styles.unitSetupCheckedCircle}>
              <span className={styles.unitSetupLink}>Unit poster</span>
            </CheckedCircle>
          </LinkDiv>
        </Card>
      );
    }

    return null;
  }
}

export class CoreExpandable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: Boolean(this.props.openByDefault),
    };
  }

  render() {
    return (
      <div className={styles.expandableContainerDiv}>
        <button className={styles.expandableHeader} onClick={() => this.setState({ open: !this.state.open })}>
          {this.props.label}
          <i
            className={
              this.state.open ? 'fa fa-caret-down collapseArrow collapseArrowUp' : 'fa fa-caret-right collapseArrow'
            }
            style={{ float: 'right' }}
            aria-hidden='true'
          />
        </button>
        <Card
          border='light'
          bsPrefix={cx(styles.expandableContent, this.state.open ? styles.visibleCard : styles.hiddenCard)}
          onToggle={() => this.setState({ open: !this.state.open })}
        >
          <Card.Body className={styles['panel-body']}>{this.props.children}</Card.Body>
        </Card>
      </div>
    );
  }
}

export class CoreStandardsExpandable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isMounted: false,
      isLoadingData: false,
    };
    this.coreStandards = this.props.coreStandards;
    this.data = this.props.data;

    this.flipOpen = this.flipOpen.bind(this);
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  render() {
    this.data = this.props.data;
    if (this.data && this.data.length > 0) {
      return (
        <CoreExpandable label={this.props.label} openByDefault={false}>
          <div
            key={0}
            className={cx(styles.transitionContent, !this.state.isOpen ? styles.visibleCard : styles.hiddenCard)}
          >
            {this.listCoreStandards()}
          </div>
          <div
            key={1}
            className={cx(styles.transitionContent, this.state.isOpen ? styles.visibleCard : styles.hiddenCard)}
          >
            {this.getCoreStandards()}
          </div>
          {this.getReadMore()}
        </CoreExpandable>
      );
    }

    return null;
  }

  listCoreStandards() {
    if (this.coreStandards == null) {
      return 'Waiting';
    }

    if (this.data.length > 0) {
      let coreString = '';
      let i = 0;
      for (; i < this.data.length - 1; i++) {
        const current = this.coreStandards[this.data[i]].label;

        if (current.substring(current.length - 1) === '.') {
          coreString = `${coreString + current.substring(0, current.length - 1)}, `;
        } else {
          coreString = `${coreString + current}, `;
        }
      }
      coreString += this.coreStandards[this.data[i]].label;
      return <p key={i}>{coreString}</p>;
    }
  }

  getReadMore() {
    if (!this.state.isOpen) {
      return (
        <LinkDiv className={styles.readMoreLink} onClick={this.flipOpen}>
          Read More <i className='fa fa-chevron-down' aria-hidden='true' />
        </LinkDiv>
      );
    }

    return (
      <LinkDiv className={styles.readMoreLink} onClick={this.flipOpen}>
        Hide <i className='fa fa-chevron-up' aria-hidden='true' />
      </LinkDiv>
    );
  }

  flipOpen() {
    this.setState({ isOpen: !this.state.isOpen });
    if (this.state.isMounted) {
      this.forceUpdate();
    }
  }

  getCoreStandards() {
    if (!this.coreStandards) {
      if (!this.state.isLoadingData) {
        retrieveCoreStandards.bind(this)();
      }
      return 'Waiting';
    }

    const content = [];
    const categories = Object.keys(this.coreStandards.subCategories).sort();
    for (let j = 0; j < categories.length; j++) {
      let first = true;

      for (let i = 0; i < this.data.length; i++) {
        if (categories[j] === this.coreStandards[this.data[i]].sub) {
          if (first) {
            content.push(<h4 key={j}>{categories[j]}</h4>);
            content.push(
              <p key={`${j}.${i}`}>{`${this.coreStandards[this.data[i]].label} - ${
                this.coreStandards[this.data[i]].text
              }`}</p>,
            );
            first = false;
          } else {
            content.push(
              <p key={`${j}.${i}`}>{`${this.coreStandards[this.data[i]].label} - ${
                this.coreStandards[this.data[i]].text
              }`}</p>,
            );
          }
        }
      }
    }
    return content;

    async function retrieveCoreStandards() {
      try {
        this.setState({
          isLoadingData: true,
        });
        const standards = await gcs();
        this.coreStandards = standards;
        if (this.state.isMounted) {
          this.forceUpdate();
          this.setState({
            isLoadingData: false,
          });
        }
      } catch (error) {
        throw error;
      }
    }
  }
}

export class GetHelpFooter extends Component {
  render() {
    return (
      <div className={styles.getHelpFooter}>
        <hr className={styles.contentSeparator} />
        <SubHeader className={styles.getHelpFooterHeader} text="Can't find what you're looking for?" />
        <Button className={styles.getHelpFooterButton}>Get Help</Button>
      </div>
    );
  }
}

export class LearningOutcomesExpandable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rendered: false,
    };
    this.data = this.props.data;
  }

  render() {
    this.data = this.props.data;
    if (this.data !== undefined && this.data.length > 0) {
      return (
        <CoreExpandable label={this.getLabel()} openByDefault={this.props.openByDefault}>
          <p>Students will:</p>
          {this.firstFive()}
          <div style={{ display: this.state.open ? 'block' : 'none' }}>{this.expandedOutcomes()}</div>
          {this.getReadMore()}
        </CoreExpandable>
      );
    }
    return null;
  }

  firstFive() {
    const content = [];
    if (this.data === undefined) {
      return content;
    } else if (this.data.length >= 5) {
      for (var i = 0; i < 5; i++) {
        content.push(
          <div key={i} className={styles.learningOutcomeWrapper}>
            <CheckedCircle className={styles.learningOutcomesCheckedCircle}>
              <span className={styles.learningOutcomeText}>{this.data[i]}</span>
            </CheckedCircle>
          </div>,
        );
      }
    } else {
      for (i = 0; i < this.data.length; i++) {
        content.push(
          <div key={i} className={styles.learningOutcomeWrapper}>
            <CheckedCircle className={styles.learningOutcomesCheckedCircle}>
              <span className={styles.learningOutcomeText}>{this.data[i]}</span>
            </CheckedCircle>
          </div>,
        );
      }
    }
    return content;
  }

  expandedOutcomes() {
    const content = [];
    if (this.data === undefined) {
      return content;
    }
    for (let i = 5; i < this.data.length; i++) {
      content.push(
        <div key={i} className={styles.learningOutcomeWrapper}>
          <CheckedCircle className={styles.learningOutcomesCheckedCircle}>
            <span className={styles.learningOutcomeText}>{this.data[i]}</span>
          </CheckedCircle>
        </div>,
      );
    }
    return content;
  }

  getReadMore() {
    if (this.data === undefined || this.data.length <= 5) {
      return;
    } else if (!this.state.open) {
      return (
        <LinkDiv className={styles.readMoreLink} onClick={this.flipOpen.bind(this)}>
          Read More <i className='fa fa-chevron-down' aria-hidden='true' />
        </LinkDiv>
      );
    }

    return (
      <LinkDiv className={styles.readMoreLink} onClick={this.flipOpen.bind(this)}>
        Hide <i className='fa fa-chevron-up' aria-hidden='true' />
      </LinkDiv>
    );
  }

  flipOpen() {
    this.setState({ open: !this.state.open });
    this.forceUpdate();
  }

  getLabel() {
    if (this.props.contentType === 'unit') {
      return 'Student Learning Objectives for this Unit';
    }
    return 'Student Learning Objectives';
  }
}

export class Resources extends Component {
  constructor(props) {
    super(props);
    this.data = this.props.data;
  }

  render() {
    this.data = this.props.data;
    if (this.data !== undefined && this.data.length > 0) {
      return (
        <CoreExpandable label={this.getLabel()} openByDefault>
          {this.data.map((current, index) => (
            <LinkDiv
              key={index}
              className={styles.resourcesExpandable}
              onClick={() => openResource(current.actualName)}
            >
              <i className={this.getFileTypeIcon(current.displayName.split('.')[1])} /> {current.displayName}
            </LinkDiv>
          ))}
        </CoreExpandable>
      );
    }

    return null;
  }

  getFileTypeIcon(type) {
    const fileStuff = {
      pdf: 'fa fa-file-pdf-o',
      zip: 'fa fa-file-archive-o',
      mp3: 'fa fa-file-audio-o',
      xlsx: 'fa fa-file-excel-o',
      jpg: 'fa fa-file-image-o',
      jpeg: 'fa fa-file-image-o',
      mp4: 'fa fa-file-video-o',
      docx: 'fa fa-file-word-o',
      txt: 'fa fa-file-text-o',
      file: 'fa fa-file-o',
    };

    if (fileStuff[type]) {
      return fileStuff[type];
    }

    return fileStuff.file;
  }

  getLabel() {
    if (this.props.contentType === 'unit') {
      return 'Unit Resources';
    } else if (this.props.contentType === 'lesson') {
      return 'Lesson Resources';
    } else if (this.props.contentType === 'step') {
      return 'Resources';
    } else if (this.props.contentType === 'attachment') {
      return 'Attachment';
    }

    return 'Resources';
  }
}

export function openResource(fileName) {
  window.open(`${getPublicMediaRoot()}resources/${fixedEncodeURIComponent(fileName)}`, '_blank');
}

export class TeacherTip extends Component {
  render() {
    if (this.props.text !== undefined || this.props.text === '' || this.props.text !== null) {
      return (
        <div className={styles.teacherTip}>
          <h3 className={styles.teacherTipHeader}>Teacher Tip</h3>
          <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
        </div>
      );
    }
    return null;
  }
}
