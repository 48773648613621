import { getUsersKeyRing } from './GlobalFunctions';
//
// CC 12-02-2017: Decorator logic modelled after
// @mixin from core-decorators
// https://github.com/jayphelps/core-decorators.js/blob/master/src/mixin.js
//

export function lockWithPreviewUnlessHasAny(...keys: string[]) {
  if (typeof keys[0] === 'function') {
    return handleClassForHasAny(keys[0], []);
  }

  return (target) => handleClassForHasAny(target, keys);
}

const isLockedForHasAnyDescriptor = {
  value() {
    const keyRing = getUsersKeyRing();

    if (!keyRing || !keyRing.length) {
      return true;
    }

    let targetLocks = this._locks;
    let mustHaveLocks;

    if (this.getAndContentAccessKeys) {
      mustHaveLocks = this.getAndContentAccessKeys();
    }

    if (this.getOrContentAccessKeys) {
      targetLocks = [...targetLocks, ...this.getOrContentAccessKeys()];
    }

    if (mustHaveLocks) {
      for (let i = 0; i < mustHaveLocks.length; i++) {
        if (!keyRing.includes(mustHaveLocks[i])) {
          return true;
        }
      }
    }

    for (let i = 0; i < targetLocks.length; i++) {
      for (let j = 0; j < keyRing.length; j++) {
        if (keyRing[j] === targetLocks[i]) {
          return false;
        }
      }
    }

    return true;
  },
  enumerable: false,
  configurable: true,
  writable: true,
};

const locksDescriptor = {
  value: [],
  enumerable: false,
  configurable: true,
  writable: true,
};

function handleClassForHasAny(target, keys: string[]) {
  if (!keys.length) {
    throw new SyntaxError(
      `@lockWithPreviewUnlessHasAny() class ${target.name} requires at least one key as an argument`,
    );
  }

  if (!hasProperty('isLocked', target.prototype)) {
    Object.defineProperty(target.prototype, 'isLocked', isLockedForHasAnyDescriptor);
  }

  if (!hasProperty('_locks', target.prototype)) {
    locksDescriptor.value = [...keys];
    Object.defineProperty(target.prototype, '_locks', locksDescriptor);
  } else {
    // CC 12-02-2017: not sure when this particular branch would be reached
    // probably only when we have a *HasAll function that stacks on top of
    // a *HasAny
    target.prototype._locks = [...target.prototype._locks, ...keys];
  }

  return target;
}

function buggySymbol(symbol) {
  return Object.prototype.toString.call(symbol) === '[object Symbol]' && typeof symbol === 'object';
}

function hasProperty(prop, obj) {
  // We have to traverse manually prototypes' chain for polyfilled ES6 Symbols
  // like "in" operator does.
  // I.e.: Babel 5 Symbol polyfill stores every created symbol in Object.prototype.
  // That's why we cannot use construction like "prop in obj" to check, if needed
  // prop actually exists in given object/prototypes' chain.
  if (buggySymbol(prop)) {
    do {
      if (obj === Object.prototype) {
        // Polyfill assigns undefined as value for stored symbol key.
        // We can assume in this special case if there is nothing assigned it doesn't exist.
        return typeof obj[prop] !== 'undefined';
      }
      if (Object.hasOwnProperty.call(obj, prop)) {
        return true;
      }
    } while ((obj = Object.getPrototypeOf(obj)));
    return false;
  }
  return prop in obj;
}

export function useCheckIfUserHasKeyFor(lock: string) {
  const keyRing = getUsersKeyRing();

  if (!keyRing || !keyRing.length) {
    return false;
  }

  if (keyRing.includes(lock)) return true;

  return false;
}
