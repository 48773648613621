import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './GradientLock.module.scss';
import Button from '../Button';

const GradientLock = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);
  const history = useHistory();
  return (
    <div className={styles.whiteout}>
      <div className={styles.upgrade}>
        <p>This Content is not available at your current license level. Please upgrade to gain access.</p>
        <Button onClick={() => history.push('/activatetrial')}>UPGRADE NOW</Button>
      </div>
    </div>
  );
};

export default GradientLock;
