import { useHistory } from 'react-router-dom';
import Button from '../Button';
import styles from './ReviewSubmittedImage.module.scss';

interface Props {
  data: Record<string, unknown>;
  studentWork: {
    userId: string;
    createdAt: number;
    work: { content: { content: { data_url: string }[] }[] };
  };
  teacherReview?: boolean;
}

const ReviewSubmittedImage = ({ data, studentWork, teacherReview }: Props): JSX.Element => {
  const history = useHistory();
  console.log(studentWork);

  return (
    <div className={styles.container}>
      <h3>{teacherReview ? 'Student ' : 'Your '} Submission</h3>
      {studentWork.work.content.map((submission, index: number) => {
        return submission.content.map((image, ind) => {
          console.log(image.data_url);
          return (
            <div key={`${index} image ${ind}`}>
              <img src={image.data_url} alt='' />
            </div>
          );
        });
      })}
      {!teacherReview && (
        <div className={styles.submitButton}>
          <Button onClick={() => history.push('/studentportal/toDos')} darkBlue thinText>
            View ToDos
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReviewSubmittedImage;
