export const ErrorType = {
  ENTERPRISE_USER: 'EnterpriseUserError',
  UNAUTHORIZED: 'UnauthorizedError',
  UNKNOWN: 'UnknownError',
} as const;

export type ErrorType = (typeof ErrorType)[keyof typeof ErrorType];

export interface AugmentedError extends Error {
  code: string;
  data: string;
}

export class EnterpriseUserError extends Error implements AugmentedError {
  // JSON-stringified object with additional information
  constructor(
    public message: string,
    public code: ErrorType,
    public data: string,
  ) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

export class AxiosAugmentedError extends Error implements AugmentedError {
  constructor(
    public message: string,
    public code: ErrorType,
    public data: string,
  ) {
    super(message);
    this.code = code;
    this.data = data;
  }
}
