import { Component, createRef } from 'react';
import loadable from '@loadable/component';
import { LoginState } from './UserManagement';
import ChooseRoleDialog from './components/ChooseRoleDialog/ChooseRoleDialog';
import { State as ClassroomState, loadClassrooms, setSelectedClassroom, LoadParams } from './Classrooms';
import { loadMessages as lm } from './Messages';
import { bootupIntercom } from './IntercomUtilityFunctions';
import HeaderRedirect from './core/HeaderRedirect';
import Footer from './core/Footer';
import { getMessages, updateUserObjectWithPageTrackingInfo } from './core/context/contextHelpers';
import { sendMixpanelPageRenderEvent } from './mixpanel';
import Analytics from './Analytics';
import { loginIntercomUser } from './IntercomUtilityFunctions';
import { isStudent, reactivateUserSession, signOutUser } from './UserManagementHelpers';
import { canUpgradeToClassroom, getUserFriendlyErrorMessage, sendUserToRoleHomePage } from './GlobalFunctions';
import MyHelmet from './MyHelmet';
import Banner from './components/HeroBanner';
import styles from './SCSS/MainLayout.module.scss';
import { Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { History, Location } from 'history';
import { connect, ConnectedProps } from 'react-redux';
import { RootState, AppDispatch } from './ApplicationState';
// import MiddleSchoolStudentLayout from './MiddleSchoolStudentLayout';
// import ApplicationLayout from './ApplicationLayout';
import UnitLessonRedirect from './UnitLessonRedirect';
import AuthenticatedRoute from './AuthenticatedRoute';
import VersionInformation from './VersionInformation';
import Upgrade from './Upgrade/Upgrade';
import RedeemCode from './RedeemCode';
import ActivateTrial from './ActivateTrial';
import ConditionalRender from './components/ConditionalRender';
import SchoolEditor from './SchoolEditor';
import NotificationsPage from './NotificationsPage';
import StorybookLayout from './StorybookLayout';
import { EnableErrorMessagePopup } from './components/EnableErrorMessagePopup';
import CleverRostering from './CleverRostering';
import { MessageType } from '../peekapak-types/DataProtocolTypes';
import { getClassrooms } from './BackendInterface';
import { AxiosAugmentedError, ErrorType } from './AugmentedError';
import { expireSessionAndRedirect } from './aaa';
const notificationPollingInterval = 60000;
const ApplicationLayout = loadable(() => import('./ApplicationLayout'));
const MiddleSchoolStudentLayout = loadable(() => import('./MiddleSchoolStudentLayout'));

const mapStateToProps = (state: RootState) => ({
  classrooms: {
    state: state.classrooms.state,
    list: state.classrooms.classrooms,
    selectedClassroom: state.classrooms.selectedClassroom,
  },
  messages: {
    state: state.messages.state,
    list: state.messages.messages,
    messageMark: state.messages.messageMark,
  },
  loginState: state.user.loginState,
  userProfile: state.user.userProfile,
  cognitoProfile: state.user.cognitoProfile,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSelectedClassroom: (newIndex: number) => dispatch(setSelectedClassroom(newIndex)),
  loadClassrooms: ({ classrooms, selectClassroomName }: LoadParams) =>
    dispatch(loadClassrooms({ classrooms, selectClassroomName })),
  loadMessages: (messages: { newMessages: MessageType[]; messageMark: number }) =>
    dispatch(lm({ messages: messages.newMessages, messageMark: messages.messageMark })),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface PassedInProps {
  history: History;
  params: ParamsType;
  location: Location;
  withFooter?: boolean;
  withHeader?: boolean;
  withBanner?: boolean;
}

type Props = PropsFromRedux & PassedInProps;

interface ParamsType {
  classroomName: string;
}

type State = {
  isShowGetStartedDialog: boolean;
  errorMessage: string;
  errorMessageTitle: string;
  onOkayHandler: (() => unknown) | null | undefined;
  sessionTimer: Date;
};

class MainLayout extends Component<Props, State> {
  pollForMessagesIntervalHandle: NodeJS.Timeout | null;
  header: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.header = createRef();
    this.pollForMessagesIntervalHandle = null;
    this.state = {
      isShowGetStartedDialog: false,
      errorMessage: '',
      errorMessageTitle: '',
      onOkayHandler: null,
    }; // console.debug( `mainlayout constructor` )
  }

  handleOpenGetStartedDialog = () =>
    this.setState({
      isShowGetStartedDialog: true,
    });
  handleCloseGetStartedDialog = () =>
    this.setState({
      isShowGetStartedDialog: false,
    });
  onChooseDialogSelectForGettingStarted = (selection: string) => {
    this.handleCloseGetStartedDialog();

    switch (selection) {
      case 'Student':
        window.location.href = 'https://www.mypeekaville.com';
        break;

      case 'Parent':
        this.props.history.push('/parents#top');
        break;

      case 'SchoolLeader':
        this.props.history.push('/districtLead#top');
        break;

      case 'Teacher':
      default:
        this.props.history.push('/signUp#top');
        break;
    }
  };
  loadClassrooms = async () => {
    if (this.props.classrooms.state === ClassroomState.notLoaded && this.props.loginState === LoginState.loggedIn) {
      const classrooms = await getClassrooms(this.props.userProfile!.email);
      this.props.loadClassrooms({
        classrooms,
        selectClassroomName: this.props.params.classroomName,
      });
    }
  };
  loadMessages = async () => {
    try {
      const { newMessages, messageMark } = await getMessages();
      this.props.loadMessages({ newMessages, messageMark });
    } catch (e) {
      if ((e as AxiosAugmentedError).code === ErrorType.UNAUTHORIZED) {
        expireSessionAndRedirect();
      }
    }
  };
  executeLoginTasks = async () => {
    bootupIntercom();
    loginIntercomUser(this.props.userProfile);
    await Analytics.login(this.props.userProfile!);

    if (!isStudent(this.props.userProfile)) {
      await Promise.all([this.loadClassrooms(), this.loadMessages()]);
    }

    sendUserToRoleHomePage(this.props.userProfile, this.props.history, true, false);
  };

  async componentDidUpdate(prevProps: Props) {
    // console.debug(`mainlayout didupdate`);
    if (this.props.loginState !== prevProps.loginState) {
      // console.debug(`mainlayout didupdate loginstate change`);
      if (this.props.loginState === LoginState.loggedIn) {
        this.executeLoginTasks();
      } else if (this.props.loginState === LoginState.expiredSession) {
        const duration = (new Date().valueOf() - this.state.sessionTimer.valueOf()) / 1000;
        Analytics.interactionEnd(this.props.userProfile!, duration, 'idle');
        this.props.history.push('/sessionExpired');
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      // console.debug(
      //   `mainlayout didupdate location change `,
      //   prevProps.location.pathname,
      //   ' / ',
      //   this.props.location.pathname
      // );
      this.updateUserObjectWithNewPageInfo();
      sendMixpanelPageRenderEvent(this.props.userProfile);
    }
  }

  async componentDidMount() {
    // console.debug(`mainlayout didmount`);
    this.setupInteractionEndEvent();

    if (this.props.userProfile && !isStudent(this.props.userProfile)) {
      this.pollForMessagesIntervalHandle = setInterval(() => {
        if (this.props.loginState === LoginState.loggedIn) {
          this.loadMessages();
        }
      }, notificationPollingInterval);
    }

    try {
      await reactivateUserSession();
    } catch (error) {
      const message = getUserFriendlyErrorMessage(error);

      if (message === 'Elementary student users not allowed on this site') {
        // console.debug(
        //   `%cSigning out from mainlayout because student`,
        //   'background: black; color: red'
        // );
        await signOutUser();
        this.props.history.push('/forStudents');
        return;
      }
    }

    // console.debug( `mainlayout done reactivate` )
    if (this.props.loginState === LoginState.loggedIn) {
      this.executeLoginTasks();
      await Analytics.interactionStart(this.props.userProfile!);
    }
  }

  sendInteractionEnd = async () => {
    if (document.visibilityState === 'hidden') {
      const duration = (new Date().valueOf() - this.state.sessionTimer.valueOf()) / 1000;
      await Analytics.interactionEnd(this.props.userProfile!, duration, 'unload');
    }
    if (document.visibilityState === 'visible') {
      this.setState({ sessionTimer: new Date() });
    }
  };

  setupInteractionEndEvent = () => {
    this.setState({ sessionTimer: new Date() });
    document.addEventListener('visibilitychange', () => {
      return this.sendInteractionEnd();
    });
  };

  componentWillUnmount() {
    this.sendInteractionEnd();
    document.removeEventListener('visibilitychange', this.setupInteractionEndEvent);

    if (this.pollForMessagesIntervalHandle) {
      clearInterval(this.pollForMessagesIntervalHandle);
    }
  }

  updateUserObjectWithNewPageInfo = () => {
    updateUserObjectWithPageTrackingInfo();
  };
  shouldShowBanner = () => {
    if (this.props.loginState === LoginState.loggedOut) {
      return true;
    }

    if (this.props.userProfile && canUpgradeToClassroom(this.props.userProfile.licenseLevel)) {
      return true;
    }

    return false;
  };

  /* eslint-disable-next-line complexity */
  render() {
    // console.debug(`%cMainLayout render`, 'background: blue; color: yellow');
    const propsWithoutChildren = { ...this.props };
    delete propsWithoutChildren.children;
    const bannerVOffset = this.header && this.header.current ? this.header.current.offsetHeight : 0;
    const propagatingProps = {
      loginState: this.props.loginState,
      isAuthenticated: this.props.loginState === LoginState.loggedIn,
      onShowGetStartedDialog: this.handleOpenGetStartedDialog,
      history: this.props.history,
      location: this.props.location,
      params: this.props.params,
    };
    return (
      <div className={styles.mainLayout}>
        <EnableErrorMessagePopup>
          <MyHelmet />
          <ConditionalRender condition={!!this.props.withHeader} comment='header'>
            <HeaderRedirect {...propagatingProps} />
          </ConditionalRender>
          <ConditionalRender
            condition={this.shouldShowBanner() && this.props.withBanner && bannerVOffset}
            comment='banner'
          >
            <Banner {...propagatingProps} />
          </ConditionalRender>
          <Switch>
            <Route path='/moreInformation/school'>
              <ApplicationLayout {...propagatingProps} />
            </Route>
            <AuthenticatedRoute
              path={['/classroom/rostering/:providerId/:invokeSource', '/classroom/rostering/:providerId']}
            >
              <CleverRostering {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path={[
                '/library',
                '/students',
                '/myPeekavilleUnits',
                '/connectParents',
                '/classroom',
                '/reports',
                '/studentProfile/:studentID',
                '/accountSettings',
                '/gallery',
                '/videos',
              ]}
            >
              <ApplicationLayout {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path={[
                '/studentportal/toDos',
                '/studentportal/checkIn',
                '/studentportal/strengths',
                '/studentportal/accountSettings',
                '/studentportal/activity/:activity/:language/:grade/toDo/:toDo/confirmation',
                '/studentportal/activity/:activity/:language/:grade/toDo/:toDo',

                '/studentportal/activity/:activity/:language/:grade/toDo/:toDo/review',
              ]}
            >
              <MiddleSchoolStudentLayout />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path={[
                '/:classroomName/lessonPlan/readAloud/:unitId/:subunitId/Subunit:subunitNumber/Lesson:lessonNumber/Step:stepNumber', // component={AsyncStepPageWrapper} onEnter={requireAuthorization}
                '/:classroomName/lessonPlan/:unitId/:subunitId/Subunit:subunitNumber/Lesson:lessonNumber/Step:stepNumber', // component={AsyncStepPageWrapper} onEnter={requireAuthorization}
                '/:classroomName/lessonPlan/:unitId', // component={AsyncUnitPageWrapper} onEnter={requireAuthorization}
                '/lessonPlan/:unitId/:language/:grade/lesson/:lesson/activity/:activity',
                '/lessonPlan/:unitId/:language/:grade',
              ]}
            >
              <UnitLessonRedirect {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute
              path={[
                '/story', // component={AsyncStorybookLayout} onEnter={requireAuthorization}
              ]}
            >
              <StorybookLayout {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/version'>
              <VersionInformation {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/subscribe/:tier'>
              <Upgrade {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/redeemCode'>
              <RedeemCode {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/activateTrial'>
              <ActivateTrial {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/schoolEditor' requiredKeys='KEY_PEEKAPAK_MANAGEMENT'>
              <SchoolEditor {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/schoolEditor/new' requiredKeys='KEY_PEEKAPAK_MANAGEMENT'>
              <SchoolEditor {...propagatingProps} />
            </AuthenticatedRoute>
            <AuthenticatedRoute path='/notifications'>
              <NotificationsPage {...propagatingProps} />
            </AuthenticatedRoute>
          </Switch>
          <ConditionalRender condition={!!this.props.withFooter} comment='footer'>
            <Footer />
          </ConditionalRender>
          <ChooseRoleDialog
            isShow={this.state.isShowGetStartedDialog}
            onHide={this.handleCloseGetStartedDialog}
            handlerOnSelect={this.onChooseDialogSelectForGettingStarted}
          />
        </EnableErrorMessagePopup>
      </div>
    );
  }
}

function ClassFunctionWrapper(props) {
  const history = useHistory();
  const params = useParams<ParamsType>();
  const location = useLocation();
  return <MainLayout {...props} history={history} params={params} location={location} />;
}

export default connector(ClassFunctionWrapper);
