import { Fragment, ReactNode } from 'react';
import PrimaryButton from '../PrimaryButton';
import ConditionalWrapper from '../ConditionalWrapper';
import { ExternalLink } from '../ExternalLink';
import styles from './CTAButton.module.scss';

export interface CtaButtonProps {
  children: ReactNode | string;
  className?: string;
  externalLink?: string;
  isSmall?: boolean;
  onClick?: () => void;
  secondary?: boolean;
}

const CTAButton = ({
  children,
  className,
  externalLink,
  isSmall = false,
  onClick,
  secondary,
}: CtaButtonProps): JSX.Element => {
  return (
    <Fragment>
      <ConditionalWrapper
        condition={externalLink}
        wrapper={(children) => (
          <ExternalLink target='_blank' href={externalLink} className={styles.externalLink}>
            {children}
          </ExternalLink>
        )}
      >
        <PrimaryButton className={className} small={isSmall} secondary={secondary} onClick={onClick}>
          {children}
        </PrimaryButton>
      </ConditionalWrapper>
    </Fragment>
  );
};

export default CTAButton;
