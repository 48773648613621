import { useHistory, useParams } from 'react-router-dom';
import styles from './LessonInfoCard.module.scss';
import { Icon, IconifyIcon } from '@iconify/react';
import { getModuleIcon } from '../GlobalFunctions';
import bxCheck from '@iconify-icons/bx/bx-check';
import { LessonCompletionStatus, ModuleReference } from '../../peekapak-types/LessonPlanTypes';
import { isUserHasKey } from '../GlobalFunctions';
import lockAlt from '@iconify-icons/bx/lock-alt';

interface urlParams {
  language: string;
  unitId: string;
  grade: string;
  lesson: string;
  activity: string;
}

interface Props {
  lessonId: string;
  title: string;
  time: number;
  overview: string;
  index: number;
  lessonFlow: ModuleReference[];
  completionStatus?: LessonCompletionStatus;
}

const LessonInfoCard = ({
  lessonFlow,
  lessonId,
  index,
  // time,
  overview,
  completionStatus,
  title,
}: Props): JSX.Element => {
  const lessonNumber = index + 1;
  const { language, unitId, grade }: urlParams = useParams();
  const history = useHistory();

  const isLessonLocked = (lessonNum: number) => {
    if (lessonNum === 1) return false;
    if (unitId.includes('stress')) return false;
    if (isUserHasKey('KEY_ALL_LESSON_PLANS')) return false;
    return true;
  };

  const goToActivity = (activityId: string) => {
    history.push(`/lessonPlan/${unitId}/${language}/${grade}/lesson/${lessonId}/activity/${activityId}`);
  };
  return (
    <div className={styles.lessonContainer}>
      <div className={styles.contentCard}>
        <div className={styles.titleRow}>
          <div className={styles.unitNumber}>
            <div className={styles.number}>{lessonNumber}</div>
          </div>
          <h2>{title}</h2>
        </div>
        <div className={styles.contentButtonRow}>
          <div
            className={styles.lessonText}
            dangerouslySetInnerHTML={{
              __html: Array.isArray(overview) ? overview[0].content : overview,
            }}
          />
          {/*<h4>{time} Mins.</h4>*/}
        </div>
        {lessonFlow.map((x) => {
          const { id, type } = x;
          const icon = getModuleIcon(type) as IconifyIcon;
          return (
            <div
              className={styles.activityRow}
              onClick={() => goToActivity(id)}
              style={{
                color:
                  completionStatus !== undefined &&
                  completionStatus?.[lessonId]?.[id] !== 'NotStarted' &&
                  lessonId in completionStatus &&
                  id in completionStatus[lessonId]
                    ? '#86939e'
                    : type === 'Featured Story'
                      ? '#00afd8'
                      : '#43484d',
              }}
              key={`activity ${id}`}
            >
              <div className={styles.iconContainer}>
                {completionStatus !== undefined &&
                completionStatus?.[lessonId]?.[id] !== 'NotStarted' &&
                lessonId in completionStatus &&
                id in completionStatus[lessonId] ? (
                  <Icon icon={bxCheck} width={24} style={{ transform: 'translateX(-4px)' }} />
                ) : (
                  <Icon icon={icon} width={16} />
                )}
              </div>
              {x.title}
              {isLessonLocked(lessonNumber) && (
                <div className={styles.lockIcon}>
                  {' '}
                  <Icon icon={lockAlt} width={24} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LessonInfoCard;
