import { Field, useFormikContext } from 'formik';
import { Icon } from '@iconify/react';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import styles from './ToDoStrengthsEvaluation.module.scss';

const OptionsText = ['Very much like me', 'Somewhat like me', 'Somewhat like me', 'Very much like me'];

interface Props {
  questions: {
    index: number;
    question1: string;
    question2: string;
  }[];
  isRadioDisabled: boolean;
  onRadioChange: (
    values: Record<string, any>,
    questionIndex: number,
    value: number,
    {
      validateForm,
      setTouched,
      setFieldValue,
    }: {
      validateForm: () => Promise<Record<string, any>>;
      setTouched: (fieldName: string, value?: boolean, shouldValidate?: boolean) => void;
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    },
  ) => void;
}

const MultipleChoiceQuestions = ({ questions, isRadioDisabled, onRadioChange }: Props): JSX.Element => {
  const { values, errors, touched, handleChange, validateForm, setTouched } = useFormikContext();
  return (
    <div className={styles.questionContainer}>
      {questions.map((question, ind) => {
        const questionIndex = question.index.toString();
        return (
          <div className={styles.questionBox} key={`question ${ind + 1}`}>
            <div id={questionIndex} role='group' aria-labelledby={question.index.toString()} className={styles.answers}>
              <div className={styles.questionRow}>
                <p>{question.question1}</p>
                <p>{question.question2}</p>
              </div>
              <div className={styles.answerRow}>
                {OptionsText.map((option: string, aInd: number) => {
                  return (
                    <label key={`question#: ${ind + 1} answer: ${aInd + 1}`}>
                      <Field
                        type='radio'
                        name={question.index}
                        value={aInd.toString()}
                        onChange={(e) => {
                          handleChange(e);
                          onRadioChange(values, questionIndex, aInd.toString(), {
                            validateForm,
                            setTouched,
                          });
                        }}
                        disabled={isRadioDisabled}
                      />
                      {option}
                    </label>
                  );
                })}
              </div>
            </div>
            {errors[questionIndex as keyof typeof errors] && touched[questionIndex as keyof typeof touched] && (
              <div className={styles.errors}>
                <Icon icon={alertCircleFill} color='#ff6319' className={styles.icon} width={18} />
                {errors[questionIndex as keyof typeof errors]}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default MultipleChoiceQuestions;
