import { useHistory } from 'react-router-dom';
import LeftRightCard, { Copy, ImagePart, Prompt, TextPart, Title } from '../components/LeftRightCard/LeftRightCard';
import SectionHeader from '../components/SectionHeader';
import AppleAppStoreBadge from '../components/AppleAppStoreBadge';
import GooglePlayStoreBadge from '../components/PlayStoreBadge/PlayStoreBadge';
import cx from 'classnames';
import checkmarkCircleGrey from '../images/pkp-checkmark-in-circle-grey.svg';
import elaAndSelImage from '../images/mockup-k-8.png';
import elementarySolutionsImage from '../images/new-mockup-k-5.png';
import secondarySolutionsImage from '../images/home-left-right-2.png';
import dataUsageImage from '../images/mockup-data.png';
import styles from '../SCSS/HighlightsSegment.module.scss';

const defaultProps = {
  elaAndSel: true,
  elementarySolutions: true,
  middleSchoolSolutions: true,
  systemWideData: true,
};

interface Props {
  elaAndSel: boolean;
  elementarySolutions: boolean;
  middleSchoolSolutions: boolean;
  systemWideData: boolean;
}

const HighlightsSegment = ({
  elaAndSel,
  elementarySolutions,
  middleSchoolSolutions,
  systemWideData,
}: Props & typeof defaultProps): JSX.Element => {
  const history = useHistory();
  return (
    <section className={styles.content}>
      <div className={styles.verticalWhiteSpace} />
      {elaAndSel && (
        <LeftRightCard textLeadEven>
          <ImagePart>
            <img className={styles.imageMaxWidth} src={elaAndSelImage} alt='mockup pk-12' />
          </ImagePart>
          <div className={cx(styles.leftMarginHack, styles.elaAndSelCard)}>
            <TextPart>
              <SectionHeader alignLeft preTitle='INTEGRATE ELA AND SEL' title='Where SEL and Literacy Meet' />
              <Copy>
                <p>
                  Prepare students with future ready skills through Peekapak’s SEL curriculum. Literacy aligned and
                  flexible to help weave bite sized SEL lessons into academic instruction - Peekapak is the next
                  generation platform that goes beyond software to empower educators to support both offline and online
                  learning to unlock students&apos; full potential.
                </p>
                <ul className={styles.lists}>
                  <li>
                    <img src={checkmarkCircleGrey} alt='checkmark icon' />
                    ELA and CASEL Aligned
                  </li>
                  <li>
                    <img src={checkmarkCircleGrey} alt='checkmark icon' />
                    Blended, Plug and Play Lessons
                  </li>
                  <li>
                    <img src={checkmarkCircleGrey} alt='checkmark icon' />
                    Gamified Learning
                  </li>
                  <li>
                    <img src={checkmarkCircleGrey} alt='checkmark icon' />
                    In Depth Analytics
                  </li>
                </ul>
              </Copy>
              <Prompt clickHandler={() => history.push('/requestInfo')}>Request a Quote</Prompt>
            </TextPart>
          </div>
        </LeftRightCard>
      )}
      <div className={styles.verticalWhiteSpace} />
      {elementarySolutions && (
        <LeftRightCard imageLead>
          <ImagePart>
            <img className={styles.imageMaxWidth} src={elementarySolutionsImage} alt='classroom interaction' />
          </ImagePart>
          <TextPart>
            <Copy>
              <h3 className={styles.preTitle}>PRE-K TO 5TH GRADE</h3>
            </Copy>
            <Title>Elementary Solutions</Title>
            <Copy>
              <p>
                Our easy-to-use, educator-led curriculum features levelled readers and fully-planned lessons to help
                students develop empathy, self-regulation and more while also developing core academic skills, including
                reading, writing, listening, speaking, representing and problem-solving.
              </p>
              <p>
                In myPeekaville, Peekapak&apos;s elementary digital learning game, students experience SEL concepts in
                the context of an interactive story-world, filled with quests, games, embedded with extrinsic rewards to
                motivate learning.
              </p>
            </Copy>
            <div className={styles.linksContainer}>
              <Prompt className={styles.elementaryPrompt} clickHandler={() => history.push('/elementary')}>
                Review Elementary Resources
              </Prompt>
              <div className={styles.storeBadges}>
                <AppleAppStoreBadge />
                <GooglePlayStoreBadge />
              </div>
            </div>
          </TextPart>
        </LeftRightCard>
      )}
      <div className={styles.verticalWhiteSpace} />
      {middleSchoolSolutions && (
        <LeftRightCard textLeadEven>
          <ImagePart>
            <img className={styles.imageMaxWidth} src={secondarySolutionsImage} alt='peekaville map' />
          </ImagePart>
          <div className={styles.leftMarginHack}>
            <TextPart>
              <Copy>
                <h3 className={styles.preTitle}>6TH TO 12TH GRADE</h3>
              </Copy>
              <Title>Secondary Solutions</Title>
              <Copy>
                <p>
                  Our curriculum teaches CASEL competencies and SEL skills through reading and writing instruction. The
                  curriculum features human interest stories that showcase diverse perspectives and relatable
                  experiences for secondary school students (ages 10 to 17), to help them empathize with others and
                  learn about themselves.
                </p>
              </Copy>
              <Prompt clickHandler={() => history.push('/secondary')}>Review Secondary Resources</Prompt>
            </TextPart>
          </div>
        </LeftRightCard>
      )}
      <div className={styles.verticalWhiteSpace} />
      {systemWideData && (
        <LeftRightCard imageLead>
          <ImagePart>
            <img style={{ maxWidth: '500px' }} src={dataUsageImage} alt='classroom interaction' />
          </ImagePart>
          <div className={styles.rightMarginHack}>
            <TextPart>
              <Copy>
                <h3 className={styles.preTitle}>FOR CLASSROOMS, SCHOOL AND DISTRICTS</h3>
              </Copy>
              <Title>Usage & Sentiment Data</Title>
              <Copy>
                <p>
                  Peekapak solutions equip educators and administrators with real-time data analytics to track student
                  usage and progress using a data-analytics tool embedded within the curriculum.
                </p>
                <ul className={styles.dataUsageList}>
                  <li>
                    <strong>Moodboard: </strong>Monitor students&apos; feelings through a digital check-in tool on the
                    student platform.
                  </li>
                  <li>
                    <strong>Assessment: </strong>At a glance, see how students are progressing in their learning to make
                    data-informed decisions to support learners.
                  </li>
                  <li>
                    <strong>ELA standards: </strong>Measure which standards have been taught by lesson completion.
                  </li>
                </ul>
              </Copy>
            </TextPart>
          </div>
        </LeftRightCard>
      )}
    </section>
  );
};

export default HighlightsSegment;

HighlightsSegment.defaultProps = defaultProps;
