import { PageTracker } from '../peekapak-types/DataProtocolTypes';

export type SessionState = {
  unit: string | null | undefined;
};
const SET_UNIT = 'SET_UNIT';
const UNSET_UNIT = 'UNSET_UNIT';
const UPDATE_STORYBOOK_PERCENTAGE_READ = 'UPDATE_STORYBOOK_PERCENTAGE_READ';
const RESET_STORYBOOK_PERCENTAGE_READ = 'RESET_STORYBOOK_PERCENTAGE_READ';
const UPDATE_PAGE_TRACKER = 'UPDATE_PAGE_TRACKER';
export const setUnit = (newUnit: string) => ({
  type: SET_UNIT,
  payload: {
    newUnit,
  },
});
export const unsetUnit = () => ({
  type: UNSET_UNIT,
});
export const updateStorybookPercentageRead = (percent: number) => ({
  type: UPDATE_STORYBOOK_PERCENTAGE_READ,
  payload: {
    percent,
  },
});
export const resetStorybookPercentageRead = () => ({
  type: RESET_STORYBOOK_PERCENTAGE_READ,
});
export const updatePageTracker = (newPageTracker: PageTracker) => ({
  type: UPDATE_PAGE_TRACKER,
  payload: { ...newPageTracker },
});
type SessionAction =
  | ReturnType<typeof setUnit>
  | ReturnType<typeof unsetUnit>
  | ReturnType<typeof updateStorybookPercentageRead>
  | ReturnType<typeof resetStorybookPercentageRead>
  | ReturnType<typeof updatePageTracker>;
export const initialState = {
  unit: undefined,
  storybookTracker: undefined,
  pageTracker: undefined,
};
export function session(state: SessionState = initialState, action: SessionAction) {
  switch (action.type) {
    case SET_UNIT:
      return Object.assign({}, state, {
        unit: (action as ReturnType<typeof setUnit>).payload.newUnit,
      });

    case UNSET_UNIT:
      return Object.assign({}, state, {
        unit: undefined,
      });

    case UPDATE_STORYBOOK_PERCENTAGE_READ:
      return Object.assign({}, state, {
        storybookTracker: {
          percent: (action as ReturnType<typeof updateStorybookPercentageRead>).payload.percent,
        },
      });

    case RESET_STORYBOOK_PERCENTAGE_READ:
      return Object.assign({}, state, {
        storybookTracker: {
          percent: 0,
        },
      });

    case UPDATE_PAGE_TRACKER:
      return Object.assign({}, state, {
        pageTracker: {
          ...(action as ReturnType<typeof updatePageTracker>).payload,
        },
      });

    default:
      return state;
  }
}
export default session;
