import { useRef, useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { useOutsideClick } from '../../CustomHooks';
import { ExternalLink } from '../ExternalLink';
import { Icon } from '@iconify/react';
import bxChevronDown from '@iconify-icons/bx/bx-chevron-down';
import styles from './UnauthenticatedHeader.module.scss';

interface NavProps {
  history: RouteComponentProps['history'];
  name: string;
  route: any;
  hasSubMenu: boolean;
  onClickCallback?: () => void;
  isMobile?: boolean;
  subItems?: { name: string; route: string }[];
}

const HeaderNavItem = ({
  name,
  route,
  history,
  hasSubMenu,
  subItems,
  onClickCallback,
  isMobile,
}: NavProps): JSX.Element => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const ref = useRef<HTMLUListElement>(null);
  useOutsideClick(ref, () => setShowSubMenu(false));

  if (!hasSubMenu) {
    return flatComponent();
  }

  function flatComponent() {
    return (
      <ul className={styles.headerButton}>
        <li>
          <Link onClick={() => handleOnClick(route)} to={route}>
            <span>{name}</span>
          </Link>
        </li>
      </ul>
    );
  }

  function handleOnClick(definedRoute: string | undefined) {
    if (definedRoute?.includes('http')) {
      window.open(definedRoute, '_blank');
    } else {
      definedRoute && history.push(definedRoute);
    }

    if (onClickCallback) {
      onClickCallback();
    }
  }

  return (
    <ul
      ref={isMobile ? ref : null}
      className={styles.menuContainer}
      onClick={() => setShowSubMenu(!showSubMenu)}
      onMouseEnter={!isMobile ? () => setShowSubMenu(true) : undefined}
      onMouseLeave={!isMobile ? () => setShowSubMenu(false) : undefined}
    >
      <li className={styles.headerButton}>
        <button className={styles.resembleLinkButton}>
          <span>{name}</span>
          <span>
            <Icon
              className={styles.chevron}
              icon={bxChevronDown}
              width={18}
              height={18}
              color={isMobile ? 'white' : '#00afd8'}
            />
          </span>
        </button>
      </li>
      {showSubMenu && (
        <ul className={styles.dropdownBox}>
          {subItems &&
            subItems.map((sm, index) => (
              <li key={`submenu-${index}`} className={styles.dropdownItem}>
                {sm.route.includes('http') ? (
                  <ExternalLink href={sm.route}>
                    <span>{sm.name}</span>
                  </ExternalLink>
                ) : sm.route === 'contact' ? (
                  <a href='mailto:hello@peekapak.com' target='_blank' rel='noreferrer noopener'>
                    {sm.name}
                  </a>
                ) : (
                  <Link to={sm.route} onClick={() => handleOnClick(sm.route)}>
                    <span>{sm.name}</span>
                  </Link>
                )}
              </li>
            ))}
        </ul>
      )}
    </ul>
  );
};

export default HeaderNavItem;
