import { useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { useWidth } from '../../CustomHooks';
import styles from './LoadingBar.module.scss';

interface Props {
  numComplete: number;
  totalNum: number;
  barColor: string;
  width?: number;
  title?: string;
}

const OverallCompletion = ({ numComplete, totalNum, barColor, width, title }: Props): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const barWidth = useWidth(ref);
  const overallProgressSpringProps = useSpring({
    from: { width: 0 },
    to: {
      width: barWidth !== null && barWidth !== 0 ? barWidth * (numComplete / totalNum) : 0,
    },
  });

  return (
    <div className={styles.container} style={{ width: width ? `${width}px` : '100%' }}>
      <div className={styles.titleContainer}>
        <h3>{title}</h3>
        <div className={styles.incompleteBar} ref={ref}>
          <animated.div
            className={styles.completeBar}
            style={{
              backgroundColor: barColor,
              ...overallProgressSpringProps,
              borderTopRightRadius: numComplete / totalNum === 1 ? '4px' : 0,
              borderBottomRightRadius: numComplete / totalNum === 1 ? '4px' : 0,
            }}
          >
            {barWidth != null &&
              (barWidth * numComplete) / totalNum > 30 &&
              `${Math.floor((numComplete / totalNum) * 100)}%`}
          </animated.div>
        </div>
      </div>
    </div>
  );
};

export default OverallCompletion;
