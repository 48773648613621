import cx from 'classnames';
import styles from './RoleCard.module.scss';

interface ChildProps {
  children: React.ReactNode;
}

interface ClickHandlerProps {
  clickHandler?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

interface RoleSpecificProps {
  accentColour: string;
  className?: string;
  description: string | string[];
  footer: string | string[];
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  title: string | string[];
}

type PromptProps = ChildProps & ClickHandlerProps;

export const Prompt = ({ children, clickHandler }: PromptProps): JSX.Element => {
  return (
    <div className={styles.prompt} onClick={clickHandler}>
      {children}
      <svg width='9px' height='15px' viewBox='0 0 9 15' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.396'>
          <g id='ionic-ios-arrow-back' transform='translate(-1.000000, -1.000000)' fill='#FFFFFF' fillRule='nonzero'>
            <path
              d='M3.49526101,8.19721855 L8.99526101,2.69721855 C9.35307868,2.28403415 9.32958419,1.66426885 8.94151187,1.27936016 C8.55343954,0.894451462 7.93350269,0.87603079 7.52326101,1.23721855 L1.29226101,7.46421855 C0.901273724,7.85687236 0.888061533,8.48753429 1.26226101,8.89621855 L7.51926101,15.1662186 C7.77926368,15.4388458 8.16647179,15.5493729 8.53121155,15.455076 C8.89595132,15.3607791 9.18107223,15.0764335 9.27636191,14.7119519 C9.3716516,14.3474702 9.26217926,13.9599626 8.99026101,13.6992186 L3.49526101,8.19721855 Z'
              id='Icon_ionic-ios-arrow-back'
              transform='translate(5.150131, 8.233109) rotate(180.000000) translate(-5.150131, -8.233109) '
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

const RoleCard = ({ accentColour, className, description, footer, onClick, title }: RoleSpecificProps): JSX.Element => {
  return (
    <div className={cx(styles.card, { [`${className}`]: className })}>
      <div className={styles.title}>
        <div className={styles.icon}>
          <svg xmlns='http://www.w3.org/2000/svg' width='24.386' height='12.194' viewBox='0 0 24.386 12.194'>
            <path
              id='Path_1'
              data-name='Path 1'
              d='M4397.362,780.786h-5.6a6.594,6.594,0,1,0-13.188,0h-5.6a12.193,12.193,0,1,1,24.386,0Z'
              transform='translate(-4372.976 -768.592)'
              fill={`${accentColour}`}
            />
          </svg>
        </div>
        <div>{title}</div>
      </div>
      <div className={styles.description}>{description}</div>
      <div
        className={styles.footer}
        style={{
          background: accentColour,
        }}
      >
        <Prompt clickHandler={onClick}>{footer}</Prompt>
      </div>
    </div>
  );
};

export default RoleCard;
