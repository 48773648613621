import { useState } from 'react';
import { getMonthName, getReadableUnixTimeElements } from '../../GlobalFunctions';
import TeacherReviewModal from '../TeacherReviewModal';
import Tooltip from '../Tooltip';
import ModalContainer from '../ModalContainer';
import ConfirmIrreversible from '../ConfirmIrreversible';
import { InlineIcon } from '@iconify/react';
import bxCheck from '@iconify-icons/bx/bx-check';
import replyAll from '@iconify-icons/bx/reply-all';
import styles from './StudentSubmissionItem.module.scss';
import cancelLine from '@iconify/icons-clarity/cancel-line';
import { Module, Metawork } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  moduleData: Module;
  firstName: string;
  lastName?: string;
  modifiedAt?: number;
  status: string;
  createdAt: number;
  todoTitle: string;
  userId: string;
  todo: string;
  metawork?: Metawork<{ type: string }>;
  deleteStudentAssignment: (userId: string) => void;
  retractAssignment: (userId: string, createdAt: number) => void;
}

const deleteWarning = `Are you sure that you want to cancel the assignment for this student? This will delete their work and any records for this student's assignments in this module. This change cannot be undone.`;
const deleteInstructions = `To confirm, please type the student's name and click the button below:`;

const StudentSubmissionItem = ({
  moduleData,
  firstName,
  lastName,
  modifiedAt,
  status,
  createdAt,
  todoTitle,
  userId,
  todo,
  metawork,
  deleteStudentAssignment,
  retractAssignment,
}: Props): JSX.Element => {
  const [isReviewStudentWork, setReviewStudentWork] = useState(false);
  const [isShowCancelModal, setShowCancelModal] = useState(false);
  const thisStudentContent = moduleData.studentContent.filter((obj) => obj.id === todo);
  const todoDisplayContent = thisStudentContent[0].content;
  const assetImage = thisStudentContent[0].representativeImage;
  const timeSubmitted = (() => {
    if (!modifiedAt) return 'Unknown';
    const [year, _month, date, hour, min, _sec] = getReadableUnixTimeElements(modifiedAt);
    return `${getMonthName(modifiedAt)} ${date}, ${year} @ ${hour > 12 ? hour - 12 : hour}:${
      min < 10 ? `0${min}` : `${min}`
    } ${hour > 11 ? 'pm' : 'am'}`;
  })();
  const submitted = status === 'submitted';

  const isSlideDeck = thisStudentContent[0].content[0].type === 'slideDeck';

  const clickable = submitted && !isSlideDeck;

  const handleClick = () => {
    if (isSlideDeck) return null;
    if (submitted) setReviewStudentWork(true);
    else return null;
  };

  const handleCloseModal = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) setReviewStudentWork(false);
  };

  const handleToggleCancelModal = () => {
    setShowCancelModal((prevState) => !prevState);
  };

  const getScoreColour = () => {
    const percentage = (metawork?.studentScore / metawork?.totalScore) * 100;
    if (percentage > 70) return '#16c98d';
    if (percentage < 70 && percentage > 50) return '#ff6319';
    if (percentage < 50) return '#fb7270';
  };

  const handleRetract = (e: React.MouseEvent) => {
    e.preventDefault();
    retractAssignment(userId, createdAt);
  };

  return (
    <>
      <div className={clickable ? styles.studentListClickable : styles.studentList} onClick={handleClick}>
        <InlineIcon icon={bxCheck} width={30} color={submitted ? '#16c98d' : 'transparent'} />
        {
          //TODO: Replace avatar div with component
        }
        <div
          className={styles.avatar}
          style={{
            backgroundColor:
              status === 'assigned'
                ? '#86939e'
                : status === 'inProgress' || status === 'retracted'
                  ? '#012a73'
                  : '#00afd8',
          }}
        >
          {firstName?.charAt(0)}
          {lastName?.charAt(0)}
        </div>
        <div className={styles.textContainer}>
          <div className={styles.nameCol}>
            <h4>
              {firstName} {lastName}
            </h4>
            <div className={styles.submitInfo}>
              {todoTitle} -{' '}
              {submitted ? 'Complete' : status === 'inProgress' || status === 'retracted' ? 'In Progress' : 'assigned'}
              {metawork?.totalScore && (
                <span
                  style={{
                    fontFamily: 'GT-Walsheim-Pro-Bold',
                    color: getScoreColour(),
                    transform: 'translateY(-2px)',
                  }}
                >
                  &nbsp;&nbsp;{metawork?.studentScore}/{metawork?.totalScore}
                </span>
              )}
            </div>
          </div>
          <div className={styles.alignRight}>
            {submitted && modifiedAt && (
              <span onClick={(e) => handleRetract(e)} className={styles.cancelAssignment}>
                <Tooltip content='Retract Assignment Submission' direction='left' color='blue'>
                  <InlineIcon icon={replyAll} width={18} className={styles.cancelIcon} />
                </Tooltip>
              </span>
            )}
            <span onClick={handleToggleCancelModal} className={styles.cancelAssignment}>
              <Tooltip content='Cancel Student Assignment' direction='left' color='blue'>
                <InlineIcon icon={cancelLine} className={styles.cancelIcon} />
              </Tooltip>
            </span>
            {submitted && modifiedAt && <div className={styles.dateSubmitted}>{timeSubmitted}</div>}
          </div>
        </div>
      </div>
      {isReviewStudentWork && (
        <div className={styles.reviewModal} onClick={(e) => handleCloseModal(e)}>
          <TeacherReviewModal
            assetImage={assetImage}
            studentId={userId}
            title={moduleData.title}
            createdAt={createdAt}
            displayData={todoDisplayContent}
            handleCloseModal={handleCloseModal}
            firstName={firstName}
            lastName={lastName}
            timeSubmitted={timeSubmitted}
          />
        </div>
      )}
      {isShowCancelModal && (
        <ModalContainer hideModal={handleToggleCancelModal}>
          <ConfirmIrreversible
            hideModal={handleToggleCancelModal}
            title='Cancel Assignment'
            warningMessage={deleteWarning}
            instructions={deleteInstructions}
            confirmTestString={firstName}
            performIrreversibleAction={() => deleteStudentAssignment(userId)}
          />
        </ModalContainer>
      )}
    </>
  );
};

export default StudentSubmissionItem;
