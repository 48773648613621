import { forwardRef } from 'react';
import { Button } from 'react-bootstrap';
import combineClasses from 'classnames';
import styles from './SecondaryButton.module.scss';
const SecondaryButton = forwardRef((props, ref) => {
  const { small, tiny, noborder, disabled, className, ...otherProps } = props;
  const combinedClasses = combineClasses(
    {
      [`${styles.secondaryButton}`]: true,
      [`${styles.small}`]: small,
      [`${styles.tiny}`]: tiny,
      [`${styles.noborder}`]: noborder,
      [`${styles.disabled}`]: disabled,
    },
    className,
  );
  return (
    <div className={combinedClasses} ref={ref}>
      <Button {...otherProps}>{otherProps.children}</Button>
    </div>
  );
});
export default SecondaryButton;
