import styles from './ToDoAssets.module.scss';
import { useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { RootState } from '../../ApplicationState';
import { selectClassroom } from '../../Classrooms';
import { submitAssignment } from '../../BackendInterface';
import { getPublicMediaRoot } from '../../serviceAgent';
import MuxPlayer from '../MuxPlayer';
import { InlineIcon } from '@iconify/react';
import layersOutline from '@iconify/icons-eva/layers-outline';
import { UrlParams } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  data: {
    uri: string;
    title: string;
    type: string;
    content: string;
  }[];
  assetImage: string;
  userProfile: { grade: string; userId: string };
  classroom: { language: string };
}

const mapStateToProps = (state: RootState) => ({
  userProfile: state.user.userProfile,
  classroom: selectClassroom(state),
});

const ToDoAssets = ({ data, assetImage, ...props }: Props): JSX.Element => {
  const history = useHistory();
  const [isShowPlayer, setShowPlayer] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [videoTitle, setVideoTitle] = useState('');

  const { activity, toDo }: UrlParams = useParams();
  const { grade, userId } = props.userProfile;
  const language = props.classroom?.language || 'en';

  const getText = (assetType: string) => {
    if (assetType === 'slideDeck') return 'View Slides';
    if (assetType === 'pdf') return 'View PDF';
    if (assetType === 'video') return 'Watch Video';
    return 'View Materials';
  };

  const handleHide = () => setShowPlayer(false);

  const populateVideoPlayer = useCallback(async (asset: { id: string; title: string }) => {
    try {
      setVideoId(asset.id);
      setVideoTitle(asset.title);
    } finally {
      setShowPlayer(true);
    }
  }, []);

  const handleClick = async (asset: { type: string; uri?: string; id?: string; title: string }) => {
    if (asset.type === 'slideDeck') {
      const levelPrefix = parseInt(grade) > 8 ? 'highSchool' : 'middleSchool';
      try {
        const submitResponse = await submitAssignment(userId, parseInt(toDo), []);
        console.info(`result = ${JSON.stringify(submitResponse, null, 2)}`);
        console.log('Slideshow assignment marked as complete');
      } catch (error) {
        console.info(`error is ${JSON.stringify(error, null, 2)}`);
      } finally {
        history.push(`/${levelPrefix}Slides/${activity}/${language}/${grade}`);
      }
      return;
    }
    if (asset.type === 'pdf') {
      asset.uri !== undefined && history.push(asset.uri);
      return;
    }
    if (asset.type === 'video' && asset.id) {
      populateVideoPlayer(asset);
      return;
    } else history.push('/404');
  };

  return (
    <div className={styles.container}>
      {data?.map((asset) => {
        return asset.type === 'textBlock' || asset.type === 'checkInTool' ? (
          <>
            <h3>{asset.title}</h3>
            <div
              key={`textBlock ${asset.title}`}
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: asset.content,
              }}
            />
          </>
        ) : (
          <div key={asset.title} className={styles.assetCard} onClick={() => handleClick(asset)}>
            <img src={`${getPublicMediaRoot()}images/secondary/${assetImage}`} alt='' />
            <h4 className={styles.cardTitle}>{asset.title}</h4>
            <div className={styles.assetLinks}>
              <InlineIcon icon={layersOutline} />
              &nbsp;{getText(asset)}
            </div>
          </div>
        );
      })}
      {isShowPlayer && <MuxPlayer videoId={videoId} isShow={isShowPlayer} onHide={handleHide} title={videoTitle} />}
    </div>
  );
};

export default connect(mapStateToProps, null)(ToDoAssets);
