const Images = {
  activateTrialSecondary:
    'https://peekaplatform.s3.amazonaws.com/media30/images/platformPages/activate-trial-secondary.png',
  leoWithBackpack: 'https://s3.amazonaws.com/peekaplatform/media30/images/platformIntroduction/leo-with-backpack.png',
  successApolloAndSebastian: 'https://s3.amazonaws.com/peekaplatform/media30/images/success.png',
  embarrassedKenji: 'https://s3.amazonaws.com/peekaplatform/media30/images/kenji.png',
  codyErrorPicture: 'https://s3.amazonaws.com/peekaplatform/media30/images/cookie%20error.png',
  storybookCoverSelfRegulation:
    'https://s3.amazonaws.com/peekaplatform/media30/images/platformPages/Self-Reg-cover.png',
  leoGraphic: 'https://s3.amazonaws.com/peekaplatform/media30/images/platformIntroduction/leo-with-backpack.png',
  peekapakPals: 'https://s3.amazonaws.com/peekaplatform/assets/webpages/peekapak-pals.png',
  successSmall: 'https://s3.amazonaws.com/peekaplatform/media30/images/success-small.png',
  landingPageStorybookForParents:
    'https://s3.amazonaws.com/peekaplatform/media30/images/platformPages/landing-page-front-640.png',
  backpackToolsGeneric:
    'https://s3.amazonaws.com/peekaplatform/media30/images/platformPages/backpack-tools-generic.png',
  backpackToolsApart: 'https://s3.amazonaws.com/peekaplatform/media30/images/platformPages/backpack-tools-apart.png',
  backpackPatch: 'https://s3.amazonaws.com/peekaplatform/media30/images/platformPages/backpack-patch.png',
  backpackPurpleBg: 'https://s3.amazonaws.com/peekaplatform/media30/images/platformPages/backpack-purple-bg.png',
  messageSent: 'https://peekaplatform.s3.amazonaws.com/media30/images/platformPages/message-sent.png',
  placeholderUnitHeader: 'https://peekaplatform.s3.amazonaws.com/media30/images/secondary/Placeholder-Unit-Header.png',
};

export default Images;
