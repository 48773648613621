import * as React from 'react';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import cx from 'classnames';
import styles from './YesNoDialog.module.scss';

type Props = {
  isShow: boolean;
  onHide: () => unknown;
  title: string;
  continueButtonLabel?: string;
  cancelButtonLabel?: string;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  onYesResponse: () => unknown;
  onNoResponse: () => unknown;
  enableCloseButton?: boolean;
};

class YesNoDialog extends React.Component<Props> {
  render(): JSX.Element {
    return (
      <Modal
        show={this.props.isShow}
        onHide={this.props.onHide}
        dialogClassName={cx(styles.modalDialog, this.props.className)}
        keyboard={!!this.props.enableCloseButton}
        backdrop={this.props.enableCloseButton ? true : 'static'}
      >
        <Modal.Header className={styles.header} closeButton={!!this.props.enableCloseButton}>
          <Modal.Title className={styles.title}>
            <h3>{this.props.title}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modalBody}>
          {typeof this.props.children === 'string' ? <p>{this.props.children}</p> : this.props.children}
          <div className={styles.buttonsSection}>
            <PrimaryButton onClick={this.props.onNoResponse} alertSecondary small>
              {this.props.cancelButtonLabel || 'No'}
            </PrimaryButton>
            <PrimaryButton onClick={this.props.onYesResponse} small>
              {this.props.continueButtonLabel || 'Yes'}
            </PrimaryButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default YesNoDialog;
