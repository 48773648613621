import style from './SingleMessagePage.module.scss';

interface Props {
  graphic: JSX.Element;
  title: string;
  body: JSX.Element;
}

export const SingleMessagePage = (props: Props) => {
  return (
    <div className={style.page}>
      <div className={style.content}>
        <div className={style.graphic}>{props.graphic}</div>
        <h1>{props.title}</h1>
        <div className={style.body}>{props.body}</div>
      </div>
    </div>
  );
};
