import { Component } from 'react';
import { OverViewActivityCard } from './OverViewActivityCard';
import { getLetterGradeFromNumberGrade } from '../GlobalFunctions';
import { lockWithPreviewUnlessHasAny } from '../lockDecorators';
import styles from '../SCSS/BreakdownOverview.module.scss';
import { ClassroomType, ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';
import { ClassroomsStateType } from '../Classrooms';
import { History } from 'history';
import { UnitPosition } from './ActivityStepPage';

interface Props {
  classroom: ClassroomType;
  classrooms: {
    state: ClassroomsStateType;
    list: ClassroomType[];
    selectedClassroom: number;
  };
  completionStatus: ClassroomType['completionStatus'];
  data: { value: number; color: string }[];
  here: boolean;
  subunitStub: ElementaryUnit['value']['lessonPlan']['subUnits'][number];
  subunitCardIndex: number;
  isReadAloudCard: boolean;
  parentContainer: React.ReactNode;
  onClickLockedContent: () => void;
  history: History;
  onMarkStepComplete: (unitPosition: UnitPosition) => void;
  isPreviewMode?: boolean;
  onPreviewChange?: (newUnitPosition: UnitPosition) => void;
  unitId: string;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
}
interface State {
  subunitData: ElementaryUnit['value']['lessonPlan']['subUnits'][number] | undefined;
  isSuppressPiePlacemarker: boolean;
  isShowTooltip: boolean;
}
class _NavigatorCard extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      subunitData: undefined,
      isSuppressPiePlacemarker: false,
      isShowTooltip: false,
    };

    this.suppressPiePlacemarker = this.suppressPiePlacemarker.bind(this);
    this.allowPiePlacemarker = this.allowPiePlacemarker.bind(this);
    // this.getUnitUrlFor = this.getUnitUrlFor.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  /*getAndContentAccessKeys() {
    if (this.state.subunitData) {
      const lessonMeta =
        this.props.subunitData.lessons[this.props.unitPosition.lesson];
      if (lessonMeta.andContentAccessKey) {
        return [lessonMeta.andContentAccessKey];
      } else if (lessonMeta.andContentAccessKeys) {
        return lessonMeta.andContentAccessKeys;
      }
    }

    return [];
  }

  getOrContentAccessKeys() {
    if (this.state.subunitData) {
      const lessonMeta =
        this.props.subunitData.lessons[this.props.unitPosition.lesson];
      if (lessonMeta.orContentAccessKey) {
        return [lessonMeta.orContentAccessKey];
      } else if (lessonMeta.orContentAccessKeys) {
        return lessonMeta.orContentAccessKeys;
      }
    }

    return [];
  }*/

  toggleTooltip() {
    if (this.state.isShowTooltip) {
      this.hideTooltip();
    } else {
      this.showTooltip();
    }
  }

  showTooltip() {
    this.suppressPiePlacemarker();
    this.setState({
      isShowTooltip: true,
    });
  }

  hideTooltip() {
    this.allowPiePlacemarker();
    this.setState({
      isShowTooltip: false,
    });
  }

  UNSAFE_componentWillMount() {
    const subunit = this.props.subunitCardIndex;
    const subUnitData = this.props.unitMeta.subUnits[subunit];
    this.setState({
      subUnitData: subUnitData,
    });
  }

  suppressPiePlacemarker() {
    this.setState({
      isSuppressPiePlacemarker: true,
    });
  }

  allowPiePlacemarker() {
    this.setState({
      isSuppressPiePlacemarker: false,
    });
  }

  getCardTitle = () => {
    if (this.props.subunitStub.label.length < 20) {
      return `${this.props.subunitStub.label}: ${this.props.subunitStub.title}`;
    } else {
      return this.props.subunitStub.label;
    }
  };

  render() {
    return (
      <div className={styles.breakdownOverview}>
        <button className={styles.breakdownCardHeader}>{this.getCardTitle()}</button>
        <OverViewActivityCard
          unitMeta={this.props.unitMeta}
          subunitStub={this.props.subunitStub}
          completionStatus={this.props.completionStatus}
          unit={this.props.unit}
          classroom={this.props.classroom}
          subunitCardIndex={this.props.subunitCardIndex}
          classrooms={this.props.classrooms}
          onClickLockedContent={this.props.onClickLockedContent}
          history={this.props.history}
          onMarkStepComplete={this.props.onMarkStepComplete}
          isReadAloudCard={this.props.isReadAloudCard}
          isPreviewMode={this.props.isPreviewMode}
          onPreviewChange={this.props.onPreviewChange}
          unitId={this.props.unitId}
        />
      </div>
    );
  }

  /*
  getUnitUrlFor(level, lessonIndex, stepIndex) {
    const classroomName = this.props.classroom.className;
    const unitId = `${
      this.props.unitMeta.title
    }-${getLetterGradeFromNumberGrade(this.props.classroom.grade)}`;
    const subunitId = this.state.subunitData.url;

    if (this.props.isReadAloudCard) {
      if (level === 'subunit') {
        return (
          `/${classroomName}/lessonPlan/readAloud/${unitId}/${subunitId}/` +
          `Subunit${this.props.pieIndex + 1}`
        );
      }

      return (
        `/${classroomName}/lessonPlan/readAloud/${unitId}/${subunitId}/` +
        `Subunit${this.props.pieIndex + 1}/Lesson${lessonIndex + 1}/Step${
          stepIndex + 1
        }`
      );
    }

    if (level === 'subunit') {
      return (
        `/${classroomName}/lessonPlan/${unitId}/${subunitId}/` +
        `Subunit${this.props.pieIndex + 1}`
      );
    }

    return (
      `/${classroomName}/lessonPlan/${unitId}/${subunitId}/` +
      `Subunit${this.props.pieIndex + 1}/Lesson${lessonIndex + 1}/Step${
        stepIndex + 1
      }`
    );
  }
  */
}

export const NavigatorCard = lockWithPreviewUnlessHasAny('KEY_ALL_LESSON_PLANS', 'KEY_MASTER_KEY')(_NavigatorCard);
