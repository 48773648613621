import { Component } from 'react';
import styles from '../../SCSS/Header.module.scss';
import userIcon from '../../images/pkp-user-grey.svg';
import { UserProfileType } from '../../../peekapak-types/DataProtocolTypes';

interface Props {
  displayDropdown: boolean;
  isAuthenticated: boolean;
  userProfile: UserProfileType | null;
}

class SidebarId extends Component<Props> {
  constructor(props: Props) {
    console.debug('SidebarId constructor');
    super(props);
  }
  render() {
    if (!this.props.isAuthenticated || !this.props.userProfile) {
      return null;
    }

    return (
      <div className={styles.sidebarId}>
        <div className={styles.idBlock}>
          <UserIconWithBadge />
          <div className={styles.nameAndLicense}>
            <UserName
              salutation={this.props.userProfile.salutation ?? ''}
              lastName={this.props.userProfile.lastName ?? ''}
              displayDropdown={this.props.displayDropdown}
              licenseLevel={this.props.userProfile.licenseLevel}
              licenseExpires={this.props.userProfile.licenseExpires}
            />
          </div>
        </div>
      </div>
    );
  }
}

const LicenseStatus = ({ licenseLevel, licenseExpires }: { licenseLevel: string; licenseExpires: number }) => {
  switch (licenseLevel.toLowerCase()) {
    case 'pro':
    case 'premium':
    case 'renewed_pro':
      return (
        <div className={styles.licenseBadge}>
          <div>Teacher PRO</div>
        </div>
      );
    case 'trial': {
      const now = Date.now();
      const daysLeft = Math.floor((licenseExpires - now) / (24 * 3600 * 1000));
      return (
        <div className={styles.licenseBadge}>
          <div className={styles.trialDaysLeft}>{daysLeft} trial days left</div>
        </div>
      );
    }
    case 'classroom':
    case 'renewed_classroom':
      return (
        <div className={styles.licenseBadge}>
          <div>Peekapak PRO</div>
        </div>
      );
    case 'basic':
    case 'expired_pro':
    case 'expired_classroom':
      return (
        <div className={styles.licenseBadge}>
          <div>BASIC User</div>
        </div>
      );
    case 'expired_trial':
      return (
        <div className={styles.licenseBadge}>
          <div>Expired Trial</div>
        </div>
      );
    default:
      return null;
  }
};

interface UserNameProps {
  displayDropdown: boolean;
  salutation: string;
  lastName: string;
  licenseLevel: string;
  licenseExpires: number;
}

function UserName(props: UserNameProps) {
  return (
    <div className={styles.sideBarTop}>
      <div className={styles.userName}>
        <span>{`${props.salutation} ${props.lastName} ${props.displayDropdown ? '▲' : '▼'}`}</span>
      </div>
      <LicenseStatus licenseLevel={props.licenseLevel} licenseExpires={props.licenseExpires} />
    </div>
  );
}

function UserIconWithBadge() {
  return (
    <div className={styles.userIconWithBadge}>
      <img alt='User' src={userIcon} />
    </div>
  );
}

export default SidebarId;
