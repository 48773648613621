import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedEffect } from '../../CustomHooks';
import styles from './ExpandableContentCard.module.scss';
import { Icon } from '@iconify/react';
import bxsRightArrow from '@iconify-icons/bx/bxs-right-arrow';
import { UrlParams } from '../../../peekapak-types/LessonPlanTypes';

export interface UnitCardProps {
  expandable?: boolean;
  expanded: boolean;
  estimatedTime?: string;
  content: string | string[];
  isLessonFlow?: boolean;
  handleNavigatorClick?: (lessonType: string, lessonId: string) => void;
  title: string;
}

const ExpandableContentCard = ({
  expandable,
  expanded,
  estimatedTime,
  content,
  title,
  isLessonFlow,
  handleNavigatorClick,
}: UnitCardProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [textHeight, setTextHeight] = useState('0px');

  const contentRef = useRef<HTMLDivElement>(null);
  const { lesson }: UrlParams = useParams();

  const toggleAccordion = () => {
    if (expandable) {
      setIsExpanded(!isExpanded);
      setTextHeight(isExpanded || contentRef.current === null ? '0px' : `${contentRef.current.scrollHeight}px`);
    }
  };

  const handleResize = () => {
    const newTextHeight = !isExpanded || contentRef.current === null ? `0px` : `${contentRef.current.scrollHeight}px`;
    setTextHeight(newTextHeight);
  };

  useDebouncedEffect(() => window.addEventListener('resize', handleResize), [handleResize], 1000);

  useEffect(() => {
    if (expanded || isExpanded) setTextHeight(`${contentRef.current?.scrollHeight}px`);
  }, [content, handleNavigatorClick, lesson]);

  return (
    <div className={styles.accordionContainer}>
      <button onClick={toggleAccordion}>
        <h3>{title}</h3>
        <div>
          <Icon icon={bxsRightArrow} width={16} height={16} rotate={isExpanded ? '90deg' : ''} color='#00afd8' />
        </div>
      </button>
      <div className={styles.accordionText} ref={contentRef} style={{ maxHeight: `${textHeight}` }}>
        {!isLessonFlow ? (
          <div
            className={styles.contentContainer}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        ) : (
          <>
            {estimatedTime && <em>{estimatedTime}</em>}
            <ol className={styles.lessonFlowList}>
              {content?.map((x, index) => {
                return (
                  <li
                    key={`Lesson flow ${index}`}
                    onClick={() => handleNavigatorClick && handleNavigatorClick(lesson, x?.data?.id)}
                  >
                    <span>{index + 1}</span>
                    {x?.data?.summary}
                  </li>
                );
              })}
            </ol>
          </>
        )}
      </div>
    </div>
  );
};

export default ExpandableContentCard;
