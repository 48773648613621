import { useHistory } from 'react-router-dom';
import Button from '../Button';
import styles from './ReviewQuestionAnswer.module.scss';

interface Props {
  data: {
    question: string;
    placeholder: string;
    index: number;
    template?: string;
    isMultiline?: boolean;
  }[];
  studentWork: {
    userId: string;
    createdAt: number;
    work: { content: { answerText: string; index: number }[] };
  };
  teacherReview?: boolean;
}

const ReviewQuestionAnswer = ({ data, studentWork, teacherReview }: Props): JSX.Element => {
  const history = useHistory();

  const mergedData = data.map((question) => {
    if (typeof question.index === 'object') {
      const filteredStudentWork = [];
      for (let i = 0; i < question.index.length; i++) {
        const filtered = studentWork?.work?.content?.filter(
          (item) => item.index === question.index[i] && item.answerText && item,
        );
        filtered.length > 0 ? filteredStudentWork.push(filtered[0].answerText) : filteredStudentWork;
      }
      const answer = filteredStudentWork ? filteredStudentWork.join('. ') : filteredStudentWork;
      return { ...question, answerText: answer };
    } else {
      return {
        ...question,
        ...studentWork?.work?.content?.find((item) => item.index === question.index && item.answerText && item),
      };
    }
  });

  return (
    <div className={styles.container}>
      <h3>{teacherReview ? 'Student ' : 'Your '} Responses</h3>
      <ol className={styles.orderedList}>
        {mergedData.map((question, index) => {
          return (
            <div key={`response ${index + 1}`}>
              <li
                className={styles.formList}
                dangerouslySetInnerHTML={{
                  __html: question.question,
                }}
              />
              <div
                className={styles.answerContainer}
                dangerouslySetInnerHTML={{
                  __html: question.answerText,
                }}
              />
            </div>
          );
        })}
      </ol>
      {!teacherReview && (
        <div className={styles.submitButton}>
          <Button onClick={() => history.push('/studentportal/toDos')} darkBlue thinText>
            View ToDos
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReviewQuestionAnswer;
