import styles from './SCSS/Flipbook.module.scss';

export function StorybookChevron(props) {
  function handleTurn() {
    if (props.direction === 'left') {
      props.goToPreviousPage();
    } else {
      props.goToNextPage();
    }
  }

  return (
    <div
      className={`${styles.storybookChevron} ${
        props.direction === 'left' ? styles['chevron-left'] : styles['chevron-right']
      }`}
      onClick={handleTurn}
    >
      <i className={props.direction === 'left' ? `fa fa-chevron-left` : `fa fa-chevron-right`} aria-hidden='true' />
    </div>
  );
}
