import { expireSessionAndRedirect } from '../../aaa';
import { getContentRequest, postServiceRequest } from './../../serviceAgent';
import { getAppRoot } from './../../identity';
import {
  getAuthorizationToken,
  getCoreStandards as gcs,
  getStorySpec,
  getElementaryUnit,
  getCompletionStatus as getCompletionStatusBackend,
  getRecentMessages as getRecentMessagesBackend,
} from '../../BackendInterface';
import { getUsersEmail, getUsersMessageMark } from '../../GlobalFunctions';
import { getStore } from '../../ApplicationState';
import { updatePageTracker } from '../../Session';
import {
  ClassroomCompletionStatus,
  ElementaryUnit,
  ElementaryUnitCompletionStatus,
  PageTracker,
} from '../../../peekapak-types/DataProtocolTypes';

// Get functions
export const getUnit = async (unitInfo: {
  keys: { name: string; version: string };
  lessonMeta: {};
  lastVersion: number;
}): Promise<ElementaryUnit> => {
  try {
    const result = await getElementaryUnit(unitInfo.keys.name);
    return {
      key: 'unit',
      value: result,
    };
  } catch (error) {
    throw error;
  }
};

export const getCompletionStatus = async (
  classroomId: string,
  unitId: string,
): Promise<ElementaryUnitCompletionStatus> => {
  try {
    const result = await getCompletionStatusBackend(classroomId, unitId);
    return result;
  } catch (error) {
    throw error;
  }
};

export const getStory = async (storyId: string) => {
  try {
    const result = await getStorySpec(storyId);
    return result;
  } catch (error) {
    throw error;
  }
};

export const updateUserObjectWithPageTrackingInfo = () => {
  const { pageTracker } = getStore().getState().session;
  const newPageTracker: PageTracker = {};

  if (pageTracker) {
    if (pageTracker.previousPage == null || pageTracker.timeCount == null) {
      newPageTracker.timeCount = 0;
    }

    if (pageTracker.timeEntered) {
      newPageTracker.timeSpentOnPreviousPage = Date.now() - pageTracker.timeEntered;
      newPageTracker.previousPage = pageTracker.currentPage;
      newPageTracker.timeCount += pageTracker.timeSpentOnPreviousPage;
    }

    newPageTracker.refreshCount = 0;
    newPageTracker.currentPage = window.location.pathname;
    newPageTracker.timeEntered = Date.now();
  } else {
    newPageTracker.timeCount = 0;
    newPageTracker.refreshCount = 0;
    newPageTracker.currentPage = window.location.pathname;
    newPageTracker.timeEntered = Date.now();
  }

  getStore().dispatch(updatePageTracker(newPageTracker));
};

export const getMessages = async () => {
  try {
    const email = getUsersEmail();
    const messageMark = getUsersMessageMark() ?? Date.now();
    const afterTime = new Date().getTime() - 1000 * 60 * 60 * 24 * 30; // 30 days
    const result = await getRecentMessagesBackend(email!, afterTime);
    if (!result || result.length === 0) {
      return {
        newMessages: [],
        messageMark,
      };
    }
    return {
      newMessages: result.sort((a: { date: number }, b: { date: number }) => b.date - a.date),
      messageMark,
    };
  } catch (error) {
    throw error;
  }
};

export const getCoreStandardsObject = async () => {
  try {
    const result = await gcs();
    return result;
  } catch (error) {
    throw error;
  }
};
