import RedeemLicenseCodeForm from './components/RedeemLicenseCodeForm';
import styles from './SCSS/RedeemCode.module.scss';

const RedeemCode = () => {
  return (
    <div className={styles.redeemCode}>
      <div className={styles.title}>Redeem License Code</div>
      <div className={styles.text}>
        To upgrade your account, please input or copy and paste the license code you were provided into the space below.
        The code will be validated, and if valid you can click on the Upgrade button to upgrade your account.
      </div>
      <RedeemLicenseCodeForm buttonText={'Upgrade'} />
    </div>
  );
};

export default RedeemCode;
