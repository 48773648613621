import React from 'react';
import { Field, ErrorMessage } from 'formik';
import cx from 'classnames';
import TextError from './TextError';
import styles from './FormikStyles.module.scss';

interface Props {
  className?: string;
  options: string[];
  label: string;
  name: string;
  isrequired?: boolean;
}

function CheckboxGroup(props: Props): JSX.Element {
  const { label, name, options, ...rest } = props;
  return (
    <div className={cx(styles.checkboxGroup, props.className)}>
      <label className={cx({ [`${styles.required}`]: props.isrequired })}>{label}</label>
      <div className={styles.checkboxesContainer}>
        <Field name={name} {...rest}>
          {({ field }: any) => {
            return options.map((option: any) => {
              return (
                <React.Fragment key={option.key}>
                  <input
                    type='checkbox'
                    id={option.value}
                    {...field}
                    value={option.value}
                    checked={field.value.includes(option.value)}
                  />
                  <label htmlFor={option.value}>{option.key}</label>
                </React.Fragment>
              );
            });
          }}
        </Field>
      </div>
      <div className={styles.errorHintContainer}>
        <ErrorMessage name={name} component={TextError} />
      </div>
    </div>
  );
}

export default CheckboxGroup;
