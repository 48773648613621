import { parsePhoneNumberWithError, ParseError, CountryCode, PhoneNumber } from 'libphonenumber-js';
import { logger } from './error-tracker';
import { getConfiguration } from './GlobalFunctions';
import { getStore } from './ApplicationState';
import { LoginState } from './UserManagement';
import countries from 'i18n-iso-countries';
import countriesInEnglish from 'i18n-iso-countries/langs/en.json';
const suppressStudentData = true;
countries.registerLocale(countriesInEnglish);

export const bootupIntercom = () => {
  if (import.meta.env.MODE !== 'development' && isShouldLog()) {
    const intercomAppId = getConfiguration('intercom').apiKey;
    window.Intercom('boot', {
      app_id: intercomAppId,
    });
  }
};

export const loginIntercomUser = (userProfile): Promise<void> => {
  return new Promise((resolve, reject) => {
    const newUserProfile = fixUpBadData(userProfile);

    if (!isShouldLog()) return resolve();

    const {
      email,
      tel,
      createdAt,
      country,
      grade,
      licenseLevel,
      role,
      licenseExpires,
      lastAccessTime,
      fullName,
      optOut,
      userId,
      type,
      schoolId,
      district,
      schoolName,
      placeId,
    } = newUserProfile;

    const intercomSettings = {
      email,
      phone: tel,
      unsubscribed_from_emails: optOut,
      name: fullName,
      country,
      grade,
      licenseLevel,
      role,
      type,
      user_id: userId,
    };

    setCompany();
    setMiscellaneous();

    window.Intercom('update', intercomSettings);
    return resolve();

    function setMiscellaneous() {
      if (createdAt) {
        intercomSettings.signed_up_at = createdAt;
      }

      if (lastAccessTime) {
        intercomSettings.last_request_at = lastAccessTime;
      }

      if (licenseExpires) {
        intercomSettings.licenseExpires_at = licenseExpires;
      }

      if (schoolId) {
        intercomSettings.schoolId = schoolId;
      }

      if (district) {
        intercomSettings.district = district;
      }
    }

    function setCompany() {
      if (schoolId || placeId) {
        intercomSettings.company = {
          name: getSchoolName(),
          company_id: schoolId || placeId,
        };
      }

      function getSchoolName() {
        return schoolName || 'Unknown';
      }
    }
  });
};

export const logoutIntercomUser = () => {
  return new Promise((resolve) => {
    // TODO: this is a workaround, because using
    // shutdown to clear the message history for
    // privacy also shuts down Intercom causing
    // the next login and subsequent activities
    // to no longer be logged by Intercom.
    // Awaiting fix from Intercom.
    //
    // So right now, we'll shutdown to clear,
    // and hopefully immediately boot again to
    // reactivate Intercom again
    if (isShouldLog()) {
      window.Intercom('shutdown');
      bootupIntercom();
    }

    return resolve();
  });
};

export const showIntercom = () => {
  return new Promise((resolve) => {
    if (isShouldLog()) {
      window.Intercom('show');
    }
    return resolve();
  });
};

export const showIntercomNewMessage = (messageUserWillSend) => {
  if (isShouldLog()) {
    window.Intercom('showNewMessage', messageUserWillSend);
  }
};

export const updateIntercomOnRouteChange = () => {
  return new Promise((resolve) => {
    window.Intercom('update');
    return resolve();
  });
};

const convertToSeconds = (milliseconds) => Math.round(milliseconds / 1000);

function fixUpBadData(userProfile) {
  let newUserProfile = normalizeDataFormat(userProfile);
  newUserProfile = fixUpInconsistentOptIn(newUserProfile);
  newUserProfile = createFullName(newUserProfile);
  newUserProfile = fixAllTimestamps(newUserProfile);
  newUserProfile = normalizePhoneNumber(newUserProfile);

  return newUserProfile;
}

function fixUpInconsistentOptIn(record) {
  const newRecord = { ...record };
  // fix up optIn and optout inconsistencies
  if (
    record.optIn === undefined ||
    record.optIn.length === 0 ||
    (record.optIn !== undefined && record.optIn === 'on')
  ) {
    if (record.optout === undefined || record.optout.length === 0) {
      // both don't exist
      newRecord.optIn = true;
    } else {
      // optIn doesn't exist and optout was non-empty, so optout === true is inferred
      newRecord.optIn = false;
    }

    newRecord.optOut = !newRecord.optIn;
  } else if (record.optIn !== undefined && (record.optIn === true || record.optIn === false)) {
    newRecord.optOut = !record.optIn;
  }

  return newRecord;
}

function createFullName(record) {
  const newRecord = { ...record };
  newRecord.fullName = `${record.firstName} ${record.lastName}`;

  return newRecord;
}

function fixAllTimestamps(record) {
  const { lastAccessTime, licenseExpires, lastModifiedAt, modifiedAt, createdAt } = record;

  const newRecord = { ...record };

  if (lastAccessTime) {
    newRecord.lastAccessTime = convertToSeconds(lastAccessTime);
  }

  if (licenseExpires && licenseExpires > 1924819260000) {
    newRecord.licenseExpires = convertToSeconds(1924819260000);
  } else if (!licenseExpires) {
    newRecord.licenseExpires = convertToSeconds(1924819260000);
  } else {
    newRecord.licenseExpires = convertToSeconds(newRecord.licenseExpires);
  }

  if (lastModifiedAt) {
    newRecord.lastModifiedAt = convertToSeconds(lastModifiedAt);
  }

  if (modifiedAt) {
    newRecord.modifiedAt = convertToSeconds(modifiedAt);
  }

  if (createdAt) {
    newRecord.createdAt = convertToSeconds(createdAt);
  }

  return newRecord;
}

function normalizeDataFormat(record) {
  const newRecord = { ...record };

  Object.keys(record).forEach((key) => {
    newRecord[key] = trimStringAttribute(record, key);
  });

  return newRecord;
}

function normalizePhoneNumber(record) {
  const newRecord = { ...record };

  if (!record.tel) {
    return newRecord;
  } else if (record.tel === 'undefined' || record.tel === 'null' || record.tel === 'Not provided') {
    newRecord.tel = '';
    return newRecord;
  }

  let alpha2code: CountryCode = record.country ? (countries.getAlpha2Code(record.country, 'en') as CountryCode) : 'US';

  if (!alpha2code) {
    alpha2code = 'US';
  }

  try {
    const phone = parsePhoneNumberWithError(record.tel, alpha2code);
    newRecord.tel = phone.formatInternational();
  } catch (exception) {
    newRecord.tel = `Bad: ${record.tel}, ${alpha2code}, ${exception}`;
  }

  try {
    const phoneNumber = parsePhoneNumberWithError(record.tel, alpha2code.toUpperCase() as CountryCode);
    newRecord.tel = phoneNumber.formatInternational();
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      const message = `Bad: ${record.tel}, ${alpha2code}, ${error.message}`;
      newRecord.tel = message;
      logger.logMessage(message);
    } else {
      throw error;
    }
  }

  return newRecord;
}

function trimStringAttribute(record, key) {
  if (typeof record[key] === 'string' || record[key] instanceof String) {
    return record[key].trim();
  }

  return record[key];
}

function isShouldLog() {
  const { loginState } = getStore().getState().user;

  if (loginState !== LoginState.loggedIn) {
    return true;
  }

  const { userProfile } = getStore().getState().user;

  if (userProfile.type === 'Student' && suppressStudentData) {
    return false;
  }

  return true;
}
