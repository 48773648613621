import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ToDoContent from '../ToDoContent';
import { SpinnerOverlay } from '../../SpinnerOverlay';
import { retrieveAssignment } from '../../BackendInterface';
import { secondarySchoolLessonNumberLookup } from '../../GlobalFunctions';
import styles from './TeacherReviewModal.module.scss';
import { Assignment, UrlParams } from '../../../peekapak-types/LessonPlanTypes';

interface Props {
  assetImage?: string;
  studentId: string;
  title: string;
  createdAt: number;
  displayData: Record<string, unknown>;
  firstName: string;
  lastName: string | undefined;
  timeSubmitted: string | 0 | undefined;
  handleCloseModal: (e: React.MouseEvent) => void;
}

const unitNumberLookup = (unitName: string) => {
  const unitNumbers: { [key: string]: number } = {
    welcome: 0,
    identity: 1,
    stress: 2,
    decision: 3,
    conflict: 4,
    empathy: 5,
  };
  return unitNumbers[unitName];
};

const TeacherReviewModal = ({
  assetImage,
  studentId,
  title,
  createdAt,
  displayData,
  firstName,
  lastName,
  timeSubmitted,
  handleCloseModal,
}: Props): JSX.Element => {
  const [assignment, setAssignment] = useState<Assignment | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { unitId, lesson, grade }: UrlParams = useParams();
  const isHighSchool = parseInt(grade) > 8;

  const getToDo = useCallback(async () => {
    setIsLoading(true);
    try {
      const toDoPayload: Assignment[] = await retrieveAssignment(studentId, createdAt);
      setAssignment(toDoPayload[0]);
    } catch {
      console.error('Student work does not exist for this assignment');
    } finally {
      setIsLoaded(true);
      setIsLoading(false);
    }
  }, [assignment, isLoaded]);

  useEffect(() => {
    !isLoaded && getToDo();
  }, [assignment, isLoaded]);

  return (
    <div className={styles.container}>
      <SpinnerOverlay isShow={isLoading} />
      <div className={styles.titleRow}>
        <div className={styles.contextInfo}>
          <h4>
            Unit {unitNumberLookup(unitId)}, Lesson {secondarySchoolLessonNumberLookup(lesson, isHighSchool)}
          </h4>
          <h2>{title}</h2>
        </div>
        <div className={styles.closeX} onClick={(e) => handleCloseModal(e)}>
          x
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.studentInfo}>
          <div className={styles.avatar}>
            {firstName?.charAt(0)}
            {lastName?.charAt(0)}
          </div>
          <div className={styles.infoColumn}>
            <h2>
              {firstName} {lastName}
            </h2>
            <p>{timeSubmitted}</p>
          </div>
        </div>
        <div className={styles.contentSheet}>
          {isLoaded && (
            <ToDoContent
              content={displayData}
              studentWork={assignment}
              teacherReview
              assetImage={assetImage}
              // notClickable
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherReviewModal;
