import ExternalProviderButton from './ExternalProviderButton';
import cleverLogo from '../../images/logos/logo-clever.png';
import styles from './ExternalProviderButton.module.scss';

interface Props {
  onClick: () => unknown;
  disable?: boolean;
}

const CleverSignUpButton = (props: Props): JSX.Element => (
  <ExternalProviderButton className={styles.cleverButton} onClick={props.onClick} disable={props.disable}>
    <img className={styles.logoImage} src={cleverLogo} alt='clever logo' />
    Register with Clever
  </ExternalProviderButton>
);

export default CleverSignUpButton;
