import { Component } from 'react';
import { areAnyStepsInProgressInLesson, areAnyStepsNotStartedInLesson } from '../completionStatus';
import { ActivityNavigatorCard } from './ActivityNavigatorCard';
import { getLetterGradeFromNumberGrade, getUnitTitleForDisplay } from '../GlobalFunctions';
import { openUnitOverview } from './UtilityFunctions';
import styles from '../SCSS/ActivityProgressBar.module.scss';
import storybookIcon from '../images/pkp-readStoryIcon-blue.svg';
import { ClassroomType, ElementaryUnit } from '../../peekapak-types/DataProtocolTypes';

export interface ProgressBarData {
  color: string;
  backgroundColor: string;
  cursor: string;
  boxShadow: string;
  borderColor: string;
}

interface Props {
  classroom: ClassroomType;
  unitMeta: ElementaryUnit['value']['lessonPlan'];
  completionStatus: string[][][];
}

interface State {
  isShowTooltip: boolean;
  width: number;
  height: number;
}

export class ActivityNavigator extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isShowTooltip: false,
      width: 0,
      height: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  showTooltip() {
    this.setState({
      isShowTooltip: true,
    });
  }

  hideTooltip() {
    this.setState({
      isShowTooltip: false,
    });
  }

  openUnitOverview = () => {
    const classroomName = this.props.classroom.className;
    const unitId = `${this.props.unitMeta.title}-${getLetterGradeFromNumberGrade(this.props.classroom.grade)}`;

    openUnitOverview(classroomName, unitId);
  };

  render() {
    const progressBarDataArray: ProgressBarData[] = [];
    const isMobile = this.state.width <= 1024;

    const cs: string[][][] = this.props.completionStatus || [
      [
        ['NotStarted', 'NotStarted'],
        ['NotStarted', 'NotStarted'],
      ],
      [
        ['NotStarted', 'NotStarted'],
        ['NotStarted', 'NotStarted'],
      ],
      [
        ['NotStarted', 'NotStarted'],
        ['NotStarted', 'NotStarted'],
      ],
      [
        ['NotStarted', 'NotStarted'],
        ['NotStarted', 'NotStarted'],
      ],
    ];

    cs.forEach((subunit, index) => {
      subunit.forEach((_lessonArray, lessonIndex) => {
        if (
          areAnyStepsInProgressInLesson(cs, {
            subunit: index,
            lesson: lessonIndex,
          })
        ) {
          progressBarDataArray.push({
            color: '#00afd8',
            backgroundColor: '#00afd8',
            cursor: 'pointer',
          });
        } else if (
          areAnyStepsNotStartedInLesson(cs, {
            subunit: index,
            lesson: lessonIndex,
          })
        ) {
          progressBarDataArray.push({
            color: '#ebedef',
            backgroundColor: '#ebedef',
            cursor: 'pointer',
          });
        } else {
          progressBarDataArray.push({
            color: '#00afd8',
            backgroundColor: '#00afd8',
            cursor: 'pointer',
          });
        }
      });
    });

    const activityNavigatorCards: Array<typeof ActivityNavigatorCard> = [];

    generateActvityNavigatorCard(this.props, cs);

    function generateActvityNavigatorCard(props: Props, cs: string[][][]) {
      let cardIndex = 0;
      let subunitIndex = 0;
      while (subunitIndex < cs.length) {
        const subunit = cs[subunitIndex];
        let lessonIndex = 0;
        while (lessonIndex < subunit.length) {
          activityNavigatorCards.push(
            <ActivityNavigatorCard
              {...props}
              subunitLength={props.unitMeta.subUnits[0].lessons.length}
              subunitIndex={subunitIndex}
              lessonIndex={lessonIndex}
              cardIndex={cardIndex++}
              isMobile={isMobile}
              progressBarDataArray={progressBarDataArray}
              key={`${subunitIndex}, ${lessonIndex}`}
            />,
          );

          lessonIndex += 1;
        }

        subunitIndex += 1;
      }
    }
    const unitName = getUnitTitleForDisplay(this.props.unitMeta.title);

    return (
      <div className={styles.activityProgressContainer}>
        <img
          alt='Storybook'
          src={storybookIcon}
          className={styles.unitOverviewGraphicLink}
          onClick={this.openUnitOverview}
        />
        <div className={styles.unitOverviewTextLink} onClick={this.openUnitOverview}>
          <div>{unitName}</div>
        </div>
        <div className={styles.lessonBubblesContainer}>
          <div className={styles.navigationChain} />
          <div className={styles.lessonBubbles}>{activityNavigatorCards}</div>
        </div>
      </div>
    );
  }
}
